/**
 *  budgerConcepts.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de presupuestos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Paginador from "../components/paginador/paginador";
import BudgetConceptTable from "../components/budgetConcept/budgetConcept"
import ReactModal from "react-modal"
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css'
// import "../css/style.css"
import DropdownTreeContainer from "./DropdownTreeContainer";

const request = new Request();

class BudgerConcepts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			idFraccionamiento: 0,
			conceptos: [],
			egr_conceptos: [],
			id_concepto_padre: 0,
			nombre: '',
			presupuesto: 0,
			cuenta_contable: '',
			page: 1,
			filter: '',
			subfraccionamiento: localStorage.getItem('subfrac'),
			loadingSave: false,
			escritura: false,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			pageLength: 20,
			direction: {
				descripcion: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			loading_egr_Conceptos: false
		}

		this.validarPermisos();
	}

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
		//console.log(permisosInfo);

	}

	openModal(idModal) {
		this.setState({ message: null });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		document.getElementById("conceptname").focus();
	}
	closeModal(idModal) {
		this.setState({ message: null });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	reload() {
		this.getConceptos()
		this.get_EGR_Conceptos()
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	async paginatorChange(length) {
		await this.setState({ pageLength: length });
		this.setPage(1);
	}

	changeFrac() {
		this.getConceptos()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	nuevoConcepto() {
		this.setState({ showModal: true });
	}

	setFilter(filter) {
		this.setState({ filter });
	}

	filter(conceptos) {
		conceptos = conceptos.filter(con => {
			if (con.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return conceptos;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.conceptos, this.state.direction[key]);

		this.setState({
			conceptos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {

		let conceptos = [];

		if (Array.isArray(this.state.egr_conceptos[0])) {
			conceptos = this.state.egr_conceptos[0];
			//conceptos 	= this.filter(conceptos);
		}

		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header sidebar={true} active={'presupuestoConcepto'} parent={'presup'} panel={'panel2'} nav={"administracion"} change={this.changeFrac.bind(this)} reload={this.reload.bind(this)} />
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						{this.state.lectura ?
							<div className="justify-center">
								<div className="container column">
									<Controls title={this.state.submenu}
										nuevo={this.openModal.bind(this, "newBudgetConcept")}
										dataSet={conceptos}
										setFilter={this.setFilter.bind(this)}
										subChange={this.subChange.bind(this)}
										selectSub={false}
										newButton={this.state.escritura}
									/>
									{this.state.loading_egr_Conceptos ?
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
										:
										<BudgetConceptTable
											conceptos={conceptos}
											page={this.state.page}
											reload={this.reload.bind(this)}
											escritura={this.state.escritura}
											pageLength={this.state.pageLength}
											sortBy={this.sortableTable.bind(this)}
											filterColumn={this.state.filterColumn}
											treeValue={this.state.egr_conceptos}
										></BudgetConceptTable>
									}
									<div className="white-space-16" />
									{/* <Paginador 
								pages={Math.ceil(conceptos.length / this.state.pageLength)} 
								setPage={this.setPage.bind(this)} 
								pageLength = {this.state.pageLength} 
								paginatorChange = {this.paginatorChange.bind(this)} 
								showPageLenght = {true}
								/> */}
								</div>
							</div> :
							null
						}

					</div>
					{/*</div>*/}
				</div>


				<DinamicModal
					idModal={'newBudgetConcept'}
					sizeModalContainer={'small'}
					title={'Añadir concepto'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					closeModal={this.resetValue.bind(this)}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.state.egr_conceptos.length > 0 ? this.renderNewBudgetConcept() : ''}
				</DinamicModal>
			</div>
		);
	}

	componentDidMount() {
		this.getConceptos();
		this.get_EGR_Conceptos();
		this.getSubmenu();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	resetValue() {
		this.setState({ nombre: '' })
		this.setState({ cuenta_contable: '' })
		this.setState({ presupuesto: 0 })
		this.setState({ id_concepto_padre: 0 })
	}
	componentDidUpdate() {

	}

	data = [
		{
			label: "Nomina",
			data: { nombre: "Nomina" },
			children: [
				{
					label: "Administracion",
					data: { nombre: "Administracion" },
					children: [
						{ label: "Internos" },
						{ label: "Externos" },
						{ data: { nombre: "Internos" } },
						{ data: { nombre: "Externos" } },

					]
				},
				{
					label: "Seguridad",
					data: { nombre: "Seguridad" },
					children: [
						{ label: "Caseta" },
						{ label: "Puerta Proveedores" },
						{ data: { nombre: "Caseta" } },
						{ data: { nombre: "Puerta proveedores" } }
					]
				},
				{
					label: "Mantenimientos",
					data: { nombre: "Mantenimientos" },
					children: [
						{ label: "Jardines" },
						{ label: "Albercas" },
						{ label: "Alumbrado publico" },
						{ data: { nombre: "Jardines" } },
						{ data: { nombre: "Albercas" } },
						{ data: { nombre: "Alumbrado publico" } },
					]
				}
			]
		}
	];

	onChange = (currentNode, selectedNodes) => {
		//console.log('onChange::', currentNode, selectedNodes);
		if (selectedNodes[0]) {
			console.log(selectedNodes[0].id);
			if (selectedNodes[0]._depth < 2)
				this.setState({ id_concepto_padre: selectedNodes[0].id });
			else {
				this.setState({ id_concepto_padre: selectedNodes[0].id_parent });
			}
		} else {
			this.setState({ id_concepto_padre: 0 });
		}


	}

	onAction = (node, action) => {
		console.log('onAction::', action, node)
	}
	onNodeToggle = currentNode => {
		console.log('onNodeToggle::', currentNode)
	}

	renderNewBudgetConcept() {
		return (
			<div className="full row align-center">
				<div className="column full">

					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p><b>Nivel:	</b></p>
						</div>
						<div className="column full">
							<DropdownTreeContainer data={this.state.egr_conceptos[0]} mode="radioSelect"
								onChange={this.onChange} texts={{ placeholder: 'Seleccione un nivel' }} />
						</div>
						<div className="column label-duo-normal align-end">

						</div>
						<div className="column full">

						</div>

					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p><b>Partida:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="conceptName"
								id="conceptname"
								value={this.state.nombre}
								onChange={event => this.setState({ nombre: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
								required
							/>
						</div>
						<div className="column label-duo-normal align-end">
							<label className="container-check">
								<input
									type="checkbox"
									id="presupuesto"
									checked={this.state.presupuesto === 1 ? true : false}
									name="presupuesto"
									onChange={(event) => {
										//console.log(this.state.residente);
										this.setState({ presupuesto: event.target.checked === true ? 1 : 0 });
									}}
								/>
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column full">
							<h5>
								{" "}
								<b>Mostrar en la App</b>{" "}
							</h5>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p><b>Cuenta contable:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="cuenta_contable"
								id="cuenta_contable"
								value={this.state.cuenta_contable}
								onChange={event => this.setState({ cuenta_contable: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
							/>
						</div>
						<div className="column label-duo-normal align-end">

						</div>
						<div className="column full">

						</div>
					</div>
					<div className="white-space-8" />
				</div>
			</div>

		)
	}

	handleOpen() {
		this.setState({ showModal: !this.state.showModal, nombre: '', cuenta_contable: '', presupuesto: 0 });

		this.getFraccionamiento()

	}
	async handleSubmit(event) {
		this.setState({ loadingSave: true, message: '' });
		event.preventDefault();
		let data = {
			id_concepto_padre: this.state.id_concepto_padre,
			nombre: this.state.nombre,
			presupuesto: this.state.presupuesto,
			cuenta_contable: this.state.cuenta_contable,
			id_fraccionamiento: localStorage.getItem('frac')

			//idFrac: localStorage.getItem('frac')
		}
		const response = await request.post("/conceptos/concepto/create", data);
		if (response.created) {
			this.setState({ showModal: false, nombre: '', cuenta_contable: '', presupuesto: 0, id_concepto_padre: 0 });
			this.closeModal("newBudgetConcept");
			this.reload();
			cogoToast.success('Concepto agregado.', {
				position: 'bottom-right',
			});
		} else {
			//this.setState({message: "NO SE PUDO AGREGAR CONCEPTO"});
			cogoToast.error('No se pudo agregar concepto.', {
				position: 'bottom-right',
			});
		}
		this.setState({ loadingSave: false, nombre: '' });
	}

	async getFraccionamiento() {
		let data = { IdFraccionamiento: localStorage.getItem('frac') };

		const response = await request.post("/admin/administracion/get/fraccionamiento", data);

		if (response && !response.error) {
			if (response.fraccionamiento && !response.empty) {
				this.setState({
					nombreFrac: response.fraccionamiento[0].nombre,
					idFraccionamiento: response.fraccionamiento[0].id_fraccionamiento
				});
			} else {
				this.setState({
					empty: true,
					//message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				//message: response.message
			});

		}
	}

	async getConceptos() {
		this.setState({ conceptos: [], loadingConceptos: true });
		if (!localStorage.getItem('frac')) {
			return
		} else {
			let data = { idFraccionamiento: localStorage.getItem('frac') };
			const response = await request.post("/presupuestos/get/all", data);
			if (response && !response.error) {
				if (response.conceptos && !response.empty) {
					this.setState({
						conceptos: response.conceptos
					});
				} else {
					this.setState({
						empty: true,
						//message: response.message
					});
				}
			} else {
				this.setState({ error: true/*, message: response.message*/ });

			}
		}
		this.setState({ loadingConceptos: false });
	}

	async get_EGR_Conceptos() {
		this.setState({ egr_conceptos: [], loading_egr_Conceptos: true });

		let data = { id_fraccionamiento: localStorage.getItem('frac'), arbol: true };
		const response = await request.post("/conceptos/get/all", data);
		if (response && !response.error) {
			if (response.conceptos && !response.empty) {
				this.setState({
					egr_conceptos: response.conceptos
				});
			} else {
				this.setState({
					empty: true,
					//message: response.message
				});
			}
		} else {
			this.setState({ error: true/*, message: response.message*/ });

		}

		this.setState({ loading_egr_Conceptos: false });
	}
}

export default Consumer(BudgerConcepts);