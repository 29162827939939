import React, { Component } from "react";
import { Consumer } from "../../context";
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarAdministracion from '../../components/header/sidebarAdministracion';
import SuperCore from '../../components/core/SuperCore';
import Chart from "chart.js";
import moment from "moment";
import cogoToast from 'cogo-toast';
import Request from "../../core/httpClient";
import Paginador from "../../components/paginador/paginador";

const request = new Request();

const ReportSheet = (props) => {
    return (
        <React.Fragment key={props.key}>
            <div className="reporter_sheet" style={{ width: '100%', height: '100%' }}>
                <div className="reporter_content">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
}

class DashboardDocs extends SuperCore {
    constructor(props) {
        super(props);
        this.state = {
            docs: [],
            page: 1,
            subfraccionamiento: 0,
            subfraccionamientos: [],
            filter: '',
            id_sub: parseInt(localStorage.getItem('subfrac')) || 0,
            id_sub_init: parseInt(localStorage.getItem('subfrac')) || 0,
            id_month: 0,
            year: 0,
            startDate: moment().add(-7, 'day').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            empty: true,
            excelDisabled: true,
            pdfDisabled: true,
            print: true,
            pageLength: 20,
            selected: {},
            unidades: [],
            submenu: '',
            subLabel: ''
        }

    }

    componentDidMount() {
        this.getSubmenu();
        this.getSubFraccionamientos();
        this.getReports();
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuAdminName();
        if (submenu) {
            this.setState({ submenu });
        }

        const subLabel = window.localStorage.getItem('subLabel');
        if (subLabel) {
            this.setState({ subLabel: subLabel.toUpperCase() });
        }
    }

    async getUnidades(idSub) {
        await this.setState({ unidades: [] });
        let data = {
            id_fraccionamiento: localStorage.getItem('frac'),
            id_subfraccionamiento: idSub
        }
        const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);
        if (response.empty || response.error) {
            await this.setState({ unidades: [] });
            cogoToast.info('No se encontraron viviendas en este fraccionamiento.', {
                position: 'bottom-right',
            });
            return;
        }

        if (response.viviendas) {
            //console.log(response.viviendas);
            await this.setState({
                unidades: response.viviendas
            })
        }
    }

    async getReport(event) {
        event.preventDefault();

        this.setState({ loading: true, page: 1 });
        const reportSelected = this.state.selected;

        const startdate = event.target.startdate.value;
        const enddate = event.target.enddate.value;
        const sp = reportSelected.consulta;
        const baseDatos = reportSelected.baseDatos;
        const frac = window.localStorage.getItem('frac');

        if (frac) {
            const data = {
                start: startdate,
                end: enddate, sp,
                id_frac: parseInt(frac),
                id_subfrac: this.state.id_sub,
                id_vivienda: this.state.id_unidad,
                baseDatos: baseDatos
            };
            const res = await request.post('/reportes/get', data);
            //console.log(res);
            if (res.reports) {
                this.setState({ data: this.filter(res.reports), empty: false, loading: false, print: false, excelDisabled: false, pdfDisabled: false });
            }
            if (res.empty) {
                this.setState({ data: [], empty: res.empty, loading: false, print: true, excelDisabled: true, pdfDisabled: true });
            }
        }
    }

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    async paginatorChange(length) {
        await this.setState({ pageLength: length });
        this.setPage(1);
    }

    startChange(event) {
        this.setState({ startDate: event.target.value })
    }

    reportsChange(event) {
        // se obtiene el reporte
        let selected = this.state.tipos.filter((item) => parseInt(item.id_reporte) === parseInt(event.target.value));
        //console.log(selected);
        // se guarda en estado
        this.setState({ selected: selected[0] });
    }

    endChange(event) {
        this.setState({ endDate: event.target.value })
    }

    subChange(event) {
        const idSub = event.target.value;
        this.setState({ id_sub: event.target.value });

        if (idSub === "0") {
            this.setState({ unidades: [], id_unidad: 0 });
        } else {
            this.getUnidades(idSub);
        }
    }

    async ExportPdf() {
        this.setState({ pdfDisabled: true });
        let dataSet = this.state.data;
        let columns = '';
        let rows = '';
        // Definición de columnas
        let excelColumnsShow = dataSet[0].excelColumnsShow;
        if (excelColumnsShow) {
            //Si el store tiene formato de excel
            excelColumnsShow = excelColumnsShow.split(',');
            let excelColumnsName = dataSet[0].excelColumnsName;
            excelColumnsName = excelColumnsName.split(',');
            let excelColumnsType = dataSet[0].excelColumnsType;
            excelColumnsType = excelColumnsType.split(',');

            const cols = excelColumnsName;
            cols.forEach(col => {
                columns = columns + `<th>${col}</th>`
            });
            dataSet.forEach(row => {
                let newRow = '';
                excelColumnsShow.forEach((col, colIndex) => {
                    if (excelColumnsType[colIndex] === 'image') {
                        if (row[col].indexOf('data:image/') !== -1 || row[col].indexOf('http://') !== -1 || row[col].indexOf('https://') !== -1) {
                            newRow = newRow + `<td><img src="${row[col]}" width="150"/></td>`
                        } else {
                            newRow = newRow + `<td><img src="data:image/png;base64,${row[col]}" alt="reportImage" width="150"></img></td>`
                        }
                    } else if (excelColumnsType[colIndex] === 'date' || excelColumnsType[colIndex] === 'datetime') {
                        if (moment(row[col]).isValid()) {
                            newRow = newRow + `<td>${moment(row[col]).format('DD/MM/YYYY hh:mm A')}</td>`;
                        } else {
                            newRow = newRow + `<td>-</td>`;
                        }
                    }
                    else {
                        newRow = newRow + `<td>${row[col] || ''}</td>`
                    }
                })
                rows = rows + `<tr>${newRow}</tr>`;
            });
        } else {
            const cols = Object.keys(dataSet[0]);
            cols.forEach(col => {
                columns = columns + `<th>${col}</th>`
            });

            dataSet.forEach(row => {
                const newRow = cols.map(col => `<td>${row[col] || ''}</td>`)
                rows = rows + `<tr>${newRow}</tr>`;
            });
        }

        const html = `<html>
            <head>
                <title>Appmosphera</title>
                <meta charset="utf8">
                <style>
                    body {
                        font-family: "Mukta", sans-serif;
                        font-size: 12px;
                    }
                    table {
                        width: 100%;
                        height: 100%;
                    }
                    th {
                        white-space: nowrap;
                        font-size: 11px;
                    }
                    td {
                        color: #4e4a4a;
                        font-size: 10px;
                    }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            ${columns}
                        </tr>
                    </thead>
                    <tbody>
                        ${rows}
                    </tbody>
                </table>
            </body></html>`
        const data = {
            info: dataSet,
            html: html
        };
        const response = await request.post('/pdf/exportar/reporte', data);
        const { buffer } = response;
        if (buffer) {
            cogoToast.success('Generando archivo.', {
                position: 'bottom-right'
            });
            let titulo = 'Appmosphera';
            const select = document.getElementById('report-select');
            if (select) {
                titulo = select.options[select.selectedIndex].text;;
            }
            const bytes = new Uint8Array(buffer.data);
            const blob = new Blob([bytes]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const fileName = `${titulo}_${moment().format('YYMMDDHHmm')}.pdf`;
            link.download = fileName;
            link.click();
        } else {
            cogoToast.error(response.message || 'No se pudo crear el documento de excel.', {
                position: 'bottom-right'
            });
        }
        this.setState({ pdfDisabled: false });
    }

    async ExportExcel() {
        this.setState({ excelDisabled: true, print: true });
        let dataSet = this.state.data;
        // Definición de columnas
        if (!dataSet[0].excelColumnsShow) {
            cogoToast.error('No se encontraron definición de columnas en el reporte.', {
                position: 'bottom-right'
            });
            this.setState({ excelDisabled: false, print: false });
            return;
        }
        let excelColumnsShow = dataSet[0].excelColumnsShow;
        excelColumnsShow = excelColumnsShow.split(',');
        let excelColumnsName = dataSet[0].excelColumnsName;
        excelColumnsName = excelColumnsName.split(',');
        let excelColumnsType = dataSet[0].excelColumnsType;
        excelColumnsType = excelColumnsType.split(',');
        // Construir las filas
        let rows = [];
        dataSet.forEach(element => {
            const object = {};
            excelColumnsShow.forEach((columnShow, index) => {
                const column = excelColumnsName[index];
                const row = element[columnShow];
                object[column] = row;
            });
            rows.push(object);
        });
        //Datos a enviar al API
        const { context } = this.props;
        let titulo = 'Appmosphera';
        const select = document.getElementById('report-select');
        if (select) {
            titulo = select.options[select.selectedIndex].text;;
        }
        dataSet = {
            columns: excelColumnsName,
            rows,
            excelColumnsType,
            titulo: titulo.toUpperCase(),
            fraccionamiento: context.fraccionamiento || 'Appmosphera',
            usuario: context.usuario || '',
            fecha: moment().format('DD/MM/YYYY HH:mm'),
            tz: moment.tz.guess()
        };
        const data = {
            info: dataSet
        }
        const response = await request.post('/excel/exportar/reporte', data);
        const { buffer } = response;
        if (buffer) {
            cogoToast.success('Generando archivo.', {
                position: 'bottom-right'
            });
            const bytes = new Uint8Array(buffer.data);
            const blob = new Blob([bytes]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const fileName = `${titulo}_${moment().format('YYMMDDHHmm')}.xlsx`;
            link.download = fileName;
            link.click();
        } else {
            cogoToast.error(response.message || 'No se pudo crear el documento de excel.', {
                position: 'bottom-right'
            });
        }
        this.setState({ excelDisabled: false, print: false });
    }

    PrintElem() {
        let columns = '';
        let rows = '';
        let dataSet = this.state.data;
        // Definición de columnas
        let excelColumnsShow = dataSet[0].excelColumnsShow;
        if (excelColumnsShow) {
            //Si el store tiene formato de excel
            excelColumnsShow = excelColumnsShow.split(',');
            let excelColumnsName = dataSet[0].excelColumnsName;
            excelColumnsName = excelColumnsName.split(',');
            let excelColumnsType = dataSet[0].excelColumnsType;
            excelColumnsType = excelColumnsType.split(',');

            const cols = excelColumnsName;
            cols.forEach(col => {
                columns = columns + `<th>${col}</th>`
            });
            dataSet.forEach(row => {
                let newRow = '';
                excelColumnsShow.forEach((col, colIndex) => {
                    if (excelColumnsType[colIndex] === 'image') {
                        if (row[col].indexOf('data:image/') !== -1 || row[col].indexOf('http://') !== -1 || row[col].indexOf('https://') !== -1) {
                            newRow = newRow + `<td><img src="${row[col]}" width="150"/></td>`
                        } else {
                            newRow = newRow + `<td><img src="data:image/png;base64,${row[col]}" alt="reportImage" width="150"></img></td>`
                        }
                    } else if (excelColumnsType[colIndex] === 'date' || excelColumnsType[colIndex] === 'datetime') {
                        if (moment(row[col]).isValid()) {
                            newRow = newRow + `<td>${moment(row[col]).format('DD/MM/YYYY HH:mm')}</td>`;
                        } else {
                            newRow = newRow + `<td>-</td>`;
                        }
                    }
                    else {
                        newRow = newRow + `<td>${row[col] || ''}</td>`
                    }
                })
                rows = rows + `<tr>${newRow}</tr>`;
            });
        } else {
            const cols = Object.keys(dataSet[0]);
            cols.forEach(col => {
                columns = columns + `<th>${col}</th>`
            });

            dataSet.forEach(row => {
                const newRow = cols.map(col => `<td>${row[col] || ''}</td>`)
                rows = rows + `<tr>${newRow}</tr>`;
            });
        }

        const html = `<html>
        <head>
            <title>Appmosphera</title>
            <style>
                body {
                    font-family: "Mukta", sans-serif;
                    font-size: 11px;
                }
                th {
                    white-space: nowrap;
                }
                td {
                    color: #4e4a4a;
                }
            </style>
        </head>
        <body>
            <table>
                <thead>
                    <tr>
                        ${columns}
                    </tr>
                </thead>
                <tbody>
                    ${rows}
                </tbody>
            </table>
        </body></html>`
        const mywindow = window.open('', 'PRINT', 'height=400,width=600');
        mywindow.document.write(html);

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    async getSubFraccionamientos() {
        try {
            const fraccionamiento = localStorage.getItem('frac');
            const data = { Idsub: fraccionamiento, filter: 1 };

            const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

            if (response && !response.error) {
                if (response.subfraccionamiento && !response.empty) {
                    this.setState({
                        subfraccionamientos: response.subfraccionamiento
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message,
                        subfraccionamientos: []
                    });
                };
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            };
        } catch (error) {
            console.log(error);
        };
    };

    async reload() {
        // Recargar los registros principales
    }

    async getReports() {
        try {
            const data = {
                id_fraccionamiento: parseInt(localStorage.getItem('frac')),
                tipo: 2 // tipo de reporte
            };
    
            const response = await request.post('/reportes/tipos', data);
    
            if (response.tipos) {
                this.setState({ tipos: response.tipos, selected: response.tipos[0] });
            };

        } catch (error) {
            console.log(error);
        };
    };

    filter(dataSet) {
        let result = dataSet;
        const filtro = this.state.filter.toLowerCase();
        if (Array.isArray(result)) {
            const columns = Object.keys(dataSet[0]);
            if (columns.length > 0) {
                result = result.filter((row) => {
                    let valid = false;
                    columns.forEach((col) => {
                        let item = row[col];
                        item = item ? item.toString().toLowerCase() : '';
                        if (item.indexOf(filtro) !== -1) {
                            valid = true;
                        }
                    });
                    return valid;
                });
            }
        }
        return result;
    }

    render() {

        let subfrac = [], reportes = [], unidades = [];
        if (this.state.subfraccionamientos) {
            subfrac = this.state.subfraccionamientos;
        };

        let tiposReportes = [];
        if (this.state.tipos) {
            tiposReportes = this.state.tipos;
        };

        if (this.state.tipos) {
            reportes = this.state.tipos;
        };

        if (this.state.unidades) {
            unidades = this.state.unidades;
        };

        const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

        //Definición de hojas
        let report_sheets = [];
        //Data State
        let { data } = this.state;
        //DataSet
        let dataSet = [], columns = [], orientation = [];

        if (this.state.loading) {
            report_sheets.push(
                <div className="row justify-center align-center">
                    <div>
                        <div className="white-space-32"></div>
                        <i className="fas fa-spinner fa-spin"></i>
                    </div>
                </div>
            )
        } else if (Array.isArray(data)) {
            if (data.length <= 0) {
                report_sheets.push(
                    <div className="row justify-center align-center">
                        <div>
                            <h1>Sin Datos</h1>
                        </div>
                    </div>
                )
            } else {
                report_sheets = [];
                dataSet = data;
                let rowsSheet = 30;
                //ExcelFormat Options
                if (dataSet[0].excelColumnsShow) {
                    if (dataSet[0].rowSheet) {
                        rowsSheet = dataSet[0].rowSheet;
                    }
                    // Definición de columnas
                    let excelColumnsShow = dataSet[0].excelColumnsShow;
                    excelColumnsShow = excelColumnsShow.split(',');
                    let excelColumnsName = dataSet[0].excelColumnsName;
                    excelColumnsName = excelColumnsName.split(',');
                    let excelColumnsType = dataSet[0].excelColumnsType;
                    excelColumnsType = excelColumnsType.split(',');

                    columns = dataSet[0].excelColumnsName.split(',');
                    if (dataSet[0].excelColumnsOrientation) {
                        orientation = dataSet[0].excelColumnsOrientation.split(',');
                    }

                    // Reconstruir las filas
                    let rows = [];
                    dataSet.forEach(element => {
                        const object = {};
                        excelColumnsShow.forEach((columnShow, colIndex) => {
                            const column = excelColumnsName[colIndex];
                            let row = element[columnShow] || '';
                            const type = excelColumnsType[colIndex];
                            if (type === 'image') {
                                if (row.indexOf('data:image/') !== -1 || row.indexOf('http://') !== -1 || row.indexOf('https://') !== -1) {
                                    row = <img src={row} alt="reportImage" width="150"></img>;
                                } else {
                                    row = <img src={`data:image/png;base64,${row}`} alt="reportImage" width="150"></img>
                                }
                                object[column] = row;
                            } else if (type === 'date' || type === 'datetime') {
                                if (moment(row).isValid()) {
                                    object[column] = moment(row).format('DD/MM/YYYY HH:mm');
                                } else {
                                    object[column] = '-';
                                }
                            } else {
                                object[column] = row;
                            }
                        });
                        rows.push(object);
                    });
                    dataSet = rows;
                } else {
                    columns = Object.keys(dataSet[0]);
                };

                const Sheet = (
                    <ReportSheet>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    {columns.map(column =>
                                        <th>{column}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataSet.map((row, index) => {
                                        return (
                                            <tr>
                                                {columns.map(column =>
                                                    <td style={{ fontSize: '13px' }}>{row[column]}</td>
                                                )}
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={columns.length}>
                                        <div className="column full justify-center align-center">
                                            <div className="white-space-32"></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ReportSheet>
                )
                report_sheets.push(Sheet);
            }
        } else {
            report_sheets.push(
                <div className="row justify-center align-center">
                    <div>
                        <h1>Sin Datos</h1>
                    </div>
                </div>
            )
        }

        return (
            <div className="admin paymentDocs column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header
                    reload={this.reload.bind(this)}
                    change={this.reload.bind(this)}
                    sidebar={true}
                    active={'Dashboard'}
                    parent={'EstCuenta'}
                    panel={'panel14'}
                    nav={"administracion"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <form className="container column full" onSubmit={this.getReport.bind(this)}>
                                    <div className="navigation align-center">
                                        <div className="justify-start">
                                            <h3 className="weight-semi">{this.state.submenu}</h3>
                                        </div>
                                        <div className="full row align-center justify-end">
                                            <h5 className="color-black">TIPO DE REPORTE: </h5>
                                            <div className="space-16">
                                                <select id="report-select" name="reportSelect" className="input input-select" onChange={this.reportsChange.bind(this)}>
                                                    {tiposReportes.map((tipo, index) =>
                                                        <option key={index} value={tipo.id_reporte}>{tipo.nombre}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="row full">
                                        <div className="column justify-between full">
                                            <div className="get-reports row-responsive">
                                                <div className="full column">
                                                    <h5 className="color-black">DESDE:</h5>
                                                    <input
                                                        type="date"
                                                        id="startdate"
                                                        name="startdate"
                                                        className="input input-text"
                                                        placeholder="Desde"
                                                        value={this.state.startDate}
                                                        onChange={this.startChange.bind(this)}
                                                    />
                                                </div>
                                                <div className="white-space-8" />
                                                <div className="full column space-16">
                                                    <h5 className="color-black">HASTA:</h5>
                                                    <input
                                                        type="date"
                                                        id="enddate"
                                                        name="enddate"
                                                        className="input input-text"
                                                        placeholder="Hasta"
                                                        value={this.state.endDate}
                                                        onChange={this.endChange.bind(this)}
                                                    />
                                                </div>
                                                <div className="white-space-8" />
                                                <div className="full column space-16">
                                                    <h5 className="color-black">{this.state.subLabel}:</h5>
                                                    <select
                                                        className="input input-select"
                                                        name="subfrac"
                                                        id="subfrac"
                                                        value={this.state.id_sub}
                                                        onChange={this.subChange.bind(this)}
                                                        required
                                                        disabled={this.state.id_sub_init == 0 ? false : true}>
                                                        <option value="0">Todos</option>
                                                        {
                                                            subfrac.map((subfraccionamiento, key) => (
                                                                <option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="white-space-8" />
                                                {this.state.id_sub !== 0 && unidades.length !== 0 &&
                                                    <div className="full column space-16">
                                                        <h5 className="color-black">VIVIENDA:</h5>
                                                        <select
                                                            className="input input-select"
                                                            name="unidad"
                                                            id="unidad"
                                                            value={this.state.id_unidad}
                                                            onChange={(event) => this.setState({ id_unidad: event.target.value })}
                                                            required
                                                            disabled={this.state.id_sub_init == 0 ? false : true}>
                                                            <option value="0">Todos</option>
                                                            {
                                                                unidades.map((unidad, key) => (
                                                                    <option value={unidad.id_vivienda} key={key}>{unidad.unidad_privativa}</option>))
                                                            }
                                                        </select>
                                                    </div>}
                                                <div className="justify-start align-end space-16">
                                                    <button className="btn btn-primary color-white width-100">
                                                        {!this.props.loading ?
                                                            <i className="fas fa-search i-small" />
                                                            : <i className="fas fa-spinner fa-spin" />
                                                        }
                                                        &nbsp; Obtener
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="white-space-24"></div>
                                    <div className="column full">
                                        <div className="row justify-end">
                                            <div className="auto">
                                                <button className={`btn ${this.state.excelDisabled ? 'btn-disable' : 'btn-primary'}`} id="rptBtnExcel" onClick={this.ExportExcel.bind(this)} disabled={this.state.excelDisabled}>
                                                    <i className="far fa-file-excel color-white" />
                                                </button>
                                            </div>
                                            <div className="auto space-8">
                                                <button className={`btn ${this.state.pdfDisabled ? 'btn-disable' : 'btn-primary'}`} id="rptBtnPdf" onClick={this.ExportPdf.bind(this)} disabled={this.state.pdfDisabled}>
                                                    <i className="far fa-file-pdf color-white" />
                                                </button>
                                            </div>
                                            <div className="auto space-8">
                                                <button className={`btn color-white ${this.state.print ? 'btn-disable' : 'btn-primary'}`} id="rptBtnPrint" onClick={this.PrintElem.bind(this, 'reporter_content')} disabled={this.state.print}>
                                                    <i className="fas fa-print color-white" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="column align-center">
                                            {this.state.loading ?
                                                <React.Fragment>
                                                    <div className="white-space-32"></div>
                                                    <div className="row justify-center">
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                    </div>
                                                </React.Fragment> : !this.state.empty ?
                                                    <React.Fragment>

                                                        <div className="card-table card-owners column space-48" id="card-owners">
                                                            <div className="table-responsive column">
                                                                <table>
                                                                    <thead>
                                                                        <tr className="text-left">
                                                                            {columns.map((column, index) => {
                                                                                return <th className={`text-${orientation[index]}`} key={index}>{column}</th>
                                                                            })}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            dataSet.slice(((this.state.page - 1) * this.state.pageLength), (this.state.page * this.state.pageLength)).map((row, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        {columns.map(column => {
                                                                                            if (row[column].indexOf('$') === 0 || row[column].indexOf('%') === 0) {
                                                                                                return <td style={{ fontSize: '13px', textAlign: 'start' }}>{row[column]}</td>
                                                                                            } else {
                                                                                                return <td style={{ fontSize: '13px' }}>{row[column]}</td>
                                                                                            }
                                                                                        }
                                                                                        )}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {Math.ceil(dataSet.length / this.state.pageLength) > 0 ?
                                                            <React.Fragment>
                                                                <div className="white-space-16"></div>
                                                                <Paginador
                                                                    pages={Math.ceil(dataSet.length / this.state.pageLength)}
                                                                    setPage={this.setPage.bind(this)}
                                                                    paginatorChange={this.paginatorChange.bind(this)}
                                                                    pageLength={this.state.pageLength}
                                                                    showPageLenght={true} />
                                                            </React.Fragment> : null}
                                                    </React.Fragment> :
                                                    <React.Fragment>
                                                        <div className="white-space-48"></div>
                                                        <div className="justify-center">
                                                            <h3>No se encontraron resultados.</h3>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Consumer(DashboardDocs);