import React, { Component } from 'react';
import moment from "moment";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
const request = new Request();

class InquilinoEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 			this.props.inquilino.id_persona,
            INombre: 			this.props.inquilino.nombre,
            IDireccion: 		this.props.inquilino.direccion,
            IMunicipio: 		this.props.inquilino.municipio,
            IEstado: 			this.props.inquilino.estado,
            ICp: 				this.props.inquilino.cp,
            ITelefono: 			this.props.inquilino.telefono,
            IPais:				this.props.inquilino.pais,
            IGenero: 			this.props.inquilino.genero,
            IId: 				this.props.inquilino.tipo_identificacion,
            INumId: 			this.props.inquilino.num_identificacion,
            IVigencia: 			this.props.inquilino.vigencia_identificacion,
			IFechaIngreso: 		this.props.inquilino.fecha_entrada,
			IFechaSalida: 		this.props.inquilino.fecha_salida,
            code: 				this.props.inquilino.code,
            Iviv: 				this.props.inquilino.numero_registro,
            IIdViv: 			this.props.inquilino.id_vivienda,
			email: 				this.props.inquilino.email,
			citye:				this.props.inquilino.ciudad,
			residente_status: 	this.props.inquilino.residente_status || 0,
			comentarios:        this.props.inquilino.comentarios,
			es_propietario:     this.props.inquilino.es_propietario||false
		}
		
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.inquilino !== prevProps.inquilino) {
			const phone = parsePhoneNumber(`+${this.props.inquilino.telefono}`) || '';
			this.setState({ 
				idPersona: 			this.props.inquilino.id_persona,
				INombre: 			this.props.inquilino.nombre || '',
				IDireccion: 		this.props.inquilino.direccion || '',
				IMunicipio: 		this.props.inquilino.municipio || '',
				IEstado: 			this.props.inquilino.estado || '',
				ICp: 				this.props.inquilino.cp || '',
				ITelefono: 			phone.number || '',
				IPais: 				this.props.inquilino.pais || '',
				IGenero: 			this.props.inquilino.genero || 0,
				IId: 				this.props.inquilino.tipo_identificacion || 0,
				INumId: 			this.props.inquilino.num_identificacion || '',
				IVigencia: 			this.props.inquilino.vigencia_identificacion || '',
				IFechaIngreso: 		this.props.inquilino.fecha_entrada,
				IFechaSalida: 		this.props.inquilino.fecha_salida,
				code: 				this.props.inquilino.code || '',
				Iviv: 				this.props.inquilino.numero_registro || '',
				IdViv:				this.props.inquilino.id_vivienda || 0,
				email: 				this.props.inquilino.email || '',
				citye:				this.props.inquilino.ciudad,
				residente_status: 	this.props.inquilino.residente_status || 0,
				comentarios:        this.props.inquilino.comentarios || '',
				es_propietario:     this.props.inquilino.es_propietario||false

			});
		}
	}

    render() {
		let estdosApp = [];
		if(Array.isArray(this.props.estadosAPP))
			estdosApp = this.props.estadosAPP;

        return (
            <div>
				<DinamicModal 
					idModal					=	{'editInquilinos'}
					sizeModalContainer		=	{'big'}
					title					=	{'Editar residente'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{this.props.escritura}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
                    message					=	{this.state.message}
                    closeModal              =   {() => this.props.closeModal()}
				>
					<div className="full row align-center">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b> Vivienda:	</b></p>
								</div>
								<div className="column full">
									<input 
										type		=	"text" 
										name		=	"vivienda" 
										disabled		 
										value		=	{this.state.Iviv}
										onChang		=	{event => this.setState({ Iviv: event.target.value })}
										className	=	"input input-modals" 
										required		 
										autoComplete = 'off'
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
                            <div className="input-form-content row full align-cente">
								<div className="column label-medium">
									<p><b>  Teléfono:	</b><b style={{color:'red'}}> * </b></p>
								</div>
								<div className="column full">
									<PhoneInput
										displayInitialValueAsLocalNumber
										name="phoneNumber"
										defaultCountry="MX"
										maxLength="18"
										autoComplete = 'off'
										value={this.state.ITelefono}
										onChange={ value => this.setState({ ITelefono: value, telefonoError: undefined }) }
										error={ this.state.telefonoError }
                                        disabled = {true}
                                        readOnly
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>Nombre completo:</b><b style={{color:'red'}}> * </b></p>
								</div>
								<div className="column full">
									<input 
										type		=	"text" 
										name		=	"fullName" 
										value		=	{this.state.INombre}
										onChange	=	{event => this.setState({ INombre: event.target.value })} 
										className	=	"input input-modals" 
										autoComplete = 'off'
										required 
                                        disabled
									/>
								</div>
							</div>
                            <div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
                                <div className="column label-duo-big">
								    <p className="justify-start">
										<b>Género:</b>
									</p>
								</div>
								<div className="column full">
									<select name="sex" id="" value={this.state.IGenero} required
										onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
										<option value="0">Hombre</option>
										<option value="1">Mujer</option>
										<option value="2">No especificado</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p>
										<b>Correo:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className	=	"input input-modals"  
										type		=	"email" 
										name		=	"fullName" 
										value		=	{this.state.email}
										onChange	=	{event => this.setState({ email: event.target.value })} 
										autoComplete = 'off' 
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Identificación:</b>
									</p>
								</div>
								<div className="column full">
									<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
										<option value="0">INE</option>
										<option value="1">Pasaporte/Visa</option>
										<option value="2">Curp</option>
										<option value="3">Cartilla Militar</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p  className="justify-start">
										<b>ID:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className="input input-modals" 
										type="text" 
										name="numIdEddit" 
										value={this.state.INumId}
										onChange={event => this.setState({ INumId: event.target.value })} 
										autoComplete = 'off'
										/* required  */
										 
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Dirección:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										type		=	"text" 
										name		=	"address" 
										value		=	{this.state.IDireccion}
										onChange	=	{event => this.setState({ IDireccion: event.target.value })} 
										className	=	"input input-modals" 
										autoComplete = 'off' 
										/* required  */ 
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p >
										<b>Municipio:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className="input input-modals"  
										type="text" 
										name="municipality" 
										value={this.state.IMunicipio}
										onChange={event => this.setState({ IMunicipio: event.target.value })} 
										/* required */ 
										autoComplete = 'off'
									/>
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start">
										<b>Ciudad:</b>
									</p>
								</div>
								<div className="column full">
								<input className='input input-modals' /* required  */
										autoComplete = 'off'
										id			=	'citye' 
										type		=	"text" 
										value		=	{this.state.citye}
										onChange	=	{event => this.setState({ citye: event.target.value })} 
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>C.P:</b>
									</p>
								</div>
								<div className="column full">
								<input 
										className="input input-modals" 
										type="text" 
										value={this.state.ICp}
										onChange={event => this.setState({ ICp: event.target.value })} 
										name="zip"  
										/* required  */
										autoComplete = 'off'
									/>
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start">
										<b>Estado:</b>
									</p>
								</div>
								<div className="column full">
								   <input 
										className="input input-modals"  
										type="text" 
										name="state" 
										value={this.state.IEstado}
										onChange={event => this.setState({ IEstado: event.target.value })} 
										/* required */ 
										autoComplete = 'off'
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big ">
									<p>
										<b>País:</b>
									</p>
								</div>
								<div className="column full">
								   <input 
										className="input input-modals" 
										type="text" 
										value={this.state.IPais}
										onChange={event => this.setState({ IPais: event.target.value })} 
										name="country"
										/* required */ 
										autoComplete = 'off' 
									/>
								</div>
								<div className="column label-duo-normal">
									<p>
										<b>Estado APP:</b>
									</p>
								</div>
								<div className="column full">
								<select type="date" value={this.state.residente_status}
										onChange={event => this.setState({ residente_status: event.target.value })} required className='input input-modals' id='estadoAPP'>
											{estdosApp.map(sta => <option key={sta.id} value={sta.id}>{sta.estatus}</option>)}
									</select>
								</div>
							</div>


							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Fecha de ingreso:</b><b style={{color:'red'}}> * </b>
									</p>
								</div>
								<div className="column full">
								<input type="date" value={this.state.IFechaIngreso}
										onChange={event => this.setState({ IFechaIngreso: event.target.value })} required className='input input-modals' id='fcompra' />
								</div>
								<div className="column label-duo-normal">
									<p>
										<b>Fecha de salida:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" value={this.state.IFechaSalida}
										onChange={event => this.setState({ IFechaSalida: event.target.value })} className='input input-modals' id='fcompra' />
								</div>
							</div>
							<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Comentarios:</b>
							</p>
						</div>
						<div className="column full">
						<textarea 
                            name="comentarios"
                            id="comentarios_inquilinos"
                            value={this.state.comentarios}
                            cols="30"
                            rows="3"
                            maxLength="500"
                            className='textarea font full'					
						 onChange={(event) => this.setState({comentarios: event.target.value})} >
                        </textarea>
						</div>
						<div className="column label-duo-normal space">
						<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="esPropietario" id="esPropietario" disabled="disabled"  checked={this.state.es_propietario ? true : false} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Es propietario </b>{" "}
							</h5>
						</div>
					</div>
						</div>
						<div className="column full">
						
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							<b style={{color:'red'}}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
						
					</div>

						</div>
					</div>
				</DinamicModal>
        	</div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }

    async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		/**Validar Telefono */
		
		if(!isValidPhoneNumber(this.state.ITelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
        const form = event.target
        let data = {
            idVivienda: 			this.state.IdViv,
            Nombre: 				this.state.INombre,
            Direccion: 				this.state.IDireccion,
            Municipio: 				this.state.IMunicipio,
            Estado: 				this.state.IEstado,
            cp: 					this.state.ICp,
            Telefono: 				this.state.ITelefono,
            pais: 					this.state.IPais,
            Genero: 				this.state.IGenero,
            Id: 					this.state.IId,
            NumId: 					this.state.INumId,
            Vigencia: 				this.state.IVigencia,
			Fechaingreso: 			this.state.IFechaIngreso,
			FechaSalida: 			this.state.IFechaSalida,
            Code: 					this.state.code,
            IdPersona: 				this.state.idPersona,
			email: 					this.state.email,
			idFraccionamiento: 		localStorage.getItem('frac') || 0,
			city:					this.state.citye,
			estadoAPP:				this.state.residente_status,
			comentarios:            this.state.comentarios,
			id_usuario_upd :    localStorage.getItem("user_id")
        }

        const response = await request.post("/viviendas/inquilino/update", data)

		//this.setState({message: response.message});
		if(response.updated) {
			this.props.reload();
			cogoToast.success('Datos de residente actualizados.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({message: "NO SE PUDO ACTUALIZAR INQUILINO"});
			cogoToast.error('No se pudieron actualizar datos de residente.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});  
    }
}

export default InquilinoEditarModal;