/**
 *  Environment.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de datos del emisor
 */

import React, {Component} from "react";
import {Consumer} from "../../../context";
import {Helmet} from "react-helmet";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/header/sidebar";
import Request from "../../../core/httpClient";
import Permissions from "../../..//middlewares/Permissions";
import cogoToast from "cogo-toast";
import DinamicModal from "../../../components/dinamicModal/dinamicModal";

const request = new Request();

class parametersData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				idUsuario: 0,
				idPerfil: 0,
				idFraccionamiento: 0,
				idAdministracion: 0,
				nombre: null,
			},
			escritura: false,
			Fraccionamiento: null,
			disabled1: true,
			disabled2: true,
			disabled3: true,
			configGeneral: [],
			configSat: [],
			loading: false,
			lista: [],
			confGeneral: [],
			cfSAT: [],
			descriptores: [],

			respaldoGeneral: [],
			respaldoSat: [],
			respaldoDescriptores: [],
		};

		this.validarPermisos();
	}

	async componentDidMount() {
		await this.load();
		// await this.getDataParameters();
		await this.getConfigGeneral();
		await this.getConfigSAT();
		await this.getDescriptores();
	}

	async getDescriptores() {
		let data = {
			id_fraccionamiento: parseInt(localStorage.getItem("frac") || 0),
		};

		const response = await request.post("/facturacion/descriptores", data);
		if (response.error || response.empty) {
			await this.setState({descriptores: [], respaldoDescriptores: []});
			return;
		}

		if (response.descriptores) {
			await this.setState({
				descriptores: response.descriptores,
				respaldoDescriptores: response.descriptores,
			});
		}
	}

	async load() {
		const user = await this.props.context.loadUser();

		const frac = window.localStorage.getItem("frac");

		if (user) {
			await this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					nombre: user.id.usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					idAdministracion: user.id.id_administracion,
				},
				Fraccionamiento: frac,
			});
			await this.getDataParameters();
		}
	}

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult();
		this.setState({escritura: permisosInfo.escritura});
	}

	change() {
		this.load();
	}

	handleEddit(id) {
		if (id === 1) {
			this.setState({disabled1: !this.state.disabled1});
		}
		if (id === 2) {
			this.setState({disabled2: !this.state.disabled2});
		}
		if (id === 3) {
			this.setState({disabled3: !this.state.disabled3});
		}
	}

	onChangeData(key, event) {
		const dataBilling = this.state.dataBilling;
		dataBilling[key] = event.target.value;
		this.setState({dataBilling: dataBilling});
	}

	async getDataParameters() {
		this.setState({loading: true});
		let data = {
			id_fraccionamiento: parseInt(localStorage.getItem("frac")) || 0,
		};

		const response = await request.post("/facturacion/config", data);

		if (response && !response.error) {
			if (response.config && !response.empty) {
				let general = response.config[0];
				let sat = response.config[1];

				general.Campos = JSON.parse(response.config[0].Campos);
				sat.Campos = JSON.parse(response.config[1].Campos);

				// Separar listas (valores predefinidos)
				general.Campos.map((config) => (config.Lista = config.Lista.split("|")));
				sat.Campos.map((config) => (config.Lista = config.Lista.split("|")));

				// reemplazando valores
				general.Campos.map((config) => (config.Valor = config.Valor[0]));
				sat.Campos.map((config) => (config.Valor = config.Valor[0]));

				await this.setState({configGeneral: general, configSat: sat});
			} else {
				await this.setState({
					empty: true,
					message: response.message,
				});
			}
		} else {
			await this.setState({
				error: true,
				message: response.message,
			});
		}
		this.setState({loading: false});
	}

	filtrarCambios() {
		let aux = [];
		let config = this.state.confGeneral;

		config.forEach((cf) => {
			// aux.push([cf.id_campo,cf.valor]);
			aux.push({
				id_reg: cf.id_reg,
				valor: cf.valor,
			});
		});

		config = this.state.cfSAT;

		config.forEach((cf) => {
			aux.push({
				id_reg: cf.id_reg,
				valor: cf.valor,
			});
		});

		return JSON.stringify(aux);
	}

	async editDataParameters(event) {
		this.setState({loading: true, message: ""});
		event.preventDefault();
		const form = event.target;

		let json = this.filtrarCambios();

		let data = {
			jsonVal: json,
		};
		const response = await request.post("/facturacion/config/update", data);

		if (!response || response.error) {
			if (response.updated && !response.empty) {
				await this.setState({
					error: true,
					//message: 'No se pudieron actualizar los datos'
				});
				cogoToast.error("No se pudieron actualizar los datos.", {
					position: "bottom-right",
				});
				//alert("No se pudieron actualizar los datos");
			}
		}

		if (response && response.updated) {
			cogoToast.success("Datos actualizados correctamente.", {
				position: "bottom-right",
			});
		}

		await this.setState({
			loading: false,
		});
	}

	async selectGeneralChange(value, key) {
		let newConfig = this.state.configGeneral;
		newConfig.Campos[key].Valor = value;
		this.setState({configGeneral: newConfig});
	}
	async getConfigSAT() {
		let configSat = this.state.configSat.Campos;

		if (!(Array.isArray(configSat) && configSat.length > 0)) {
			console.info(` No hay configuración`);
			return [];
		}

		let filteredData = [];

		// Lista 0,1,2
		// Valor[0].valor
		let lista = null;
		let valor = null;
		let i = 0;

		let data = {};
		let aux = [];
		configSat.forEach((cf) => {
			data.id_campo = cf.id_campo;
			data.nombre = cf.nombre;
			data.lista = cf.Lista;
			data.valor = cf.Valor.valor;
			data.id_reg = cf.Valor.id_reg;
			data.index = this.getIndex(cf.Lista, cf.Valor.valor);
			aux.push(data);
			data = {};
		});

		await this.setState({cfSAT: aux, respaldoSat: aux});
	}

	async getConfigGeneral() {
		let configGeneral = this.state.configGeneral.Campos;

		if (!(Array.isArray(configGeneral) && configGeneral.length > 0)) {
			console.info(` No hay configuración`);
			return [];
		}

		let filteredData = [];

		// Lista 0,1,2
		// Valor[0].valor
		let lista = null;
		let valor = null;
		let i = 0;

		let data = {};
		let aux = [];
		configGeneral.forEach((cf) => {
			data.id_campo = cf.id_campo;
			data.nombre = cf.nombre;
			data.lista = cf.Lista;
			data.valor = cf.Valor.valor;
			data.id_reg = cf.Valor.id_reg;
			data.index = this.getIndex(cf.Lista, cf.Valor.valor);
			aux.push(data);
			data = {};
		});
		await this.setState({confGeneral: aux, respaldoGeneral: aux});
		// return configGeneral;
	}

	getIndex(lista, valor) {
		for (let i = 0; i < lista.length; i++) {
			if (lista[i] === valor) return i;
		}
		return 0;
	}
	getValorByIndex(lista, index) {
		for (let i = 0; i < lista.length; i++) {
			if (i === index) return lista[i];
		}
	}
	async changeConfSAT(id_reg, index) {
		let conf = this.state.cfSAT;
		conf.forEach((cf) => {
			if (cf.id_reg === id_reg) {
				cf.index = parseInt(index);
				cf.valor = this.getValorByIndex(cf.lista, parseInt(index));
			}
		});
		await this.setState({cfSAT: conf});
	}
	async changeConf(id_reg, index) {
		let conf = this.state.confGeneral;
		conf.forEach((cf) => {
			if (cf.id_reg === id_reg) {
				cf.index = parseInt(index);
				cf.valor = this.getValorByIndex(cf.lista, parseInt(index));
			}
		});
		await this.setState({confGeneral: conf});
	}

	render() {
		return (
			<div className="billing column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - datos configuración</title>
				</Helmet>
				<Header sidebar={true} active={"entorno"} parent={"config"} panel={"panel4"} nav={"catalogos"} change={this.change.bind(this)} />

				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<div className="navigation align-center">
									<div className="justify-start">
										<h3 className="weight-semi">Parámetros</h3>
									</div>
								</div>
								<div className="white-space-16" />

								<div className="card-table card-owners column" id="card-owners">
									<form className="column align-center" onSubmit={this.editDataParameters.bind(this)}>
										<div className="white-space-24" />
										<div className="container column">
											<div className="edit-button justify-start">
												<div className="column full align-start">
													<h3>Configuración general</h3>
												</div>
												<div className="column full">
													<div className="justify-end">
														<button className="btn btn-edit color-white" type="button" id="btn-modal-edit" onClick={this.handleEddit.bind(this, 1)} disabled={!this.state.escritura}>
															<i className="fas fa-pen"></i> Editar
														</button>
													</div>
												</div>
											</div>

											<div className="white-space-24"></div>
											<div>
												{this.state.loading ? (
													<div className="row justify-center full">
														<i className="fas fa-sync fa-spin"></i>
													</div>
												) : this.state.confGeneral ? (
													this.state.confGeneral.map((data, key) => {
														return (
															<div className="billing-rows dinamic row full" key={key}>
																<div className="justify-start label-title-config">
																	<h4>{data.nombre}:</h4>
																</div>
																<div className="justify-start align-center">
																	<select
																		className={this.state.disabled1 ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
																		value={data.index}
																		onChange={(event) => {
																			this.changeConf(data.id_reg, event.target.value);
																		}}
																		disabled={this.state.disabled1}
																	>
																		{data.lista.map((list, key) => (
																			<option value={key} key={key}>
																				{list}
																			</option>
																		))}
																	</select>
																</div>
															</div>
														);
													})
												) : null}
											</div>

											{this.state.disabled1 == false && (
												<div className="btn-container justify-end">
													<button type="submit" className="btn btn-primary color-white">
														{this.state.loading ? (
															<div className="row justify-center">
																<i className="fas fa-spinner fa-spin"></i>
															</div>
														) : (
															<div>
																<i className="fas fa-check" />
																&nbsp; ACTUALIZAR
															</div>
														)}
													</button>
													<button type="button" className="btn btn-secondary color-white" onClick={this.handleEddit.bind(this, 1)}>
														<i className="fas fa-times" />
														&nbsp; CANCELAR
													</button>
												</div>
											)}
										</div>
										<div className="white-space-24" />
									</form>
								</div>
								<div className="white-space-48"></div>
								<div className="card-table card-owners column" id="card-owners">
									<form className="column align-center" onSubmit={this.editDataParameters.bind(this)}>
										<div className="white-space-24" />
										<div className="container column">
											<div className="edit-button justify-start">
												<div className="column full align-start">
													<h3>Configuración Del SAT</h3>
												</div>
												<div className="column full">
													<div className="justify-end">
														<button className="btn btn-edit color-white" type="button" id="btn-modal-edit" onClick={this.handleEddit.bind(this, 2)} disabled={!this.state.escritura}>
															<i className="fas fa-pen"></i> Editar
														</button>
													</div>
												</div>
											</div>

											<div className="white-space-24"></div>
											<div>
												{this.state.loading ? (
													<div className="row justify-center full">
														<i className="fas fa-sync fa-spin"></i>
													</div>
												) : this.state.cfSAT ? (
													this.state.cfSAT.map((data, key) => {
														return (
															<div className="billing-rows dinamic row full" key={key}>
																<div className="justify-start label-title-config">
																	<h4>{data.nombre}:</h4>
																</div>
																<div className="justify-start align-center">
																	<select
																		className={this.state.disabled2 ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
																		value={data.index}
																		onChange={(event) => {
																			this.changeConfSAT(data.id_reg, event.target.value);
																		}}
																		disabled={this.state.disabled2}
																	>
																		{data.lista.map((list, key) => (
																			<option value={key} key={key}>
																				{list}
																			</option>
																		))}
																	</select>
																</div>
															</div>
														);
													})
												) : null}
											</div>

											{this.state.disabled2 == false && (
												<div className="btn-container justify-end">
													<button type="submit" className="btn btn-primary color-white">
														{this.state.loading ? (
															<div className="row justify-center">
																<i className="fas fa-spinner fa-spin"></i>
															</div>
														) : (
															<div>
																<i className="fas fa-check" />
																&nbsp; ACTUALIZAR
															</div>
														)}
													</button>
													<button type="button" className="btn btn-secondary color-white" onClick={this.handleEddit.bind(this, 2)}>
														<i className="fas fa-times" />
														&nbsp; CANCELAR
													</button>
												</div>
											)}
										</div>
										<div className="white-space-24" />
									</form>
								</div>
								{/* END CONFIGURACIÓN DEL SAT */}

								<div className="white-space-32" />
								<div className="navigation align-center">
									<div className="justify-start">
										<h3 className="weight-semi">Descriptores</h3>
									</div>
								</div>
								<div className="white-space-16" />

								<div className="card-table card-owners column" id="card-owners">
									<form
										className="column align-center"
										// onSubmit={this.editDataParameters.bind(this)}
									>
										<div className="white-space-24" />
										<div className="container column">
											<div className="edit-button justify-start">
												<div className="column full align-start">
													<h3>Descriptores</h3>
												</div>
												<div className="column full">
													<div className="justify-end">
														<button className="btn btn-edit color-white" type="button" id="btn-modal-edit" onClick={this.handleEddit.bind(this, 3)} disabled={!this.state.escritura}>
															<i className="fas fa-pen"></i> Editar
														</button>
													</div>
												</div>
											</div>

											<div className="white-space-24"></div>
											<div>
												{this.state.loading ? (
													<div className="row justify-center full">
														<i className="fas fa-sync fa-spin"></i>
													</div>
												) : this.state.descriptores ? (
													this.state.descriptores.map((data, key) => {
														return (
															<div className="billing-rows dinamic row full" key={key}>
																<div className="justify-start label-title-config">
																	<h4>{data.Nombre}:</h4>
																</div>
																<div className="justify-start align-center">
																	<input
																		type="text"
																		name=""
																		id=""
																		className={this.state.disabled3 ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
																		value={data.Prefijo}
																	/>
																</div>
															</div>
														);
													})
												) : null}
											</div>

											{this.state.disabled3 == false && (
												<div className="btn-container justify-end">
													<button type="submit" className="btn btn-primary color-white">
														{this.state.loading ? (
															<div className="row justify-center">
																<i className="fas fa-spinner fa-spin"></i>
															</div>
														) : (
															<div>
																<i className="fas fa-check" />
																&nbsp; ACTUALIZAR
															</div>
														)}
													</button>
													<button type="button" className="btn btn-secondary color-white" onClick={this.handleEddit.bind(this, 3)}>
														<i className="fas fa-times" />
														&nbsp; CANCELAR
													</button>
												</div>
											)}
										</div>
										<div className="white-space-24" />
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(parametersData);
