/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import TimeField from "react-simple-timefield";

const request = new Request();

const HOURS = [
	{label: "Seleccionar", value: ""},
    {label: "12 hr", value: "12"},
    {label: "18 hr", value: "18"},
    {label: "24 hr", value: "24"},
    {label: "30 hr", value: "30"},
    {label: "36 hr", value: "36"},
    {label: "42 hr", value: "42"},
    {label: "48 hr", value: "48"},
    {label: "54 hr", value: "54"},
];

const ExitConfiguration = (props) => {
	const { 
		escritura, 
		id_config,
		id_usuario,
		salida_automatica,
		desactivar_notificaciones,
		hora_desactivacion,
		hora_activacion
	} = props;

	const [editExits, setEditExits] = useState(false);
	const [disabledEdit, setDisabledEdit] = useState(true);
	const [autoExiHours, setAutoExiHours] = useState("");
	const [disabledNotificationsHours, setDisabledNotificationsHours] = useState("");
	const [notificationsLimits, setNotificationsLimits] = useState({from: "", to: ""})
	const [isScheduleChecked, setIsScheduleChecked] = useState(true);

	useEffect(() => {
		setAutoExiHours(salida_automatica);
		setDisabledNotificationsHours(desactivar_notificaciones);
		setNotificationsLimits({ from: hora_desactivacion, to: hora_activacion });
	}, [salida_automatica, desactivar_notificaciones, hora_desactivacion, hora_activacion]);

	useEffect(() => {
		if(notificationsLimits.from !== "" || notificationsLimits.to !== ""){
			setIsScheduleChecked(true);
		}else {
			setIsScheduleChecked(false);
		}
	},[notificationsLimits])
	  
	const handleShowEditConfig = () => {
		setEditExits(true);
		setDisabledEdit(false);
	}

	const handleClose = () => {
		setEditExits(false);
		setDisabledEdit(true)
		setAutoExiHours(salida_automatica);
		setDisabledNotificationsHours(desactivar_notificaciones);
		setNotificationsLimits({from: hora_desactivacion, to: hora_activacion})
	}

	const handleCheckboxChange = () => {
		setIsScheduleChecked(!isScheduleChecked);
	};
	  

	const validateExit =() => {
		if (id_config === 0) {
			cogoToast.warn("Este fraccionamiento no cuenta una configuración inicial, favor de actualizarla antes de cambiar los datos de las salidas y notificaciones.", {
				position: "bottom-right",
			});
			setEditExits(false);
			setDisabledEdit(true)
		};
		if(!isScheduleChecked){
			setNotificationsLimits({from: "", to: ""})
		};
		handleEditConfig();
	}

	const handleEditConfig = async () => {

		if ((!notificationsLimits.from && notificationsLimits.to) || 
		    (notificationsLimits.from && !notificationsLimits.to)) {
			cogoToast.warn('Debes de seleccionar ambos o ninguno', {
				position: 'bottom-right',
			});
			return;
		}
		try {
			const data = {
				idConfig: id_config,
				idUsuario: id_usuario,
				salida_automatica: autoExiHours === "" ? null : autoExiHours,
				desactivar_notificaciones: disabledNotificationsHours === "" ? null : disabledNotificationsHours,
				hora_desactivacion: !isScheduleChecked ? null: notificationsLimits.from,
                hora_activacion: !isScheduleChecked ? null : notificationsLimits.to,
			}

			const response = await request.post("/environments/update/exits-notifications", data);

			if (response && !response.error) {
				if (response.updated && !response.empty) {
					setEditExits(false);
					setDisabledEdit(true);
					cogoToast.success("Datos de configuración actualizados.", {
						position: "bottom-right",
					});
				}
			} else {
				setAutoExiHours(salida_automatica);
				setDisabledNotificationsHours(desactivar_notificaciones);
				setNotificationsLimits({from: hora_desactivacion, to: hora_activacion});
				cogoToast.error("Error al actualizar datos de salidas, inténtalo nuevamente.", {
					position: "bottom-right",
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

    return (
        <div className="column">
			<div className="edit-button justify-end">
				<div className="justify-start">
					<h3>Notificaciones de entradas y salidas</h3>
				</div>
				<div className="justify-end">
					<button
						className="btn btn-mini btn-primary color-white"
						type="button"
						id="btn-modal-edit"
						onClick={handleShowEditConfig}
						disabled={!escritura}
					>
						<i className="fas fa-pen"></i> Editar
					</button>
				</div>
			</div>
			<div className="white-space-16"></div>
			<div className="configuration-rows vip row">
				<div className="justify-start">
				    <div 
				        class="tooltip right size" 
					    data-tooltip="Configura a las cuantas horas deseas darle salida automática a los registros de entrada."
				    >
					    <h4>Salidas automáticas:</h4>
				    </div> 
                 </div>
				<div className="justify-start">
					<select
						className="description input-select-configuration"
						disabled={disabledEdit}
						value={autoExiHours}
						onChange={(event) => setAutoExiHours(event.target.value)}
					>
						{HOURS.map((hour, index) => (
                            <option key={index} value={hour.value}>
                                {hour.label}
                            </option>
                        ))}
					</select>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="configuration-rows vip row">
			    <div className="justify-start">
				    <div 
				        class="tooltip right size" 
					    data-tooltip="Configura a las cuantas horas desea no enviar las notificaciones de salida"
				    >
					    <h4>No enviar notificaciones de salida:</h4>
				    </div>    
                </div>
				<div className="justify-start">
					<select
						className="description input-select-configuration"
						disabled={disabledEdit}
						value={disabledNotificationsHours}
						onChange={(event) => setDisabledNotificationsHours(event.target.value)}
					>
						{HOURS.map((hour, index) => (
                            <option key={index} value={hour.value}>
                                {hour.label}
                            </option>
                        ))}
					</select>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="configuration-rows vip row" style={{paddingTop:"12px", paddingLeft:"30px"}}>
			    <label className="container-check">
					<input
					type="checkbox"
					name=""
					id=""
					disabled={disabledEdit}
					checked={isScheduleChecked}
                    onChange={handleCheckboxChange}
					/>
						<span className="checkmark"/>
				</label>
				<div className="justify-start">
				    <div 
				        class="tooltip right size" 
					    data-tooltip="Configura el horario en el que deseas no enviar notificaciones de entrada y salida."
				    >
					    <h4>No enviar notificaciones de entrada y salida:</h4>
				    </div>    
                </div>
				<div className="justify-start">
				    <div className="data-from column">
						<h5 className="color-black" style={{margin: "4px 8px 0 0"}}>Desde:</h5>
					</div>
					<TimeField
						value={notificationsLimits.from || "00:00"}
						onChange={(event) => setNotificationsLimits({ ...notificationsLimits, from: event.target.value })}
						disabled={disabledEdit}
						style={{
							fontSize: 20,
							width: "15%",
							color: "#333",
							textAlign: "center",
						}}
					/>
					<div className="data-from column">
						<h5 className="color-black"style={{margin: "4px 8px"}}>Hasta:</h5>
					</div>
					<TimeField
						value={notificationsLimits.to || "00:00"}
						onChange={(event) => setNotificationsLimits({ ...notificationsLimits, to: event.target.value })}
						disabled={disabledEdit}
						style={{
							fontSize: 20,
							width: "15%",
							color: "#333",
							textAlign: "center",
						}}
					/>
				</div>
			</div>
			<div className="white-space-8"/>
			<div className="white-space-48"/>
			{editExits ? (
				<div className="btn-container justify-end">
					<button
						type="button"
						className="btn btn-primary color-white"
						onClick={validateExit}
					>
						<i className="fas fa-check" />
							&nbsp; ACTUALIZAR
					</button>
					<button
						type="button"
						className="btn btn-secondary color-white"
						onClick={handleClose}
					>
						<i className="fas fa-times" />
						&nbsp; CANCELAR
					</button>
				</div>
			) : ( 
			    <>
				    <div className="white-space-32"></div>
				    <div className="white-space-4"></div>
			    </>
			)}
		</div>
    )
}

export default ExitConfiguration;