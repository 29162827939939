import React, { Component } from 'react';
import Request from "../../core/httpClient";
const request = new Request();

class ContactoModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingContactos: false,
			contactos: []
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.getPropietariosVivienda();
		}
	}

	componentDidMount() {
		this.getContactosVivienda();
	}

  	render() {
		let id = 0
		if (this.props.id) {
			id = this.props.id;
		}

    	return (
			<div className="full row align-center">
				<div className="column full">
					<div className="column card-table">
						<div className="table-responsiv column">
							<table>
								<tbody>
									<tr className="row-without-border">
										<th colSpan="2" className="text-left">
											<h4 className="weight-semi">Llamar a</h4>
										</th>
									</tr>
									<tr className="text-left">
										<th className="">Nombre</th>
										<th className="">Teléfono</th>
									</tr>
								{	this.state.loadingContactos? 
									<tr>
										<td colSpan="4">
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									this.state.contactos.map((contacto, key) => (
									<tr key={key}>
										<td className="text-left">
											{contacto.nombre} 
										</td>
										<td className="text-left">
											{contacto.telefono}
										</td>
									</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
		</div>
		);
	}

	// TRAE TODOS LOS CONTACTOS DE LA VIVIENDA
	async getContactosVivienda() {
		this.setState({loadingContactos: true});
		let data = { id_vivienda: this.props.id };

		const response = await request.post("/emergencys/vivienda/contactos", data);
		if (response.contactosVivienda && !response.empty) {
			this.setState({
				contactos: response.contactosVivienda,
				//isPrincipal: response.contactosVivienda[0].principal
			});
		} else {
			this.setState({
				empty: true,
				message: response.message,
				contactos: []
			});
		}
		this.setState({loadingContactos: false});
	}


	
}
export default ContactoModal;