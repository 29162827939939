import moment from "moment-timezone";

export const setAbonoFormat = (Id_DocCobro, Abono, FechaAbono, formasCobro) => { 
    return { Id_DocCobro, Abono, FechaAbono, formasCobro }; 
};

export const calcularRecargosYDescuentos = (documentos, fecha) => {

    const fechaPago = moment(fecha).tz("GMT");

    const newDocumentosPendientes = documentos.map((documento) => {

        const { Importe, Pagado, DetalleRecargo, DetalleDescuento } = documento;
        let calculoDescuento = 0;

        const descuentosSelected = DetalleDescuento.map((descuento) => {

            const { fecha, importe } = descuento;
            const fechaDescuento = moment(fecha).tz("GMT");

            if(calculoDescuento === 0) {
                const select = fechaDescuento.isSameOrAfter(fechaPago, "days");
                calculoDescuento = select ? importe : 0;
                return { ...descuento, selected: select };
            }
            else {
                return { ...descuento, selected: false };
            }
        });

        const calculoRecargos = DetalleRecargo.reduce((total, { fecha, importe }) => {

            const fechaRecargo = moment(fecha, "YYYY-MM-DD").tz("GMT");
            
            return fechaRecargo.isSameOrBefore(fechaPago, "days") ?
                total + importe : total;
        }, 0).toFixed(2);

        const descuentoParsed = parseFloat(calculoDescuento.toFixed(2));
        const recargosParsed = parseFloat(calculoRecargos);

        return {
            ...documento,
            DetalleDescuento: descuentosSelected,
            Descuento: descuentoParsed,
            Recargos: recargosParsed,
            Total: Importe + recargosParsed - descuentoParsed,
            Saldo: Importe + recargosParsed - descuentoParsed - Pagado,
        }
    });

    return newDocumentosPendientes;
};

export const calcularPagoPrelacion = (documentos, { importe, fecha }) => {

    let importeRestante = importe;
    let documentosVencidos = [];
    const documentosNoVencidos = {};
    let headersInfo = {};

    const aplicarAbonos = (documentos, importeRestante) => {

        for (let index = 0; index < documentos.length; index++) {
            
            const { Saldo } = documentos[index];

            importeRestante -= Saldo;

            if(importeRestante >= 0) {
                const Aplicado = Saldo;
                documentos[index] = { ...documentos[index], Aplicado, checked: true };
            }

            if(importeRestante < 0) {
                const Aplicado = Saldo + importeRestante;
                documentos[index] = { ...documentos[index], Aplicado, checked: true };
                return 0;
            }

            if(importeRestante === 0) return 0;
        }

        return importeRestante;
    };

    const segmentarDocumentos = () => {

        [...documentos].forEach((documento) => {

            const { Fecha, Descuento } = documento;

            const fechaPago = moment(fecha).tz("GMT");
            const fechaDocumento = moment(Fecha, "MM-YYYY").tz("GMT");

            if (fechaDocumento.isBefore(fechaPago, "months")) {
                documentosVencidos.push(documento);
            }

            else if (fechaDocumento.isSame(fechaPago, "months") && Descuento === 0) {
                documentosVencidos.push(documento);
            }

            else {

                if (!Object.keys(documentosNoVencidos).find((key) => key === Fecha)) {
                    documentosNoVencidos[Fecha] = [];
                } 

                documentosNoVencidos[Fecha].push(documento);
            }
        });
    };

    const setHeadersInfo = (index, title) => { headersInfo[index] = title };

    const concatenarDocumentos = () => {

        if(documentosVencidos.length > 0) setHeadersInfo(0, "Vencidos");

        return Object.entries(documentosNoVencidos).reduce((newDocumentos, [key, documentos], index) => {
            
            setHeadersInfo(
                index === 0 ? documentosVencidos.length : newDocumentos.length, 
                moment(key, "MM-YYYY").locale('es').format("MMMM YYYY").replace(".", "")
            );

            return newDocumentos.concat(documentos);
        }, documentosVencidos);
    }

    segmentarDocumentos();

    documentosVencidos = documentosVencidos.sort(
        (docA, docB) => docA.Prelacion - docB.Prelacion
    );

    Object.entries(documentosNoVencidos).forEach(([key, documentos]) => {

        documentosNoVencidos[key] = documentos.sort((docA, docB) => {
            const descuentoDiff = docB.Descuento - docA.Descuento;

            if (descuentoDiff !== 0) return descuentoDiff;

            return docA.Prelacion - docB.Prelacion;
        });
    });

    const documentosOrdenados = concatenarDocumentos();

    const saldoAFavorGenerado = aplicarAbonos(documentosOrdenados, importeRestante);

    return { saldoAFavorGenerado, documentosOrdenados, headersInfo };
};

export const calcularPagoPrelacion2 = (documentos, { importe, fecha }) => {

    let importeRestante = importe;
    let documentosVigentes = [];
    let documentosActuales = [];
    const documentosProximos = {};
    let headersInfo = {};

    const aplicarAbonos = (documentos, importeRestante) => {

        for (let index = 0; index < documentos.length; index++) {
            
            const { Saldo } = documentos[index];

            importeRestante -= Saldo;

            if(importeRestante >= 0) {
                const Aplicado = Saldo;
                documentos[index] = { ...documentos[index], Aplicado, checked: true };
            }

            if(importeRestante < 0) {
                const Aplicado = Saldo + importeRestante;
                documentos[index] = { ...documentos[index], Aplicado, checked: true };
                return 0;
            }

            if(importeRestante === 0) return 0;
        }

        return importeRestante;
    };

    const segmentarDocumentos = () => {

        [...documentos].forEach((documento) => {

            const { Fecha } = documento;

            const fechaPago = moment(fecha).tz("GMT");
            const fechaDocumento = moment(Fecha, "MM-YYYY").tz("GMT");

            if (fechaDocumento.isBefore(fechaPago, "months")) {
                documentosVigentes.push(documento);
            }

            else if (fechaDocumento.isSame(fechaPago, "months")) {
                documentosActuales.push(documento);
            }

            else {

                if (!Object.keys(documentosProximos).find((key) => key === Fecha)) {
                    documentosProximos[Fecha] = [];
                } 

                documentosProximos[Fecha].push(documento);
            }
        });
    };

    const setHeadersInfo = (index, title) => { headersInfo[index] = title };

    const concatenarDocumentos = () => {

        if(documentosVigentes.length > 0) setHeadersInfo(0, "Vigentes");

        return Object.entries(documentosProximos).reduce((newDocumentos, [key, documentos], index) => {
            
            setHeadersInfo(
                index === 0 ? documentosVigentes.length : newDocumentos.length, 
                moment(key, "MM-YYYY").locale('es').format("MMMM YYYY").replace(".", "")
            );

            return newDocumentos.concat(documentos);
        }, documentosVigentes);
    }

    segmentarDocumentos();

    const descuentoEnActuales = documentosActuales.some((documento) => documento.Descuento > 0);
    const saldoMesesPrevios = documentosVigentes.reduce((total, documento) => total + documento.Saldo, 0);

    if (descuentoEnActuales && saldoMesesPrevios <= importe) {

        documentosVigentes.sort(
            (docA, docB) => docA.Prelacion - docB.Prelacion
        );
        
        documentosActuales.sort((docA, docB) => {
            const descuentoDiff = docB.Descuento - docA.Descuento;

            if (descuentoDiff !== 0) return descuentoDiff;

            return docA.Prelacion - docB.Prelacion;
        });

        documentosVigentes = documentosVigentes.concat(documentosActuales);

    } else {

        documentosVigentes = documentosVigentes.concat(documentosActuales).sort(
            (docA, docB) => docA.Prelacion - docB.Prelacion
        )
    }

    Object.entries(documentosProximos).forEach(([key, documentos]) => {

        documentosProximos[key] = documentos.sort((docA, docB) => {
            const descuentoDiff = docB.Descuento - docA.Descuento;

            if (descuentoDiff !== 0) return descuentoDiff;

            return docA.Prelacion - docB.Prelacion;
        });
    });

    const documentosOrdenados = concatenarDocumentos();

    const saldoAFavorGenerado = aplicarAbonos(documentosOrdenados, importeRestante);

    return { saldoAFavorGenerado, documentosOrdenados, headersInfo };
};