import React, {Component} from "react";
import ModalBorrar from "../modals/modalDelete";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import Request from "../../core/httpClient";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import TimeField from "react-simple-timefield";
import cogoToast from "cogo-toast";

import moment from "moment";
const request = new Request();

class AreasSchedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			add: false,
			eddit: false,
			inicio: moment(`${moment().format("YYYY/MM/DD")} 00:00`),
			inicioPrefijo: "AM",
			cierre: moment(`${moment().format("YYYY/MM/DD")} 00:00`),
			cierrePrefijo: "AM",
			Einicio: "",
			EinicioPrefijo: "",
			Ecierre: 0,
			EcierrePrefijo: "",
			IdHorario: 0,
			id: this.props.id,
			horarios: [],
			idHorarioDelete: 0,
			loadingSave: false,
			time1: "00:00",
			time2: "00:00",
			ubicacion: "",
			dayChecked: false,
			loading_day: false,
		};
	}

	delete = (id, close) => {
		this.handleDelete(id, close);
	};

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.setState({id: this.props.id, message: ""});
		}
	}

	resetData() {
		this.setState({time1: "00:00", time2: "00:00", ubicacion: ""});
	}

	async checkDay(day, id) {
		this.setState({loading_day: `${day.dia}-${id}`});
		const data = {
			id_horario: id,
			dia: day.dia,
			tipo: day.status ? 0 : 1,
		};
		const response = await request.post("/commonArea/day/schedule", data);
		if (response) {
			await this.props.getSchedules(this.props.id);
		}

		this.setState({loading_day: false});
	}

	render() {
		return (
			<div className="row full">
				<div className="column justify-center">
					<div className="card-table card-owners column" id="card-owners">
						<div className="table-responsiv column">
							<table>
								<tbody>
									<tr className="row-without-border">
										<th colSpan="9" className="text-left">
											<h4 className="weight-semi">Definición de horarios</h4>
										</th>
										{this.props.escritura && (
											<th>
												<button
													className="btn btn-mini btn-primary color-white"
													type="button"
													id="btn-modal-edit"
													onClick={this.openModal.bind(this, "AddNewSchedule")}
												>
													<i className=" fas fa-clock" /> &nbsp; Nuevo horario
												</button>
											</th>
										)}
									</tr>
									<tr className="text-left">
										<th className="">Horarios</th>
										<th className="text-center">D</th>
										<th className="text-center">L</th>
										<th className="text-center">M</th>
										<th className="text-center">M</th>
										<th className="text-center">J</th>
										<th className="text-center">V</th>
										<th className="text-center">S</th>
										<th className="text-center th-options-small">Editar</th>
										<th className="text-center th-options-small">Eliminar</th>
									</tr>
									{this.props.horarios.length > 0 ? this.props.horarios.map((horario, key) => (
										<tr key={key}>
											<td className="text-left">
												<h5>{horario.descripcion}</h5>
											</td>
											{horario.bloqueos.map((day, i) => {
												return (
													<td className="text-center">
														{this.state.loading_day === `${day.dia}-${horario.id_horario}` ? (
															<div className="row justify-center">
																<i className="fas fa-spinner fa-spin"></i>
															</div>
														) : (
															<React.Fragment>
																<input
																	type="checkbox"
																	id={`cbx${horario.id_horario}-${day.dia}`}
																	style={{display: "none"}}
																	checked={day.status}
																	onChange={this.checkDay.bind(this, day, horario.id_horario)}
																/>
																<label htmlFor={`cbx${horario.id_horario}-${day.dia}`} className="check align-center">
																	{day.status ? 
																		<svg width="18" height="18" viewBox="0 0 18 18">
																			<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
																		</svg>
																	 : 
																	 	<svg width="18px" height="18px" viewBox="0 0 18 18">
																			<path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
																			<polyline points="1 9 7 14 15 4"></polyline>
																		</svg>
																	}
																</label>
															</React.Fragment>
														)}
													</td>
												);
											})}
											<td className="text-center">
												<button type="button" onClick={this.openModal.bind(this, "EditModal", horario)}>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											<td className="text-center">
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={this.openModalDelete.bind(this, horario.id_horario)}
													style={{padding: "5px"}}
													disabled={!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " />
												</button>
											</td>
										</tr>
									)) : 
									<tr className="row-without-border">
										<th colSpan="10" className="text-center">
											<h5>No se encontraron resultados.</h5>
										</th>
									</tr> }
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={"AddNewSchedule"}
					sizeModalContainer={"small"}
					title={"Añadir horario"}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
					closeModal={this.resetData.bind(this)}
				>
					{this.renderAddNewSchedule()}
				</DinamicModal>
				<DinamicModal
					idModal={"EditModal"}
					sizeModalContainer={"small"}
					title={"Editar horario"}
					success={this.handleEddit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
					closeModal={this.resetData.bind(this)}
				>
					{this.renderAddNewSchedule()}
				</DinamicModal>
				<ModalBorrar id={this.state.idHorarioDelete} delete={this.delete.bind(this)} />
			</div>
		);
	}

	handleOpen() {
		this.setState({showModal: true});
		this.props.getSchedules();
	}

	openModal(idModal, horario) {
		this.setState({message: ""});
		if (horario.hora_inicio) {
			let ubicacion = "";
			if (horario.descripcion.indexOf("-") !== -1) {
				const desc = horario.descripcion.split("-");
				ubicacion = desc[1].replace(/ /g, "");
			}
			this.setState({
				id_horario: horario.id_horario,
				time1: horario.hora_inicio,
				time2: horario.hora_fin,
				ubicacion,
			});
		} else {
			this.setState({
				time1: `00:00`,
				time2: `00:00`,
			});
		}
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	openModalDelete(id) {
		this.setState({idHorarioDelete: id});
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove("hide-modal");
			modal.classList.add("show-modal");
		}
	}

	renderAddNewSchedule() {
		return (
			<div className="column justify-center">
				<div className="white-space-8" />
				<div className="row justify-center">
					<div className="column" style={{paddingLeft: "90px"}}>
						<div className="align-center row full">
							<p style={{paddingRight: "15px"}}>
								<b>Inicio:</b>
							</p>
							<div className="column full">
								<TimeField
									value={this.state.time1}
									onChange={(event, time) => {
										this.setState({time1: time});
									}}
									style={{
										fontSize: 31,
										width: "100%",
										color: "#333",
									}}
								/>
							</div>
						</div>
					</div>
					<div className="column" style={{paddingLeft: "100px"}}>
						<div className="align-center row full">
							<p style={{paddingRight: "15px"}}>
								<b>Cierre:</b>
							</p>
							<div className="column full">
								<TimeField
									value={this.state.time2}
									onChange={(event, time) => {
										this.setState({time2: time});
									}}
									style={{
										fontSize: 31,
										width: "100%",
										color: "#333",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="white-space-16" />
				<div className="column">
					<div className="row" style={{paddingLeft: "90px"}}>
						<div className="column label-medium">
							<p>
								<b>Ubicación:</b>
							</p>
						</div>
						<div className="column full" style={{paddingRight: "60px"}}>
							<input
								className="input input-modals"
								value={this.state.ubicacion}
								onChange={(event) => this.setState({ubicacion: event.target.value})}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async handleEddit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ""});
		let formato = "HH:mm";
		let inicio = moment(this.state.time1, formato);
		let cierre = moment(this.state.time2, formato);
		let ubicacion = this.state.ubicacion;
		let duracion = cierre.diff(inicio, "minutes");
		let descripcion = `${inicio.format(formato)} a ${cierre.format(formato)} hrs - ${ubicacion}`;

		if (duracion <= 0) {
			cogoToast.warn("La hora de cierre debe ser mayor a la de inicio.", {
				position: "bottom-right",
			});

			this.setState({loadingSave: false});
			return;
		}

		if (inicio > cierre) {
			cogoToast.warn("La hora de inicio no puede ser mayor a la de cierre.", {
				position: "bottom-right",
			});

			this.setState({loadingSave: false});
			return;
		}

		if (!this.horarioValido(inicio, cierre, ubicacion, true)) {
			cogoToast.warn("El nuevo horario no puede estar en el rango de horarios anteriores.", {
				position: "bottom-right",
			});
			this.setState({loadingSave: false});
			return;
		}

		let data = {
			IdHorario: this.state.id_horario,
			inicio: inicio.format(formato),
			cierre: cierre.format(formato),
			inicioP: "hrs",
			cierreP: "hrs",
			// inicioP: 		inicio.format('a'),
			// cierreP: 		cierre.format('a'),
			duracion: duracion,
			descripcion: descripcion,
		};

		const response = await request.post("/commonArea/update/schedule", data);
		if (response.updated) {
			this.props.getSchedules(this.state.id);
			this.closeModal("EditModal");
			cogoToast.success("Horario actualizado.", {
				position: "bottom-right",
			});
			this.setState({time1: "00:00", time2: "00:00"});
		} else {
			cogoToast.error("No se pudo actualizar horario.", {
				position: "bottom-right",
			});
			//this.setState({message: "NO SE PUDO EDITAR HORARIO"});
		}
		this.setState({loadingSave: false});
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ""});
		let formato = "HH:mm";
		let ubicacion = this.state.ubicacion;
		let inicio = moment(this.state.time1, formato);
		let cierre = moment(this.state.time2, formato);

		let duracion = cierre.diff(inicio, "minutes");
		let descripcion = `${inicio.format(formato)} a ${cierre.format(formato)} hrs - ${ubicacion}`;

		if (duracion <= 0) {
			cogoToast.warn("La hora de cierre debe ser mayor a la de inicio.", {
				position: "bottom-right",
			});

			this.setState({loadingSave: false});
			return;
		}

		if (inicio > cierre) {
			cogoToast.warn("La hora de inicio no puede ser mayor a la de cierre.", {
				position: "bottom-right",
			});

			this.setState({loadingSave: false});
			return;
		}

		let inRange = false;

		if (!this.horarioValido(inicio, cierre, ubicacion, false)) {
			cogoToast.warn("El nuevo horario no puede estar en el rango de horarios anteriores.", {
				position: "bottom-right",
			});
			this.setState({loadingSave: false});
			return;
		}

		let data = {
			id: this.props.id,
			inicio: inicio.format(formato),
			cierre: cierre.format(formato),
			inicioP: "hrs",
			cierreP: "hrs",
			// inicioP: 		inicio.format('a'),
			// cierreP: 		cierre.format('a'),
			duracion: duracion,
			descripcion: descripcion,
		};
		const response = await request.post("/commonArea/create/schedule", data);
		if (response.created) {
			this.closeModal("AddNewSchedule");
			this.props.getSchedules(this.props.id);
			cogoToast.success("Horario agregado.", {
				position: "bottom-right",
			});
			this.setState({time1: "00:00", time2: "00:00"});
		} else {
			//this.setState({message: "NO SE PUDO AGREGAR HORARIO"});
			cogoToast.error("No se pudo agregar horario.", {
				position: "bottom-right",
			});
		}
		this.setState({loadingSave: false});
	}

	horarioValido(inicio, cierre, ubicacion, editando) {
		let validado = true;
		if (Array.isArray(this.props.horarios)) {
			let horarios = this.props.horarios;

			if (editando) {
				horarios = horarios.filter((h) => h.id_horario != this.state.id_horario);
			}
			horarios = horarios.filter(h => {
				let str = '';
				const desc = h.descripcion.split('-');
				if (desc[1]) {
					str = desc[1].trim();
				}
				
				if (ubicacion == str) {
					return true;
				}
				return false;
			});
			horarios.forEach((horario) => {
				const ini = moment(`${moment().format("YYYY-MM-DD")} ${horario.hora_inicio}`);
				const fin = moment(`${moment().format("YYYY-MM-DD")} ${horario.hora_fin}`);

				if (inicio >= ini && inicio < fin) {
					validado = false;
				};

				if (cierre > ini && cierre <= fin) {
					validado = false;
				};

				if (ini >= inicio && ini < cierre) {
					validado = false;
				};

				if (fin > inicio && fin <= cierre) {
					validado = false;
				};
			});
		}

		return validado;
	}

	async handleDelete(id, close) {
		let data = {
			id: id,
		};
		const response = await request.post("/commonArea/delete/schedule", data);
		if (response && !response.error) {
			if (response.deleted && !response.empty) {
				this.setState({message: response.message});
				cogoToast.success("Horario eliminado.", {
					position: "bottom-right",
				});
			} else {
				this.setState({empty: true /*, message: response.message*/});
				cogoToast.error("No se pudo eliminar horario.", {
					position: "bottom-right",
				});
			}
		} else {
			cogoToast.error("No se pudo eliminar horario.", {
				position: "bottom-right",
			});
		}

		this.props.getSchedules(this.props.id);
		close();
	}
}

export default AreasSchedule;
