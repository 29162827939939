import React from 'react';
import SuperCore from './../../core/SuperCore';

class Body extends SuperCore{
    constructor(props){
        super(props);
        this.state ={
            screens:        this.props.screens,
            id_menu_activo: this.props.id_menu_activo
        }
    }
    async componentDidUpdate(prevProps, prevState){
        if(prevProps.screens != this.props.screens){
            await this.setState({screens: this.props.screens})
        }

        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            await this.setState({id_menu_activo: this.props.id_menu_activo})
        }

        

    }

    render(){
        return(
            this.state.screens ? 
                this.state.screens[this.state.id_menu_activo] ? 
                this.state.screens[this.state.id_menu_activo] 
                : 
                <div className="center-all">
                    <div className="row justify-center full">
                        <i className="fas fa-sync fa-spin"></i>
                    </div>
                </div>
            :
                <div className="center-all">
                    <div className="row justify-center full">
                        <i className="fas fa-sync fa-spin"></i>
                    </div>
                </div>
            
        );
    }
}

export default Body;