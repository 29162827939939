import React, {Component} from "react";
import {Link} from "react-router-dom";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import SuperCore from "../core/SuperCore";
import SuperModal from "../coreModal/SuperModal";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";

const request = new Request();

class InvoiceTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			dataInvoice: [],
			loadingInvoice: false,
		};
	}

	componentDidMount() {}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.dataInvoice != this.props.dataInvoice) {
			await this.setState({dataInvoice: this.props.dataInvoice});
		}

		if (this.props.subfraccionamientos != prevProps.subfraccionamientos) {
			await this.setState({
				subfraccionamientos: this.props.subfraccionamientos,
			});
		}
		if (this.props.fracName != prevProps.fracName) {
			await this.setState({
				fracName: this.props.fracName,
			});
		}
	}

	async savePDF(doc, key) {
		this.setState({downloadPdf: true, key: key});
		const response = await request.get("/documentos/get/pdf", {
			Id_Fraccionamiento: parseInt(doc.Id_Fraccionamiento),
			Id_DocCobro: parseInt(doc.Id_DocCobro),
		});
		//console.log(response);
		if (!response.error) {
			const linkSource = `data:application/pdf;base64,${response.content}`;
			const downloadLink = document.createElement("a");
			const fileName = `${doc.NumFolio}.pdf`;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();

			this.setState({downloadPdf: false, key: -1});
		} else {
			cogoToast.error(response.message, {
				position: "bottom-right",
				hideAfter: 5,
			});
			this.setState({downloadPdf: false, key: -1});
		}
	}

	render() {
		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="row">
					<table className="full">
						<thead className="sticky-th">
							<tr className="text-left">
							    <th className="text-start th-options">N° Folio</th>
								<th className="text-center th-options">
									<div className="row">
										Fecha &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "Fecha_Creacion" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "Fecha_Creacion")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "Fecha_Creacion")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "Fecha_Creacion")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-start th-options">N° Doc.</th>
								<th className="text-start th-options">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "numero_registro" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "numero_registro")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "numero_registro")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "numero_registro")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-start ">
									<div className="row">
										Receptor &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "RazonSocial" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "RazonSocial")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "RazonSocial")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "RazonSocial")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-center th-options">Importe</th>
								<th className="text-center th-options">Estado</th>
								<th className="text-center th-options">Ver</th>
								<th className="text-center th-options">Cancelar</th>
							</tr>
						</thead>
						<tbody>
							{this.props.dataInvoice.length == 0 ? (
								<tr>
									<td colSpan="9" className="text-center">
										Sin datos que mostrar.
									</td>
								</tr>
							) : (
								this.props.dataInvoice
									.filter((dc) => dc.numero_registro.toLowerCase().includes(this.props.filter) || dc.NumFolio.toLowerCase().includes(this.props.filter))
									.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength)
									.map((doc, key) => (
										<React.Fragment key={key}>
											<tr className={this.state.Id_DocTimbrado === doc.Id_DocTimbrado ? "row-active text-left" : ""}>
											    <td className="text-left">{doc.NumFolio}</td>
												<td className="text-left">{moment(doc.Fecha_Creacion).format("DD/MM/YYYY")}</td>
												<td className="text-left">{doc.NumDoc}</td>
												<td className="text-left">{doc.numero_registro}</td>												
												<td className="text-left">{doc.RazonSocial}</td>
												<td className="text-right">
													<NumberFormat value={doc.Total} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
												</td>												
												<td className="text-center">{doc.EdoFactura}</td>
												<td className="text-center">
												{doc.NumFolio !== "" && (
														<Link
															to="#"
															className="font-small"
															onClick={() => {
																if (!this.state.downloadPdf) {
																	this.savePDF(doc, key);
																}
															}}
														>
															{this.state.downloadPdf && this.state.key == key ? 
															<i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-eye"></i>}
														</Link>
													)}
												</td>
												<td className="text-right width-small ">
													{doc.Estado_Factura === 1 ? (
														<button
															className="btn-full justify-center btn-mini btn-secondary color-white"
															type="button"
															onClick={() => {
																this.props.openModalDelete(doc);
															}}
														>
															<i className="font-text fas fa-times"></i>
														</button>
													) : (
														<div className=""></div>
													)}
												</td>
											</tr>
										</React.Fragment>
									))
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default InvoiceTable;
