import React from 'react'
import { Consumer } from "../context";
import cogoToast from 'cogo-toast';
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";

import SidebarAdministracion from '../components/header/sidebarAdministracion';
import Header from "../components/header/header";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Permissions from '../middlewares/Permissions';
import Paginador from "../components/paginador/paginador";
import DinamicModal from '../components/dinamicModal/dinamicModal';

import Core from './../components/core/SuperCore';
import CarpetasTable from './../components/galerias/galeria-carpetas';

import sortableData from '../helpers/sortableDataTable';

const request = new Request();
const logo = require ("../img/logo-AppMosphera-r.png");

class Galerías extends Core{
    constructor(props){
        super(props);
        this.state = {
            escritura:      false,
            lectura:        false,
            page:           1,
            title_modal:    'Añadir carpeta',
            month_year:      new Date(),
            modalCrear:     true,
            min_date:       new Date(),
            nombre:         '',
            descripcion:     '',
			carpetas: [],
			pageLength: 20,
			direction: {
				nombre: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			filter: '',
        }
        this.validarPermisos();
	}

    async validarPermisos(){
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult()
        await this.setState({escritura: permisosInfo.escritura,lectura: permisosInfo.lectura});
        
    }

    async componentDidMount() {
		this.getSubmenu();
		// obtener info del usuario
		let user = await this.getUserInfo();
		await this.setState({usuario: user.info});
        await this.getCarpetas();
    }

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

    async getCarpetas() {
		this.setState({loadingCarpetas: true});
        const response = await request.get('/galerias/get/carpetas', {
            IdFraccionamiento: parseInt(localStorage.getItem('frac'))
        });

        if(response.error || response.empty){
			await this.setState({carpetas: [], loadingCarpetas: false});
			cogoToast.info(response.message, {position: 'bottom-right'});
            return false;
        }
        await this.setState({carpetas: response});

		this.setState({loadingCarpetas: false});
    }

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.carpetas, this.state.direction[key]);

		this.setState({
			carpetas: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	filter(carpetas) {
		carpetas = carpetas.filter(viv => {
			if (viv.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			};
		});
		return carpetas;
	};

    render() {
		let carpetas = [];

		if (this.state.carpetas) {
			carpetas = this.state.carpetas;
			carpetas = this.filter(carpetas);
		};
		
        return (
            <div className="owners column">
                <Helmet><title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title></Helmet>
                <Header sidebar = {true} nav={"administracion"}/>

                <div className="row">
                    <div className="column" id = {'sidebar'}>
                        <SidebarAdministracion/>
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                            {this.state.lectura ?
                            <React.Fragment>
                                    <Controls title={this.state.submenu}
                                        nuevo		=	{this.openModal.bind(this, 'newCarpeta')}
                                        newButton   =   {this.state.escritura}
										setFilter   =   {this.setFilter.bind(this)}
                                        excelButton =   {false}
										selectSub	=	{false}
                                    />


                                {this.state.loadingCarpetas ? 
                                    <div className="row justify-center">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                :
								<CarpetasTable
                                    carpetas 	= 	{carpetas}
									page    	= 	{this.state.page}
									usuario		=	{this.state.usuario}
									reload		= 	{this.getCarpetas.bind(this)}
									pageLength 	=	{this.state.pageLength} 
									sortBy		=	{this.sortableTable.bind(this)}
									filterColumn=	{this.state.filterColumn}
                                ></CarpetasTable>
							
								}
                                <div className="white-space-16" />
								<Paginador 
									pages={Math.ceil(carpetas.length / this.state.pageLength)} 
									setPage={this.setPage.bind(this)} 
									pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}
								/>
                            
                            </React.Fragment>
                            :
                                null
                            }

                            </div>
                        </div>
                    </div>
                </div>
				<DinamicModal 
					idModal					=	{'newCarpeta'}
					enforceFocus		    =	{true}
					sizeModalContainer		=	{'small'}
					title					=	{this.state.title_modal} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
					closeModal				=	{this.closeModal.bind(this)}
				>
					{this.renderNewCarpeta()}
				</DinamicModal> 
            </div>

        );
    }

	async handleSubmit(e) {
		e.preventDefault();
		this.setState({loadingSave: true})
		const usuario = await this.getUserInfo();
		const data = {
			IdFraccionamiento:  parseInt(localStorage.getItem('frac')),
			Nombre:             this.state.nombre,
			Usuario:            usuario.info.usuario
		}
		const response = await request.post('/galerias/carpetas/add', data);
		if(response.error){
			cogoToast.error('No se pudo crear la carpeta.', { position: 'bottom-right' });
			this.setState({loadingSave: false});
			return false;
		}
		this.getCarpetas();
		cogoToast.success('Carpeta creada con éxito.',{ position: 'bottom-right' });
		this.closeModal('newCarpeta');
		this.clearData();
		this.setState({loadingSave: false});
	}

	async clearData(){
		this.setState({
			month_year:     new Date(),
			nombre:         '',
			descripcion:    ''
		});
	}

	renderNewCarpeta() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center"> 
						<div className="column label-medium">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								typeof      =   "text" 
								className   =   "input input-modals"
								required
								autoComplete = 'off'
								value   =   {this.state.nombre}
								onChange =  { (e)=>{this.setState({nombre: e.target.value})}}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}

	setPage(page) {
		this.setState({ page });
	}

	async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
	}
}

export default Consumer(Galerías);