import React, { Component, memo, useState, useEffect } from "react";
import { Consumer } from "../../context";
import {Helmet} from "react-helmet";
import Header from "../../components/header/header";
import Controls from "../../components/controls/controls";
import SidebarAdministracion from "../../components/header/sidebarAdministracion";
import cogoToast from "cogo-toast";
import moment from 'moment';

import InvoiceTable from "../../components/paymentDocuments/invoiceTable";
import Paginador from "../../components/paginador/paginador";

import SuperCore from "../../components/core/SuperCore";
import SuperModal from "../../components/coreModal/SuperModal";
import Timbrado from "../../components/paymentDocuments/Timbrado";
import TimbradoStatus from "../../components/paymentDocuments/TimbradoStatus";

import Request from "../../core/httpClient";
import Exportar from "../../components/Billing/Exportar";
import Permissions from "../../middlewares/Permissions";

import ReactExport from "react-export-excel";
import sortableData from "../../helpers/sortableDataTable";
import ModalBorrar from "../../components/modals/modalDelete";
import ConfirmModal from "../../components/modals/confirmModal";
import { stubTrue } from "lodash";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const request = new Request();

class InvoiceDocuments extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			docs: [],
			page: 1,
			loading: true,
			columnas: null,
			escritura: false,
			lectura: false,
			pageLength: 20,
			filter: "",
			direction: {
				Unidad: "desc",
				Propietario: "desc",
			},
			filterColumn: {
				col: "",
				filt: "",
			},
			frac_name: null,
			subfraccionamientos: [],
			screens: [],
			screenCreateDocument: [],
			id_menu_activo: null,
			productos: [],
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			userName: "",
			userId: 0,
			factura: {},

			dataInvoice: [],
			loadingTimbrado: false,
			viviendas: [],

			docsTimbrar: [],
			docsStatus: [],

			closeModal: false,

			exportarScreen: [],
			confirmExport: false,
			startDate: new Date(),
			download: false,
			submenu: '',
			folio: "",
			start: moment().format('YYYY-MM-DD'),
			end: moment().format('YYYY-MM-DD')
		};
		Array.prototype.unique = (function (a) {
			return function () {
				return this.filter(a);
			};
		})(function (a, b, c) {
			return c.indexOf(a, b + 1) < 0;
		});

		this.validarPermisos();

		this.timbradoSubmitted = this.timbradoSubmitted.bind(this);
		this.timbradoStatusSubmitted = this.timbradoStatusSubmitted.bind(this);
	}

	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();
		await this.setState({
			escritura: permisosInfo.escritura,
			lectura: permisosInfo.lectura,
		});
	}

	componentDidMount() {
		this.getSubmenu();
		this.userData();
		this.getDataInvoice();
		this.getSubFraccionamientos();
		this.getFraccionamiento();
		this.loadViviendas();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.setState({subfraccionamiento: this.state.subfraccionamiento});
		}
		if (this.state.userName !== prevState.userName) {
			this.setState({userName: this.state.userName});
		}
		if (this.state.frac_name !== prevState.frac_name) {
			this.setState({frac_name: this.state.frac_name});
		}
		if (this.state.totalDocs !== prevState.totalDocs) {
			this.setState({frac_name: this.state.totalDocs});
		}
		if (this.state.confirmDocs !== prevState.confirmDocs) {
			this.setState({confirmDocs: this.state.confirmDocs});
		}
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	// generales
	async setPage(page) {
		await this.setState({page});
	}

	async paginatorChange(length) {
		await this.setState({pageLength: length});
		this.setPage(1);
	}

	async subChange(val) {
		await this.setState({
			subfraccionamiento: parseInt(val),
		});
	}

	async setFilter(filter) {
		await this.setState({filter: filter.toLowerCase()});
	}

	filter(folio) {
		folio = folio.filter(res => {
			if (res.NumFolio.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return folio;
	}

	async handleInvoice (invoice) {
		await this.setState({filter: invoice.toLowerCase()});
	}

	async setMenu(id) {
		await this.setState({id_menu_activo: null});
		await this.setState({id_menu_activo: id});
	}

	sortableTable(key) {
		let sd = new sortableData();
		const {items, direction} = sd.sortBy(key, this.state.dataInvoice, this.state.direction[key]);
		this.setState({
			dataInvoice: items,
			direction: {
				[key]: direction,
			},
			filterColumn: {
				col: key,
				filt: direction,
			},
		});
	}

	closeModal(id) {
		let modal = document.getElementById(id);
        if (modal) {
            modal.classList.add("hide-dinamic-modal");
            modal.classList.remove("show-dinamic-modal");
        }
	}

	// load
	async getDocTimbrado() {
	 	this.setState({ loadingTimbrado: true });
		// this.updateTimbradoScreen();

		this.setMenu(0);

		const res = await request.post("/documentos/get/documents/pendientestimbrado", {
			id_fraccionamiento: parseInt(localStorage.getItem("frac")) || 0,
		});

		const resTimbrado = await request.post("/documentos/get/documents/timbrado/errores", {
			id_fraccionamiento: parseInt(localStorage.getItem("frac")) || 0,
		});

		if (res.empty || res.error) {
			this.setState({ docsTimbrar: [] });
		}

		if (resTimbrado.empty || resTimbrado.error) {
			this.setState({ docsStatus: [] });
		}

		if (res.documents || resTimbrado.documents) {
			let vivs = [];
			if (res.documents) {
				res.documents.forEach((dd) => {
					vivs.push(dd.Id_Vivienda);
				});
			}

			if(resTimbrado.documents){
				resTimbrado.documents.forEach((dd) => {
					vivs.push(dd.Id_Vivienda);
				});
			}

			const resp = await request.post("/viviendas/getViviendaById", {
				viviendas_json_ids: JSON.stringify(vivs.unique()),
			});
			this.setState({ viviendas: resp.viviendas });
		}

		if (res.documents) {

			const docsCopy = res.documents.map(doc => {

				doc.numero_registro = this.getNumeroRegistro(doc.Id_Vivienda);
				return doc;
			});

			this.setState({ docsTimbrar: docsCopy });
		}

		if (resTimbrado.documents) {

			const docsCopy = resTimbrado.documents.map(doc => {

				doc.numero_registro = this.getNumeroRegistro(doc.Id_Vivienda);
				return doc;
			})

			this.setState({ docsStatus: docsCopy });
		}

		this.setState({ loadingTimbrado: false });

	}

	async getMetodosPago() {
		const result = await request.get("/documentos/get/metodopago/recibo");
		if (!result.empty || !result.error) {
			await this.setState({metodosPago: result.metodos});
		}
	}

	async loadViviendas() {
		const frac = await localStorage.getItem("frac");
		const dataVivienda = {id_fraccionamiento: frac, id_subfraccionamiento: 0};
		const responseViviendas = await request.post("/viviendas/getViviendaBySubFraccionamiento", dataVivienda);
		if (responseViviendas && !responseViviendas.error) {
			if (!responseViviendas.empty) {
				await this.setState({viviendas: responseViviendas.viviendas});
			}
		}
	}

	async userData() {
		let user = await this.getUserInfo();

		this.setState({userName: user.info.usuario, userId: user.info.id_usuario});
	}

	getNumeroRegistro(id_vivienda) {
		let viviendas = this.state.viviendas;
		if (viviendas.length > 0) {
			for (let i = 0; i < viviendas.length; i++) {
				if (parseInt(viviendas[i].id_vivienda) === parseInt(id_vivienda)) {
					return viviendas[i].numero_registro;
				}
			}
		}
	}

	// events

	async triggerCloseModal() {
		await this.setState({closeModal: !this.state.closeModal});
	}

	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem("frac");

		let data = {Idsub: fraccionamiento, filter: 1};
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response && !response.error) {
			if (response.subfraccionamiento && !response.empty) {
				this.setState({
					subfraccionamientos: response.subfraccionamiento,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					subfraccionamientos: [],
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
			});
		}
	}

	async getFraccionamiento() {
		const frac = await localStorage.getItem("frac");
		const idFraccionamiento = frac;
		const data = {IdFraccionamiento: idFraccionamiento};

		const response = await request.post("/admin/administracion/get/fraccionamiento", data);

		if (response && !response.error) {
			if (response.fraccionamiento && !response.empty) {
				await this.setState({
					frac_name: response.fraccionamiento[0].nombre,
				});
			}
		}
	}

	async getDataInvoice() {
		try {

		    await this.setState({loading: true, dataInvoice: []});

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
				inicio: this.state.start,
				fin: this.state.end
			};

			const response = await request.post("/facturacion/get/all", data);
			if (!response.empty && !response.error) {
				this.setState({
					dataInvoice: response.data,
				})
			} else {
				this.setState({
					dataInvoice: [],
					error: true,
					message: response.message,
				});
			};

			await this.setState({loading: false});
		} catch (error) {
			console.log(error);
		};
	}

	async sendEmail() {
		await this.setState({loading: true});
		
		const data = {
			id_fraccionamiento: localStorage.getItem('frac'),
		}
		const response = await request.post("/facturacion/sendEmail", data);

		if (!response.empty && !response.error) {
			cogoToast.success("Facturas enviadas de manera correcta.", {
				position: "bottom-right",
				hideAfter: 5,
			});
		}else {
			cogoToast.error("No se pudieron enviar las facturas.", {
				position: "bottom-right",
				hideAfter: 5,
			});
			await this.setState({loading: false});
			this.closeModalConfirm("confirmModal");
			return;
		}
		await this.setState({loading: false});
		this.closeModalConfirm("confirmModal");
		this.getDataInvoice();
	}

	openModalDelete(doc) {
		this.setState({factura: doc});
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove("hide-modal");
			modal.classList.add("show-modal");
		}
	}

	openModal(idModal) {
		let modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-modal");
			modal.classList.add("show-modal");
		}
	}

	// submitted

	timbradoSubmitted() {
		this.getDataInvoice();
		this.superCloseModal("timbrar");
	}

	timbradoStatusSubmitted() {
		this.getDocTimbrado();
		this.superCloseModal("timbrar");
	}

	async cancelDocument(doc) {
		//console.log(doc);
		let data = {Id_DocTimbrado: doc.Id_DocTimbrado, usuario_name: this.state.userName};
		const response = await request.post("/facturacion/cancelar", data);
		if (!response.empty && !response.error) {
			cogoToast.success("Factura cancelada con éxito.", {
				position: "bottom-right",
				hideAfter: 5,
			});
			this.setState({factura: {}});
			this.getDataInvoice();
			this.closeModal("deleteModal");
		} else {
			cogoToast.error(response.message, {
				position: "bottom-right",
				hideAfter: 5,
			});
		}
	}

	closeModalConfirm(id) {
		let modal = document.getElementById(id);
		if (modal) {
			modal.classList.add("hide-modal");
			modal.classList.remove("show-modal");
		}
	}

	render() {
		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header 
				    reload  = {this.getDataInvoice.bind(this)} 
					change  = {this.getDataInvoice.bind(this)} 
					sidebar = {true} 
					active  = {"EstadosCuenta"} 
					parent  = {"EstCuenta"} panel={"panel14"} 
					nav     = {"administracion"} 
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<Controls 
								    title       = {this.state.submenu} 
									nuevo       = {() => {}} 
									dataSet     = {this.state.docs} 
									newButton   = {false} 
									setFilter   = {this.setFilter.bind(this)} 
									subChange   = {this.subChange.bind(this)}
									selectSub   = {false}
									excelButton = {false}
								/>
								<div className="row">
									<div className="row-responsive full justify-start">
									    <div className="data-to column justify-center" style={{ maxWidth: '130px' }}>
									        <button
										        className="btn btn-primary btn-small color-white"
										         onClick={(event) => {
											        this.getDocTimbrado();
											        this.superOpenModal("timbrar");
										        }}
									        >
										        <i className="fas fa-circle"></i> &nbsp; Timbrar
									        </button>
								        </div>
										<div className="data-to column justify-center" style={{ maxWidth: '130px', marginLeft: "8px" }}>
									        <button
										        className="btn btn-primary btn-small color-white"
												onClick={this.openModal.bind(this,"confirmModal")}
									        >
										        <i className="fas fa-envelope"/> &nbsp; Enviar
									        </button>
								        </div>
									</div>
								    <div id='second_control' className="row-responsive full justify-end" style={{ backgroundColor: 'white' }} >
									    <div className="row">
									        <div className="data-from column justify-center">
											    <h5 className="color-black">Desde:</h5>
										    </div>
										    <div className="column">
											    <input
												    type="date"
												    id="startdate"
												    name="startdate"
												    className="input input-text"
												    placeholder="Desde"
												    value={this.state.start}
												    onChange={(event) => this.setState({start: event.target.value})}
											    />
											</div>
									    </div>
										<div style={{ width: '16px' }}></div>
										<div className="row">
										    <div className="data-to column justify-center">
											    <h5 className="color-black">Hasta:</h5>
										    </div>
										    <div className="column">
										        <input
												    type="date"
												    id="enddate"
												    name="enddate"
												    className="input input-text"
												    placeholder="Hasta"
												    value={this.state.end}
												    onChange={(event) => this.setState({end: event.target.value})}
											    />
										    </div>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column justify-center" style={{ maxWidth: '80px' }}>
											<button 
											    type="button" 
												className="btn btn-primary btn-small color-white justify-center" 
												onClick={this.getDataInvoice.bind(this)}
											>
												Buscar
											</button>
										</div>
								    </div>
								    <div className="row-responsive full justify-end">
								        <div className="row">
										    <div className="data-to column justify-center">
											    <h5 className="color-black">N° Folio:</h5>
										    </div>
										    <div className="column">
										        <input
												    type="text"
												    id="folio"
												    name="filterData"
												    style={{ maxWidth: '120px' }}
												    className="input input-text"
													value={this.state.folio}
												    onChange={(event) => this.setState({folio: event.target.value})}
											    />
										    </div>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column justify-center" style={{ maxWidth: '80px' }} >
											<button 
											    type="button" 
												className="btn btn-primary btn-small color-white justify-center" 
												onClick={this.handleInvoice.bind(this, this.state.folio)}
											>
												Buscar
											</button>
										</div>
									</div>
								</div>
								<div className="white-space-16"></div>
								{this.state.loading ? (
									<div className="row justify-center">
										<div className="white-space-16"></div>
										<i className="fas fa-spinner fa-spin"></i>
										<div className="white-space-16"></div>
									</div>
								) : (
									<InvoiceTable
										dataInvoice={this.state.dataInvoice}
										filterColumn={this.state.filterColumn}
										getDataInvoice={this.getDataInvoice.bind(this)}
										filter={this.state.filter}
										pageLength={this.state.pageLength}
										sortBy={this.sortableTable.bind(this)}
										page={this.state.page}
										escritura={this.state.escritura}
										fracName={this.state.frac_name}
										subfraccionamientos={this.state.subfraccionamientos}
										userName={this.state.userName}
										userId={this.state.userId}
										openModalDelete={this.openModalDelete.bind(this)}
									/>
								)}
								<div className="white-space-16"></div>
								<Paginador
									pages={Math.ceil(this.state.dataInvoice.length / this.state.pageLength)}
									setPage={this.setPage.bind(this)}
									pageLength={this.state.pageLength}
									paginatorChange={this.paginatorChange.bind(this)}
									showPageLenght={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<ModalBorrar id={this.state.factura} delete={this.cancelDocument.bind(this)} />
				<TimbrarModal
					id_menu_activo={this.state.id_menu_activo}
					loadingTimbrado={this.state.loadingTimbrado}
					//Timbrado
					docsTimbrar={this.state.docsTimbrar}
					successfulTimbradoSubmit={this.timbradoSubmitted}
					//TimbradoStatus
					docsStatus={this.state.docsStatus}
					successfulStatusSubmit={this.timbradoStatusSubmitted}
				/>
				<ConfirmModal 
				    id={"confirmModal"} 
					onSubmit={this.sendEmail.bind(this)} 
					onClose={this.closeModalConfirm.bind(this)} 
				>
					¿Estás seguro que desea realizar ésta acción?
				</ConfirmModal>
			</div>
		);
	}
}

const TimbrarModal = memo((props) => {

	const {
		id_menu_activo, triggerCloseModal, // SuperModal
		loadingTimbrado, // TimbradoScreens
		docsTimbrar, successfulTimbradoSubmit, // Timbrado
		docsStatus, successfulStatusSubmit // TimbradoStatus
	} = props;

	const [timbradoTextFilter, setTimbradoTextFilter] = useState("");
	const [timbradoSelectedDocs, setTimbradoSelectedDocs] = useState([]);

	const [statusTextFilter, setStatusTextFilter] = useState("");
	const [statusSelectedDocs, setStatusSelectedDocs] = useState([]);

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	useEffect(() => {

		if (loadingTimbrado) {
			setTimbradoTextFilter("");
			setStatusTextFilter("");
			setTimbradoSelectedDocs([]);
			setStatusSelectedDocs([]);
		}

	}, [loadingTimbrado]);

	const onFinishedSubmitting = (modalScreen, { error, warning, successful }) => {

		setLoadingSubmit(false);

		if(warning) {
			cogoToast.warn(warning, {
				position: "bottom-right",
				hideAfter: 5,
			});
		}

		if(error) {
			cogoToast.error(error, {
				position: "bottom-right",
				hideAfter: 5,
			});
		}

		if(successful) {

			cogoToast.success(error, {
				position: "bottom-right",
				hideAfter: 5,
			});

			modalScreen === "timbrado" ? 
			successfulTimbradoSubmit() 
			: successfulStatusSubmit();
		}
	}

	const timbradoScreens = [
		<Timbrado
			loadingTimbrado={loadingTimbrado}
			loadingSubmition={loadingSubmit}
			docsTimbrar={docsTimbrar}
			selectedDocs={timbradoSelectedDocs}
			textFilter={timbradoTextFilter}
			setTextFilter={(text) => setTimbradoTextFilter(text)}
			setSelectedDocs={(docs) => setTimbradoSelectedDocs(docs)}
			onStartSubmitting={() => setLoadingSubmit(true)}
			onFinishedSubmitting={(successful) => onFinishedSubmitting("timbrado", successful)}
		/>,
		<TimbradoStatus
			loadingTimbrado={loadingTimbrado}
			loadingSubmition={loadingSubmit}
			docsStatus={docsStatus}
			selectedDocs={statusSelectedDocs}
			textFilter={statusTextFilter}
			setTextFilter={(text) => setStatusTextFilter(text)}
			setSelectedDocs={(docs) => setStatusSelectedDocs(docs)}
			onStartSubmitting={() => setLoadingSubmit(true)}
			onFinishedSubmitting={(successful) => onFinishedSubmitting("timbradoStatus", successful)}
		/>
	]

	return (
		<SuperModal
			id={"timbrar"}
			title={"Timbrado SAT"}
			size={"static-nav"}
			screens={timbradoScreens}
			id_menu_activo={id_menu_activo}
			menus={["Facturar", "Estado timbrado"]}
			nexFooter={false}
			showClose={false}
			showSubmit={false}
			disableFooter={true}
			onSubmit={() => {}}
			triggerCloseModal={triggerCloseModal}
		/>
	)
});


export default Consumer(InvoiceDocuments);
