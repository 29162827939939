import React, { Component } from 'react';
import SuperCore from './../superModal/SuperModalCore';
import DinamicModal from './../dinamicModal/dinamicModal';
import ImpuestosModal from './impuestosModal';
import ModalBorrar from '../modals/modalDelete';

import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';



const request = new Request();


class ImpuestosTable extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            impuestos:      this.props.impuestos,
            impuestosLabel: 'Añadir impuesto',
            
            id_producto:        this.props.id_productoActivo,
            id_impuesto:        0,
            id_tipo:            0,
            valor:              16,
            retencion:          0,
            impuestoEdit:       null,
            impuestoOrigin:     0
            
        }
    }

    async componentDidMount(prevProps, prevState){
        
    }

    

    render(){

        let impuestos = this.props.productoImpuesto;
        
        return(
            <div className="full row align-center">
            <div className="column full">
                {/*<div className="">
                    <div className="justify-end">
                        <button className="btn btn-mini btn-primary color-white" 
                            type		=	"button" 
                            id			=	"btn-modal-edit" 
                            onClick		=	{(event)=>{
                                this.oldOpenModal("impuestos");
                            }} 
                            disabled	=	{!this.props.escritura}
                        >
                            <i className="fas fa-plus"/> Añadir
                        </button>
                    </div>
                </div>
                <div className="white-space-16" />*/}
 
                <div className="column card-table">
                    <div className="table-responsiv column">
                        <table>
                            <tbody>
                                <tr className="pad-bot row-without-border">
                                    <th colSpan="5" className="text-left">
                                        <h4 className="weight-semi">Impuestos</h4>
                                    </th>
                                    <th className="text-right">
                                        <button className="btn btn-mini btn-primary color-white"
                                            type="button"
                                            id="btn-modal-edit"
                                            onClick={(event) => {
                                                this.oldOpenModal("impuestos");
                                            }}
                                            disabled={!this.props.escritura}
                                        >
                                            <i className="fas fa-plus" /> Añadir
                                        </button>
                                    </th>
                                </tr>
                                <tr className="text-left">
                                    <th className="text-center">Impuesto</th>
                                    <th className="text-center">Tipo</th>
                                    <th className="text-center ">Valor</th>
                                    <th className="text-center ">Retención</th>
                                    <th className="text-center ">Editar</th>
                                    <th className="text-center ">Eliminar</th>
                                </tr>
                            {impuestos.map((impuesto, key)=>{
                                return(
                                    <tr>
                                        <td className="text-center">{impuesto.impuesto_desc}</td>
                                        <td className="text-center">{impuesto.tipo_desc}</td>
                                        <td className="text-center">{impuesto.Valor}</td>
                                        <td className="text-center">{impuesto.Retencion}</td>
                                        <td className="text-center">
                                            <button className ="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                onClick = {(event)=>{this.beforeOpenEdit(impuesto,'editarImpuesto')}}
                                            >
                                                <i className="fas fa-edit"></i>
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <button className ="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                 onClick = {(event)=>{
                                                        this.setState({impuestoEdit: impuesto});
                                                        //console.log("Abriendo modal de impuestos");
                                                        this.oldOpenModalDelete('deleteModal-impuestos');
                                                    }}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            {/* botones para enviar el formulario */}
            </div>

            <ModalBorrar 
            id		= 	{`deleteModal-impuestos-${this.state.id_producto}`}
            delete	=	{this.deleteImpuesto.bind(this)} 
        />

        {/* MODAL CREAR IMPUESTO */}
        <DinamicModal 
            idModal				=	{'impuestos'}
            sizeModalContainer	=	{'small'}
            title				=	{this.state.impuestosLabel} 
            success				=	{this.handleSubmit.bind(this)} 
            showBtnSuccess		=	{true}
            btnTextSuccess		=	{"GUARDAR"}
            showBtnCancel		=	{true}
            btnTextCancel		=	{"CANCELAR"}
            loadingSave			=	{this.state.loadingSave}
            message				=	{this.state.message}
            >
            <ImpuestosModal
                impuestos           =   {this.props.impuestos}
                tipos               =   {this.props.tipos}
                id_productoActivo	=	{this.props.id_productoActivo}
                updateData          =   {this.updateData.bind(this)}

            ></ImpuestosModal>
        </DinamicModal>
    
        {/* MODAL EDITAR IMPUESTO */}
        <DinamicModal 
            idModal				=	{'editarImpuesto'}
            sizeModalContainer	=	{'small'}
            title				=	{this.state.impuestosLabel} 
            success				=	{this.editImpuesto.bind(this)} 
            showBtnSuccess		=	{true}
            btnTextSuccess		=	{"GUARDAR"}
            showBtnCancel		=	{true}
            btnTextCancel		=	{"CANCELAR"}
            loadingSave			=	{this.state.loadingSave}
            message				=	{this.state.message}
            >
            <ImpuestosModal
                impuestos           = {this.props.impuestos}
                tipos               =   {this.props.tipos}
                id_productoActivo	=	{this.props.id_productoActivo}
                updateData          =   {this.updateData.bind(this)}

                impuestoEdit        =   {this.state.impuestoEdit}

            ></ImpuestosModal>
        </DinamicModal>
  </div>

        )
    }

    async deleteImpuesto(){
    
        //console.log("ELIMINANDO");

        let data = {
            id_producto:    this.state.id_producto,
            id_impuesto:    this.state.id_impuesto
        }
        const response = await request.post("/facturacion/productos/impuestos/delete", data);
        //console.log(response);
        if(!response.deleted || response.error){
            cogoToast.error('No se pudo eliminar impuesto.');
        }

        if(response.deleted){
            cogoToast.success('Impuesto eliminado con éxito.');
            this.oldCloseModalDelete(`deleteModal-impuestos-${this.state.id_producto}`)
            this.props.getProductosImpuestos();
        }
        
        // this.
    }
    async beforeOpenEdit(impuesto,idModal){
        
        await this.setState({impuestoEdit: impuesto, impuestoOrigin: impuesto.Id_Impuesto});
        this.oldOpenModal(idModal)
    }
    async updateData(data){
        
        await this.setState(data);
    }
    async editImpuesto(event){
        event.preventDefault();

        let data = {
            id_producto:        parseInt(this.state.id_producto),
            id_impuesto:        parseInt(this.state.id_impuesto),
            id_tipo_impuesto:   parseInt(this.state.id_tipo),
            valor:              parseFloat(this.state.valor),
            retencion:          parseFloat(this.state.retencion),
            old_impuesto:       parseInt(this.state.impuestoOrigin)
        }
        

        

        if(data.id_impuesto === 0){
            cogoToast.warn("Debes seleccionar un impuesto.",{
                position: 'bottom-center',
                // heading:	'Crear nuevo producto'
            });
            return;
        }
        if(data.id_tipo_impuesto === 0){
            cogoToast.warn("Debes seleccionar un tipo de impuesto.",{
                position: 'bottom-center',
                // heading:	'Crear nuevo producto'
            });
            return;
        }

        const response = await request.post("/facturacion/productos/impuestos/update", data);
        
        if(!response || response.error){
            
            cogoToast.error("No se pudo agregar impuesto.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
        }

        if(!response.updated){
            cogoToast.warn("Este impuesto ya existe, intenta con otro.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
        }

        if(response.updated){
            this.oldCloseModal("editarImpuesto");
            await this.clearData();
            cogoToast.success("Impuesto actualizado con éxito.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
            });
            this.props.getProductosImpuestos();
        }


    }

    async handleSubmit(event){
        event.preventDefault();

        let data = {
            id_producto:        parseInt(this.state.id_producto),
            id_impuesto:        parseInt(this.state.id_impuesto),
            id_tipo_impuesto:   parseInt(this.state.id_tipo),
            valor:              parseFloat(this.state.valor),
            retencion:          parseFloat(this.state.retencion)
        }
        

        if(data.id_impuesto === 0){
            cogoToast.warn("Debes seleccionar un impuesto.",{
                position: 'bottom-center',
                // heading:	'Crear nuevo producto'
            });
            return;
        }

        if(data.id_tipo_impuesto === 0){
            cogoToast.warn("Debes seleccionar un tipo de impuesto.",{
                position: 'bottom-center',
                // heading:	'Crear nuevo producto'
            });
            return;
        }
        

        const response = await request.post("/facturacion/impuestos/create", data);
        
        if(!response || response.error){
        
            cogoToast.error("No se pudo agregar impuesto.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
        }

        if(response.repetido){
            cogoToast.warn("Este impuesto ya existe, intenta con otro.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
        }

        if(response.created){
            this.oldCloseModal("impuestos");
            await this.clearData();
            cogoToast.success("Impuesto agregado correctamente.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
            });
            this.props.getProductosImpuestos();

        }



    }

    async clearData(){
        await this.setState({
            id_impuesto:    0,
            id_tipo:        0,
            valor:          16,
            retencion:      0
        })
    }




}

export default ImpuestosTable;