import React from 'react';
let LOGO = require ("../../img/logo-AppMosphera-r.png");

class PDFPreview extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            link: this.props.link,
            on: this.props.on
        }
    }
    async componentDidUpdate(prevProps, prevState){

        if(prevProps.link != this.props.link){
            await this.setState({link: this.props.link});
        }

        if(prevProps.on != this.props.on){
            await this.setState({on: this.props.on});
        }

    }

    closePreview(){
        let prev = document.getElementById(this.props.id);
        if(prev){
            prev.classList.remove('view-pdf');
            prev.classList.add('hide-pdf');
        }
        // this.setState({link: ''});
    }


    render(){
        //console.log("LINK");
        //console.log(this.state.link);
        //console.log("FIN LINK");
        return(
            <React.Fragment>
                <div className="pdf-container hide-pdf" id = {this.props.id}>
                    <div className="pdf-head">
                        <i className ="fas fa-caret-left color-white"
                            onClick = {(e)=>{
                                this.closePreview();
                            }}
                        > Salir</i>
                    </div>
                    <div className="iframe">
                    {this.state.on ? 

                        <iframe className="iframe" src={this.state.link} 
                            width = "100"
                            height = "100"
                        ></iframe>:
                        null

                        }
                    </div>

            </div>
            </React.Fragment>
        );

    }
}

export default PDFPreview;
