import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import cogoToast from "cogo-toast";
import Request from "../../core/httpClient";

const req = new Request();

const Importar = (props) => {

	const { viviendas, confirmEliminar, cargos, onCargosChanged, onConfirmEliminar } = props;

	const [mes, setMes] = useState("");
	const [producto, setProducto] = useState("");
	const [vivienda, setVivienda] = useState("");
	const [loading, setLoading] = useState(false);

	const selectCargos = (value, index = -1) => {

		const newCargos = index !== -1 ?
			cargos.map((cargo, cargoIndex) => {

				if (index === cargoIndex) return { ...cargo, eliminar: value };

				return cargo;
			})
			:
			cargos.map((cargo, index) => {

				return { ...cargo, eliminar: value };
			});

		onCargosChanged(newCargos);
	}

	async function buscarDocumentos() {
		try {
			setLoading(true);

			if (!mes && !producto && !vivienda) {
				cogoToast.warn("Se debe seleccionar al menos un parámetro.", { position: "top-center" });
				setLoading(false);
				return;
			};

			const fecha = moment(mes).isValid() === true ? moment(mes).format("MMYYYY") : "";

			const data = {
				id_vivienda: vivienda,
				id_producto: producto,
				fecha,
				id_fraccionamiento: localStorage.getItem("frac"),
			};

			const response = await req.post("/documentos/eliminar/get/documentos", data);

			if (response.documentos) {
				onCargosChanged(response.documentos);
				console.log("Cargos: ", response.documentos);
			} else {
				cogoToast.warn(response.message || "No se obtuvieron documentos.", { position: "bottom-right" });
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
		};
	};

	const cargosEliminarLength = cargos.filter((c) => c.eliminar).length;

	return (
		<div className="container">
			<div className="column full">
				<div className="white-space-24"></div>
				<div className="input-form-content row full align-center justify-between">
					<div className="row align-center">
						<div className="column label-big">
							<p>
								<b>Mes:</b>
							</p>
						</div>
						<DatePicker
							className="input input-modals"
							dateFormat="MM/yyyy"
							selected={mes}
							onChange={(value) => {
								setMes(value);
							}}
							locale="es"
							showMonthYearPicker
							placeholderText="Mes/Año"
						/>
					</div>
					<div className="row align-center">
						<div className="column label-big">
							<p>
								<b>Vivienda: </b>
							</p>
						</div>
						<input
							type="text"
							className="input input-modals"
							autoComplete="off"
							placeholder="Elija"
							name="unidad-eliminar"
							list="unidad-eliminar"
							//placeholder="Unidad"
							onChange={(event) => {
								let valor = event.target.value;
								let viviendaSelect = viviendas.filter((viv) => viv.numero_registro === valor);

								if (viviendaSelect[0]) {
									setVivienda(viviendaSelect[0].id_vivienda);
								} else {
									setVivienda(0);
								}
							}}
						/>
						<datalist id="unidad-eliminar">
							<select className="input input-modals" name="conceptos">
								{viviendas.map((vivienda, key) => (
									<option value={vivienda.numero_registro} key={key}></option>
								))}
							</select>
						</datalist>
					</div>
					<div className="column justify-center align-center">
						{loading ? (
							<i className="fas fa-spinner fa-spin"></i>
						) : (
							<button type="button" className="btn btn-primary btn-small color-white" onClick={() => buscarDocumentos()}>
								Buscar
							</button>
						)}
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="full table-rules row scroll">
					<table className="table full ">
						<thead>
							<tr>
								<th className="text-left">Vivienda</th>
								<th className="text-left">Mes/Año</th>
								<th className="text-left">Descripción</th>
								<th className="text-left">Importe</th>
								<th className="text-center">
									<div className="row justify-center">
										<label className="container-check">
											<input
												type="checkbox"
												className="check-option"
												defaultValue={false}
												onChange={(event) => selectCargos(event.target.checked)}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</th>
								<th className="text-center">Estatus</th>
							</tr>
						</thead>

						<tbody className="">
							{cargos.map((cargo, index) => {
								return (
									<tr key={cargo.Id_DocCobro}>
										<td className="text-left">{cargo.numero_registro}</td>
										<td className="text-left">{cargo.MesAnyo}</td>
										<td className="text-left">{cargo.Descripcion}</td>
										<td className="text-left">{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(cargo.Total)}</td>
										<td className="text-center">
											<div className="row justify-center">
												<label className="container-check">
													<input
														type="checkbox"
														className="check-option"
														checked={cargo.eliminar || false}
														onChange={(event) => selectCargos(event.target.checked, index)}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
										</td>
										<td className="text-center" style={{ width: "14%" }}>
											<div 
											    className="tooltip right"
												data-tooltip={cargo.result ? cargo.result.message : ""}
											>
												{cargo.status === "OK" && <i className="fas fa-check-circle color-green"></i>}
												{cargo.status === "KO" && <i className="fas fa-exclamation-circle color-red"></i>}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="white-space-16"></div>
				<div className="row justify-start align-center">
					<label className="container-check">
						<input
							type="checkbox"
							className="check-option"
							checked={confirmEliminar}
							disabled={cargosEliminarLength === 0}
							onChange={(event) => onConfirmEliminar(event.target.checked)}
						/>
						<span className="checkmark"></span>
					</label>
					<p>
						<b>{`Se van a eliminar ${cargosEliminarLength} cargos`}</b>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Importar;
