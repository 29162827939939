/**
 *  Environment.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO 
 *  @description: Página de datos del emisor
 */

import React, { Component } from "react";
import { Consumer } from "../../../context";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/header/sidebar";
import Request from "../../../core/httpClient";
import Permissions from '../../..//middlewares/Permissions';
import toaster from "toasted-notes";

const request = new Request();

class senderData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                idUsuario: 0,
                idPerfil: 0,
                idFraccionamiento: 0,
                idAdministracion: 0,
                nombre: null
            },
            escritura: false,
            Fraccionamiento: null,
            disabled: true,
            dataBilling: [],
            estados:    [],
            regimen:    [],
            regimen_id: 0,
            submenu: ''
        }

        this.validarPermisos();

    }

    async componentDidMount() {
        await this.load();
        // get países
        await this.getEstados();
        await this.getRegimen();
    }
    async getRegimen(){
        await this.setState({regimen_id:this.state.dataBilling.Id_RegimenFiscal});

        const response = await request.post("/facturacion/regimen", []);
        if(response.empty || response.error){
            await this.setState({regimen: []});
        }

        if(response.regimen){
            await this.setState({regimen: response.regimen});
        }
        //console.log(response.regimen);

    }

    async getEstados(){
        await this.setState({estado: this.state.dataBilling.Id_EstadoPais})
        let data = {
            id_pais: parseInt(this.state.dataBilling.Id_Pais)
        };

        const response = await request.post("/facturacion/estados", data);

        if(response.empty || response.error){
            await this.setState({estados: []});
        }

        if(response.estados){
            await this.setState({estados: response.estados});
        }
        //console.log(response.estados);
        
    }
    
    async load() {
        const submenu = await this.props.context.getSubmenuName();
        if (submenu) {
            this.setState({ submenu });
        }
        
        const user = await this.props.context.loadUser();
        //console.log({user});
        const frac = window.localStorage.getItem("frac");
       
        if (user) {
            await this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    nombre: user.id.usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento,
                    idAdministracion: user.id.id_administracion
                },
                Fraccionamiento: frac
            });
            await this.getDataBilling();

        }
    }


    async validarPermisos() {
        let permisos = new Permissions();
        //  permisos.getResult();
        let permisosInfo = await permisos.getResult()
        this.setState({ escritura: permisosInfo.escritura });
        //console.log({ permisosInfo });

    }
    change() {
        this.load()
    }

    handleEddit() {
        this.setState({ disabled: !this.state.disabled });
    }


    onChangeData(key, event) {
        const dataBilling = this.state.dataBilling;
        dataBilling[key] = event.target.value;
        this.setState({dataBilling: dataBilling});
    }

    async getDataBilling() {
        let data = {
            id_fraccionamiento: this.state.Fraccionamiento
        }
        //console.log({data})
        const response = await request.post("/facturacion/emisor", data)
        //console.log("respuesta frac ->",response);
        if (response && !response.error) {
            if (response.emisor && !response.empty) {
                await this.setState({dataBilling: response.emisor[0]})
                //console.log("data frac ->",response.emisor[0]);
                console.info(this.state.dataBilling);
            } else {
                await this.setState({
                    empty: true,
                    message: response.message
                });
                //console.log("empty ->",response.message);
            }
        } else {
            await this.setState({
                error: true,
                message: response.message
            });
            //console.log("error ->",response.message);
        }
    }

    async editDataBilling(event) {
        this.setState({loading: true, message: ''});
        event.preventDefault();
        const form = event.target;
        let data = {
            id_fraccionamiento: this.state.Fraccionamiento,
            razonSocial:    form.BName.value,
            rfc: form.BRfc.value,
            domicilio: form.BDom.value,
            id_regimenFiscal: this.state.regimen_id,
            correo: form.BEmail.value,
            telefono: form.BTell.value,
            id_pais: this.state.dataBilling.Id_Pais,
            id_estadoPais: this.state.estado,
            ciudad: form.BCity.value,
            municipio: form.BMunicipality.value,
            cp: form.BCp.value,
            usuario: this.state.user.nombre

        }
        //console.log({data})
        const response = await request.post("/facturacion/emisor/update", data)
        //console.log("respuesta frac ->",response);
        if (response && !response.error) {
            if (response.updated && !response.empty) {
                this.setState({loading: false});
                await this.handleEddit();
                this.getDataBilling();
                this.setNotification('Datos actualizados correctamente',false); 
            } else {
                await this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
                //console.log("empty ->",response.message);
                this.setNotification('Los datos no se pudieron actualizar',true);
            }
        } else {
            await this.setState({
                error: true,
                message: response.message,
                loading: false
            });
            this.setNotification('Los datos no se pudieron actualizar',true);
            //console.log("error ->",response.message);
        }
    }
    setNotification(MSJ, error){

        let ICON    = "fa-check-circle";
        let COLOR   = "green";
        if(error){
            ICON    = "fa-times";
            COLOR   = "red";
        }

        toaster.notify(
            <React.Fragment>
                <span className={`fas ${ICON}`}style={{"color":`${COLOR}`}}></span>
                &nbsp;
                <p>{MSJ}</p>
            </React.Fragment>, {
            duration: 5000,
            position: 'bottom-right'
        });
    }
    render() {


        let billingData = [];
        if(this.state.dataBilling){
            billingData = this.state.dataBilling;
        }

        let estados = [];
        if(this.state.estados){
            estados = this.state.estados;
        }

        let regimen = [];
        if(this.state.regimen){
            regimen = this.state.regimen;
        }

        return (
            <div className="billing column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header sidebar={true} active={'entorno'} parent={'config'} panel={'panel4'} nav={"catalogos"} change={this.change.bind(this)} />

                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                {/* <div className="navigation align-center">
                                    <div className="justify-start">
                                        <h3 className="weight-semi">Datos Emisor</h3>
                                    </div>
                                </div>
                                <div className="white-space-16" /> */}

                                <div className="card-table card-owners column" id="card-owners">
                                    <form className="column align-center" onSubmit={this.editDataBilling.bind(this)}>
                                        <div className="white-space-24" />
                                        <div className="container column">

                                            <div className="edit-button justify-start">
                                            <div className="column full align-start"> 
                                                <h3>{this.state.submenu}</h3>
                                            </div>
                                            <div className="column full"> 
                                                <div className="justify-end">
                                                    &nbsp;
                                                    <button className="btn btn-primary btn-small color-white"
                                                        type="button"
                                                        id="btn-modal-edit"
                                                        onClick={this.handleEddit.bind(this)}
                                                        disabled={!this.state.escritura}>
                                                        <i className="fas fa-pen"></i> Editar
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            </div>

                                            <div className="white-space-24"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title">
                                                    <h4>Razón social:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BName" id="name" 
                                                    value={billingData.RazonSocial}  
                                                    onChange={this.onChangeData.bind(this, 'RazonSocial')} 
                                                    disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title">
                                                    <h4>Régimen Fiscal:</h4>
                                                </div>
                                                <div className="justify-start align-center">

                                                    <select className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
                                                        name        =   "estado"      
                                                        value       =   {this.state.regimen_id}
                                                        onChange    =   {(event)=>{this.setState({regimen_id:    event.target.value})}}
                                                        disabled={this.state.disabled} 
                                                    >
                                                        {  
                                                            regimen.map((reg, key) => (
                                                            <option 
                                                                value   =   {reg.Id_RegimenFiscal} 
                                                                key     =   {key}
                                                            >
                                                                {reg.clave_nombre}
                                                            </option>
                                                            )) 
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title">
                                                    <h4>Domicilio:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BDom" id="dom" 
                                                    value={billingData.Domicilio} 
                                                    onChange={this.onChangeData.bind(this, 'Domicilio')} 
                                                    disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title">
                                                    <h4>Correo:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BEmail" id="email" 
                                                    value={billingData.Correo} 
                                                    onChange={this.onChangeData.bind(this, 'Correo')} 
                                                    disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title-duo">
                                                    <h4>Teléfono:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BTell" id="tell" 
                                                    value={billingData.Telefono} 
                                                    onChange={this.onChangeData.bind(this, 'Telefono')}  
                                                    disabled={this.state.disabled} />
                                                </div>
                                                <div className="justify-start label-title label-midle">
                                                    <h4>RFC:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BRfc" id="rfc" 
                                                    value={billingData.RFC}  
                                                    onChange={this.onChangeData.bind(this, 'RFC')}  
                                                    disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title-duo">
                                                    <h4>País:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={ "full input input-disabled font-regular" } type="text" name="BCountry" id="Country" 
                                                    value={billingData.nombrePais} 
                                                    onChange={this.onChangeData.bind(this, 'nombrePais')} 
                                                    disabled={false} />
                                                </div>
                                                <div className="justify-start label-title  label-midle">
                                                    <h4>Estado:</h4>
                                                </div>
                                                <div className="justify-start align-center">


                                                    <select className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
                                                        name        =   "estado"      
                                                        value       =   {this.state.estado}
                                                        onChange    =   {(event)=>{this.setState({estado:    event.target.value})}}
                                                        disabled={this.state.disabled} 
                                                    >
                                                        {  
                                                            estados.map((edo, key) => (
                                                            <option 
                                                                value   =   {edo.id_estado} 
                                                                key     =   {key}
                                                            >
                                                                {edo.nombre}
                                                            </option>
                                                            )) 
                                                        }
                                                    </select>
                                                    
                                                </div>
                                                   
                                            </div>
                                           
                                            <div className="white-space-16"></div>
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title-duo">
                                                    <h4>Ciudad:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BCity" id="BCity" 
                                                    value={billingData.Ciudad} 
                                                    onChange={this.onChangeData.bind(this, 'Ciudad')}  
                                                    disabled={this.state.disabled} />
                                                </div>
                                                <div className="justify-start label-title  label-midle">
                                                    <h4>Municipio:</h4>
                                                </div>
                                                <div className="justify-start align-center">
                                                    <input className={this.state.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"} type="text" name="BMunicipality" id="BMunicipality" 
                                                    value={billingData.Municipio} 
                                                    onChange={this.onChangeData.bind(this, 'Municipio')}  
                                                    disabled={this.state.disabled} />
                                                   
                                                    
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                           
                                            <div className="billing-rows row full">
                                                <div className="justify-start label-title">
                                                    <h4>CP:</h4>
                                                </div>
                                                <div className="justify-start align-center">

                                                    <input className={this.state.disabled ? "full input input-disabled font-regular small-row " : "full input input-modals font-regular small-row"} type="text" name="BCp" id="cp" 
                                                    value={billingData.CP} 
                                                    onChange={this.onChangeData.bind(this, 'CP')} 
                                                    disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                            <div className="white-space-16" />
                                            {this.state.disabled == false &&
                                            <div className="btn-container justify-end">
                                                <button type="button" className="btn-action-c color-white"  onClick={this.handleEddit.bind(this)} >
                                                    CANCELAR
                                                </button>
                                                <button type="submit" className="btn-action-s color-white"  >
                                                    {this.state.loading ?
                                                        <div className="row justify-center">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div> :
                                                        <div>
                                                            ACTUALIZAR
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        <div className="white-space-24" />
                                       
                                    </form>
                                </div>
                                <div className="white-space-48"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    

}



export default Consumer(senderData);
