import moment from 'moment-timezone';

//Http
import Request from "../../core/httpClient";

const request = new Request();

export const getInformes = async ({ id_fraccionamiento, desglose, cargosFechas }) => {
    try {

        const cargos_desde = moment(cargosFechas.desde).format('MMYYYY');
        const cargos_hasta = moment(cargosFechas.hasta).format('MMYYYY');

        const data = { id_fraccionamiento, cargos_desde, cargos_hasta, desglose };

        const response = await request.get('/informes/get', data);

        const { informes, message, empty, error } = response;

        if(error) return { error: message, informes: [] };

        if(empty) return { informes: [], message };

        return { informes };

    } catch (error) {
        console.log(error);
        return { error, informes: [] };
    }
};

export const getSubfraccionamientos = async (fraccionamiento) => {

    try {
        const data = { Idsub: fraccionamiento, filter: 1 };

        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        const { subfraccionamiento, message, empty, error } = response;

        if (!response || error) return { error: message, subfraccionamientos: [] };

        if (!subfraccionamiento || empty) 
            return { message, subfraccionamientos: [] };

        if (subfraccionamiento.length === 1) {
            localStorage.setItem("subfrac", subfraccionamiento[0].id_subfraccionamiento);

            return { 
                subfraccionamientos: subfraccionamiento,
                subfraccionamiento: subfraccionamiento[0].id_subfraccionamiento
            }
        }
        else return { subfraccionamientos: subfraccionamiento };
        

    } catch (error) {
        console.log(error);
        return { error: "Error al cargar Subfraccionamientos", subfraccionamientos: [] }
    }
};

export const getViviendas = async (idFrac, idSub) => {

    try {
        const data = { idFrac, idSub };

        const response = await request.post("/subfraccionamientos/get/viviendas", data);
        const { subTotalViviendas, message } = response;

        if (subTotalViviendas) return { viviendas: subTotalViviendas };

        else return { viviendas: [], error: message }

    } catch (error) {
        console.log(error);
        return { error, viviendas: [] };
    }
};

export const getProductos = async (id_fraccionamiento) => {

    try {
        const data = { id_frac: id_fraccionamiento, filtro: 0 };

        const response = await request.post('/facturacion/productos', data);
        const { productos, empty, error } = response;

        if(error) return { error, productos: [], };

        if(empty) return { productos: [], message: "NO SE ENCONTRARON PRODUCTOS" };

        if(productos) return { productos };

    } catch (error) {
        console.log(error);
        return { error, productos: [] };
    }
};
