import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import NotificationsTypeTable from '../components/notications/notificationsTypeTable';
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
import { Consumer } from "../context";
const request = new Request();

class ConfigurationTipeNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configuracionTipo: 		'',
            tipos: 					[],
			configuracionTipo: 		'',
			page: 					1,
			filter: 				'',
			subfraccionamiento: 	localStorage.getItem('sufrac'),
			loadingSave: 			false,
			escritura:				false,
			lectura:				false,
			logo:					require ("../img/logo-AppMosphera-r.png"),
			pageLength: 			20, 
			direction: {
				Descripcion: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: ''
		}
		
		this.validarPermisos();
	}
	
	async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura,lectura: permisosInfo.lectura});
		 //console.log(permisosInfo);

	}

    reload() {

	}
	
	openModal(idModal) {
		this.setState({mesagge: null, configuracionTipo:''});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		document.getElementById('conname').focus();
	}
	closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

    /*funcion del paginador*/
    setPage(page) {
         this.setState({ page });
    }

	async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
	}

    changeFrac() {
        this.getType()

	}

    getTipos() {
        this.getType()
    }

	nuevoTipo() {
		this.setState({showModal: true});
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(tipos) {
		
		tipos = tipos.filter(tip => {
			if(tip.Descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return tipos;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.tipos, this.state.direction[key]);

		this.setState({
			tipos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

    render() {
		let tipos = [];

        if (Array.isArray(this.state.tipos)) {
			tipos = this.state.tipos;
			tipos = this.filter(tipos);
        }
        return (
            <div className="owners column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header sidebar={true} active={'tipoN'} parent={'config'} panel={'panel3'} nav={"catalogos"} change={this.changeFrac.bind(this)} reload={this.reload.bind(this)} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
								{this.state.lectura?
								<React.Fragment>
									<Controls title={this.state.submenu}
									nuevo			=	{this.openModal.bind(this,"addNotifications")}
									dataSet			=	{tipos}
									setFilter		=	{this.setFilter.bind(this)}
									subChange		=	{this.subChange.bind(this)}
									selectSub		=	{false}
									newButton		=	{this.state.escritura}
								/>
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<NotificationsTypeTable 
										types		=	{tipos} 
										page		=	{this.state.page} 
										reload		=	{this.getTipos.bind(this)}
										escritura	=	{this.state.escritura}	
										pageLength 	= 	{this.state.pageLength} 
										sortBy		=	{this.sortableTable.bind(this)}
										filterColumn=	{this.state.filterColumn}
									>
									</NotificationsTypeTable>
								}
								<div className="white-space-16" />
								<Paginador 
									pages={Math.ceil(tipos.length / this.state.pageLength)} 
									setPage={this.setPage.bind(this)} 
									pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}
									/>
                            
								<div className="white-space-16" />
								</React.Fragment>:
								null
								}
							</div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal				=	{'addNotifications'}
					sizeModalContainer	=	{'small'}
					title				=	{'Nuevo tipo de notificación'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					closeModal			=	{this.resetValue.bind(this)}
					loadingSave			=	{this.state.loadingSave}
					//message				=	{this.state.message}
				>
				{this.renderAddNotification()}
				</DinamicModal>
            </div>


        );
	}

	componentDidMount() {
		this.getTipos();
		this.getSubmenu();
	}
	componentDidUpdate(){
		// this.refs['input' + this.props.line ].focus();
	}

	renderAddNotification() {
		return(
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-big">
						<p><b>Tipo de notificación:	</b></p>
					</div>
					<div className="column full">
						<input 
							type			=	"text" 
							name			=	"conceptName" 
							id				= 	"conname"
							value			=	{this.state.configuracionTipo}
							onChange		=	{event => this.setState({ configuracionTipo: event.target.value })} 
							className		=	"input input-modals" 
							autoComplete = 'off'
							required			 
							maxLength		=	"64"
							
						/>
					</div>
				</div>
			</div>
		</div>
   
		)
	}

    handleOpen() {
        this.setState({ showModal: true })
    }
    handleClose() {
        this.setState({ showModal: false })
	}
	
	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	async resetValue() {
		this.setState({configuracionTipo: ''})
	}

    async  handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ''});
        let data = {
            id: 	parseInt(localStorage.getItem('frac')),
            texto: 	this.state.configuracionTipo

        }
		const response = await request.post("/notificaciones/create/type/notification", data);
		
		if(response.created){
			this.getType();
			this.closeModal("addNotifications");
			cogoToast.success('Tipo de notificación añadido.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({message: "NO SE PUDO CREAR NUEVO TIPO DE NOTIFICACIÓN"});
			cogoToast.error('No se pudo crear nuevo tipo de notificación.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});

	}
	
	closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    async getType() {
		this.closeModal('addNotifications');
        this.setState({ loading: true });

        let data = {
            id: parseInt(localStorage.getItem('frac'))
        }
        const response = await request.post("/notificaciones/get/type/notification", data)

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    tipos: response.notifications
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }
		this.setState({ loading: false });
    }
}

export default Consumer(ConfigurationTipeNotification);