import React, { Component, Fragment } from "react";
import moment from "moment";
import ReactModal from "react-modal";
import ModalEddit from "./modalEddit";
import ModalBorrar from "../modals/modalDelete";
import NumberFormat from "react-number-format";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import { Consumer } from "../../context";
import cogoToast from "cogo-toast";

import Request from "../../core/httpClient";
const request = new Request();

class BudgetExpensesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
      showModal: false,
      conceptos: [],
      concepto_facturas: [],
      id_factura: "",
      concepto: "",
      sub_fraccionamiento: 0,
      presupuestos: [],
    };
  }

  // traer presupuestos
  async getPresupuestos() {
    this.setState({ presupuestos: [], loadingPresupuestos: true });
    let data = {
      //IdSub: this.state.subfraccionamiento,
      id_fraccionamiento: localStorage.getItem("frac"),
      //year: this.state.year
    };

    const response = await request.post("/presupuestos/get/all/items", data);
    console.log(response);
    if (response && !response.error) {
      if (response.presupuestos && !response.empty) {
        this.setState({
          presupuestos: response.presupuestos[0],
          //idYear: response.presupuestos[0].id_presupuesto,
          //disabled: false
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          presupuestos: [],
          disabled: true,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
    this.props.getDefaultPresupuesto(this.state.presupuestos[0].id_presupuesto);
    this.props.stopLoading();
    this.setState({ loadingPresupuestos: false });
  }

  openModal(idModal, concep, id) {
    this.setState({
      message: null,
      concepto: concep,
      id_factura: id,
      id_concepto_row: id,
    });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove("hide-dinamic-modal");
      modal.classList.add("show-dinamic-modal");
    }
    document.getElementById("fact").focus();
  }

  openRow(type, id_concepto) {
    const data = { type, id_concepto };

    window.localStorage.setItem("openRow", JSON.stringify(data));

    if (this.state.id_concepto === id_concepto && type === this.state.rowType) {
      window.localStorage.removeItem("openRow");
      this.setState({ rowType: "", id_concepto: 0 });
      return;
    }

    this.setState({
      rowType: type,
      id_concepto: id_concepto,
      id_concepto_row: id_concepto,
    });
    this.getConceptoFacturas(id_concepto);
  }

  async getConceptoFacturas(id_concepto) {
    this.setState({ concepto_facturas: [], loading_facturas: true });

    let data = {
      id_concepto: id_concepto,
      id_fraccionamiento: localStorage.getItem("frac"),
      id_subfraccionamiento: this.props.id_subfraccionamiento,
      id_presupuesto: this.props.id_presupuesto,
    };
    console.log("presupuest enviado" + data.id_presupuesto);

    const response = await request.post("/facturas/concepto", data);
    if (!response.empty && !response.error) {
      await this.setState({
        concepto_facturas: response.concepto_facturas,
      });
    } else {
      await this.setState({ concepto_facturas: [] });
    }

    this.setState({ loading_facturas: false });
  }

  reload() {
    this.props.reload();
  }
  delete = (id) => {
    this.handleDelete(id);
  };
  render() {
    let conceptos = [];

    if (this.props.conceptos) {
      // console.log(facturas);
      conceptos = this.props.conceptos;
    }

    let total_aprobado_anual = 0;
    let total_gasto_acumulado = 0;
    let total_diferencia = 0;

    if (Array.isArray(conceptos)) {
      total_aprobado_anual = conceptos.reduce(
        (accumulator, concepto) => accumulator + concepto.aprobado_anual,
        0
      );
      total_gasto_acumulado = conceptos.reduce(
        (accumulator, concepto) => accumulator + concepto.gasto_acumulado,
        0
      );
      total_diferencia = total_aprobado_anual - total_gasto_acumulado;
    }

    return (
      <div className="card-table card-owners column" id="card-owners">
        <div className="row align-center">
          <p className="font-regular weight-medium color-dark">Presupuesto:</p>
          <div className="column">
            <select
              className="input input-modals"
              id="presupuesto"
              value={this.props.id_presupuesto}
              onChange={this.props.setPresupuesto.bind(this)}
            >
              {this.state.presupuestos.map((presupuesto) => (
                <option
                  key={presupuesto.id_presupuesto}
                  value={presupuesto.id_presupuesto}
                >
                  {presupuesto.nombre}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="white-space-16" />

        <table className="full">
          <thead>
            <tr className="text-left">
              {/* <th className="">Folio</th> */}
              <th className="description">Concepto</th>
              <th className="text-left">Aprobado Anual</th>
              <th className="text-left">Gasto acumulado</th>
              <th className="text-left">Diferencia</th>
              <th className="text-center th-options-small">Facturas</th>
              {/*               <th className="text-left">Fecha</th> */}
              {/*               <th className="text-center th-options-small"> Editar </th> */}
              {/* <th className="text-center th-options-small"> Eliminar</th> */}
            </tr>
          </thead>
          <tbody>
            {conceptos.length > 0
              ? conceptos
                .slice((this.props.page - 1) * 7, this.props.page * 7)
                .map((concepto, key) => {
                  return (
                    <Fragment>
                      <tr
                        className={
                          this.state.id_concepto_row ===
                          concepto.id_factura && "row-active"
                        }
                        key={key}
                      >
                        <td>{concepto.nombre_concepto}</td>
                        <td className="text-right">
                          <NumberFormat
                            value={concepto.aprobado_anual || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={concepto.gasto_acumulado || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={
                              (concepto.aprobado_anual || 0) -
                              (concepto.gasto_acumulado || 0)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            className={
                              (concepto.aprobado_anual || 0) -
                                (concepto.gasto_acumulado || 0) >=
                                0
                                ? "diference_positive"
                                : "diference_negative"
                            }
                          />
                        </td>
                        <td className="text-center">
                          <button
                            className="btn-full btn-mini btn-secondary color-white tooltip" /*tooltip */
                            onClick={this.openRow.bind(
                              this,
                              "concepto",
                              concepto.id_concepto
                            )}
                          >
                            &nbsp;
                            <i
                              className={`font-small fa fa-list  ${this.state.rowType === "concepto" &&
                                  this.state.id_concepto_row ===
                                  concepto.id_concepto
                                  ? "color-red"
                                  : ""
                                }`}
                            />
                            {/* <span className="tooltiptext">{house.total_propietarios}</span>  */}
                          </button>
                        </td>
                      </tr>
                      {this.state.id_concepto === concepto.id_concepto &&
                        this.state.rowType === "concepto" ? (
                        this.state.loading_facturas ? (
                          <tr>
                            <td className="text-center" colSpan="8">
                              <i className="fas fa-spinner fa-spin"></i>
                            </td>
                          </tr>
                        ) : (
                          <tr className="acordeon-row">
                            <td className="acordeon" colSpan="9">
                              <div className="full row align-center">
                                <div className="column full">
                                  <div className="column card-table">
                                    <div className="table-responsiv column">
                                      <table>
                                        <tbody>
                                          <tr className="pad-bot row-without-border">
                                            <th
                                              colSpan="2"
                                              className="text-left"
                                            >
                                              <h4 className="weight-semi">
                                                Facturas
                                              </h4>
                                            </th>
                                          </tr>
                                          <tr className="text-left">
                                            <th className="">Folio</th>
                                            <th className="">Fecha</th>
                                            <th className="">Tipo</th>
                                            <th className="text-center th-options-small">
                                              Importe
                                            </th>
                                          </tr>
                                          {this.state.concepto_facturas.map(
                                            (factura, key) => (
                                              <tr key={key}>
                                                <td className="text-left">
                                                  {factura.num_factura}
                                                </td>
                                                <td className="text-left">
                                                  {moment(
                                                    factura.fecha
                                                  ).format("DD-MM-YYYY")}
                                                </td>
                                                <td className="text-left">
                                                  {factura.tipo}
                                                </td>
                                                <td className="text-right">
                                                  <NumberFormat
                                                    value={factura.importe}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                  />
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </td>
                          </tr>
                        )
                      ) : null}
                    </Fragment>
                  );
                })
              : null}
            <tr>
              <td className="weight-bold" colSpan={1}>
                {" "}
                <p className="label-red">Total</p>
              </td>
              {/* <td className="weight-bold" >s </td> */}
              <td className="weight-bold text-right label-red" colSpan={1}>
                <NumberFormat
                  value={total_aprobado_anual}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </td>
              <td className="weight-bold text-right label-red" colSpan={1}>
                <NumberFormat
                  value={total_gasto_acumulado}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </td>
              <td className="weight-bold text-right" colSpan={1}>
                <NumberFormat
                  value={total_diferencia}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  className={
                    total_diferencia >= 0
                      ? "diference_positive"
                      : "diference_negative"
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>

        {/*  <ModalBorrar
          id={this.state.id_factura}
          delete={this.delete.bind(this)}
        /> */}
        {/*  <ModalEddit
          id={this.state.id_factura}
          concepto={this.state.concepto}
          conceptos={this.state.conceptos}
          reload={this.reload.bind(this)}
          subfraccionamientos={this.props.subfraccionamientos}
          escritura={this.props.escritura}
        /> */}
        {/* <DinamicModal
          idModal={"newExpense"}
          sizeModalContainer={"small"}
          title={"Añadir factura"}
          success={this.handleSubmit.bind(this)}
          showBtnSuccess={true}
          btnTextSuccess={"GUARDAR"}
          showBtnCancel={true}
          btnTextCancel={"CANCELAR"}
          loadingSave={this.state.loadingSave}
          message={this.state.message}
        >
          {this.renderNewExpense()}
        </DinamicModal> */}
      </div>
    );
  }

  componentDidMount() {
    this.load();
    this.getPresupuestos();
    // this.getConcepts();
  }

  openModalDelete(id) {
    this.setState({ id_factura: id, id_concepto_row: id });
    let modal = document.getElementById("deleteModal");
    if (modal) {
      modal.classList.remove("hide-modal");
      modal.classList.add("show-modal");
    }
  }

  closeModal(idModal) {
    let modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.add("hide-modal");
      modal.classList.remove("show-modal");
    }
  }
  componentDidUpdate() { }

  renderNewExpense() {
    let subfraccionamientos = [];
    if (Array.isArray(this.props.subfraccionamientos)) {
      subfraccionamientos = this.props.subfraccionamientos;
    }
    return (
      <div className="full row align-center">
        <div className="column full">
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>{this.props.subLabel}:</b>
              </p>
            </div>
            <div className="column full">
              <select
                className="input input-modals"
                name="subfraccionamiento"
                id="subfrac"
                value={this.state.sub_fraccionamiento}
                onChange={(event) =>
                  this.setState({ sub_fraccionamiento: event.target.value })
                }
              >
                {subfraccionamientos.map((sub, index) => (
                  <option key={index} value={sub.id_subfraccionamiento}>
                    {sub.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>Concepto:</b>
              </p>
            </div>
            <div className="column full">
              <select name="concepto" id="" className="input input-modals">
                {this.state.conceptos.map((concepto, key) => (
                  <option key={key} value={concepto.id_concepto}>
                    {concepto.descripcion}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>N° Factura:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="text"
                name="factura"
                className="input input-modals"
                autoComplete="off"
                required
                minLength={2}
              />
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>Fecha:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="date"
                name="fecha"
                className="input input-modals"
                placeholder="Fecha"
                required
                minLength="2"
                maxLength="4"
              />
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>Importe:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="number"
                name="importe"
                className="input input-modals"
                autoComplete="off"
                required
                minLength={2}
                step="any"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  async load() {
    const user = await this.props.context.loadUser();
    if (user) {
      this.setState({
        user: {
          idUsuario: user.id.id_usuario,
          idPerfil: user.id.id_perfil,
          idFraccionamiento: user.id.id_fraccionamiento,
        },
      });
    }
  }

  handleOpen() {
    this.setState({ showModal: true });
    this.getConcepts();
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  async handleSubmit(event) {
    this.setState({ loadingSave: true, message: "" });
    event.preventDefault();

    const form = event.target;

    let data = {
      IdSub: form.subfraccionamiento.value,
      IdConcepto: parseInt(form.concepto.value),
      importe: parseInt(form.importe.value),
      NumFactura: form.factura.value,
      Fecha: form.fecha.value,
      IdPresupuesto: this.props.IdPresupuesto,
      IdUsuario: this.state.user.idUsuario,
    };

    let validado = false;

    const response = await request.post(
      "/presupuestos/presupuesto/verify/factura",
      { NumFactura: form.factura.value }
    );
    if (!response.concepts) {
      validado = true;
    } else {
      //await this.setState({message: "EL NÚMERO DE FACTURA YA EXISTE"});
      cogoToast.warn("El número de la factura ya existe.", {
        position: "bottom-right",
      });
    }

    if (validado) {
      const response = await request.post(
        "/presupuestos/presupuesto/create/factura",
        data
      );
      if (response.created) {
        this.closeModal("newExpense");
        this.props.reload();
        cogoToast.success("Factura creada.", {
          position: "bottom-right",
        });
      } else {
        //this.setState({message: "NO SE PUDO CREAR FACTURA"});
        cogoToast.error("No se pudo crear factura.", {
          position: "bottom-right",
        });
      }
    }
    this.setState({ loadingSave: false });
  }

  // traer todos los conceptos por fraccionamiento
  async getConcepts() {
    this.setState({ conceptos: [], idconcepto: 0 });
    let data = {
      IdFraccionamiento: localStorage.getItem("frac"),
    };
    let response = await request.post(
      "/presupuestos/get/all/real/concepts",
      data
    );

    if (response && !response.error) {
      if (response.concepts && !response.empty) {
        this.setState({
          conceptos: response.concepts,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }

  async handleDelete(id) {
    let data = {
      idDetalle: id,
    };
    const response = await request.post("/presupuestos/delete/factura", data);
    if (response && !response.error) {
      if (response.deleted && !response.empty) {
        this.reload();
        cogoToast.success("Factura eliminada.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          //message: response.message
        });
        cogoToast.error("No se pudo eliminar factura.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({ error: true /*, message: response.message*/ });
      cogoToast.error("No se pudo eliminar factura.", {
        position: "bottom-right",
      });
    }
  }
}

export default Consumer(BudgetExpensesTable);