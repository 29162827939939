import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from '../components/header/header';
import { Consumer } from "../context";
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Permissions from '../middlewares/Permissions';
import DocumentsTable  from '../components/documents/documents-table';
import cogoToast from 'cogo-toast';
import superagent from "superagent";
import SH from './../helpers/StringHelper';
import sortableData from '../helpers/sortableDataTable';

let jwt = require('jsonwebtoken');

const request = new Request();

class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {

            URL_PDF_API: process.env.NODE_ENV == 'development' ? 'docsappmosphera.dwitsites.com' : 'http://documentos.appmosphera.com/',
            escritura:              false,
            page:                   0,
            documentosObtenidos:    false,
            documentos:             [],
            subfraccionamiento:     localStorage.getItem('subfrac') || 0,
			fraccionamiento:        localStorage.getItem('frac') || 0,
            selectedFile:			null,
            base64:                 '',
            nombrePdf:              'NuevoDocumento',
            subfraccionamientos:    [],
            subf:                   localStorage.getItem('subfrac') || 0,
            loadingSave:            false,
            saveMessage:            '',
            logo:					require ("../img/logo-AppMosphera-r.png"),
            lectura:                false,
            carpeta:                '',
            folders:                [],
            folder:                 '',
			pageLength: 20,
            direction: {
                path: 'desc'
            },
            filterColumn: {
                col: '',
                filt: ''
            },
            submenu: '',
            filter: ''
		}
		
		this.validarPermisos();
	}

	async validarPermisos(){
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult()
        await this.setState({escritura: permisosInfo.escritura,lectura: permisosInfo.lectura});
        
    }
    setPage(page) {
        this.setState({ page });
    }

    reload(){
        this.getCarpeta();
        this.getDocumentos();
    }
    changeFrac(){
        // //console.log("Cambiando sub fraccionamiento");
    }

    async paginatorChange( length ){
        await this.setState({pageLength:length});
        this.setPage(1);
    }
    
    async subChange(sub) {
        await this.setState({subfraccionamiento: sub, subf:sub});
        // await this.getDocumentos();
	}

    openModal(idModal) {
		this.setState({saveMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    async componentDidMount(){
        this.getSubmenu();
        // await this.getSubFraccionamientos();
        await this.getCarpeta();
        await this.getDocumentos();

        //console.log(process.env.NODE_ENV);
        
	}

    componentDidUpdate(prevProps, prevState) {
		// if(this.state.subf !== prevState.subf) {
		// 	this.getDocumentos();
        // }
		
	}

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuAdminName();
        if (submenu) {
            this.setState({ submenu });
        }
    }

    async getDocumentos() {
        await this.setState({documentosObtenidos: false});
        let data = {
            id_fraccionamiento:    localStorage.getItem('frac')
        }
        const response = await request.post("/subfraccionamientos/get/documentos", data);
        
		if(Array.isArray(response.documentos) && response.documentos.length > 0) {
            await this.setState({documentos: response.documentos});
            //console.log(response.documentos);
		} else {
            this.setState({documentos: []});
            
        }
        //console.log(response);
        await this.setState({documentosObtenidos: true});
    }

    async changeSubFrac(event){
		let subfraccionamiento 		=  event.target.value;
		await this.setState({subfraccionamiento:	subfraccionamiento});
	}


async uploadFile(event){
    event.preventDefault();
    await  this.setState({loadingSave:true, saveMessage:''});

    let extencion = '.pdf';
    let sh = new SH();
    let path    = localStorage.getItem('frac') + '/';
        path   += `${this.state.folder}/`;

    let folder = this.state.folders.filter((f)=>f.path == this.state.folder);
        
    let pdf =  document.getElementById('file').files[0];
    let formData = new FormData();

    // si es mayor de 16M
    if(!this.bytesToSize(pdf.size)){
        cogoToast.warn('El tamaño del archivo debe ser menor a 16M.',{
            position: 'bottom-right'
        });
        this.setState({ loadingSave: false });

        return;
    }
        
    
    if(!(pdf instanceof File)){
        cogoToast.warn('Debes seleccionar un archivo PDF.',{
            position: 'bottom-right'
        })
        this.setState({ loadingSave: false });

        return;
    }
    
    cogoToast.loading('Sé paciente, esto puede demorar unos segundos.',{
        position: 'bottom-right'
    })

    let nombre_archivo = pdf.name.split('.');
    nombre_archivo  = sh.eliminarCaracteresRaros(nombre_archivo[0]) + '.' +nombre_archivo[1];


    formData.append('pdf',pdf);
    formData.append('path_carpeta',path);
    formData.append('nombre_archivo',nombre_archivo);
    formData.append('id_folder',folder[0].id);
    formData.append('id_fraccionamiento',localStorage.getItem('frac'));
    
    

    let response = await request.post('/documents/postfile',
        formData
    );
    //console.log(response);

    if(response.error){
        cogoToast.error('No se pudo subir archivo.',{
            position: 'bottom-right'
        })
        this.setState({ loadingSave: false });
        return;
    }


    if(response.created){
        cogoToast.success('Archivo subido con éxito.',{
            position: 'bottom-right'
        });

        
        this.getCarpeta();
        this.getDocumentos();
        document.getElementById('file').value = '';
        this.closeModal('modal-subir-documentos');
        
    }

    this.setState({ loadingSave: false });
}

 bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + ' ' + sizes[i];
    //console.log((bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]);

    if(i > 2) return false;

    let fake_size = (bytes / Math.pow(1024, i)).toFixed(1);
    if(i == 2 && fake_size >= 16.0){
        //console.log("Es mayo");
        //console.log(fake_size);
        return false
    }

    //console.log("paso");
    return true;
    // return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};
    
    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.folders, this.state.direction[key]);

        this.setState({
            folders: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    }

    setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	filter(folders) {
		folders = folders.filter(viv => {
			if (viv.path.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			};
		});
		return folders;
	};

    render() {
        return this.renderBaseTemplate();
    }

    renderBaseTemplate() {
        let folders = [];

		if (this.state.folders) {
			folders = this.state.folders;
			folders = this.filter(folders);
		};

        return(
            <div className="owners column">
                <Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
                <Header sidebar={true} nav={"administracion"} />
                <div className="row">
                    <div className="column" id="sidebar">
                    <SidebarAdministracion />
                    </div>
                
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">

                                {this.state.lectura?
                                <React.Fragment>
                                    <Controls title={this.state.submenu}
                                        nuevo		    =	{this.openModal.bind(this, 'modal-subir-documentos')}
                                        dataSet		    =	{this.state.documentos}
                                        setFilter	    =	{this.setFilter.bind(this)}
                                        subChange	    =	{this.subChange.bind(this)}
                                        newButton       =   {this.state.escritura}
                                        excelButton     =   {false}
                                        selectSub	    =	{false}
                                        // search          =   {false}
                                />
                                {!this.state.documentosObtenidos ?
                                        <div className="row justify-center">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div>
                                        :
                                        <React.Fragment>
                                        <div className="row">
                                            <button className="btn btn-primary color-white btn-small"
                                                onClick = {(event)=>this.openModal('carpeta')}
                                            >Nueva carpeta</button>
                                        </div>
                                        <div className="white-space-16"></div>

                                        <DocumentsTable
                                            folders     =   {folders}
                                            documentos  =   {this.state.documentos}
                                            page        =   {this.state.page}
                                            escritura   =   {this.state.escritura}
                                            reload      =   {this.reload.bind(this)}
                                            pageLength	=   {this.state.pageLength}
                                            sortBy      =   {this.sortableTable.bind(this)}
                                            filterColumn=   {this.state.filterColumn}
                                            />

                                        
                                        </React.Fragment>
                                        
                                }

                                <div className="white-space-16"></div>

                                <Paginador 
                                    pages   =   {Math.ceil(folders.length / this.state.pageLength)} 
                                    setPage =   {this.setPage.bind(this)} 
                                    pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}
                                />
                                </React.Fragment>:
                                null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <DinamicModal 
					idModal					=	{'carpeta'}
                    enforceFocus		    =	{true}
					sizeModalContainer		=	{'small'}
					title					=	{'Añadir carpeta'} 
                    success					=	{this.createFolder.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
					closeModal				=	{this.closeModal.bind(this)}
				>
                    {this.renderCarpeta()}
				</DinamicModal>


                <DinamicModal 
                    enforceFocus		    =	{true}
					idModal					=	{'modal-subir-documentos'}
					sizeModalContainer		=	{'big'}
					title					=	{'Agregar documento'} 
                    success					=	{this.uploadFile.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
					closeModal				=	{this.closeModal.bind(this)}
				>
                    {this.nuevoForm()}
					{/* {this.postUpload()} */}
				</DinamicModal>
            </div>
        );
    }

    async createFolder(e){
        e.preventDefault();
        
        if(this.state.carpeta == null || this.state.carpeta.length < 4 ){
            cogoToast.warn('El nombre de la carpeta debe ser menor o igual a 4 caracteres.',{
                position: 'bottom-right'
            });

            return;
        }

        const response = await request.post('/documents/crear/carpeta',{
            carpeta:    this.state.carpeta,
            id_frac:    localStorage.getItem('frac')
        });

        if(response.error){
            let msj = 'No se pudo crear carpeta.';
            if(response.existente)
                msj = 'La carpeta ya existe.';
            cogoToast.error(msj,{
                position: 'bottom-right'
            })
        }

        if(response.created){

            this.closeModal('carpeta');
            await this.setState({carpeta: ''});
            // this.getCarpetas();
            cogoToast.success('Carpeta creada con éxito.',{
                position: 'bottom-right'
            });
            this.getCarpeta();
        }
    }
    renderCarpeta(){
        
        return(

            
            <div className="full row align-center">
				<div className="column full">
                    <div className="input-form-content row full align-center"> 
                        <div className="column label-medium">
                            <p>
                                <b>Carpeta:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                className       = "input input-modals"
                                type            = 'text'
                                autoComplete    = 'off'
                                required
                                value           =   {this.state.carpeta}      
                                onChange        =   {(e)=>{this.setState({carpeta: e.target.value})}}
                            ></input>
                        </div>
                    </div>
				</div>
			</div>

        );
    }

    closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
    }
    async getCarpeta(){

        const response = await request.post('/documents/carpetas/get',{
            id_fraccionamiento: localStorage.getItem('frac')
        });

        if(response.error || response.empty){
            await this.setState({folders: []});
        }

        if(response.carpetas){
            await this.setState({
                folders: response.carpetas,
                folder: response.carpetas[0].path
            });
            //console.log(response.carpetas);
        }
        //console.log(response);
        


    }
    
	nuevoForm() {
        
        
		return (
            <div className="full row align-center">
				<div className="column full">
                    <div className="input-form-content row full align-center"> 
                        <div className="column label-medium">
                            <p>
                                <b>Carpeta:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <select 
                                className	=	"input input-modals" 
                                name		=	"carpeta" 
                                required 
                                value       = {this.state.folder}
                                onChange    = {(e)=>{this.setState({folder: e.target.value})}}
                            >
                            {this.state.folders.map(folder => 
                                <option key={folder.id} value={folder.path}>
                                    {folder.path}
                                </option>
                            )}
                        </select>
                        </div>
                    </div>
					
					<div className="white-space-8" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Documento PDF:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								type		=	"file" 
								name		=	"file" 
								id			= 	"file"
								className	=	"input input-modals" 
                                accept="application/pdf"
								required	
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Consumer(Documents);
