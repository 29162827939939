import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import moment from 'moment-timezone';
import PersonasTable from "../components/personas/personasTable";
import Header from "../components/header/header";
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Sidebar from "../components/header/sidebar";
import Request from "../core/httpClient";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Permissions from '../middlewares/Permissions';
import StringHelper from '../helpers/StringHelper';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
import BudgetTableTeoric from '../components/budgetTeoric/budgetTeoric';
import SidebarAdministracion from '../components/header/sidebarAdministracion';
import BudgetTableProveedor from '../components/budgetProveedor/budgetProveedor';
const request = new Request();


class ProveedorPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			subfraccionamientos: [],
			subfraccionamientoNombre: '',
			subfraccionamiento: localStorage.getItem('subfrac'),
			idSub: 0,
			proveedores: [],
			id: 0,
			total: 0,
			totalReal: 0,
			idYear: 0,
			totales: [],
			disabled: false,
			page: 1,
			year: parseInt(moment().format("YYYY")),
			IdPresupuesto: 0,
			filter: '',
			escritura: false,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			pageLength: 20,
			direction: {
				razon_social: 'desc',
				rfc: 'desc',
				municipio: 'desc',
				cp: 'desc',
				nombre_pais: 'desc',
				nombre_estado: 'desc',
				cuenta_contable: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			subLabel: '',
			idfrac: localStorage.getItem('frac') || 0,
			razon_social: '',
			rfc: '',
			municipio: '',
			pais: '',
			estado: '',
			cp: '',
			cuenta_contable: '',
			observaciones: '',
			nombre_concepto: '',
			paises: [],
			estados: [],
			conceptos: [],
			Id_Pais: 1,
			Id_Estado: 1,
			id_concepto: 1
		}

		this.validarPermisos();
	}

	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult()
		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
		//console.log(permisosInfo);

	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getProveedores();
		}

		if (this.state.year !== prevState.year) {
			this.getProveedores();
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	async paginatorChange(length) {
		await this.setState({ pageLength: length });
		this.setPage(1);
	}

	reload() {
		this.getProveedores();
	}

	changeFrac() {
		this.setState({
			subfraccionamientos: [],
			idSub: 0,
			proveedores: [],
			total: 0,
			conceptos: [],
			idYear: 0,
		})
		this.getProveedores();
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	openModal(idModal, proveedor) {
		this.clearValues();
		const { fraccionamiento } = this.props.context;
		this.setState({ formMessage: null, vivienda: { fraccionamiento }, proveedor: proveedor });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		//document.getElementById('sunfrac').focus();
	}
	clearValues() {
		//document.getElementById('importe').value = '';
		//document.getElementById('observaciones').value = '';
	}
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	setYear(event) {
		this.setState({ year: event.target.value });
	}

	filter(presupuestos) {
		presupuestos = presupuestos.filter(con => {
			if (con.razon_social.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return presupuestos;
	}

	filterIndex(proveedores) {
		let aux = [];
		// //console.log(presupuestos);


		proveedores.forEach(item => {

			aux.push({
				razon_social: item.razon_social,
				id_proveedor: item.id_proveedor,
				rfc: item.rfc,
				municipio: item.municipio,
				cp: item.cp,
				nombre_estado: item.nombre_estado,
				nombre_pais: item.nombre_pais,
				cuenta_contable: item.cuenta_contable,
				nombre_concepto: item.nombre_concepto

			});

		});
		return aux;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.proveedores, this.state.direction[key]);

		this.setState({
			proveedores: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let proveedores = [];
		let subfraccionamientos = [];
		let paises = [];
		let estados = [];
		let conceptos = [];



		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}

		if (Array.isArray(this.state.proveedores)) {
			proveedores = this.state.proveedores;
			// //console.log(dataSet);
			proveedores = this.filter(proveedores);
		}

		if (Array.isArray(this.state.paises)) {
			paises = this.state.paises;
		}

		if (Array.isArray(this.state.estados)) {
			estados = this.state.estados;
		}

		if (Array.isArray(this.state.estados)) {
			conceptos = this.state.conceptos;
		}


		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header sidebar={true} active={'presupuestoProveedor'} parent={'presup'} panel={'panel2'} nav={"administracion"} />
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls title={this.state.submenu}
											nuevo={this.openModal.bind(this, 'newBudget')}
											dataSet={proveedores}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={this.state.escritura}
										/>
										{this.state.loadingProveedores ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<BudgetTableProveedor
												proveedores={proveedores}
												page={this.state.page}
												reload={this.reload.bind(this)}
												escritura={this.state.escritura}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												paises={this.state.paises}
												estados={this.state.estados}
												conceptos={this.state.conceptos}
											></BudgetTableProveedor>
										}
										<div className="white-space-16" />
										<Paginador
											pages={Math.ceil(proveedores.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>

									</React.Fragment> :
									null
								}

							</div>
						</div>
					</div>
				</div>
				{<DinamicModal
					idModal={'newBudget'}
					sizeModalContainer={'normal'}
					title={'Añadir proveedor'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
				>
					<div className="full row align-center">
						<div className="column full">
							<div className="white-space-16" />
							<div className="input-form-content row full align-center ">
								<div className="column label-medium">
									<p><b>Razón social:	</b></p>
								</div>

								<div className="column full">
									<input
										type="text"
										name="razon_social"
										id="razon_social"
										className="input input-modals"
										autoComplete='off'
										maxLength="64"
										required
										value={this.state.razon_social}
										onChange={event => this.setState({ razon_social: event.target.value })}
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>RFC:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="text"
										name="rfc"
										id="rfc"
										className="input input-modals"
										autoComplete='off'
										maxLength="64"
										required
										value={this.state.rfc}
										onChange={event => this.setState({ rfc: event.target.value })}
									/>
								</div>
								<div className="column label-duo-normal align-end">

								</div>
								<div className="column full">

								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Municipio:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="text"
										name="municipio"
										id="municipio"
										className="input input-modals"
										autoComplete='off'
										maxLength="64"
										required
										value={this.state.municipio}
										onChange={event => this.setState({ municipio: event.target.value })}
									/>
								</div>
								<div className="column label-duo-normal align-end">
									<div className="column label-duo-big">
										<p>
											<b>CP:</b>
										</p>
									</div>
								</div>
								<div className="column full">
									<input
										type="text"
										name="cp"
										className="input input-modals"
										id="cp"
										maxLength='10' required minLength='3'
										value={this.state.cp}
										onChange={(event) => this.setState({ cp: event.target.value })}
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>País:</b>
									</p>
								</div>
								<div className="column full">
									<select
										className={"input input-modals"}
										name="pais"
										value={this.state.Id_Pais}
										onChange={(event) => {
											this.setState({ Id_Pais: event.target.value });
										}}
									>
										{paises.map((pais, key) => (
											<option value={pais.id} key={key}>
												{pais.nombre}
											</option>
										))}
									</select>
								</div>
								<div className="column label-duo-normal align-end">
									<div className="column label-duo-big">
										<p>
											<b>Estado:</b>
										</p>
									</div>
								</div>
								<div className="column full">
									<select
										className={"input input-modals"}
										name="estado"
										value={this.state.Id_Estado}
										onChange={(event) => {
											this.setState({
												Id_Estado: event.target.value
											},
											);
										}}
									>
										{estados.map((estado, key) => (
											<option value={estado.id_estado} key={key}>
												{estado.nombre}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Concepto:</b>
									</p>
								</div>
								<div className="column full">
									<select
										className={"input input-modals"}
										name="concepto"
										value={this.state.id_concepto}
										onChange={(event) => {
											this.setState({ id_concepto: event.target.value });
										}}
									>
										{conceptos.map((concepto, key) => (
											<option value={concepto.id} key={key}>
												{concepto.label}
											</option>
										))}
									</select>
								</div>
								<div className="column label-duo-normal align-end">
									<div className="column label-duo-big">
										<p>
											<b>Cuenta contable:</b>
										</p>
									</div>
								</div>
								<div className="column full">
									<input
										type="text"
										name="cuenta_contable"
										id="cuenta_contable"
										value={this.state.cuenta_contable}
										onChange={event => this.setState({ cuenta_contable: event.target.value })}
										className="input input-modals"
										autoComplete='off'
										maxLength="64"
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Observaciones:</b>
									</p>
								</div>
								<div className="white-space-8" />
								<div className="align-center row full">
									<div className="column full">
										<textarea className='textarea font-small full' name="observaciones" id="observaciones" cols="5" rows="5" value={this.state.observaciones}
											onChange={event => this.setState({ observaciones: event.target.value })}>

										</textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</DinamicModal>}
			</div>
		);
	}

	componentDidMount() {
		this.getSubmenu();
		this.getProveedores();
		this.getSubFraccionamientos();
		this.getCountries();
		this.get_EGR_Conceptos();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	handleClose() {
		this.setState({ showModal: false })
	}

	// obtener proveedores
	async getProveedores() {
		this.setState({ proveedores: [], loadingProveedores: true });

		let data = {
			//IdSub: this.state.subfraccionamiento,
			id_fraccionamiento: localStorage.getItem('frac'),
			//year: this.state.year
		};

		const response = await request.post("/proveedores/get/all/", data);

		if (response && !response.error) {
			if (response.proveedores && !response.empty) {
				this.setState({
					proveedores: response.proveedores,
				});

			} else {
				this.setState({
					empty: true,
					message: response.message,
					proveedores: [],
					disabled: true
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({ proveedor: [], loadingProveedores: false });
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ loadingSave: true });
		//const form = event.target;
		let data = {
			razon_social: this.state.razon_social,
			rfc: this.state.rfc,
			municipio: this.state.municipio,
			id_pais: this.state.Id_Pais,
			id_estado: this.state.Id_Estado,
			id_concepto: this.state.id_concepto,
			cp: this.state.cp,
			cuenta_contable: this.state.cuenta_contable,
			observaciones: this.state.observaciones,
			id_fraccionamiento: localStorage.getItem('frac')
		}

		/* form.nombre.value = '';
		form.mes.value = 1;
		form.anyo.value = parseInt(moment().format("YYYY"));
		form.nivel.value = 1;
		form.comentarios.value =''; */

		const response = await request.post("/proveedores/proveedor/create", data)


		if (response.created) {
			this.setState({ showModal: false });
			this.closeModal("newBudget");
			this.getProveedores();
			cogoToast.success('Proveedor agregado.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({ empty: true/*, formMessage: "NO SE PUDO CREAR NUEVO PRESUPUESTO"*/ });
			cogoToast.error('No se pudo agregar nuevo proveedor.', {
				position: 'bottom-right',
			});
		}

		this.setState({
			loadingSave: false, razon_social: '', rfc: '', municipio: '', cp: '', Id_Estado: 1,
			Id_Pais: 1, id_concepto: 1, cuenta_contable: '', observaciones: ''
		});
	}

	async getIdPresupuesto() {
		let data = {
			anio: this.state.year,
			subfrac: localStorage.getItem('subfrac')
		};

		const response = await request.post("/presupuestos/presupuesto/get/id", data);
		if (response.id_presupuesto) {
			this.setState({ IdPresupuesto: response.id_presupuesto });
		}
	}

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if (!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento });
		} else {
			this.setState({ subfraccionamientos: [] });
		}
	}

	async getCountries() {
		const response = await request.post("/facturacion/paises", []);
		if (response.empty || response.error) {
			await this.setState({ paises: [] });
		}

		if (response.paises) {
			await this.setState({ paises: response.paises });
			this.getStates(this.state.Id_Pais);
		}
	}

	async getStates(idCountry) {
		let data = {
			id_pais: idCountry,
		};

		const response = await request.post("/facturacion/estados", data);
		if (response.empty || response.error) {
			await this.setState({ estados: [] });
		}

		if (response.estados) {
			await this.setState({ estados: response.estados });
		}
	}

	async get_EGR_Conceptos() {
		let data = { id_fraccionamiento: localStorage.getItem('frac') };

		const response = await request.post("/conceptos/get/all", data);
		if (response.empty || response.error) {
			await this.setState({ conceptos: [] });
		}

		if (response.conceptos) {
			await this.setState({ conceptos: this.TransformRowsToTreeGridFormat(response.conceptos) });
		}
	}

	//ordenar los datos recibidos a formato: arbol con sus ramas
	TransformRowsToTreeGridFormat = (lista) => {
		let listaFinal = [];
		let arboles = lista.filter((item) => item.id_parent === 0);
		for (let index = 0; index < arboles.length; index++) {
			listaFinal.push(arboles[index]);
			lista
				.filter((item) => item.id_parent === arboles[index].id)
				.forEach((element) => {
					listaFinal.push(element);
					lista
						.filter((item) => item.id_parent === element.id)
						.forEach((elemento) => {
							listaFinal.push(elemento);
						});
				});
		}
		return listaFinal;
	};

}

export default Consumer(ProveedorPage);