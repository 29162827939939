import React, { Component } from 'react';
import ReactModal from "react-modal";
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import cogoToast from 'cogo-toast';
import { AsYouType } from 'libphonenumber-js';
import NumberFormat from "react-number-format";
const request = new Request();

class SubFraccTable extends Component {
	constructor(props) {
		super(props);
		this.focusAllValue = this.focusAllValue.bind(this);
		this.state = {
			showModalSubEddit: false,
			id: '',
			nombre: '',
			direccion: '',
			telefono: '',
			message: null,
			URL: '',
			indiviso: 0,
		};
	};

	getNumberFormat(cell_phone) {
		try {
			if (cell_phone.includes('+'))
				return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+' + cell_phone);

		} catch (error) {
			return null;
		};
	};

	openModal(idModal, sub) {

		this.setState({ activo: sub.status });

		let phone = '';
		if (sub.telefono) {
			phone = parsePhoneNumber(`+${sub.telefono}`);
			phone = phone.number;
		};

		this.setState({
			message: null,
			nombre: sub.nombre,
			direccion: sub.direccion,
			telefono: phone,
			id: sub.id_subfraccionamiento,
			indiviso: sub.indiviso,
			id_subfraccionamiento_row: sub.id_subfraccionamiento
		});

		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
	};

	delete = (id, close) => {
		this.handleDelete(id, close)
	};

	openModalDelete(sub, close) {
		if (sub.Num_Viviendas > 0) {
			this.setState({ id_subfraccionamiento_row: sub.id_subfraccionamiento });
			cogoToast.warn('No es posible borrar subfraccionamiento con viviendas.', {
				position: 'bottom-right'
			});
		} else {
			this.setState({ id_subfraccionamiento: sub.id_subfraccionamiento, id_subfraccionamiento_row: sub.id_subfraccionamiento });
			let modal = document.getElementById("deleteModal");
			if (close) {
				modal.classList.remove('hide-modal');
				modal.classList.add('show-modal');
			};
		};
	};

	handleOpen(sub) {
		this.setState({
			nombre: sub.nombre,
			direccion: sub.direccion,
			telefono: sub.telefono,
			id: sub.id_subfraccionamiento,
			showModalSubEddit: !this.state.showModalSubEddit,
			activo: sub.status,
			indiviso: sub.indiviso
		});
	};

	async handleEddit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });

			if (!isValidPhoneNumber(this.state.telefono)) {
				this.setState({ telefonoError: 'Número inválido', loadingSave: false });
				return;
			} else {
				this.setState({ telefonoError: undefined });
			};

			const data = {
				IdSub: this.state.id,
				Nombre: this.state.nombre,
				Direccion: this.state.direccion,
				Telefono: this.state.telefono,
				Status: this.state.activo ? 1 : 0,
				indiviso: this.state.indiviso,
				IdFraccionamiento: localStorage.getItem('frac'),
			};

			const response = await request.post("/subfraccionamientos/eddit/subfraccionamiento", data);

			if (response && !response.error && response.created) {
				this.props.getSubFraccionamientos();
				cogoToast.success('Datos de subfraccionamiento actualizados.', {
					position: 'bottom-right'
				});
			} else {
				cogoToast.error('No se pudo actualizar subfraccionamiento.', {
					position: 'bottom-right'
				});
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};

	async handleDelete(id, handleClose) {
		try {
			const data = { IdSub: id };
			const response = await request.post("/subfraccionamientos/delete/subfraccionamiento", data);

			if (response && !response.error) {
				if (response.eddited && !response.empty) {
					await this.props.reload();
					handleClose();

					if (id == localStorage.getItem('subfrac')) {
						localStorage.setItem('subfrac', 0);
					};

					cogoToast.success('Subfraccionamiento eliminado.', {
						position: 'bottom-right'
					});
				} else {
					this.setState({ empty: true, message: response.message });
					cogoToast.error('No se pudo eliminar el subfraccionamiento.', {
						position: 'bottom-right'
					});
				};

			} else {
				this.setState({ error: true, message: response.message });
				cogoToast.error('No se pudo eliminar el subfraccionamiento.', {
					position: 'bottom-right'
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	focusAllValue(event) {
		const element = event.currentTarget;
		if (document.activeElement === element) {
			element.select();
		};
	};

	render() {

		let subfraccionamiento = [];

		if (this.props.sub) {
			subfraccionamiento = this.props.sub;
		};

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="th-options-huge">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											}
										</div>
									</div>
								</th>
								<th className="th-options-huge">
									<div className="row">
										Dirección &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'direccion' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small" >
									<div className="row">
										Indiviso &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'indiviso' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'indiviso')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'indiviso')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'indiviso')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'Num_Viviendas' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'Num_Viviendas')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'Num_Viviendas')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'Num_Viviendas')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
							</tr>
						</thead>
						<tbody>

							{subfraccionamiento.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((sub, key) => {
								return (
									<tr className={this.state.id_subfraccionamiento_row === sub.id_subfraccionamiento ? 'row-active text-left' : ''} key={sub.id_subfraccionamiento}>
										<td >{sub.nombre}</td>
										<td >{sub.direccion}</td>
										<td className="text-center">
											<NumberFormat
												value={sub.indiviso}
												displayType={"text"}
												thousandSeparator={true}
												decimalScale={4}
												fixedDecimalScale={true}
											/>
										</td>
										<td className="text-center">
											<h4 className="font-small weight-bold">{sub.Num_Viviendas}</h4>
										</td>
										<td className="text-center">
											<button
												type="button"
												onClick={this.openModal.bind(this, 'editSubfrac', sub)}
												className='btn-full justify-center btn-mini btn-secondary color-white'
											>
												<i className="font-small fas fa-pen" />
											</button>
										</td>

										<td className="text-center">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white"
												type='button' onClick={this.openModalDelete.bind(this, sub)}
												style={{ padding: '5px' }}
												disabled={!this.props.escritura}
											>
												<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>

				<DinamicModal
					idModal={'editSubfrac'}
					sizeModalContainer={'small'}
					title={`Editar ${this.props.subLabel}`}
					success={this.handleEddit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.renderEditSubfrac()}
				</DinamicModal>
				<ModalBorrar
					id={this.state.id_subfraccionamiento}
					delete={this.delete.bind(this)} />
			</div>
		);
	};

	renderEditSubfrac() {
		return (
			<div className="full row align-center">

				<div className="column full">

					<div className="input-form-content row full align-center">

						<div className="column label-normal">
							<p><b>Nombre:</b></p>
						</div>
						<div className="column full">
							<input
								type="text" name="fullName"
								value={this.state.nombre}
								onChange={event => this.setState({ nombre: event.target.value })}
								className="input input-modals"
								minLength="6"
								maxLength="100"
								required
								autoComplete={'off'}
							/>
						</div>
					</div>


					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="address"
								value={this.state.direccion}
								onChange={event => this.setState({ direccion: event.target.value })}
								className="input input-modals"
								required minLength="6"
								maxLength="150"
								autoComplete={'off'}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Teléfono:</b>
							</p>
						</div>
						<div className="column ">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								defaultCountry="MX"
								maxLength="18"
								value={this.state.telefono}
								onChange={value => this.setState({ telefono: value, telefonoError: undefined })}
								error={this.state.telefonoError}
								className={'input-modals'}
								autoComplete={'off'}
							/>
						</div>

						<div className="column label-medium space">
							<p>
								<b>Indiviso:</b>
							</p>
						</div>
						<div className="column tooltip left" data-tooltip="El valor del indiviso es base 100">
							<input className="input input-modals text-center"
								type="number"
								name=""
								id=""
								step='any'
								value={this.state.indiviso}
								onChange={(event) => { this.setState({ indiviso: event.target.value }) }}
								autoComplete={'off'}
								onClick={this.focusAllValue}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check" >
								<input
									type="checkbox"
									checked={this.state.activo ? (true) : (false)}
									name="activo" id="activar"
									onChange={(event) => { this.setState({ activo: event.target.checked }) }}
								/>
								<span className="checkmark" ></span>
							</label>
						</div>
						<div className="column">
							<h5>
								<b>Activar {this.props.subLabel}</b>
							</h5>
						</div>
					</div>

				</div>
			</div>
		);
	};
};

export default SubFraccTable;