import React, { useState } from 'react';
import moment from 'moment-timezone';

const Invitaciones = (props) => {
	const { values } = props;

	return (
		<div className="column invitacion-detalle">
			<div className="white-space-4" />
			<div className="row justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Nombre invitado</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.invitado ? values.invitado : ''}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Fecha invitación</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={
									moment(values.fecha_invitacion).isValid() ?
										moment(values.fecha_invitacion).format('DD/MM/YYYY [-] hh:mm A')
										:
										'-'
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="white-space-4" />
			<div className="row justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Nombre residente</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.residente ? values.residente : ''}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Vivienda</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.vivienda ? values.vivienda : ''}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="white-space-4" />
			<div className="row justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Usuario entrada</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.usuario_entrada ? values.usuario_entrada : '-'}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Usuario salida</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.usuario_salida ? values.usuario_salida : '-'}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="white-space-4" />
			<div className="row justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Entrada</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={
									moment(values.fecha_entrada).isValid() ?
										moment(values.fecha_entrada).format('DD/MM/YYYY [-] hh:mm A')
										:
										'-'
								}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Salida</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={
									moment(values.fecha_salida).isValid() ?
										moment(values.fecha_salida).format('DD/MM/YYYY [-] hh:mm A')
										:
										'-'
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="white-space-4" />
			<div className="row justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Usuario entrada 2</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.usuario_entrada2 ? values.usuario_entrada2 : '-'}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Usuario salida 2</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.usuario_salida2 ? values.usuario_salida2 : '-'}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="white-space-4" />
			<div className="row justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Entrada 2</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={
									moment(values.fecha_entrada2).isValid() ?
										moment(values.fecha_entrada2).format('DD/MM/YYYY [-] hh:mm A')
										:
										'-'
								}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Salida 2</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={
									moment(values.fecha_salida2).isValid() ?
										moment(values.fecha_salida2).format('DD/MM/YYYY [-] hh:mm A')
										:
										'-'
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="white-space-16" />
			<div className="row justify-center align-center detalle-imagenes">
				{props.loadingImages ?
					<div className="row full justify-center">
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<div className="container-img justify-center align-center">
						<img src={values.identificacion_1} alt="identificacion"></img>
					</div>
				}
				{props.loadingImages ?
					<div className="row full justify-center">
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<div className="container-img justify-center align-center">
						<img src={values.identificacion_2} alt="identificacion"></img>
					</div>
				}
				{props.loadingImages ?
					<div className="row full justify-center">
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<div className="container-img justify-center align-center">
						<img src={values.matricula} alt="identificacion"></img>
					</div>
				}
			</div>
			<div className="white-space-16" />
			<div className="column justify-center detalle-datos full">
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>No. Cono</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.numero_cono ? values.numero_cono : ''}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>No. de Pasajeros</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.numero_pasajeros || '-'}
							/>
						</div>
					</div>
				</div>
				<div className="white-space-4" />
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Coche</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.coche ? values.coche : ''}
							/>
						</div>
					</div>
					<div className="column label-duo-normal space">
						<p>
							<b>Placas</b>
						</p>
					</div>
					<div className="column full">
						<div className="row full input-add">
							<input
								type="text"
								className="input input-modals"
								disabled
								value={values.placas ? values.placas : ''}
							/>
						</div>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="input-form-content row full">
					<div className="column label-normal">
						<p>
							<b>Información extra</b>
						</p>
					</div>
					<div className="row full input-add">
						<textarea
							type="text"
							className="input input-modals"
							rows="2"
							disabled
							value={values.extra_info ? values.extra_info : ''}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Invitaciones;
