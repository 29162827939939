
export const waitForElement = (selector, target = document.body) => {

    return new Promise(resolve => {

        if(target.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if(target.querySelector(selector)) {
                observer.disconnect();
                resolve(target.querySelector(selector));
            }
        })

        observer.observe(target, {
            childList: true,
            subtree: true
        });
    });
}