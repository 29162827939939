import React, { Component } from 'react';
import VacacionalTableEdit from './VacacionalTableEdit';
import ModalBorrar from '../modals/modalDelete';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import { AsYouType } from 'libphonenumber-js';
import { Consumer } from "../../context";

const request = new Request();

class VacacionalHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invitado: {}
		}
	}

	openModal(idModal, invitado) {
		this.setState({ invitado, id_invitado_row: invitado.id_frecuente_invitacion});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		document.getElementById('nom').focus();
	}

	openModalDelete(inv) {
		this.setState({ id_frecuente_invitacion: inv.id_frecuente_invitacion, id_invitado_row: inv.id_frecuente_invitacion });
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleDelete(id, close) {

		let user = this.props.context.user.id.usuario;

        let data = {
            id_frecuente_invitacion: id,
			usuario: user
		}
		const response = await request.post('/invitadosFrecuentes/invitados/borrar', data);
		if (response) {
			if (response.deleted) {
				cogoToast.success('Invitado eliminado.', {
					position: 'bottom-right',
				});
			} else {
				cogoToast.error('No se pudo eliminar invitado.', {
					position: 'bottom-right',
				});
			}
		} else {
			cogoToast.error('No se pudo eliminar invitado.', {
				position: 'bottom-right',
			});
		}
		//this.setState({message: response});
		this.props.reload();
		if(close) {
			close();
		}
    }

	getNumberFormat(cell_phone, oculto) {

        try {
			if(oculto)
				return `******${cell_phone.substring(cell_phone.length - 4)}`;

            if(cell_phone.includes('+'))
                return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+'+cell_phone);

        } catch (error) {
            return null;
        }
	}

    render() {
        let invitados = []

        if (this.props.invitados) {
            invitados = this.props.invitados
        }

        return (
            <div>
                <div className="table-responsiv column">
                    <table>
						<thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'invitado' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
											}
										</div>
									</div>
								</th>
								<th className="">Teléfono</th>
                                <th className="">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-left">
									<div className="row">
										Acceso &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'acceso' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'acceso')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'acceso')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'acceso')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-left">Tipo</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
							{invitados.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength  ).map((invitado, key) => (
								<tr className={`${this.state.id_invitado_row === invitado.id_frecuente_invitacion && 'row-active'}`} key={key}>
                                    <td>{invitado.invitado}</td>
									<td>{this.getNumberFormat(invitado.telefono, true)}</td>
                                    <td>{invitado.numero_registro}</td>
                                    <td>{invitado.acceso}</td>
                                    <td>Vacacional</td>
									<td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this,"editVacacional", invitado)}>
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
												type		=	'button' onClick={this.openModalDelete.bind(this, invitado)} 
												style		=	{{ padding: '5px' }} 
												disabled	=	{!this.props.escritura}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
				{<VacacionalTableEdit 
					reload		=	{this.props.reload.bind(this)} 
					invitado	=	{this.state.invitado} 
					escritura	=	{this.props.escritura}
					getNumberFormat = {this.getNumberFormat.bind(this)}
				/>}
				<ModalBorrar
					id		=	{this.state.id_frecuente_invitacion}
					delete	=	{this.handleDelete.bind(this)}
				/>
            </div>
        );
	}
}

export default Consumer(VacacionalHistoryTable);