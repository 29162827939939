import React from 'react';
import { Helmet } from "react-helmet";
import Header from '../../components/header/header';
import SidebarOperacion from '../../components/header/sidebarOperacion';
import { Consumer } from "../../context";
import TablaHistorial from '../../components/invitaciones/tablaHistorial';
import Controls from '../../components/controls/controls';
import Paginador from "../../components/paginador/paginador";
import Request from "../../core/httpClient";
import moment from 'moment-timezone';
import Permissions from '../../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import Core from '../../components/core/SuperCore';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import sortableData from '../../helpers/sortableDataTable';
/**Socket */
import Socket from '../../core/socket';

registerLocale('es', es)

const request = new Request();

class Activas extends Core {
	constructor(props) {
		super(props);
		this.state = {
			lectura: false,
			escritura: false,
			logo: require("../../img/logo-AppMosphera-r.png"),
			filter: '',
			filterTipo: '',
			reservas: [],
			subfraccionamientos: [],
			id_subfraccionamiento: localStorage.getItem('subfrac'),
			id_unidad: 0,
			unidades: [],
			areas: [],
			id_area: 0,
			fecha: null,
			min_day: null,
			horarios: [],
			usuario: {},
			fianza: null,
			comentarios: '',
			pageLength: 20,
			startDate: moment().format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			direction: {},
			filterColumn: {},
			submenu: ''
		};
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.getSubmenu();
		await this.getInvitaciones();

		// obtener info del usuario
		let user = await this.getUserInfo();
		this.setState({ usuario: user.info });

		Socket.on('newInvitacion', async (data) => {
			await this.getInvitaciones();
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.loading !== prevState.loading) {
			this.stickyTablePosition('header', 'controls', 'second_controls', 'header_table');
		};
	};

	stickyTablePosition(headerId, controlsId, secondControlsId, tableId) {
		const header_Heigh = document.getElementById(headerId);
		const height_controls = document.getElementById(controlsId);
		const second_controls = document.getElementById(secondControlsId);

		const table_height = document.getElementById(tableId);

		let limit_sticy_top = header_Heigh && height_controls ? header_Heigh.offsetHeight + height_controls.offsetHeight : 226;

		if (second_controls) {
			second_controls.style.position = 'sticky';
			second_controls.style.top = `${limit_sticy_top}px`;

			limit_sticy_top += second_controls.offsetHeight;
		};

		if (table_height) {
			table_height.style.position = 'sticky';
			table_height.style.top = `${limit_sticy_top}px`;
		};
	};

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
	};

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	async subChange(sub) {
		this.setState({ id_subfraccionamiento: sub });
		await this.getInvitaciones();
	};

	setPage(page) {
		this.setState({ page });
	};

	paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuOpeName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	async getSubfraccionamientos() {
		try {
			const fraccionamiento = localStorage.getItem('frac');
			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento, filter: 1 };
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response.error || response.empty) {
				this.setState({ subfraccionamientos: [] });
				return;
			}

			if (response.subfraccionamiento) {
				this.setState({
					subfraccionamientos: response.subfraccionamiento,
					id_subfraccionamiento: response.subfraccionamiento[0].id_subfraccionamiento
				});
			}
		} catch (error) {
			console.log(error);
		};
	}

	async changeSubfraccionamiento(event) {
		let id_sub = event.target.value;
		this.setState({ invitaciones: [] });
		this.setState({ id_subfraccionamiento: id_sub })
		await this.getInvitaciones();
	}

	async getInvitaciones() {
		try {
			this.setState({ loading: true, invitaciones: [] });

			const data = {
				idFrac: localStorage.getItem('frac'),
				idSub: this.state.id_subfraccionamiento,
				inicio: this.state.startDate,
				fin: this.state.endDate,
				tipo: this.state.filterTipo
			};

			const response = await request.post("/invitaciones/get/historial", data);

			if (response.empty || response.error) {
				this.setState({ invitaciones: [] });
				cogoToast.info('No se encontraron invitaciones.', {
					position: 'bottom-right'
				});
			};

			if (response.invitaciones) {
				this.setState({ invitaciones: response.invitaciones });
			};

			this.setState({ loading: false });
		} catch (error) {
			console.log(error);
		};
	};
	filter(invitaciones) {
		invitaciones = invitaciones.filter(inv => {
            const filter = this.state.filter.toLowerCase();
            if (inv.invitado.toLowerCase().indexOf(filter) !== -1) {
                return true;
            } else if (inv.vivienda.toLowerCase().indexOf(filter) !== -1) {
                return true;
            } else if (inv.placas && inv.placas.toLowerCase().indexOf(filter) !== -1) {
                return true;
            } else {
                return false;
            }
        });

        return invitaciones;
	};

	startChange(event) {
		this.setState({ startDate: event.target.value })
	};

	endChange(event) {
		this.setState({ endDate: event.target.value })
	};

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.invitaciones, this.state.direction[key]);

		this.setState({
			invitaciones: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	};

	render() {
		return this.renderReservationPage();
	}

	renderReservationPage() {
		let invitaciones = [];

		if (Array.isArray(this.state.invitaciones)) {
			invitaciones = this.filter(this.state.invitaciones);
		};
		return (
			<div className="houses column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					id='header'
					sidebar={true}
					active={'activas'}
					parent={'Seguridad'}
					panel={'panel3'}
					nav={"operacion"}
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls
											id='controls'
											title={this.state.submenu}
											dataSet={invitaciones}
											dateFormat='dd/mm/yyyy HH:mm:ss'
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={false}
											selectSub={false}
										/>
										<div id='second_controls' className="row-responsive full" style={{ backgroundColor: 'white' }} >
											<div className="data-from column full">
												<h5 className="color-black">DESDE:</h5>
												<input
													type="date"
													id="startdate"
													name="startdate"
													className="input input-text"
													placeholder="Desde"
													value={this.state.startDate}
													onChange={this.startChange.bind(this)}
												/>
											</div>
											<div style={{ width: '16px' }}></div>
											<div className="data-to column full">
												<h5 className="color-black">HASTA:</h5>
												<input
													type="date"
													id="enddate"
													name="enddate"
													className="input input-text"
													placeholder="Hasta"
													value={this.state.endDate}
													onChange={this.endChange.bind(this)}
												/>
											</div>
											<div style={{ width: '16px' }}></div>
											<div className="data-to column full">
												<h5 className="color-black">TIPO:</h5>
												<select className="input input-select" onChange={(input) => this.setState({ filterTipo: input.target.value })}>
													<option value="">Todos</option>
													<option value="invitacion">Invitación</option>
													<option value="frecuente">Frecuente</option>
													<option value="manual">Manual</option>
												</select>
											</div>
											<div style={{ width: '16px' }}></div>
											<div className="data-to column justify-end" style={{ maxWidth: '80px' }} onClick={this.getInvitaciones.bind(this)}>
												<div className="white-space-16" />
												<button type="button" className="btn btn-primary btn-small color-white">
													<i className="fas fa-search"></i>
												</button>
											</div>
										</div>
										{this.state.loading ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<TablaHistorial
												id='header_table'
												invitaciones={invitaciones}
												page={this.state.page}
												user={this.state.usuario}
												reload={this.getInvitaciones.bind(this)}
												escritura={this.state.escritura}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
											/>
										}
										<div className="white-space-16"></div>
										<Paginador
											pages={Math.ceil(invitaciones.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>

									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
};

export default Consumer(Activas);