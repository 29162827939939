import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarOperacion from "../components/header/sidebarOperacion";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DetailEmergency from '../components/ModalEmergency/modalDetailEmegency';
import ContactoModal from '../components/contactos/contactosEmergencia';
import moment from "moment";
import Socket from '../core/socket';
import Permissions from '../middlewares/Permissions';
import sortableData from '../helpers/sortableDataTable';
import { Consumer } from "../context";

moment.locale();
const request = new Request();

class Emergency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emergencias: [],
            filterData: [],
            keys: [],
            page: 1,
            filter: '',
            subfraccionamiento: localStorage.getItem('subfrac'),
            dataEmergency: [],
            viviendaId: '',
            rowType: '',
            idPanic: '',
            escritura: false,
            lectura: false,
            logo: require("../img/logo-AppMosphera-r.png"),
            pageLength: 20,
            direction: {},
            filterColumn: {},
            submenu: ''
        };
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.setState({ subfraccionamiento: this.state.subfraccionamiento });
            //console.log("CHECK NEW SUB EMERGENCIES")
            this.getEmergencys();
        };
    };

    async componentDidMount() {
        await this.validarPermisos();
        let data = window.localStorage.getItem('openRow');
        if (data) {
            data = JSON.parse(data);
            this.setState({ viviendaId: data.idVivienda, rowType: data.type, idPanico: data.idPanic });
        }

        await this.getEmergencys();
        Socket.on('newEmergency', (data) => {
            this.getEmergencys();

        })

        await this.getSubmenu();
    };

    async validarPermisos() {
        let permisos = new Permissions();
        let permisosInfo = await permisos.getResult()
        this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
    };

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        }
    }

    openModal(idModal, data) {
        this.setState({ dataEmergency: data, id_emergencia_row: data.id_panico, rowType: '' });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        }
        document.getElementById('comment').focus();
    };

    closeModal(idModal) {
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.add('hide-dinamic-modal');
            modal.classList.remove('show-dinamic-modal');
        };
    };

    setPage(page) {
        this.setState({ page });
    };

    async reload() {
        await this.getEmergencys();
    };

    async changeFrac() {
        await this.getEmergencys();
    };

    async paginatorChange(length) {
        this.setState({ pageLength: length });
        this.setPage(1);
    };

    /* funcion para el filtro generico */
    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;
        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;
        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ emergencias: this.state.filterData });
            form.textFilter.value = "";
        } else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ emergencias: filterData });
        };
    };

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    };

    nuevaEmergencia() {
        this.setState({ showModal: true });
    };

    setFilter(filter) {
        this.setState({ filter: filter.toLowerCase() });
    };

    filter(emergencias) {
        emergencias = emergencias.filter(eme => {
            if (eme.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (eme.telefono.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (eme.numero_vivienda.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else {
                return false;
            }
        })
        return emergencias;
    };

    async openRow(type, idVivienda, idPanic) {
        const data = { type, idVivienda, idPanic };

        window.localStorage.setItem('openRow', JSON.stringify(data));

        if (this.state.idPanico === idPanic && type === this.state.rowType) {
            window.localStorage.removeItem('openRow');
            this.setState({ rowType: '', viviendaId: 0, idPanico: idPanic });
            return;
        }
        await this.setState({ rowType: type, viviendaId: idVivienda, idPanico: idPanic, id_emergencia_row: idPanic });
    };

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.emergencias, this.state.direction[key]);

        this.setState({
            emergencias: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    };

    async getEmergencys() {
        try {
            this.setState({ emergencias: [], loading: true });

            const idSub = this.state.subfraccionamiento || 0;
            const data = { IdFraccionamiento: localStorage.getItem('frac'), IdSubfraccionamiento: idSub };

            const response = await request.post("/incidencias/get/emergencys", data);

            if (response && !response.error) {
                if (response.emergencias && !response.empty) {
                    this.setState({
                        emergencias: response.emergencias,
                        filterData: response.emergencias,
                        keys: Object.keys(response.emergencias[0]).filter(key => key.indexOf('id') === -1),
                        loadingIncidencias: false
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message,
                        loadingIncidencias: false
                    });
                };
            } else {
                this.setState({
                    error: true,
                    message: response.message,
                    loadingIncidencias: false
                });
            }
            this.setState({ loading: false });
        } catch (error) {
            console.log(error);
        };
    };

    render() {
        let emergencias = [];

        if (this.state.emergencias) {
            emergencias = this.state.emergencias;
            emergencias = this.filter(emergencias);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header
                    reload={this.reload.bind(this)}
                    change={this.changeFrac.bind(this)}
                    sidebar={true}
                    active={'historial'}
                    parent={'emergencias'}
                    panel={'panel1'}
                    nav={'operacion'}
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        {this.state.lectura ?
                            <div className="justify-center">
                                <div className="container column">

                                    <Controls
                                        title={this.state.submenu}
                                        // nuevo        =   {this.nuevaEmergencia.bind(this)}
                                        dataSet={emergencias}
                                        newButton={false}
                                        setFilter={this.setFilter.bind(this)}
                                        subChange={this.subChange.bind(this)}
                                    />

                                    <div className="card-table card-owners column" id="card-owners">
                                        <div className="table-responsiv column">
                                            <table>
                                                <thead className="sticky-th">
                                                    <tr className="text-left">
                                                        <th className="">
                                                            <div className="row">
                                                                Nombre &nbsp;
                                                                <div className="align-center">
                                                                    {this.state.filterColumn.col === 'nombre' ? this.state.filterColumn.filt === 'asc' ?
                                                                        <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'nombre')}></i>
                                                                        :
                                                                        <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'nombre')}></i>
                                                                        :
                                                                        <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'nombre')}></i>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="">Teléfono</th>
                                                        <th className="">
                                                            <div className="row">
                                                                Fecha &nbsp;
                                                                <div className="align-center">
                                                                    {this.state.filterColumn.col === 'fecha' ? this.state.filterColumn.filt === 'asc' ?
                                                                        <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'fecha')}></i>
                                                                        :
                                                                        <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'fecha')}></i>
                                                                        :
                                                                        <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'fecha')}></i>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="vivienda">
                                                            <div className="row">
                                                                Vivienda &nbsp;
                                                                <div className="align-center">
                                                                    {this.state.filterColumn.col === 'numero_vivienda' ? this.state.filterColumn.filt === 'asc' ?
                                                                        <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'numero_vivienda')}></i>
                                                                        :
                                                                        <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'numero_vivienda')}></i>
                                                                        :
                                                                        <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'numero_vivienda')}></i>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="text-center th-options-small">Editar</th>
                                                        <th className="text-center th-options-small">Contactos</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loading ?
                                                        <tr>
                                                            <td colSpan="6">
                                                                <div className="row justify-center">
                                                                    <i className="fas fa-spinner fa-spin"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        emergencias.slice(((this.state.page - 1) * this.state.pageLength), (this.state.page * this.state.pageLength)).map((emergencia, key) => {
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    <tr className={this.state.id_emergencia_row === emergencia.id_panico && 'row-active'}>
                                                                        <td>{emergencia.nombre}</td>
                                                                        <td>{`******${emergencia.telefono.substring(emergencia.telefono.length - 4)}`}</td>
                                                                        <td>{moment(emergencia.fecha).format("DD/MM/YYYY [-] hh:mm a")}</td>
                                                                        <td>{emergencia.numero_vivienda}</td>
                                                                        <td className="align-center justify-center">
                                                                            <button className="font-small weight-semi btn-secondary"
                                                                                onClick={this.openModal.bind(this, "detailEmergency", emergencia)}
                                                                            >
                                                                                <i className="font-small fas fa-pen" />
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button className="font-small weight-semi btn-secondary full" onClick={this.openRow.bind(this, "contactos", emergencia.id_vivienda, emergencia.id_panico)}>
                                                                                <i className="fas fa-address-book"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    {this.state.idPanico === emergencia.id_panico && this.state.rowType === 'contactos' &&
                                                                        <tr className="acordeon-row">
                                                                            <td className="acordeon" colSpan="6">
                                                                                <div className="row full">
                                                                                    <ContactoModal reload={this.props.reload} id={this.state.viviendaId} editVivienda={false}></ContactoModal>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }

                                                                </React.Fragment>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <Paginador
                                        pages={Math.ceil(emergencias.length / this.state.pageLength)}
                                        setPage={this.setPage.bind(this)}
                                        pageLength={this.state.pageLength}
                                        paginatorChange={this.paginatorChange.bind(this)}
                                        showPageLenght={true}
                                    />
                                </div>
                            </div> :
                            null
                        }
                    </div>
                    <DetailEmergency
                        emergency={this.state.dataEmergency}
                        reload={this.getEmergencys.bind(this)}
                        escritura={this.state.escritura}
                    ></DetailEmergency>
                </div>
            </div>
        );
    }
}

export default Consumer(Emergency);