import React, { useEffect } from 'react';

const JornadaForm = ({ jornada, jornadaInput, setJornada}) => {
	useEffect(() => {
		if (jornada) {
			//console.log(jornada);
			const input = document.getElementById('jornada_title');
			if (input) {
				input.value = jornada.jornada
			}
		}
	}, [jornada]);

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p><b>Jornada:</b></p>
					</div>
					<div className="column full">
						<input
							id				=	"jornada_title" 
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"jornada_title" 
							autoComplete 	=	'off'
							value			= 	{jornadaInput}
							onChange		= 	{(e) => setJornada(e.target.value)}
							required
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default JornadaForm;
