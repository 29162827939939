import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import moment from 'moment-timezone';
import ContactosTable from "../components/contactos/contactosTable"
import Header from "../components/header/header";
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Sidebar from "../components/header/sidebar";
import Request from "../core/httpClient";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Permissions from '../middlewares/Permissions';
import StringHelper from '../helpers/StringHelper';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
const request = new Request();


class Contactos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactos: 				[],
            INombre: 				'',
            IDireccion: 			'',
            IMunicipio: 			'',
            IEstado: 				'',
            ICp: 					'',
            iTelefono: 				'',
            IPais: 					'',
            IGenero: 				 0,
            IId: 					 0,
            iNumId: 				'',
            iVigencia: 				'',
			IFechaIngreso: 			moment().format('YYYY-MM-DD'),
			IFechaSalida:  			moment().format('YYYY-MM-DD'),
            IIdPersona: 			 0,
            IidViv: 				 0,
            page: 					 1,
            code: 					'',
			email:					'',
			fraccionamiento: 		localStorage.getItem('frac') || 0,
			subfraccionamiento: 	localStorage.getItem('subfrac') || 0,
			subfraccionamientos: 	[],
			filter: 				'',
			personas: 				[],
			saveMessage: 			'',
			city:					'',
			escritura:				false,
			subf:					localStorage.getItem('subfrac') || 0,
			viviendasFiltradas:		[],
			filter2:				'',
			viviendasJson:			[],
			viviendas_json_db:		[],
            persona_existente:		true,
			phoneValidated:         false,
			logo:					require ("../img/logo-AppMosphera-r.png"),
			lectura:				false,
			pageLength: 			20, 
			direction: {
				nombre: 'desc',
				numero_registro: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: 'Contactos',
			subLabel: ''
		}
		
		this.validarPermisos();
	}
	
	async validarPermisos(){
		let permisos = new Permissions();
		
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura: permisosInfo.lectura});
		 //console.log(permisosInfo);

	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getContactos();
		}
		
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getContactos();
	}

	async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub,  subf:sub});
		this.getViviendas();
	}

	async openModal(idModal) {
		await this.clearCheckboxs();
		await this.getViviendas();
		await this.setState({viviendasFiltradas: this.state.viviendas});
		await this.buscarViviendas();
		
		this.setState({saveMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		// document.getElementById('viv').focus();
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(inquilinos) {
		inquilinos = inquilinos.filter(inq => {
			if(inq.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(inq.telefono.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else if(inq.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return inquilinos;
	}

	checkViviendas(){
		this.clearCheckboxs();
		let checks = document.getElementsByName("viviendas-check");
		checks.forEach(check => {
			try {
				let id = check.getAttribute("id").split("-")[1];
				// Buscar id en las viviendas de la persona y marcarlo
				if(this.state.viviendas_json_db){
					this.state.viviendas_json_db.forEach(viv => {
						if(viv.id_vivienda === id){
							check.checked = true;
						}
					});
				}
			} catch (error) {
				//console.log(error);
			}
		});


	}

	async closeModal(id) {
		const modal = document.getElementById(id);
		if( modal){
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
		await this.setState({
			IidViv: 			0,
			IIdPersona: 		0,
			INombre: 			'',
			iTelefono: 			'',
			IDireccion: 		'',
			email: 				'',
			IId: 				0,
			iNumId: 			'',
			IGenero: 			0,
			IDireccion: 		'',
			IMunicipio: 		'',
			ICp: 				'',
			IEstado: 			'',
			IPais: 				'',
			saveMessage: 		'',
			city:				'',
			persona_existente: false,
			viviendasJSON:		[],
			viviendas_json_db:	[]
		});
		await this.clearCheckboxs();
		
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.contactos, this.state.direction[key]);

		this.setState({
			contactos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

    render() {
		let contactos = [];
		if(Array.isArray(this.state.contactos)) {
			contactos = this.state.contactos;
			contactos = this.filter(contactos)
		}
        return (
            <div className="owners column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} sidebar={true} active={'inquilinos'} parent={'admin'} panel={'panel1'} nav={"catalogos"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
							{this.state.lectura?
							<React.Fragment>
								<Controls title={this.state.submenu}
									nuevo		=	{this.openModal.bind(this, 'modal-contacto')}
									dataSet		=	{contactos}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
									newButton   =   {this.state.escritura}
								/>
								{this.state.loadingContactos?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<ContactosTable 
										contactos	=	{contactos} 
										page		=	{this.state.page} 
										reload		=	{this.reload.bind(this)} 
										escritura	=	{this.state.escritura}
										pageLength	=   {this.state.pageLength}
										sortBy		=	{this.sortableTable.bind(this)}
										filterColumn=	{this.state.filterColumn}
									/>
                  	              }
							<div className="white-space-16"></div>
								<Paginador 
									pages={Math.ceil(contactos.length / this.state.pageLength)} 
									setPage={this.setPage.bind(this)} 
									pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}
									/>
                           
							</React.Fragment>:
							null
							}
						   
						   
						    </div>
                        </div>
                    </div>
                </div>
				<DinamicModal 
					idModal					=	{'modal-contacto'}
					sizeModalContainer		=	{'super'}
					title					=	{'Contactos asociados a la vivienda'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
                    closeModal				=	{this.closeModal.bind(this)}
                    disabledBtnSuccess      =   {!this.state.phoneValidated}
				>
					{this.formContactosViviendas()}
					{/* {this.nuevoForm()} */}
				</DinamicModal>
            </div>
        );
	}

	async changeUnidad(event){
		let viv =  event.target.value;

		let  vivienda = [];
		this.state.viviendas.find(v => {
			
			if(v.id_vivienda === viv){
				vivienda = v;
			}
		});

		await this.setState({
			IidViv:		viv, 
			IDireccion: vivienda.direccion
		});
		
	}
	async changeSubFrac(event){
		//let subf 		=  event.target.value;
		let vivs		=	[];
		//await this.setState({subf:	subf});
		await this.buscarViviendas();
		await this.clearCheckboxs();
		
			this.checkViviendas();
		
	}
	async clearCheckboxs(){
		let checks = document.getElementsByName("viviendas-check");
		if(checks){
			checks.forEach(check => {
				check.checked = false;
			});	
		}
		
	}

	async filtrarViviendas(){
		let viviendas 		= 	this.state.viviendas;
		let vivs			=	[];
		
		if(this.state.subf === 0){
			if (this.state.subfraccionamientos.length !== 0) {
				await this.setState({ subf: this.state.subfraccionamientos[0].id_subfraccionamiento });
			} else {
				return []
			}
		}
		viviendas.map(	(viv)=>{
			if(viv.id_subfraccionamiento === this.state.subf){
				vivs.push(viv);
			}
		});
		

		await this.setState({viviendasFiltradas: vivs});
	}

	async buscarViviendas(event){
		if(event)
		await this.setState({filter2: event.target.value});
		
		await this.filtrarViviendas();
		if(this.state.filter2 !== ""){
			let sh =  new StringHelper();
			
			// todas las viviendas
			let viviendas = this.state.viviendasFiltradas;

			let viviendasBuscadas = viviendas.map(	(viv)=>{
				let vivienda 		= sh.eliminarCaracteresRaros(viv.numero_registro,true);
				let datosBusqueda 	= sh.eliminarCaracteresRaros(this.state.filter2);
				// //console.log(viv);
				if(sh.multiMatch(vivienda, datosBusqueda)){
					return viv;
				}
				
				
			});
			
			// quito datos undefined
			viviendasBuscadas = viviendasBuscadas.filter(item => item !== undefined);
			
			await this.setState({viviendasFiltradas: viviendasBuscadas});

			
				this.checkViviendas();
			
		}else{
			
				this.checkViviendas();
			
		}

	}
	async addCheck(id){
		let jsons_viv = this.state.viviendas_json_db;
		let check = document.getElementById(`check-${id}`);

		let address = this.state.viviendasFiltradas.filter(viv => viv.id_vivienda === id );
		if (address.length > 0) {
			if (this.state.IDireccion === "") {
				this.setState({IDireccion: address[0].direccion })
			}
		}

		if(check.checked){
			//console.log(`Se agregó ${id}`);
			if(!jsons_viv) jsons_viv = [];
			jsons_viv.push({id_vivienda: id, subf: parseInt(this.state.subf)});
		}else{
			//console.log(`Se eliminó ${id}`);
			for( var i = 0; i < jsons_viv.length; i++){ 
				if ( parseInt(jsons_viv[i].id_vivienda) === parseInt(id)) {
					jsons_viv.splice(i, 1); 
					break;
				}
			 }
		}
		await this.setState({viviendas_json_db: jsons_viv});
		//console.log(this.state.viviendas_json_db);

	}

	async clearFilter(){
		await this.setState({filter2: ''});
		await this.filtrarViviendas();
		
			this.checkViviendas();
		

	}
	formContactosViviendas(){
		return(
			<div className="row " >
				<div className="itinerary">
				{this.nuevoForm()}
				</div>
				<div className="schedule">
					<div className="title">
						<h4 className="titulo">Viviendas</h4>
						<div className="input-search">
								<input
									className	=	"input-filter input"
									type		=	"text"
									value		=	{this.state.filter2}
									onChange	=	{(event) => this.buscarViviendas(event)}
								>
								</input>
								<i className="fas fa-search font-small"></i>
								{/*<i className="fas fa-times clear" onClick={() => {this.clearFilter()}}></i>*/}
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="columns-ininerary">
						{/* this.state.subf */}
						{this.state.subf != 0&&
							this.state.viviendasFiltradas.length !== 0 ?
							this.state.viviendasFiltradas.map(viv => 
								<div className="item" htmlFor={`check-${viv.id_vivienda}`}>
									<label htmlFor={`check-${viv.id_vivienda}`}>
										{viv.numero_registro}
									</label>
									<div className="check" >
										<label className="container-check" >
											<input 
												type		=	"checkbox" 
												// checked	=	{false} 
												name		=	"viviendas-check"  
												id			=	{`check-${viv.id_vivienda}`}  
												onClick={this.addCheck.bind(this,viv.id_vivienda)}
											/>
											<span className="checkmark" ></span>
										</label>
									</div>
								</div>	
							) :
							<div className="justify-center align-center">
								<h4>No hay viviendas para mostrar.</h4>
							</div>
						}


						{/*  */}
						
						{/*  */}
						


					</div>


				</div>
			</div>
		);
	}

    async validarTelefono(telefono) {
        this.setState({loadingVlidatePhone: true, phoneValidated: false});
        if (isValidPhoneNumber(telefono)) {
            const data = {
                telefono,
                id_fraccionamiento: this.state.fraccionamiento
            }
            const res = await request.post('/personas/getbyphone', data);
            const { persona } = res;
            if (persona) {
                this.setState({
                    INombre: persona.nombre || '',
                    email: persona.email || '',
                    IGenero: persona.genero || 0,
                    IDireccion: persona.direccion || '',
                    ICp: persona.cp || '',
                    city: persona.ciudad || '',
                    IMunicipio: persona.municipio || '',
                    IEstado: persona.estado || '',
                    IPais: persona.pais || '',
                    IId: persona.tipo_identificacion || 0,
                    iNumId: persona.num_identificacion || '',
                    IIdPersona: persona.id_persona || 0,
                    persona_existente: persona.encontrado
                });
            } else {
                this.setState({
                    INombre: '',
                    email: '',
                    IGenero: 0,
                    IDireccion: '',
                    ICp: '',
                    city: '',
                    IMunicipio: '',
                    IEstado: '',
                    IPais: '',
                    IId: 0,
                    iNumId: '',
                    IIdPersona: 0,
                    persona_existente: false
                });
            }
            this.setState({phoneValidated: true});
        } else {
			cogoToast.warn('Número de teléfono inválido.',{
				position: 'bottom-right',
			});
        }
        this.setState({loadingVlidatePhone: false});
    }

	nuevoForm() {
		let list_personas 		= [];
		let viviendas 			= [];
		let subfraccionamientos = [];
		if(Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map(persona => { return {label: persona.nombre, value: persona.id_persona}});
		}
		if(Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}
		// if(Array.isArray(this.state.viviendas)) {
		// 	viviendas = this.filtarViviendas();
		// }
		return (
			<div className="full row align-center">
				<div className="column full">

				<div className="input-form-content row full align-center"> 
						<div className="column label-medium">
							<p>
								<b>{this.state.subLabel}:</b>
							</p>
						</div>
						<div className="row full">
							{subfraccionamientos.length > 1 ?
								<select
									className="input input-modals"
									name="subfraccionamiento"
									required
									value={this.state.subf}
									onChange={(event) => this.setState({ subf: event.target.value })}
									disabled={this.state.subfraccionamiento === 0 ? false : true}
								>
									{/*<option value="0">SELECCIONAR SUBFRACCIONAMIENTO</option>*/}
									{subfraccionamientos.map(viv =>
										<option key={viv.id_subfraccionamiento} value={viv.id_subfraccionamiento}>
											{viv.nombre}
										</option>
									)}
								</select> :
								<select
									className="input input-modals"
									name="subfraccionamiento"
									required
									value={this.state.subf}
									disabled
								>
									{subfraccionamientos.map(viv =>
										<option key={viv.id_subfraccionamiento} value={viv.id_subfraccionamiento}>
											{viv.nombre}
										</option>
									)}
								</select>}
							{subfraccionamientos.length > 1 && this.state.subfraccionamiento === 0 && <button type="button" className="btn-primary color-white weight-bold space btn-change" onClick={this.changeSubFrac.bind(this)}>
								Cambiar
							</button>}
						</div>
					</div>	
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p><b>  Teléfono:	</b></p>
                        </div>
                        <div className="row full">
                            <PhoneInput
                                displayInitialValueAsLocalNumber
                                name="phoneNumber"
                                defaultCountry="MX"
                                autoComplete = 'off'
								maxLength = "18"
                                value={this.state.iTelefono}
                                onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined, phoneValidated: false }) }
                                error={ this.state.telefonoError }
                                className={'input-modals'}
                                required/>
                            <button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white weight-bold" onClick={this.validarTelefono.bind(this, this.state.iTelefono)}>
                                {this.state.loadingVlidatePhone? <i className="fas fa-spinner fa-spin"></i>: 'Validar'}  
                            </button>
                        </div>
					</div>
                    <div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
                        <div className="column label-medium">
							<p><b>Nombre completo:</b></p>
						</div>
						<div className="column full">
                            <input 
                                className	=	"input input-modals"
                                type		=	"text" 
                                name		=	"fullName" 
                                id			=	"fullName"
                                value		=	{this.state.INombre}
                                onChange	=	{event => this.setState({ INombre: event.target.value })}
                                required
								disabled    = {this.state.persona_existente}
								autoComplete = 'off'		
                            />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p className="justify-start">
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.IGenero} required 
								onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals"
								// disabled = {this.state.subf == 0 ?  true:false}
							>
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
                        <div className="column label-duo-normal space">
                            <div className="justify-start">
                                <p><b>Correo:</b></p>
                            </div>
						</div>
						<div className="column full">
							<input 
								type="email" 
								name="EmailEdit" 
								value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} 
								className="input input-modals" 
								autoComplete = 'off' 
								// disabled = {this.state.subf == 0 ?  true:false}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Identificación:	</b></p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" 
								value={this.state.iId} onChange={event => this.setState({ iId: event.target.value })}
								// disabled = {this.state.subf == 0 ?  true:false}
							>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start">
								<b> ID :</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className		=	"input input-modals"  
								type			=	"text" 
								name			=	"numIdEddit" 
								value			=	{this.state.iNumId}
								onChange		=	{event => this.setState({ iNumId: event.target.value })} 
								autoComplete 	= 'off'
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className="input input-modals" 
								type="text" 
								name="address" 
								value={this.state.IDireccion}
								onChange={event => this.setState({ IDireccion: event.target.value })} 
								autoComplete = 'off' 
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className="input input-modals"  
								type="text" 
								name="municipality" 
								value={this.state.IMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} 
								required 
								autoComplete = 'off'
								// disabled = {this.state.subf == 0 ?  true:false}
							/>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" >
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.ICp}
								onChange={event => this.setState({ ICp: event.target.value })} name="zip" 
								className="input input-modals"  required 
								autoComplete = 'off'
								// disabled = {this.state.subf == 0 ?  true:false}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals" 
								type		=	"text" 
								name		=	"state" 
								value		=	{this.state.IEstado}
								onChange	=	{event => this.setState({ IEstado: event.target.value })}  
								required  
								autoComplete = 'off'
							/>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start">
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className="input input-modals" 
								type="text" 
								value={this.state.IPais}
								onChange={event => this.setState({ IPais: event.target.value })} 
								name="country" 
								autoComplete = 'off' 
								required 
								
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium ">
							<p  >
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" 
								 required  
								type			=	"text" 
								value			=	{this.state.city}
								onChange		=	{event => this.setState({ city: event.target.value })} 
								name			=	"city" 
								autoComplete = 'off'
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
	getChecksChecked(checks){
		let checksChecked = [];
		checks.forEach( (check)=>{
			let subfrac = parseInt(this.state.subf);
			if(!check.subf || parseInt(check.subf) === subfrac){
				try{
					// {id_vivienda: 80, subf: 25}
					checksChecked.push(check.id_vivienda);
					//console.log(`${check.id_vivienda}`)
				}catch(err){
					//console.log(err);
				}
			}
		});
		return checksChecked;
		
	}
	/**
	 * Filtrar viviendas seleccionadas cuando por defecto se 
	 * traen viviendas que ya tiene una persona de otro
	 * subfraccioamiento
	 */
	filtrarViviendasSeleccionadas(jsonNoFiltrado){
		let id_sub = parseInt(this.state.subf);
		let viviendasPorFraccionamiento = [];

		this.state.viviendas.forEach((viv)=>{
			if(parseInt(viv.id_subfraccionamiento) == id_sub){
				viviendasPorFraccionamiento.push(viv.id_vivienda);
			}
		});

		let viviendasFiltradas = [];

		jsonNoFiltrado.forEach(viv => {
			if(viviendasPorFraccionamiento.includes(viv)){
				viviendasFiltradas.push(viv);
			}
		});

		return viviendasFiltradas;
	}

    componentDidMount() {
		this.getContactos();
		this.getPersonas();
		this.getViviendas();
		this.getSubFraccionamientos();
		this.getSubmenu();
	}

    async handleSubmit(event) {
        event.preventDefault();
		this.setState({loadingSave: true, saveMessage: ''});

		let checks 		= this.state.viviendas_json_db;
		let checksJson 	= this.getChecksChecked(checks); 


		let jsonSTR = this.filtrarViviendasSeleccionadas(checksJson);
		//console.log(checksJson);
		//console.log(jsonSTR);


		/**Validar Vivienda */
		if (!(jsonSTR.length > 0)) {
			//console.log(checksJson);
			this.setState({loadingSave: false});
            cogoToast.warn('Debes seleccionar una vivienda.',{
				position: 'bottom-right',
			});
            return;
		}
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.iTelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
		



		let data = {
			// idVivienda: 		this.state.IidViv,
			Nombre: 			this.state.INombre,
			Direccion: 			this.state.IDireccion,
			Municipio: 			this.state.IMunicipio,
			Estado: 			this.state.IEstado,
			Cp: 				this.state.ICp,
			Telefono: 			this.state.iTelefono,
			Pais: 				this.state.IPais,
			Genero: 			this.state.IGenero,
			TipoId: 			this.state.IId,
			NumId: 				this.state.iNumId,
			Vigencia: 			this.state.iVigencia,
			Fechaingreso: 		this.state.IFechaIngreso,
			FechaSalida: 		this.state.IFechaSalida,
			IdPersona: 			this.state.IIdPersona,
			Code: 				this.state.code,
			email: 				this.state.email,
			idFraccionamiento: 	parseInt(this.state.fraccionamiento),
			city:				this.state.city,
			viviendasJSON:		JSON.stringify(jsonSTR),
			subf:				parseInt(this.state.subf)
		}
		
		
		const response = await request.post("/viviendas/contacto/crear", data);

		
		//console.log(response);
		
		if(response.created){
			await this.closeModal("modal-contacto");
			this.getContactos();
			cogoToast.success('Contacto añadido.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({saveMessage: "NO SE PUDO CREAR CONTACTO"});
			cogoToast.error('No se pudo crear contacto.', {
				position: 'bottom-right',
			});
		}
		
		this.setState({loadingSave: false});
    }

    async getContactos() {
        this.setState({ loadingContactos: true });
        let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem('frac');

		let data = { IdSub, idFrac };

        const response = await request.post("/contactos/get", data)
        if (response && !response.error) {
            if (response.contactos && !response.empty) {
                this.setState({
                    contactos: response.contactos,
                    filterData: response.contactos
				});
				
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    contactos: []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
		}
		this.setState({ loadingContactos: false });
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento, filter:1 }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
	}

	async getViviendas() {
		let data	=	{
			id_fraccionamiento: localStorage.getItem('frac') || 0,
			id_subfraccionamiento:	localStorage.getItem('subfrac') ||0
		}
		
		const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);
		
		if(Array.isArray(response.viviendas)) {
			await this.setState({viviendas: response.viviendas, subname: response.viviendas[0].nombre_subfrac});
			
		} else {
			this.setState({viviendas: []});
		}
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}
}

export default Consumer(Contactos);