import React        from 'react';
import SuperCore    from './../../core/SuperCore';

class SubHeader extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            menus:          this.props.menus,
            id_menu_activo: this.props.id_menu_activo,
            id: this.props.id
        }
    }
    async componentDidUpdate(prevProps, prevState){
        if(prevProps.menus != this.props.menus){
            await this.setState({menus: this.props.menus});
        }

        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            await this.setState({id_menu_activo: this.props.id_menu_activo});
        }

        if(prevProps.id != this.props.id){
            await this.setState({id: this.props.id});
        }

    }
    async componentDidMount(){
        let menus = this.state.menus;
        let width = 0;
        if(menus){
            width = this.getMenuWith();
        }
        await this.deleteEmptySubMenu(menus);
    }


    render(){
        let menus = this.state.menus;
        let width = 0;
        if(menus){
            width = this.getMenuWith();
        }
        this.deleteEmptySubMenu(menus);
        return(
            <React.Fragment>
                {menus ? 
                    menus.map((nav, key)=>{
                        let id = `${this.props.id}-nav-${key}`;
                        return(
                            <button
                                key={key}
                                className   = {this.state.id_menu_activo === key ?  "super-item super-item-active" : "super-item" } 
                                style       = {{width:`${width}%`}}
                                id          = {id}
                                onClick     = {this.props.changeMenu.bind(this,key)}
                            >
                                <p>{nav}</p>
                            </button>
                        );
                    })

                : null}
            </React.Fragment>
        );
    }

    getMenuWith(){
        return 100 / this.state.menus.length;
    }
    async deleteEmptySubMenu(menus){
        let superNav = document.getElementById(`sub-header-${this.state.id}`);
        if(menus === null || (typeof menus) === 'undefined'){
            if(superNav){
                superNav.style.height = '0px';
            }
        }else{
            if(superNav){
                superNav.style.height = '9%';
            }
        }
    }
}

export default SubHeader;