import React, { Component } from 'react';
import Paginador from "../../paginador/paginador";
import NumberFormat from 'react-number-format';
import DinamicModal from '../../dinamicModal/dinamicModal';
import PlatosForm from './form';
import Request from '../../../core/httpClient';
import cogoToast from 'cogo-toast';

const request = new Request();

let values = {};

class PlatosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            platos: [],
            isModalOpen: false,
            plato: {},
            page: 1,
            fraccionamiento: localStorage.getItem('frac') || 0
        }
    }

    /*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
    }

    async openModal(idModal, plato) {
        this.setState({ plato, message: null, id_plato_row: plato.id_plato });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        }
        this.setState({isModalOpen: true});
    }

    openModalDelete(plato) {
        this.setState({ id_plato_row: plato.id_plato });
        this.props.selectDeletePlato(plato.id_plato);
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    getValues(data) {
        values = data;
    }

    render() {
        let platos = [];
        if (Array.isArray(this.props.platos)) {
            platos = this.props.platos;
            if (this.props.id_categoria > 0) {
                platos = platos.filter(plato => plato.id_categoria == this.props.id_categoria);
            } else {
                platos = this.props.platos;
            }
        }
        return (
            <div className="full row align-center">
                <div className="column full">
                    <div className="column card-table">
                        <div className="table-responsiv column">
							<table>
                                <thead className="sticky-th">
									<tr className="text-left">
										<th className="">
                                            <div className="row">
                                                Nombre &nbsp;
										        <div className="align-center">
                                                    {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                        <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                        :
                                                        <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                        :
                                                        <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                    }
                                                </div>
                                            </div>
                                        </th>
										<th className="text-left">
                                            <div className="row">
                                                Categoría &nbsp;
										        <div className="align-center">
                                                    {this.props.filterColumn.col === 'categoria' ? this.props.filterColumn.filt === 'asc' ?
                                                        <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'categoria')}></i>
                                                        :
                                                        <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'categoria')}></i>
                                                        :
                                                        <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'categoria')}></i>
                                                    }
                                                </div>
                                            </div>
                                        </th>
                                        <th className="text-right th-options-small">
                                            <div className="row">
                                                Precio &nbsp;
										        <div className="align-center">
                                                    {this.props.filterColumn.col === 'precio' ? this.props.filterColumn.filt === 'asc' ?
                                                        <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'precio')}></i>
                                                        :
                                                        <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'precio')}></i>
                                                        :
                                                        <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'precio')}></i>
                                                    }
                                                </div>
                                            </div>
                                        </th>
                                        <th className="text-center th-options-small">Activo</th>
										<th className="text-center th-options-small">Editar</th>
										<th className="text-center th-options-small">Borrar</th>
									</tr>
								</thead>
								<tbody>
								{	this.props.loading? 
									<tr>
										<td colSpan="6">
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									platos.slice((this.state.page - 1) * 7, this.state.page * 7).map((plato, key) => (
									<tr className={this.state.id_plato_row === plato.id_plato && 'row-active'} key={key}>
										<td className="text-left">
											{plato.nombre}
										</td>
										<td className="text-left">
                                            {plato.categoria}
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                value               =   {plato.precio}
                                                displayType         =   {"text"}
                                                thousandSeparator   =   {true}
                                                prefix              =   {"$ "}
                                                decimalScale        =   {2}
                                                fixedDecimalScale   =   {true}
                                            />
                                        </td>
                                        <td className="text-center row justify-center">
                                            <label className="container-check" style={{paddingLeft: '0px'}}>
                                                <input type="checkbox" readOnly hidden name="lectura" id="lectura" checked={plato.activo ? (true) : (false)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
										<td className="text-center ">
											<div className="aling-center justify-center">
                                                <button 
                                                    type="button"
                                                    className="btn-full justify-center btn-mini btn-secondary color-white"
                                                    onClick={this.openModal.bind(this, 'editPlato', plato)}>
													<i className="font-small fas fa-pen" />
												</button>
											</div>
										</td>
										<td className="text-center">
											<div className="aling-center justify-center">
                                                <button 
                                                        className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type		=	'button' 
														onClick		=	{this.openModalDelete.bind(this, plato)} 
														style		=	{{ padding: '5px' }} 
														disabled	=	{!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
												</button>
											</div>
										</td>
									</tr>
									))}
								</tbody>
							</table>
						</div>
                    </div>
                    <div className="white-space-16"></div>				
				    <Paginador pages={Math.ceil(platos.length / 7)} setPage={this.setPage.bind(this)} />
                </div>
                <DinamicModal
                    idModal             =   {'editPlato'}
                    title               =   {'Editar plato'}
                    success				=	{this.handleEdit.bind(this)}
					showBtnSuccess		=	{this.props.escritura}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					sizeModalContainer	=	{'big'}
                    closeModal          =   {() => this.setState({isModalOpen: false})}
                >
                    <PlatosForm
                        isModalOpen = {this.state.isModalOpen}
                        plato       = {this.state.plato}
                        getValues   = {this.getValues.bind(this)}
                        categorias  = {this.props.categorias}
                        horarios    = {this.props.horarios}
                    />
                </DinamicModal>
            </div>
        )
    }

    async handleEdit(event) {
        event.preventDefault();
        this.setState({loadingSave: true, message: null});
        //console.log(values);
        const res = await request.post('/restaurantes/platos/update', { ...values, idFraccionamiento: this.state.fraccionamiento});
        if (res.updated) {
            this.props.reload();
            cogoToast.success('Datos de plato actualizados.', {
                position: 'bottom-right',
            });
        } else {
            //this.setState({message: res.message || 'No se pudo actualizar el restaurante.'});
            cogoToast.error('No se pudieron actualizar datos de plato.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
    }
}

export default PlatosTable
