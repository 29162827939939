import React, { Component, Fragment } from 'react';
import NumberFormat from "react-number-format";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import BudgetProveedorEdit from './budgetProveedorEdit';
const request = new Request();

class BudgetTableProveedor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_proveedor: '',
            proveedor: '',
        }
    }

    recargar() {
        this.props.reload()
    }
    delete = id => {

        this.handleDelete(id)
    }

    openModalDelete() {
        let modal = document.getElementById("deleteModal");
        if (modal) {
            modal.classList.remove('hide-modal');
            modal.classList.add('show-modal');
        }
    }

    render() {
        let proveedores = [];
        if (this.props.proveedores) {
            proveedores = this.props.proveedores
        }
        return (
            <div className="card-table card-table-budget card-owners column" id="card-owners">
                <div className="table-responsiv column">
                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">
                                    <div className="row">
                                        Razón social &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'razon_social' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'razon_social')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'razon_social')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'razon_social')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">
                                    <div className="row">
                                        RFC &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'rfc' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'rfc')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'rfc')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'rfc')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">
                                    <div className="row">
                                        Municipio &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'municipio' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'municipio')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'municipio')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'municipio')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                {/*  <th className="">
                                    <div className="row">
                                        CP &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'cp' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'cp')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'cp')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'cp')}></i>
                                            }
                                        </div>
                                    </div>
                                </th> */}
                                {/* <th className="">
                                    <div className="row">
                                        Estado &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre_estado' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre_estado')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre_estado')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre_estado')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">
                                    <div className="row">
                                        País &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre_pais' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre_pais')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre_pais')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre_pais')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">
                                    <div className="row">
                                        Cuenta contable &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'cuenta_contable' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'cuenta_contable')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'cuenta_contable')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'cuenta_contable')}></i>
                                            }
                                        </div>
                                    </div>
                                </th> */}
                                <th className="">
                                    <div className="row">
                                        Concepto &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'nombre_concepto' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre_concepto')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre_concepto')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre_concepto')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="date text-center th-options-small">Editar</th>
                                <th className="date text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {proveedores.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((proveedor, key) => {
                                return (

                                    <tr className={this.state.id_proveedor_row === proveedor.id_proveedor ? 'row-active' : ''} key={key}>
                                        <td>{proveedor.razon_social}</td>
                                        <td>{proveedor.rfc}</td>
                                        <td>{proveedor.municipio}</td>
                                        {/* <td>{proveedor.cp}</td>  */}
                                        {/*  <td>{proveedor.nombre_estado}</td>
                                    <td>{proveedor.nombre_pais}</td>
                                    <td>{proveedor.cuenta_contable}</td> */}
                                        <td>{proveedor.nombre_concepto}</td>
                                        <td className="justify-arround text-center">
                                            <button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center"
                                                onClick={this.on_showEditDialog.bind(this, proveedor)} >
                                                <i className="font-small fas fa-pen" />
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                type='button'
                                                onClick={this.openModalDelete.bind(this, proveedor.id_proveedor)}
                                                style={{ padding: '5px' }}
                                                disabled={!this.props.escritura}
                                            >
                                                <i className="font-text fas fa-trash-alt " />
                                            </button>
                                        </td>
                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>
                    <BudgetProveedorEdit
                        proveedor={this.state.proveedor}
                        paises={this.props.paises}
                        estados={this.props.estados}
                        conceptos={this.props.conceptos}
                        reload={this.recargar.bind(this)}
                        escritura={this.props.escritura}
                    />
                    <ModalBorrar id={this.state.id_proveedor} delete={this.delete.bind(this)} />
                </div>
            </div>

        );
    }

    openModalDelete(id) {
        this.setState({ id_proveedor: id, id_proveedor_row: id });
        let modal = document.getElementById("deleteModal");
        if (modal) {
            modal.classList.remove('hide-modal');
            modal.classList.add('show-modal');
        }
    }

    openModal(idModal, proveedor) {
        this.setState({ mesagge: null, proveedor: proveedor, id_proveedor_row: proveedor.id_proveedor });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        }

        // document.getElementById('conceptName').focus();
    }

    async handleDelete(id) {
        let data = {

            id_proveedor: id
        }
        const response = await request.post("/proveedores/proveedor/delete", data);


        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.props.reload()

                this.setState({
                    showModal: false
                });
                cogoToast.success('Proveedor eliminado.', {
                    position: 'bottom-right',
                });
            } else {
                this.setState({
                    empty: true,
                    //message: response.message
                });
                cogoToast.error('No se pudo eliminar el proveedor.', {
                    position: 'bottom-right',
                });
            }
        } else {
            this.setState({
                error: true,
                //message: response.message
            });
            cogoToast.error('No se pudo eliminar el proveedor.', {
                position: 'bottom-right',
            });
        }
    }

    on_showEditDialog(proveedor) {
        this.openModal("editBudgetProveedor", proveedor);
    }
}

export default BudgetTableProveedor;