import React from 'react';
import SuperCore from './../../../core/SuperCore';

class Default extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            labelSubmit:    this.props.labelSubmit,
            labelCancel:    this.props.labelCancel,
            showSubmit:     this.props.showSubmit,
            showClose:      this.props.showClose
        }
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.labelSubmit != this.props.labelSubmit){
            await this.setState({labelSubmit: this.props.labelSubmit});
        }

        if(prevProps.labelCancel != this.props.labelCancel){
            await this.setState({labelCancel: this.props.labelCancel});
        }

        if(prevProps.showSubmit != this.props.showSubmit && this.props.showSubmit != null && typeof this.props.showSubmit != 'undefined'){
            await this.setState({showSubmit: this.props.showSubmit});
        }

        if(prevProps.showClose != this.props.showClose && this.props.showClose != null && typeof this.props.showClose != 'undefined'){
            await this.setState({showClose: this.props.showClose});
        }
        
    }

    render(){
        let labelSubmit = this.state.labelSubmit;
        let labelCancel = this.state.labelCancel;
        if(labelSubmit === null || typeof labelSubmit == 'undefined'){
            labelSubmit = 'Guardar';
        }

        if(labelCancel === null || typeof labelCancel == 'undefined'){
            labelCancel = 'Cancelar';
        }

        // //console.log(this.state.showSubmit);
		// //console.log(this.state.showClose);
		if (this.props.loading) {
			return (
				<div className="row full justify-center">
					<i className="fas fa-spinner fa-spin"></i>
				</div>
			)
		}

        return(
        <div className="row full justify-center">
            <div className      =   "container ">
                <div className  =   "row justify-end">
                    {this.state.showClose && 
                        <button
                            className   =   "btn-action-cancel"
                            onClick     =   {(event)=>{
                                this.props.triggerCloseModal();
                                this.superCloseModal(this.props.id)
                            }}
                        >
                            {labelCancel.toUpperCase()}
                        </button>
                    }
                    {this.state.showSubmit &&
                        <button
                            className="btn-action-success"
                            onClick={(event) => {
                                this.props.onSubmit(event);
                            }}
                        >
                            {labelSubmit.toUpperCase()}
                        </button>
                    }
                </div>
            </div>
        </div>
    
        );
    }
}

export default Default;