import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarAdministracion from "../../components/header/sidebarAdministracion";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Paginador from "../../components/paginador/paginador";
import Controls from '../../components/controls/controls';
import Permissions from '../../middlewares/Permissions';
import PersonalTable from '../../components/personalSeguridad/personalTable';
import PersonalForm from '../../components/personalSeguridad/form';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';
import moment from 'moment-timezone';
import { Consumer } from '../../context';

const request = new Request();

const PersonalSeguridad = ({ context }) => {
	const [logo, setLogo] = useState(require ("../../img/logo-AppMosphera-r.png"));
	const [lectura, setLectura] = useState(false);
	const [escritura, setEscritura] = useState(false);
	const [loadingPersonal, setloadingPersonal] = useState(false);
	const [loadingSave, setloadingSave] = useState(false);
	const [page, setPage] = useState(1);
	const [pageLength, setPageLength] = useState(20);
	const [personal, setPersonal] = useState([]);
	const [departamentos, setDepartamentos] = useState([]);
	const [posiciones, setPosiciones] = useState([]);
	const [jornadas, setJornadas] = useState([]);
	const [turnos, setTurnos] = useState([]);
	const [filter, setFilter] = useState('');
	const [message, setMessage] = useState(false);
	const [filterColumn, setFilterColumn] = useState({
		col: '',
		filt: ''});
	const [direccion, setDireccion] = useState({
		nombres: 'desc',
		apellidos: 'desc',
		departamento: 'desc'
	});
	const [personalEmpty, setPersonalEmpty] = useState({});
	const [user, setUser] = useState([]);
	const [submenu, setSubmenu] = useState('');

	useEffect(() => {
		validarPermisos();
	}, [])

	useEffect(() => {
		getCatalogos();
		getPersonal();
		getUser();
	}, [lectura])

	async function validarPermisos(){
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();

		setEscritura(permisosInfo.escritura);
		setLectura(permisosInfo.lectura);
	}

	async function getUser() {
		const submenu = await context.getSubmenuAdminName();
		if (submenu) {
			setSubmenu(submenu);
		}

		const user = await context.loadUser();
		//console.log(user);
		setUser(user);
	}

	async function getCatalogos() {
		const departamentos = await request.post("/personal/departamentos/get", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(departamentos.departamentos)) {
			setDepartamentos(departamentos.departamentos);
		} else {
			setDepartamentos([]);
		}

		const posiciones = await request.post("/personal/posiciones/get", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(posiciones.posiciones)) {
			setPosiciones(posiciones.posiciones);
		} else {
			setPosiciones([]);
		}

		const jornadas = await request.post("/personal/jornadas/get", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(jornadas.jornadas)) {
			setJornadas(jornadas.jornadas);
		} else {
			setJornadas([]);
		}

		const turnos = await request.post("/personal/turnos/get", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(turnos.turnos)) {
			setTurnos(turnos.turnos);
		} else {
			setTurnos([]);
		}
	}

	async function getPersonal() {
		setloadingPersonal(true);

		const response = await request.post("/personal/get", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personal)) {
			setPersonal(response.personal);
		} else {
			setPersonal([]);
		}

        setloadingPersonal(false);
	}

	async function paginatorChange( length ){
		setPageLength(length);
		setPage(1);
	}

	async function handleSubmit(event) {
		event.preventDefault();

		setloadingSave(true);

		const form = event.target;
		//console.log(form);
		if (form) {
			const img = form.personal_imagen;

			let file = null;
			if (img) {
				if (img.files) {
					file = img.files[0];
				}
			}

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
				departamento: form.personal_departamento.value,
				posicion: form.personal_posicion.value,
				jornada: form.personal_jornada.value,
				turno: form.personal_turno.value,
				nombres: form.personal_nombres.value,
				apellidos: form.personal_apellidos.value,
				rfc: form.personal_rfc.value,
				curp: form.personal_curp.value,
				nss: form.personal_nss.value,
				activo: form.personal_activo.checked,
				fecha_inicio: form.personal_fecha_inicio.value || moment().format("YYYY-MM-DD HH:mm:ss"),
				fecha_fin: form.personal_fecha_fin.value,
				user: user.id.nombre,
				fecha: moment().format()
			}

			let response = await request.new_postFile('/personal/create', [file], data);

			if (response.created) {
				closeModal('modal-nuevo-personal');
				reload();
				cogoToast.success('Personal creado.', {
					position: 'bottom-right',
				});
			} else {
				cogoToast.error(response.message || 'No se pudo crear el personal.', {
					position: 'bottom-right',
				});
			}
		}

		setloadingSave(false);
	}

	function openModal(idModal) {
		setPersonalEmpty({});

		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	function closeModal(idModal) {
		setPersonalEmpty({});

		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	function reload() {
        getPersonal();
	}

	function filterArray(array) {
		array = array.filter(res => {
			if (res.nombres.toLowerCase().indexOf(filter) !== -1) {
				return true;
			} else if (res.apellidos.toLowerCase().indexOf(filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return array;
	}

	function sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, personal, direccion[key]);

		setDireccion({
			[key]: direction
		});
		setPersonal(personal);
		setFilterColumn({
			col: key,
			filt: direction});
	}

	return (
		<div className="owners column">
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - {submenu}</title>
			</Helmet>
			<Header sidebar={true} active={'personalSeguridad'} parent={'personal'} nav={"administracion"} />
			<div className="row">
				<div className="column" id="sidebar">
					<SidebarAdministracion />
				</div>
				<div className="column" id="content">
					<div className="justify-center">
						<div className="container column">
							{lectura ?
							<React.Fragment>
								<Controls 
									title		=	{submenu}
									nuevo		=	{openModal.bind(this, 'modal-nuevo-personal')}
									dataSet		=	{filterArray(personal)}
									setFilter	=	{setFilter.bind(this)}
									newButton   =   {true}
								/>
							{loadingPersonal?
								<div className="row justify-center">
									<i className="fas fa-spinner fa-spin"></i>
								</div>
								:
								<PersonalTable 
									personal		=	{filterArray(personal)} 
									page			=	{page} 
									reload			=	{reload.bind(this)} 
									escritura		=	{escritura}
									pageLength		=   {pageLength}
									departamentos	=	{departamentos}
									posiciones		=	{posiciones}
									jornadas		=	{jornadas}
									turnos			=	{turnos}
									sortBy			=	{sortableTable.bind(this)}
									filterColumn	=	{filterColumn}
									user			= 	{user}
								/>
							}
							<div className="white-space-16"></div>
							<Paginador 
								pages={Math.ceil(filterArray(personal).length / pageLength)} 
								setPage={setPage.bind(this)} 
								pageLength = {pageLength} 
								paginatorChange = {paginatorChange.bind(this)} 
								showPageLenght = {true}/>
							<div className="white-space-16"></div>
							</React.Fragment>:
							null
							}
						</div>
					</div>
				</div>
			</div>
			<DinamicModal 
				idModal				=	{'modal-nuevo-personal'}
				sizeModalContainer	=	{'normal'}
				title				=	{'Añadir personal'} 
				success				=	{handleSubmit.bind(this)} 
				showBtnSuccess		=	{true}
				btnTextSuccess		=	{"GUARDAR"}
				showBtnCancel		=	{true}
				btnTextCancel		=	{"CANCELAR"}
				loadingSave			=	{loadingSave}
				message				=	{message}
				closeModal			=	{closeModal.bind(this)}
			>
				<PersonalForm
					personal		=	{personalEmpty}
					departamentos	=	{departamentos}
					posiciones		=	{posiciones}
					jornadas		=	{jornadas}
					turnos			=	{turnos}/>
			</DinamicModal>
		</div>
	)
}

export default Consumer(PersonalSeguridad);
