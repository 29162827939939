import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';


const request = new Request();

class ModalEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            idUsuario: this.props.data.id_usuario,
            nombre: this.props.data.nombre,
            usuario: this.props.data.usuario,
            password: '',
            rol: this.props.data.id_perfil,

            valid: false,
            valids: true,
            edditPassHident: true,
            user: [],
            idFraccionamiento: 0,
            editUser: false,
            eddit: true,
            valid: true,
            edditPass: false,
            existe: false,
            loadingSave: false,
            idSubfraccionamiento: '',
            listSubfraccionamiento: [],
            dissableListSubfracionamiento: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {

            this.setState({
                idUsuario: this.props.data.id_usuario,
                nombre: this.props.data.nombre,
                usuario: this.props.data.usuario,
                rol: this.props.data.id_perfil,
                password: '',
                idSubfraccionamiento: this.props.data.id_subfraccionamiento || '',
            });

            this.getUser();
        };
    };

    render() {
        let perfiles = [];
        if (this.props.perfiles) {
            perfiles = this.props.perfiles
        };

        let listSubfraccionamiento = [];

        if (this.props.listSubfraccionamiento) {
            listSubfraccionamiento = this.props.listSubfraccionamiento;
        };

        return (
            <div>

                <DinamicModal
                    idModal={'editUsers'}
                    sizeModalContainer={'normal'}
                    title={'Editar usuario'}
                    success={this.handleSubmit.bind(this)}
                    showBtnSuccess={this.props.escritura}
                    btnTextSuccess={"GUARDAR"}
                    showBtnCancel={true}
                    btnTextCancel={"CANCELAR"}
                    closeModal={this.restart.bind(this)}
                    loadingSave={this.state.loadingSave}
                    message={this.state.message}
                >
                    {this.renderEditUser(perfiles, listSubfraccionamiento)}
                </DinamicModal>

            </div>
        );
    }

    renderEditUser(perfiles, listSubfraccionamiento) {
        return (
            <div className="full row align-center">
                <div className="column full">
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Nombre:</b></p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals"
                                type="text"
                                name="fname"
                                id="fname"
                                value={this.state.nombre}
                                onChange={event => this.setState({ nombre: event.target.value })}
                                required
                                minLength="6"
                                maxLength="100"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center ">
                        <div className="column full label-medium">
                            <p><b>Usuario:</b></p>
                        </div>
                        <div className="column full">
                            <div className="row full">
                                <input
                                    className="input input-modals"
                                    type="text"
                                    name="user"
                                    value={this.state.usuario}
                                    onChange={event => this.setState({ usuario: event.target.value })}
                                    minLength="6"
                                    maxLength="150"
                                    autoComplete='off'
                                    required
                                />

                            </div>
                        </div>
                    </div>
                    <div>
                        {this.state.existe ? (<p style={{ color: 'red' }}>Este usuario ya está registrado.</p>) : null}
                    </div>
                    <div className="white-space-16" />
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Perfil:</b></p>
                        </div>
                        <div className="column full">
                            <select name="rol" id="" className="input input-modals"
                                value={this.state.rol}
                                onChange={event => this.setState({ rol: event.target.value })}>

                                {perfiles.map((perfil, key) => (
                                    <option key={key} value={perfil.id_perfil}>{perfil.nombre}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Subfraccionamiento:</b></p>
                        </div>
                        <div className="column full">
                            <select name="idSubfraccionamiento" id="" className="input input-modals"
                                value={this.state.idSubfraccionamiento}
                                onChange={event => this.setState({ idSubfraccionamiento: event.target.value })}
                                disabled={this.state.dissableListSubfracionamiento}
                            >
                                <option value="0">Todos</option>
                                {
                                    listSubfraccionamiento.map((subfraccionamiento, key) => (
                                        <option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="white-space-24"></div>
                    <div className="row align-center justify-end">
                        <button className="btn btn-mini btn-primary color-white"
                            type="button"
                            id="btn-modal-edit"
                            onClick={this.handleEdit.bind(this)}
                            disabled={!this.props.escritura}
                        >
                            <i className="font-text fas fa-lock" />&nbsp;
                            Nueva contraseña
                        </button>
                    </div>
                    {
                        this.state.edditPass ? (
                            <div>
                                <div className="white-space-8"></div>
                                <p style={{ textAlign: 'center' }}>
                                    Asignar contraseña temporal. <br /> Se le solicitara al usuario cambiarla al ingresar a la plataforma.
                                </p>
                                <div className="white-space-32"></div>
                                <div className="input-form-content row full align-center">
                                    <div className="column label-duo-big mr-10"  >
                                        <p >
                                            <b>Contraseña:</b>
                                        </p>
                                    </div>
                                    <div className="column full">
                                        <input
                                            type="password"
                                            name="pass"
                                            id="pass"
                                            className="input input-modals container"
                                            onKeyUp={this.passValidate.bind(this)}
                                            required
                                            maxLength="64"
                                            autoComplete='new-password'
                                        />
                                    </div>
                                    <div className="column label-duo-normal space">
                                        <p >
                                            <b>Confirmar Contraseña:</b>
                                        </p>
                                    </div>
                                    <div className="column full space">
                                        <input
                                            type="password"
                                            name="pass2"
                                            id="pass2"
                                            value={this.state.password}
                                            onKeyUp={this.passValidate.bind(this)}
                                            onChange={event => this.setState({ password: event.target.value })}
                                            className="input input-modals container"
                                            required
                                            maxLength="64"
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                                <div className="white-space-32"></div>
                                {this.state.valid ? (null) : (<p style={{ color: 'red' }}>Las contraseñas deben ser iguales y tener un tamaño mínimo de 4 caracteres.</p>)}

                            </div>
                        ) : null
                    }
                </div>
            </div>

        )
    }

    passValidate() {
        let pass1 = document.getElementById('pass'),
            pass2 = document.getElementById('pass2');
        if (pass1 && pass2) {
            if (pass1.value === pass2.value && pass1.value.length >= 4) {
                this.setState({ valid: true });
                return true;
            };
        };

        this.setState({ valid: false });
        return false;
    };

    restart() {
        const pass = document.getElementById('pass');

        if (pass) {
            pass.value = ""
        }

        this.setState({ password: "" });
    };

    async handleSubmit(event) {
        event.preventDefault();
        try {
            this.setState({ loadingSave: true });

            const data = {
                nombre: this.state.nombre,
                rol: parseInt(this.state.rol),
                idUser: this.state.idUsuario,
                UsuarioName: this.state.usuario,
                idSubfraccionamiento: this.state.idSubfraccionamiento || null
            };

            if (this.passValidate() || !this.state.edditPass) {
                const response = await request.post("/users/fraccionamiento/update/user", data);

                if (response.updated) {
                    if (this.state.edditPass) {
                        await this.edditPassword();
                    } else {
                        this.props.reload();
                        cogoToast.success('Datos de usuario actualizados.', {
                            position: 'bottom-right',
                        });
                    }
                } else {
                    this.setState({
                        empty: true,
                    });
                    cogoToast.error('No se pudieron actualizar datos de usuario.', {
                        position: 'bottom-right',
                    });
                }
            } else {
                this.setState({
                    empty: true,
                });
                cogoToast.warn('Verifica que las contraseñas coincidan.', {
                    position: 'bottom-right',
                });
            };

            this.setState({ loadingSave: false });
        } catch (error) {
            console.log(error);
        };
    };

    handleCloseUser() {
        this.setState({ editUser: false, eddit: true, existe: false })
    };

    handleEditUser() {
        this.setState({
            editUser: true,
            edditPass: false,
            eddit: false
        })
    };

    handleEdit() {
        this.setState({
            edditPass: !this.state.edditPass,
            edditPassHident: !this.state.edditPassHident,
            eddit: !this.state.eddit,
            editUser: !this.state.editUser
        })
    };

    handleCancel() {
        this.setState({
            edditPass: false,
            eddit: true,
            editUser: false
        })
    };

    async getUser() {
        try {

            const data = { IdUser: this.props.idUser };
            const response = await request.post("/users/get/fraccionamiento/user", data);

            if (response && !response.error) {
                if (response.user && !response.empty) {
                    this.setState({
                        nombre: response.user[0].nombre,
                        usuario: response.user[0].usuario,
                        rol: response.user[0].id_perfil,
                        idFraccionamiento: response.user[0].id_fraccionamiento
                    });
                } else {
                    this.setState({ empty: true });
                };
            } else {
                this.setState({ error: true });
            };
        } catch (error) {
            console.log(error);
        };
    };

    async edditUser() {
        this.setState({
            edditPass: true,
            edditPassHident: false,
            eddit: false,
            editUser: false
        })

        const validar = {
            Usuario: this.state.usuario,
            idFraccionamiento: this.state.idFraccionamiento,
        };

        const response = await request.post("/users/validate/user", validar);

        if (response && !response.error) {
            if (response.validate.exist && !response.empty) {
                this.setState({
                    existe: true,
                });
                return;
            } else {
                this.setState({ existe: false });
                const data = {
                    Usuario: this.state.usuario,
                    idUser: this.props.idUser
                };

                const response = await request.post("/users/fraccionamiento/update/users/user", data);

                if (response && !response.error) {
                    if (response.updated && !response.empty) {
                        this.props.reload()
                        this.setState({
                            editUser: false
                        });

                    } else {
                        this.setState({
                            empty: true,
                            message: response.message
                        });
                    };
                } else {
                    this.setState({
                        error: true,
                        message: response.message
                    });
                };
            };
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        };
    };

    async edditPassword() {
        try {
            let pass1 = document.getElementById('pass').value,
                pass2 = document.getElementById('pass2').value;

            if (pass1 === pass2) {
                this.setState({ valid: true, valids: false })
                const data = {
                    IdUser: this.state.idUsuario,
                    Password: pass2,
                };
                const response = await request.post("/users/fraccionamiento/update/user/password", data);
                if (response && !response.error) {
                    if (response.updated && !response.empty) {
                        this.props.reload()
                        this.setState({
                            edditPass: false,
                            eddit: true
                        });
                        cogoToast.success('Contraseña actualizada.', {
                            position: 'bottom-right',
                        });
                    } else {
                        this.setState({
                            empty: true,
                            message: response.message
                        });
                        cogoToast.error('No se pudo actualizar la contraseña.', {
                            position: 'bottom-right',
                        });
                    }
                } else {
                    this.setState({
                        error: true,
                    });
                    cogoToast.error('No se pudo actualizar la contraseña.', {
                        position: 'bottom-right',
                    });
                }
                this.props.reload();

            } else {
                this.setState({ valid: false, valids: true })
                cogoToast.warn('Las contraseñas deben ser iguales.', {
                    position: 'bottom-right',
                });
                return;
            }
        } catch (error) {
            console.log(error);
        };
    };
};


export default ModalEddit;