import React from 'react';
import moment from 'moment-timezone'
import { Consumer } from "../../context";

import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";
import DinamicModal from '../dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
import Core from '../core/SuperCore';
import Form from './form';

const request = new Request();

class TablaActivas extends Core{

    constructor(props){
        super(props);
        this.state = {
			invitaciones: 	this.props.invitaciones,
			invitacion:		{},
            page:   		this.props.page,

            fecha:        	new Date(),
            id_horario: 	0,
            user:       	this.props.user,
        }
        
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.page != this.props.page){
            await this.setState({page: this.props.page});
        }

        if(prevProps.invitaciones != this.props.invitaciones){
            await this.setState({invitaciones: this.props.invitaciones});
        }

        if(prevProps.user != this.props.user){
            await this.setState({user: this.props.user});
        }
    }
    async delimitarSeleccionFechas(areaSeleccionada){
        let min_day = moment();
        min_day = min_day.add(parseInt(areaSeleccionada.dias_anticipacion),'days');
        
        await this.setState({
            min_day:    min_day.toDate()
        });
    }

    async beforeOpenModal(invitacion) {
		this.setState({invitacion, id_invitacion_row: invitacion.id_invitacion});
		this.openModal('editInvitacion');
		this.getImages(invitacion);
    }

	async getImages(inv) {
		this.setState({loadingImages: true});
		const { tipo, id_invitacion } = inv;
		const response = await request.post('/invitaciones/get/imagenes', { tipo, id_invitacion });
		const { imagenes } = response;
		if (imagenes) {
			const invitacion = inv;
			invitacion.identificacion_1 = imagenes.identificacion_1 || 'https://gqspcolombia.org/wp-content/themes/consultix/images/no-image-found-360x260.png';
			invitacion.identificacion_2 = imagenes.identificacion_2 || 'https://gqspcolombia.org/wp-content/themes/consultix/images/no-image-found-360x260.png';
			invitacion.matricula = imagenes.matricula || 'https://gqspcolombia.org/wp-content/themes/consultix/images/no-image-found-360x260.png';
			this.setState({invitacion});
		} else {
			cogoToast.warn(response.message || 'No se pudieron obtener las imágenes.', { position: 'bottom-right' });
		}
		this.setState({loadingImages: false});
	}

    render() {
        let invitaciones = this.state.invitaciones;

        return(
            <div className="card-table card-owners column" id="card-owners">
                <div className="table-responsiv column">
                    <table>
						<thead className="sticky-th">
                            <tr className="text-left">
                                <th className="text-left" style={{width: '165px'}}>
									<div className="row">
										Fecha &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'fecha_entrada' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fecha_entrada')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fecha_entrada')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fecha_entrada')}></i>
											}
										</div>
									</div>
								</th>
								<th className="">
									<div className="row">
										Tipo &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'tipo' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="">
									<div className="row">
										Invitado &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'invitado' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-left">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'vivienda' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'vivienda')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'vivienda')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'vivienda')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-center th-options-small">Detalles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invitaciones.slice((this.state.page - 1) * this.props.pageLength, this.state.page * this.props.pageLength).map((inv, key) => (
                                <tr className={this.state.id_invitacion_row === inv.id_invitacion && 'row-active'} key={key}>
                                    <td>{moment(inv.fecha_entrada).format("DD/MM/YYYY [-] hh:mm A")}</td>
									<td>{inv.tipo}</td>
									<td>{inv.invitado}</td>
                                    <td>{inv.vivienda}</td>
									<td className="text-center">
                                        <button 
                                            className   =   'btn-full justify-center btn-mini btn-secondary color-white' 
                                            type        =   "button" 
                                            onClick     =   {this.beforeOpenModal.bind(this,inv)} 
                                        ><i className="font-small fas fa-pen" /> 
										</button>
									</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <DinamicModal
					idModal				=	{'editInvitacion'}
					sizeModalContainer	=	{'big'}
					title				=	{'Detalle invitación'}
					// success				=	{this.handleSubmit.bind(this)}
					// closeModal			=	{this.resetData.bind(this)}
					btnTextSuccess		=	{"ACTUALIZAR"}
					btnTextCancel		=	{"CERRAR"}
					showBtnSuccess		=	{false}
					showBtnCancel		=	{true}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					footer				=	{this.renderFooter()}
                >
                    <Form
						values = {this.state.invitacion}
						loadingImages = {this.state.loadingImages}/>
                </DinamicModal>
            </div>
        );
    }

	renderFooter() {
		return (
			<div className="row full">
				{this.state.loadingPDF? 
				<a className="btn btn-small btn-primary color-white">
					<i className="fas fa-spinner fa-spin"></i>
				</a>
				:
				<a className="btn btn-small btn-primary color-white" onClick={this.exportarPDF.bind(this)}>
					<i className="fas fa-file-pdf"></i>
					&nbsp;
					Exportar
				</a>
				}
			</div>
		)
	}

	async exportarPDF() {
		this.setState({loadingPDF: true});
		const { invitacion } = this.state;
		const data = {
			invitacion: {
				...invitacion,
				fecha_invitacion: moment(invitacion.fecha_invitacion).format('DD/MM/YYYY HH:mm'),
				fecha_entrada: invitacion.fecha_entrada? moment(invitacion.fecha_entrada).format('DD/MM/YYYY HH:mm'): '-',
				fecha_salida: invitacion.fecha_salida? moment(invitacion.fecha_salida).format('DD/MM/YYYY HH:mm'): '-',
				fecha_entrada2: invitacion.fecha_entrada2? moment(invitacion.fecha_entrada2).format('DD/MM/YYYY HH:mm'): '-',
				fecha_salida2: invitacion.fecha_salida2? moment(invitacion.fecha_salida2).format('DD/MM/YYYY HH:mm'): '-'
			}
		}
		const res = await request.post('/invitaciones/exportar/pdf', data);
		const { buffer } = res;
		if (buffer) {
			cogoToast.success('Generando archivo.', {
				position: 'bottom-right'
            });
            let titulo = 'invitacion';
            const select = document.getElementById('report-select');
            if (select) {
                titulo = select.options[select.selectedIndex].text;;
            }
			const bytes = new Uint8Array(buffer.data);
			const blob = new Blob([bytes]);
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			const fileName = `${titulo}_${moment().format('YYMMDDHHmm')}.pdf`;
			link.download = fileName;
			link.click();
		} else {
			cogoToast.error(res.message || 'No se pudo crear el documento de excel.', {
				position: 'bottom-right'
			});
		}
		this.setState({loadingPDF: false});
	}
}

export default Consumer(TablaActivas);