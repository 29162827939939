import React, { Component, Fragment } from 'react';
import NumberFormat from "react-number-format";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import PresupuestoGrid from './PresupuestoGrid';
import BudgetModalEddit from './budgetModalEddit';
import cogoToast from 'cogo-toast';
const request = new Request();

//esto es un lista temporal
var lista = [
	{
		"id_concepto": 2,
		"nombre": "Administracion",
		"mes1": 1.0,
		"mes2": 2.0,
		"mes3": 3.0,
		"mes4": 4.0,
		"mes5": 5.0,
		"mes6": 6.0,
		"mes7": 7.0,
		"mes8": 8.0,
		"mes9": 9.0,
		"mes10": 10.0,
		"mes11": 11.0,
		"mes12": 12.0
	},
	{
		"id_concepto": 3,
		"nombre": "Seguridad",
		"mes1": 5.0,
		"mes2": 34.0,
		"mes3": 5.0,
		"mes4": 345.0,
		"mes5": 345.0,
		"mes6": 345.0,
		"mes7": 345.0,
		"mes8": 5345.0,
		"mes9": 0.0,
		"mes10": 0.0,
		"mes11": 0.0,
		"mes12": 0.0
	},
	{
		"id_concepto": 4,
		"nombre": "Mantenimientos",
		"mes1": 5.0,
		"mes2": 34.0,
		"mes3": 8.0,
		"mes4": 345.0,
		"mes5": 345.0,
		"mes6": 345.0,
		"mes7": 6.0,
		"mes8": 5345.0,
		"mes9": 0.0,
		"mes10": 12.0,
		"mes11": 0.0,
		"mes12": 0.0
	},
	{
		"id_concepto": 3,
		"nombre": "Seguridad",
		"mes1": 5.0,
		"mes2": 34.0,
		"mes3": 5.0,
		"mes4": 345.0,
		"mes5": 345.0,
		"mes6": 345.0,
		"mes7": 345.0,
		"mes8": 5345.0,
		"mes9": 0.0,
		"mes10": 0.0,
		"mes11": 0.0,
		"mes12": 0.0
	}
];

class BudgetTableTeoric extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			conceptos: [],
			concepto: {},
			idconcepto: 0,
			id_detalle: '',
			years: [],
			rowType: '',
			presupuesto: '',
			id_presupuesto_row: 0,
			id_presupuesto: 0,
			meses: ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			loading_presupuestos_importe: false
		}


	}

	componentDidMount() {
		this.getYears();
		this.getConcepts();
	}

	async getYears() {
		const res = await request.get('/presupuestos/get/years');
		if (res.anios) {
			this.setState({ years: res.anios });
		} else {
			this.setState({ years: [] });
		}
	}

	recargar() {
		this.props.reload()
	}

	editado() {
		this.props.getPresupuestos()
	}

	delete = (id, close) => {
		this.handleDelete(id, close)
	}

	openModal(idModal, presupuesto) {
		this.setState({ presupuesto: presupuesto });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	async openRow(type, id_presupuesto) {
		const data = { type, id_presupuesto };

		window.localStorage.setItem('openRow', JSON.stringify(data));

		if (this.state.id_presupuesto === id_presupuesto && type === this.state.rowType) {
			window.localStorage.removeItem('openRow');
			this.setState({ rowType: '', id_presupuesto: 0 });
			return;
		}

		this.setState({ rowType: type, id_presupuesto: id_presupuesto, id_presupuesto_row: id_presupuesto });
		this.getPresupuestoImportes(id_presupuesto);
	}

	async getPresupuestoImportes(id_presupuesto) {
		this.setState({ presupuestos_importe: [], loading_presupuestos_importe: true });

		let data = {
			id_presupuesto: id_presupuesto,
		};

		const response = await request.post("/presupuestos/get/all/importes", data)
		if (!response.empty && !response.error) {
			await this.setState({ presupuestos_importe: response.presupuestos_importe });
		} else {
			await this.setState({ presupuestos_importe: [] });
		}

		this.setState({ loading_presupuestos_importe: false });
	}

	render() {
		let abilitar = false
		if (this.props.disabled) {
			abilitar = this.props.disabled
		}

		let conceptosprop = []
		let total = 0;
		let totalReal = 0
		if (Array.isArray(this.props.presupuestos)) {
			conceptosprop = this.props.presupuestos[0];
			total = conceptosprop.reduce((accumulator, concepto) => (accumulator + concepto.importe), 0);
			totalReal = conceptosprop.reduce((accumulator, concepto) => (accumulator + concepto.total), 0);
		}

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead className="sticky-th">
							<tr className="text-left">

								<th className="text-left description">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">

										</div>
									</div>
								</th>

								<th className="text-left">Mes-Año</th>
								<th className="text-center th-options-small">Conceptos</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
								<th className="text-center th-options-small">Duplicar</th>
							</tr>
						</thead>
						<tbody>
							{conceptosprop.map(presupuesto => {
								return (
									<React.Fragment key={presupuesto.id_presupuesto}>
										<tr className={this.state.id_presupuesto_row === presupuesto.id_presupuesto ? 'row-active text-left' : ''}>
											<td className="text-left">{presupuesto.nombre}</td>
											<td className="text-left">{this.state.meses[presupuesto.mes] + "-" + presupuesto.anyo} </td>

											<td className="text-center">
												<button className="btn-full btn-mini btn-secondary color-white tooltip"  /*tooltip */
													onClick={this.openRow.bind(this, "conceptos", presupuesto.id_presupuesto)}>
													&nbsp;
													<i className={`font-small fa fa-list ${this.state.rowType === "conceptos" && this.state.id_presupuesto_row === presupuesto.id_presupuesto ? '	fa fa-list color-red' : ''}`} />
													<span className="tooltiptext">Mostrar</span>
												</button>
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn-full justify-center btn-mini btn-secondary color-white"
													onClick={this.openModal.bind(this, "editPresupuesto", presupuesto)}
												>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											<td className="text-center">
												<button className="btn-full justify-center btn-mini btn-secondary color-white"
													type='button'
													onClick={this.openModalDelete.bind(this, presupuesto.id_presupuesto)}
													style={{ padding: '5px' }}
													disabled={!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " />
												</button>
											</td>
											<td className="text-center">
												<button className="btn-full btn-mini btn-secondary color-white tooltip">
													<i className="font-small fas fa-clone" />
												</button>
											</td>

										</tr>

										{this.state.id_presupuesto === presupuesto.id_presupuesto && this.state.rowType === 'conceptos' ? (
											this.state.loading_presupuestos_importe ? (
												<tr>
													<td className="text-center" colSpan="9">
														<i className="fas fa-spinner fa-spin"></i>
													</td>
												</tr>
											) : (
												<tr className="acordeon-row">
													<td className="acordeon" colSpan="9">
														<div className="row full">
															<PresupuestoGrid

																presupuesto={presupuesto}
																presupuestos_importe={this.state.presupuestos_importe}
															/>
														</div>
													</td>
												</tr>
											)
										) : null}
									</React.Fragment>
								)
							})}
						</tbody>
					</table>
				</div>

				<BudgetModalEddit
					presupuesto={this.state.presupuesto}
					reload={this.recargar.bind(this)}
					closeModal={() => this.setState({ presupuesto: {} })}
				/>
				<ModalBorrar id={this.state.id_presupuesto} delete={this.delete.bind(this)} />

			</div>
		);
	}

	handleClose() {
		this.props.handleClose();
	}

	openModalDelete(id) {
		this.setState({ id_presupuesto: id });
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleSubmit(event) {
		this.setState({ loadingSave: true, formMessage: '' });
		event.preventDefault();
		const form = event.target;
		let data = {
			id_detalle: this.state.concepto.id_detalle,
			importe: parseFloat(form.importe.value)
		}
		const response = await request.post("/presupuestos/eddit/amount/concept", data)
		if (response.edited) {
			this.setState({ formMessage: 'PRESUPUESTO ACTUALIZADO' });
			this.props.getPresupuestos();
		}
		this.handleClose();
		this.setState({ loadingSave: false, formMessage: response.message });
	}

	// traer todos los conceptos por fraccionamiento
	async getConcepts() {

		this.setState({ conceptos: [], idconcepto: 0 })

		let data = {
			IdFraccionamiento: localStorage.getItem('frac')
		}
		let response = await request.post("/presupuestos/get/all/real/concepts", data)

		if (response && !response.error) {
			if (response.concepts && !response.empty) {
				this.setState({
					conceptos: response.concepts
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}

	async handleDelete(id, close) {
		let data = {
			id_presupuesto: id
		}
		const response = await request.post("/presupuestos/presupuesto/delete", data)
		if (response.deleted) {
			if (close) {
				close();
				this.props.getPresupuestos();
				cogoToast.success('Presupuesto eliminado', {
					position: 'bottom-right',
				});
			}
		} else {
			cogoToast.error('No se pudo eliminar el presupuesto', {
				position: 'bottom-right',
			});
		}
		this.handleClose();
		this.setState({ formMessage: response.message });
	}
}

export default BudgetTableTeoric;