/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de todos los propietarios
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import OwnersTable from "../components/propietarios/Owners-Table";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from "../components/controls/controls";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Permissions from "../middlewares/Permissions";
import StringHelper from "../helpers/StringHelper";
import cogoToast from "cogo-toast";
import sortableData from "../helpers/sortableDataTable";

const request = new Request();

class Owners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			houses: [],
			error: false,
			reload: false,
			fraccionamientoNombre: "",
			propietarios: [],
			idSubfrac: 0,
			searchHouse: false,
			nombre: "",
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: 0,
			pId: 0,
			pNumId: "",
			pVigencia: "",
			pFechaCompra: "",
			pFechaVenta: "",
			pIdPersona: 0,
			pviv: "",
			pidViv: 0,
			page: 1,
			filterData: [],
			filter: "",
			keys: [],
			email: "",
			comentarios: "",
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			subf: localStorage.getItem("subfrac") || 0,
			fraccionamiento: localStorage.getItem("frac") || 0,
			subfraccionamientos: [],
			city: "",
			residente: false,
			es_residente: false,
			subname: "Subfraccionamiento",
			contact: false,
			viviendasAux: [],
			escritura: false,
			lectura: false,
			viviendasFiltradas: [],
			filter2: "",
			viviendas_json: [],
			persona_existente: true,
			phoneValidated: false,
			viviendas_json_db: [],
			logo: require("../img/logo-AppMosphera-r.png"),
			pageLength: 20,
			direction: {
				nombre: "desc",
				numero_registro: "desc",
			},
			filterColumn: {
				col: "",
				filt: "",
			},
			submenu: '',
			folio: ""
		};
		this.validarPermisos();
	};

	async validarPermisos() {

		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();

		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
	};

	componentDidUpdate(prevProps, prevState) {

		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getPropietarioFraccionamiento();
		};

		if (this.state.pIdPersona !== prevState.pIdPersona || this.state.pidViv !== prevState.pidViv) {
			this.getFechas();
		};
	};

	reload() {
		this.getSubFraccionamiento();
	};

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	};

	reloadOwners() {
		this.getPropietarioFraccionamiento();
	};

	change() {
		this.getPropietarioFraccionamiento();
	};

	propietario = (data) => {

		this.setState({
			pNombre: data.nombre,
			pDireccion: data.direccion,
			pMunicipio: data.municipio,
			pEstado: data.estado,
			pCp: data.cp,
			pTelefono: data.telefono,
			pPais: data.pais,
			pGenero: data.genero,
			pId: parseInt(data.tipo_identificacion),
			pNumId: data.num_identificacion,
			pVigencia: data.vigencia_identificacion,
			pIdPersona: data.id_persona,
			search: false,
			comentarios: data.comentarios
		});
	};

	vivienda = (data) => {
		this.setState({
			pviv: data.numero_registro,
			pidViv: data.id_vivienda,
			searchHouse: false,
		});
	};

	subChange(sub) {
		this.setState({ subfraccionamiento: sub, subf: sub });
		this.getViviendas();
	};

	nuevoPropietario() {
		this.setState({ showModalOwners: true });
	};

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	async paginatorChange(length) {

		await this.setState({ pageLength: length });

		this.setPage(1);
	};

	filter(propietarios) {

		propietarios = propietarios.filter((pro) => {

			if (pro.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (pro.telefono.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (pro.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (pro.email.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			};

		});

		return propietarios;
	};

	sortableTable(key) {

		let sd = new sortableData();

		const { items, direction } = sd.sortBy(key, this.state.propietarios, this.state.direction[key]);

		this.setState({
			subfraccionamientos: items,
			direction: {
				[key]: direction,
			},
			filterColumn: {
				col: key,
				filt: direction,
			},
		});
	};

	render() {

		let propietarios = [];

		if (this.state.propietarios) {
			propietarios = this.state.propietarios;
			propietarios = this.filter(propietarios);
		};

		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header reload={this.reload.bind(this)} change={this.change.bind(this)} sidebar={true} active={"propietarios"} parent={"admin"} panel={"panel1"} nav={"catalogos"} />

				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ? (
									<React.Fragment>
										<Controls
											title={this.state.submenu}
											nuevo={this.openModal.bind(this, "newOwner")}
											dataSet={propietarios}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={this.state.escritura}
										/>

										{this.state.loadingPropietarios ? (
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										) : (
											<OwnersTable
												reload={this.getPropietarioFraccionamiento.bind(this)}
												data={propietarios}
												page={this.state.page}
												reloadOwners={this.reloadOwners.bind(this)}
												escritura={this.state.escritura}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												subLabel={this.state.subLabel}
											/>
										)}
										<div className="white-space-16" />
										<Paginador
											pages={Math.ceil(propietarios.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>
									</React.Fragment>
								) : null}
							</div>
						</div>

						<DinamicModal
							idModal={"newOwner"}
							sizeModalContainer={"super"}
							title={"Añadir propietario"}
							success={this.handledSubmit.bind(this)}
							showBtnSuccess={true}
							btnTextSuccess={"GUARDAR"}
							showBtnCancel={true}
							btnTextCancel={"CANCELAR"}
							closeModal={this.resetValue.bind(this)}
							loadingSave={this.state.loading}
							message={this.state.message}
							disabledBtnSuccess={!this.state.phoneValidated}
						>
							{/* {this.renderNewOwner()} */}
							{this.formContactosViviendas()}
						</DinamicModal>
					</div>
				</div>
			</div>
		);
	};

	componentDidMount() {
		this.getSubmenu();
		this.getPropietarioFraccionamiento();
		this.getPersonas();
		this.getSubFraccionamientos();
		this.getViviendas();
	};

	async getSubmenu() {

		const submenu = await this.props.context.getSubmenuName();

		if (submenu) {
			this.setState({ submenu });
		};

		const subLabel = window.localStorage.getItem('subLabel');

		if (subLabel) {
			this.setState({ subLabel });
		};

	};

	async openModal(idModal) {

		this.resetValue();

		await this.clearCheckboxs();
		await this.getViviendas();
		await this.setState({ viviendasFiltradas: this.state.viviendas });
		await this.buscarViviendas();

		this.setState({ message: null });

		const modal = document.getElementById(idModal);

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		};

		let foc = document.getElementById("unidad");

		if (foc) {
			foc.focus();
		};

	};

	async resetValue() {

		await this.setState({
			pidViv: "",
			pNombre: "",
			pTelefono: "",
			pGenero: "",
			email: "",
			pId: "",
			pNumId: "",
			pDireccion: "",
			pMunicipio: "",
			pCp: "",
			pEstado: "",
			pPais: "",
			pFechaCompra: "",
			pFechaVenta: "",
			contact: false,
			residente: false,
			city: "",
			viviendasJSON: [],
			comentarios: ""
		});
	};

	async changeUnidad(event) {

		let viv = event.target.value;

		let vivienda = [];

		this.state.viviendas.find((v) => {

			if (v.id_vivienda == viv) {
				vivienda = v;
			};
		});

		await this.setState({ pidViv: viv, pDireccion: vivienda.direccion });

	};

	async filtrarViviendas() {

		let viviendas = this.state.viviendas;
		let vivs = [];

		if (this.state.subf == 0) {

			if (this.state.subfraccionamientos.length !== 0) {
				await this.setState({ subf: this.state.subfraccionamientos[0].id_subfraccionamiento });
			} else {
				return [];
			};
		};

		viviendas.map((viv) => {
			if (viv.id_subfraccionamiento == this.state.subf) {
				vivs.push(viv);
			};
		});

		await this.setState({ viviendasFiltradas: vivs });
	};

	async buscarViviendas(event) {

		if (event) await this.setState({ filter2: event.target.value });

		await this.filtrarViviendas();

		if (this.state.filter2 !== "") {

			let sh = new StringHelper();

			// todas las viviendas
			let viviendas = this.state.viviendasFiltradas;

			let viviendasBuscadas = viviendas.map((viv) => {

				let vivienda = sh.eliminarCaracteresRaros(viv.numero_registro, true);
				let datosBusqueda = sh.eliminarCaracteresRaros(this.state.filter2);

				if (sh.multiMatch(vivienda, datosBusqueda)) {
					return viv;
				};

			});

			// quito datos undefined
			viviendasBuscadas = viviendasBuscadas.filter((item) => item !== undefined);

			await this.setState({ viviendasFiltradas: viviendasBuscadas });

			this.checkViviendas();
		} else {
			this.checkViviendas();
		};

	};

	checkViviendas() {

		this.clearCheckboxs();

		let checks = document.getElementsByName("viviendas-check");

		checks.forEach((check) => {

			try {

				let id = check.getAttribute("id").split("-")[1];

				// Buscar id en las viviendas de la persona y marcarlo
				if (this.state.viviendas_json_db) {

					this.state.viviendas_json_db.forEach((viv) => {

						if (parseInt(viv.id_vivienda) == parseInt(id)) {
							check.checked = true;
						};

					});
				};

			} catch (error) {
				//console.log(error);
			};

		});
	};

	async changeSubFrac() {

		//let subf 		=  event.target.value;
		let vivs = [];
		//await this.setState({subf:	subf});
		await this.buscarViviendas();
		await this.clearCheckboxs();
		this.checkViviendas();
	};

	async clearCheckboxs() {

		let checks = document.getElementsByName("viviendas-check");

		checks.forEach((check) => {
			check.checked = false;
		});
	};

	async filtarViviendas() {

		let viviendas = this.state.viviendas;
		let vivs = [];

		if (this.state.subf == 0) {

			if (this.state.subfraccionamientos.length !== 0) {
				await this.setState({ subf: this.state.subfraccionamientos[0].id_subfraccionamiento });
			} else {
				return [];
			};
		};

		viviendas.map((viv) => {

			if (viv.id_subfraccionamiento == this.state.subf) {
				vivs.push(viv);
			};
		});

		return vivs;
	};

	async validarTelefono(telefono) {

		this.setState({ loadingVlidatePhone: true, phoneValidated: false });

		if (isValidPhoneNumber(telefono)) {

			const data = {
				telefono,
				id_fraccionamiento: this.state.fraccionamiento,
			};

			const res = await request.post("/personas/getbyphone", data);

			const { persona } = res;

			if (persona) {

				this.setState({
					pNombre: persona.nombre || "",
					email: persona.email || "",
					pGenero: persona.genero || 0,
					pDireccion: persona.direccion || "",
					pCp: persona.cp || "",
					city: persona.ciudad || "",
					pMunicipio: persona.municipio || "",
					pEstado: persona.estado || "",
					pPais: persona.pais || "",
					pId: persona.tipo_identificacion || 0,
					pNumId: persona.num_identificacion || "",
					pIdPersona: persona.id_persona || 0,
					persona_existente: persona.encontrado,
					comentarios: persona.comentarios || "",
					es_residente: persona.es_residente || false
				});

			} else {

				this.setState({
					pNombre: "",
					email: "",
					pGenero: 0,
					pDireccion: "",
					pCp: "",
					city: "",
					pMunicipio: "",
					pEstado: "",
					pPais: "",
					pId: 0,
					pNumId: "",
					pIdPersona: 0,
					persona_existente: false,
					comentarios: "",
					es_residente: false
				});
			};

			this.setState({ phoneValidated: true });

		} else {
			cogoToast.warn("Número de teléfono inválido.", {
				position: "bottom-right",
			});
		};

		this.setState({ loadingVlidatePhone: false });
	};

	renderNewOwner() {

		let list_personas = [];
		let viviendas = [];
		let subfraccionamientos = [];

		if (Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map((persona) => {
				return { label: persona.nombre, value: persona.id_persona };
			});
		};

		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		};

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>{this.state.subLabel}:</b>
							</p>
						</div>
						<div className="row full">
							{subfraccionamientos.length > 1 ? (
								<select
									className="input input-modals"
									name="subfraccionamiento"
									required
									value={this.state.subf}
									onChange={(event) => this.setState({ subf: event.target.value })}
									disabled={this.state.subfraccionamiento == 0 ? false : true}
								>
									{/*<option value="0">SELECCIONAR SUBFRACCIONAMIENTO</option>*/}
									{subfraccionamientos.map((viv) => (
										<option key={viv.id_subfraccionamiento} value={viv.id_subfraccionamiento}>
											{viv.nombre}
										</option>
									))}
								</select>
							) : (
								<select className="input input-modals" name="subfraccionamiento" required value={this.state.subf} disabled>
									{subfraccionamientos.map((viv) => (
										<option key={viv.id_subfraccionamiento} value={viv.id_subfraccionamiento}>
											{viv.nombre}
										</option>
									))}
								</select>
							)}
							{subfraccionamientos.length > 1 && this.state.subfraccionamiento == 0 && (
								<button type="button" className="btn-primary color-white weight-bold space btn-change" onClick={this.changeSubFrac.bind(this)}>
									Cambiar
								</button>
							)}
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Teléfono: </b><b style={{ color: 'red' }}> * </b>
							</p>
						</div>
						<div className="row full">
							<PhoneInput
								className={"input-modals"}
								name="phoneNumber"
								defaultCountry="MX"
								maxLength="18"
								value={this.state.pTelefono}
								onChange={(value) => this.setState({ pTelefono: value, telefonoError: undefined, phoneValidated: false })}
								error={this.state.telefonoError}
								autoComplete="off"
								displayInitialValueAsLocalNumber
							/>
							<button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white weight-bold" onClick={this.validarTelefono.bind(this, this.state.pTelefono)}>
								{this.state.loadingVlidatePhone ? <i className="fas fa-spinner fa-spin"></i> : "Validar"}
							</button>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Nombre completo: </b><b style={{ color: 'red' }}> * </b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="fullName"
								id="fullName"
								value={this.state.pNombre}
								onChange={(event) => this.setState({ pNombre: event.target.value })}
								disabled={this.state.persona_existente}
								required
								autoComplete="off"
							/>
						</div>
						{/* <div className="column label-medium space">
						<h5> <b>Residente: </b> </h5>
					</div>
					<div className="column space">
						<input 
							type		=	"checkbox" 
							name		=	"residente" 
							id			=	"residente" 
							checked		=	{this.state.residente ? (true) : (false)} 
							onChange	=	{event => this.setState({ residente: event.target.checked })} 
						/>
					</div> */}
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p className="justify-start">
								<b> Género: </b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="sex" value={this.state.pGenero} onChange={(event) => this.setState({ pGenero: event.target.value })} required>
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<div className="justify-start">
								<p>
									<b>Correo:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="email"
								name="EmailEdit"
								value={this.state.email}
								onChange={(event) => this.setState({ email: event.target.value })}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b> Identificación: </b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" onChange={(event) => this.setState({ pId: event.target.value })} name="id" id="idEddit" value={this.state.pId}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{ paddingLeft: "20px" }}>
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="numIdEddit"
								value={this.state.pNumId}
								onChange={(event) => this.setState({ pNumId: event.target.value })}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="address"
								value={this.state.pDireccion}
								onChange={(event) => this.setState({ pDireccion: event.target.value })}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="municipality"
								value={this.state.pMunicipio}
								onChange={(event) => this.setState({ pMunicipio: event.target.value })}
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" value={this.state.city} onChange={(event) => this.setState({ city: event.target.value })} id="city" autoComplete="off" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" value={this.state.pCp} onChange={(event) => this.setState({ pCp: event.target.value })} name="zip" /* required */ autoComplete="off" />
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="state"
								value={this.state.pEstado}
								onChange={(event) => this.setState({ pEstado: event.target.value })}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />

					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" value={this.state.pPais} onChange={(event) => this.setState({ pPais: event.target.value })} name="country" autoComplete="off" /* required */ />
						</div>
						<div className="column label-duo-normal">

						</div>
						<div className="column full">

						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>Fecha de compra:</b><b style={{ color: 'red' }}> * </b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.pFechaCompra} onChange={(event) => this.setState({ pFechaCompra: event.target.value })} required className="input input-modals" id="fcompra" />
						</div>
						<div className="column label-duo-normal">
							<p>
								<b>Fecha de venta:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.pFechaVenta} onChange={(event) => this.setState({ pFechaVenta: event.target.value })} className="input input-modals" id="fventa" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-normal">
							<p>
								<b>Comentarios:</b>
							</p>
						</div>
						<div className="column full input-modals">
							<textarea
								name="comentarios"
								id="comentarios_inquilinos"
								value={this.state.comentarios}
								cols="30"
								rows="3"
								maxLength="500"
								className='textarea font full'
								onChange={(event) => this.setState({ comentarios: event.target.value })} >
							</textarea>
						</div>
						<div className="column full">

						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="residente" id="residente" checked={this.state.residente ? true : false} onChange={(event) => this.setState({ residente: event.target.checked })} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Añadir propietario como residente </b>{" "}
							</h5>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b style={{ color: 'red' }}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	async clearFilter() {
		await this.setState({ filter2: "" });
		await this.filtrarViviendas();

		this.checkViviendas();
	};

	formContactosViviendas() {
		return (
			<div className="row ">
				<div className="itinerary">{this.renderNewOwner()}</div>
				<div className="schedule">
					<div className="title">
						<h4 className="titulo">Viviendas</h4>
						<div className="input-search">
							<input className="input input-filter" type="text" value={this.state.filter2} onChange={(event) => this.buscarViviendas(event)}></input>
							<i className="fas fa-search font-small"></i>
							{/*<i className="fas fa-times clear" onClick={() => {this.clearFilter()}}></i>*/}
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="columns-ininerary">
						{/* this.state.subf */}
						{this.state.subf != 0 && this.state.viviendasFiltradas.length !== 0 ? (
							this.state.viviendasFiltradas.map((viv) => (
								<div className="item" htmlFor={`check-${viv.id_vivienda}`}>
									<label htmlFor={`check-${viv.id_vivienda}`}>{viv.numero_registro}</label>
									<div className="check">
										<label className="container-check">
											<input
												type="checkbox"
												// checked	=	{false}
												name="viviendas-check"
												id={`check-${viv.id_vivienda}`}
												onClick={this.addCheck.bind(this, viv.id_vivienda)}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
							))
						) : (
							<div className="justify-center align-center">
								<h4>No hay unidades para mostrar.</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	getChecksChecked(checks) {
		let checksChecked = [];
		if (checks.length <= 0) {
			return checksChecked;
		}
		checks.forEach((check) => {
			let subfrac = parseInt(this.state.subf);
			if (!check.subf || parseInt(check.subf) === subfrac) {
				try {
					// {id_vivienda: 80, subf: 25}
					checksChecked.push(check.id_vivienda);
				} catch (err) {
					//console.log(err);
				}
			}
		});
		return checksChecked;
	};

	async addCheck(id) {
		let jsons_viv = this.state.viviendas_json_db;
		let check = document.getElementById(`check-${id}`);

		let address = this.state.viviendasFiltradas.filter((viv) => viv.id_vivienda == id);
		if (address.length > 0) {
			if (this.state.pDireccion == "") {
				this.setState({ pDireccion: address[0].direccion });
			}
		}

		if (check.checked) {
			if (!jsons_viv) jsons_viv = [];
			jsons_viv.push({ id_vivienda: id, subf: parseInt(this.state.subf) });
		} else {
			for (var i = 0; i < jsons_viv.length; i++) {
				if (parseInt(jsons_viv[i].id_vivienda) === parseInt(id)) {
					jsons_viv.splice(i, 1);
					break;
				}
			}
		}
	};

	/**
	 * Filtrar viviendas seleccionadas cuando por defecto se
	 * traen viviendas que ya tiene una persona de otro
	 * subfraccioamiento
	 */
	filtrarViviendasSeleccionadas(jsonNoFiltrado) {
		let id_sub = parseInt(this.state.subf);
		let viviendasPorFraccionamiento = [];

		this.state.viviendas.forEach((viv) => {
			if (parseInt(viv.id_subfraccionamiento) == id_sub) {
				viviendasPorFraccionamiento.push(viv.id_vivienda);
			}
		});

		let viviendasFiltradas = [];

		jsonNoFiltrado.forEach((viv) => {
			if (viviendasPorFraccionamiento.includes(viv)) {
				viviendasFiltradas.push(viv);
			}
		});

		return viviendasFiltradas;
	};

	//traer Propietarios del fraccionaiento
	async getPropietarioFraccionamiento() {
		this.setState({ loadingPropietarios: true });
		let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem("frac");

		let data = { IdSub, idFrac };

		const response = await request.post("/fraccionamientos/get/propietarios", data);

		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				const propietarios = response.propietario;
				this.setState({
					propietarios,
					filterData: propietarios,
				});
			} else {
				this.setState({
					propietarios: [],
					empty: true,
					message: response.message,
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
			});
		}
		this.setState({ loadingPropietarios: false });
	};

	async handledSubmit(event) {
		event.preventDefault();
		this.setState({ loading: true, message: "" });

		let checks = this.state.viviendas_json_db;
		let checksJson = this.getChecksChecked(checks);

		let jsonSTR = this.filtrarViviendasSeleccionadas(checksJson);

		/**Validar Vivienda */
		if (!(jsonSTR.length > 0)) {
			cogoToast.warn("Debes seleccionar una vivienda.", {
				position: "bottom-right",
			});
			await this.setState({ loading: false });
			return;
		}

		/**Validar Vivienda */
		// if (!(this.state.pidViv > 0)) {
		// 	this.setState({loading: false});
		//     alert("Para continuar debes seleccionar una vivienda")
		//     return;
		// }

		if (!(this.state.subf > 0)) {
			this.setState({ loading: false });
			cogoToast.warn("Debes seleccionar un subfraccionamiento.", {
				position: "bottom-right",
			});
			return;
		}
		/**Validar Telefono */
		if (!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({ telefonoError: "Número inválido", loading: false });
			return;
		} else {
			this.setState({ telefonoError: undefined });
		}

		let resident = document.getElementById("residente").checked ? 1 : 0;
		let contact = 0; //document.getElementById('contact').checked ? 1:0;
		let data = {
			// IdViv: 				this.state.pidViv,
			viviendasJSON: JSON.stringify(jsonSTR),
			Nombre: this.state.pNombre,
			Direccion: this.state.pDireccion,
			Municipio: this.state.pMunicipio,
			Estado: this.state.pEstado,
			Cp: this.state.pCp,
			Telefono: this.state.pTelefono,
			Pais: this.state.pPais,
			Genero: parseInt(this.state.pGenero),
			TipoId: parseInt(this.state.pId),
			NumId: this.state.pNumId,
			Vigencia: this.state.pVigencia,
			FechaCompra: this.state.pFechaCompra,
			FechaVenta: this.state.pFechaVenta,
			idPersona: this.state.pIdPersona,
			email: this.state.email,
			idFraccionamiento: parseInt(localStorage.getItem("frac") || 0),
			city: this.state.city,
			residente: resident,
			contacto: contact,
			comentarios: this.state.comentarios,
			id_usuario_add: localStorage.getItem("user_id")
		};
		const response = await request.post("/propietarios/propietario/create", data);

		if (response.created) {
			this.getPropietarioFraccionamiento();
			this.getViviendas();
			const modal = document.getElementById("newOwner");
			if (modal) {
				modal.classList.add("hide-dinamic-modal");
				modal.classList.remove("show-dinamic-modal");
			}
			await this.setState({ loading: false /*, message: 'PROPIETARIO AÑADIDO' */ });
			cogoToast.success("Propietario añadido.", {
				position: "bottom-right",
			});
		} else {
			this.setState({ loading: false /*,message: "NO SE PUDO AÑADIR PROPIETARIO"*/ });
			cogoToast.error("No se pudo añadir propietario.", {
				position: "bottom-right",
			});
		}
		this.setState({ loading: false });
	};

	handleOpen() {
		this.setState({ showModalOwners: true });
	};

	handleClose() {
		this.setState({
			showModalOwners: false,
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: "",
			pId: 0,
			pNumId: "",
			pVigencia: "",
			pFechaCompra: "",
			pIdPersona: 0,
			edditProp: false,
			pviv: "",
			email: "",
			comentarios: ""
		});
	};

	async getPersonas() {
		// alert("persona obtenida");
		const response = await request.post("/personas/getbyfraccionamiento", { id_fraccionamiento: localStorage.getItem("frac") });
		if (Array.isArray(response.personas)) {
			this.setState({ personas: response.personas });
		} else {
			this.setState({ personas: [] });
		}
	};

	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem("frac");
		if (!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento, filter: 1 };
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento });
		} else {
			this.setState({ subfraccionamientos: [] });
		}
	};

	async getViviendas() {
		let data = {
			id_fraccionamiento: localStorage.getItem("frac"),
			id_subfraccionamiento: localStorage.getItem("subfrac"),
		};

		const response = await request.post("/viviendas/getVivienda/sinpropietario", data);
		//console.log(response);
		if (Array.isArray(response.viviendas)) {
			await this.setState({ viviendas: response.viviendas, viviendasAux: response.viviendas, subname: response.viviendas[0].nombre_subfrac });
		} else {
			this.setState({ viviendas: [] });
		}
	};

	async getFechas() {
		const res = await request.post("/propietarios/get/fechas", { IdPersona: this.state.pIdPersona, IdViv: this.state.pidViv });
		if (res.dates) {
			this.setState({
				pFechaCompra: res.dates.fecha_compra || "",
				pFechaVenta: res.dates.fecha_venta || "",
			});
		}
	};

};

export default Consumer(Owners);
