import React, { Component } from 'react';
import ReactModal from 'react-modal'
import moment from 'moment'
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
import Request from "../../core/httpClient";
const request = new Request();

class ModalEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            factura:                this.props.concepto.num_factura,
            fecha:                  moment(this.props.concepto.fecha).format('YYYY-MM-DD'),
            concepto:               this.props.concepto.id_concepto,
            conceptos:              this.props.conceptos,
            showModal:              false,
            importe:                this.props.concepto.importe,
            idDetalle:              this.props.concepto.id_factura,
            subfraccionamientos:    this.props.subfraccionamientos
        }
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.concepto !== prevProps.concepto) {
			this.setState({
				factura:                this.props.concepto.num_factura,
				fecha:                  moment(this.props.concepto.fecha).format('YYYY-MM-DD'),
				concepto:               this.props.concepto.id_concepto,
				conceptos:              this.props.conceptos,
				importe:                this.props.concepto.importe,
                idDetalle:              this.props.concepto.id_factura,
                sub_fraccionamiento:    this.props.concepto.id_subfraccionamiento
			});
        }
        
	}


    render() {
        let facturas = []

        if (this.props.conceptos) {
            facturas = this.props.conceptos
        }

        return (
            <div>
				 <DinamicModal 
					idModal                     =   {'editExpense'}
					sizeModalContainer          =   {'small'}
					title                       =   {'Editar concepto'} 
					success                     =   {this.handleSubmit.bind(this)} 
					showBtnSuccess              =   {this.props.escritura}
					btnTextSuccess              =   {"ACTUALIZAR"}
					showBtnCancel               =   {true}
					btnTextCancel               =   {"CANCELAR"}
					loadingSave                 =   {this.state.loadingSave}
					message                     =   {this.state.message}
				>
				{this.renderEditExpenses()}
				</DinamicModal>
            </div>
        );
	}
	
	renderEditExpenses() {
        let subfraccionamientos = [];
        if(Array.isArray(this.props.subfraccionamientos)) {
			subfraccionamientos = this.props.subfraccionamientos;
		}
		return(
		<div className="full row align-center">
		<div className="column full">
            <div className="input-form-content row full align-center">
                <div className="column label-medium">
                    <p>
                        <b>Subfraccionamiento:</b>
                    </p>
                </div>
                <div className="column full">
                    <select className="input input-modals" name="subfraccionamiento" value={this.state.sub_fraccionamiento} onChange={(event) => this.setState({sub_fraccionamiento: event.target.value})} disabled>
                        {subfraccionamientos.map((sub, index) => <option key={index} value={sub.id_subfraccionamiento}>{sub.nombre}</option>)}
                    </select>
                </div>
            </div>
            <div className="white-space-16" />
			<div className="input-form-content row full align-center">
				<div className="column label-medium">
					<p>
						<b>Concepto:</b>
					</p>
				</div>
				<div className="column full">
                <select 
                    name        =   "concepto" 
                    id          =   "concep" 
                    className   =   "input input-modals"
                    value       =   {this.state.concepto} 
                    onChange    =   {event => this.setState({ concepto: event.target.value })} 
                   
                >
                    {this.state.conceptos.map((concepto, key) => (
                        <option value={concepto.id_concepto} key={key}>{concepto.descripcion}</option>
                    ))}
                </select>
				</div>
			</div>
			<div className="white-space-16" />
			<div className="input-form-content row full align-center">
				<div className="column label-medium">
					<p>
						<b>N° Factura:</b>
					</p>
				</div>
				<div className="column full">
                    <input 
                        type        =   "text" 
                        name        =   "factura"
                        id          =   "fact"
                        className   =   "input input-modals" 
                        autoComplete = 'off'
                        onChange    =   {event => this.setState({ factura: event.target.value })} 
                        value       =   {this.state.factura} 
                        required 
                        minLength   =   '2'
                        
					/>
				</div>
			</div>
			<div className="white-space-16" />
			<div className="input-form-content row full align-center">
				<div className="column label-medium">
					<p>
						<b>Fecha:</b>
					</p>
				</div>
				<div className="column full">
					<input type="date" name="fecha"
						className="input input-modals" value={this.state.fecha} onChange={event => this.setState({ fecha: event.target.value })} placeholder="Fecha" required minLength='2'
						maxLength='4'
					/>
				</div>
			</div>
			<div className="white-space-16" />
			<div className="input-form-content row full align-center">
				<div className="column label-medium">
					<p>
						<b>Importe:</b>
					</p>
				</div>
				<div className="column full">
					<input type="number" name="importe"
                        className="input input-modals" 
                        value={this.state.importe} 
                        onChange={event => this.setState({ importe: event.target.value })} 
                        autoComplete = 'off' 
                        required minLength='2'
						maxLength='4'
					/>
				</div>
			</div>
			
		</div>
	</div>
		)
	}


    handleOpen() {
        this.setState({ showModal: !this.state.showModal })
        this.getConcepts()
    }
    // traer todos los conceptos por fraccionamiento
    async getConcepts() {

        this.setState({ conceptos: [], idconcepto: 0 })
        let observable = JSON.parse(localStorage.getItem('object'))

        if (!observable) {
            return
        }
        let data = {

            IdFraccionamiento: observable.if
        }
        let response = await request.post("/presupuestos/get/all/real/concepts", data)

        if (response && !response.error) {
            if (response.concepts && !response.empty) {
                this.setState({
                    conceptos: response.concepts
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }

    async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
        let data = {
            idDetalle:      this.state.idDetalle,
            factura:        this.state.factura,
            fecha:          this.state.fecha,
            idconcepto:     this.state.concepto,
            importe:        this.state.importe
        }
        const response = await request.post("/presupuestos/presupuesto/eddit/factura", data)
        
        if (response.edited) {
            this.setState({ showModal: false });
            this.props.reload();
            cogoToast.success('Datos de factura actualizados.', {
                position: 'bottom-right',
            });
        } else {
            this.setState({
                empty: true,
                //message: "NO SE PUDO EDITAR FACTURA"
            });
            cogoToast.error('No se pudieron actualizar datos de factura.', {
                position: 'bottom-right',
            });
        }
    
		this.setState({loadingSave: false});
    }
}

export default ModalEddit;