import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import ReactModal from "react-modal";
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
const request = new Request();


class NotificationTypeEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: 		false,
            Descripcion: 	this.props.tipo.Descripcion,
			id: 			this.props.tipo.Id_tipo,
			loadingSave: 	false

        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.tipo !== prevProps.tipo) {
			this.setState({
				Descripcion: this.props.tipo.Descripcion,
				id: this.props.tipo.Id_tipo
			});

			
		}
	}

    render() {

        return (
            <div>
				<DinamicModal 
					idModal				={'editNotifications'}
					sizeModalContainer	={'small'}
					title				={'Editar tipo de notificación'} 
					success				={this.handleSubmit.bind(this)} 
					showBtnSuccess		={this.props.escritura}
					btnTextSuccess		={"ACTUALIZAR"}
					showBtnCancel		={true}
					btnTextCancel		={"CANCELAR"}
					loadingSave			={this.state.loadingSave}
					message				={this.state.message}
				>
				{this.renderEditNotification()}
				</DinamicModal>
		 	 </div>
        );
	}
	
	renderEditNotification() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-big align-start">
							<p><b>Tipo de notificación:	</b></p>
						</div>
						<div className="column full">
							<input 
								type		=	"text" 
								name		=	"conceptName" 
								id			=	"cname"
								value		=	{this.state.Descripcion}
								onChange	=	{event => this.setState({ Descripcion: event.target.value })} 
								className	=	"input input-modals" 
								autoComplete = 'off'
								required	 
								maxLength	=	"64" 
								// ref			=	{ 'input' + this.props.line }
						 		// autoFocus	=	{ this.props.isAutoFocus }
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}


    async  handleSubmit(event) {
        event.preventDefault()
		this.setState({loadingSave: true});
        let data = {

            Descripcion: 	this.state.Descripcion,
            id: 			this.state.id
        }
		const response = await request.post("/notificaciones/update/type", data);
		
            if (response.notification && !response.empty) {
                this.setState({
					showModal: false
                });
				this.props.reload();
				cogoToast.success('Tipo de notificación actualizado.', {
					position: 'bottom-right',
				});
            } else {
                this.setState({
                    empty: true,
					//message: "NO SE PUDO ACTUALIZAR TIPO DE NOTIFICACIÓN"
				});
				cogoToast.error('No se pudo actualizar tipo de notificación.', {
					position: 'bottom-right',
				});
			}
			this.setState({loadingSave: false});
    }
}

export default NotificationTypeEddit;