/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de todos los propietarios
 */

import React, {Component} from "react";
import Request from "../../core/httpClient";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import cogoToast from "cogo-toast";

const request = new Request();

class Owners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: {
				idUsuario: 0,
				idPerfil: 0,
				idFraccionamiento: 0,
			},
			houses: [],
			error: false,
			reload: false,
			fraccionamientoNombre: "",
			propietarios: [],
			idSubfrac: 0,
			searchHouse: false,
			nombre: "",
			pNombre: "",
			pDireccion: "",
			IDireccion: this.props.direccion,
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: 0,
			pId: 0,
			pNumId: "",
			pVigencia: "",
			pFechaCompra: "",
			pFechaVenta: "",
			pIdPersona: 0,
			pviv: "",
			pidViv: 0,
			page: 1,
			filterData: [],
			filter: "",
			keys: [],
			email: "",
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			subfraccionamientos: [],
			fraccionamiento: localStorage.getItem("frac") || 0,
			city: "",
			persona_existente: true,
			phoneValidated: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state !== prevState) {
			this.props.setInfo(this.state);
			this.props.validPhone(this.state.phoneValidated);
		}
		if (this.state.pIdPersona !== prevState.pIdPersona || this.state.pidViv !== prevState.pidViv) {
			this.getFechas();
		}
		if (this.props.showNewModal !== prevProps.showNewModal) {
			this.setState({
				pIdPersona: 0,
				iTelefono: "",
				INombre: "",
				email: "",
				IGenero: 0,
				IDireccion: "",
				ICp: "",
				city: "",
				IMunicipio: "",
				IEstado: "",
				IPais: "",
				IId: 0,
				iNumId: "",
				IIdPersona: 0,
				persona_existente: false,
				pFechaCompra: "",
				pFechaVenta: "",
			});
		}
	}

	reload() {
		this.getSubFraccionamiento();
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({page});
	}

	reloadOwners() {
		this.getPropietarioFraccionamiento();
	}
	change() {
		this.getPropietarioFraccionamiento();
	}

	propietario = (data) => {
		this.setState({
			pNombre: data.nombre,
			pDireccion: data.direccion,
			pMunicipio: data.municipio,
			pEstado: data.estado,
			pCp: data.cp,
			pTelefono: data.telefono,
			pPais: data.pais,
			pGenero: data.genero,
			pId: parseInt(data.tipo_identificacion),
			pNumId: data.num_identificacion,
			pVigencia: "",
			pIdPersona: data.id_persona,
			search: false,
		});
	};

	vivienda = (data) => {
		this.setState({
			pviv: data.numero_registro,
			pidViv: data.id_vivienda,
			searchHouse: false,
		});
	};

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoPropietario() {
		this.setState({showModalOwners: true});
	}

	setFilter(filter) {
		//console.log(filter);
		this.setState({filter});
	}

	componentDidMount() {
		this.getPropietarioFraccionamiento();
		this.getPersonas();
		this.getViviendas();
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	async validarTelefono(telefono) {
		this.setState({loadingVlidatePhone: true, phoneValidated: false});
		if (isValidPhoneNumber(telefono)) {
			const data = {
				telefono,
				id_fraccionamiento: this.state.fraccionamiento,
			};
			const res = await request.post("/personas/getbyphone", data);
			const {persona} = res;
			if (persona) {
				this.setState({
					INombre: persona.nombre || "",
					email: persona.email || "",
					IGenero: persona.genero || 0,
					IDireccion: persona.direccion || "",
					ICp: persona.cp || "",
					city: persona.ciudad || "",
					IMunicipio: persona.municipio || "",
					IEstado: persona.estado || "",
					IPais: persona.pais || "",
					IId: persona.tipo_identificacion || 0,
					iNumId: persona.num_identificacion || "",
					IIdPersona: persona.id_persona || 0,
					persona_existente: persona.encontrado,
				});
			} else {
				this.setState({
					INombre: "",
					email: "",
					IGenero: 0,
					IDireccion: "",
					ICp: "",
					city: "",
					IMunicipio: "",
					IEstado: "",
					IPais: "",
					IId: 0,
					iNumId: "",
					IIdPersona: 0,
					persona_existente: false,
				});
			}
			this.setState({phoneValidated: true});
		} else {
			cogoToast.warn("Número de teléfono inválido.", {
				position: "bottom-right",
			});
		}
		this.setState({loadingVlidatePhone: false});
	}

	render() {
		let list_personas = [];
		let viviendas = [];
		if (Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map((persona) => {
				return {label: persona.nombre, value: persona.id_persona};
			});
		}
		if (Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>{this.props.subLabel}:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" name="sub" value={this.props.subname} autoComplete="off" disabled />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className="input"
								name="vivienda"
								disabled={this.props.editVivienda ? false : true}
								value={this.props.editVivienda ? this.state.IidViv : this.props.idVivienda}
								onChange={(event) => this.setState({IidViv: event.target.value})}
							>
								<option value="0">SELECCIONAR VIVIENDA</option>
								{viviendas.map((viv) => (
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Teléfono: </b>
							</p>
						</div>
						<div className="row full">
							<PhoneInput
								className={"input-modals"}
								name="phoneNumber"
								defaultCountry="MX"
								value={this.state.iTelefono}
								onChange={(value) => this.setState({iTelefono: value, telefonoError: undefined, phoneValidated: false})}
								error={this.state.telefonoError}
								autoComplete="off"
								maxLength="18"
								required
								displayInitialValueAsLocalNumber
							/>
						</div>
						<button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white" onClick={this.validarTelefono.bind(this, this.state.iTelefono)}>
							{this.state.loadingVlidatePhone ? <i className="fas fa-spinner fa-spin"></i> : "Validar"}
						</button>
					</div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Nombre completo: </b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								maxLength="64"
								id="contact"
								type="text"
								name="fullName"
								value={this.state.INombre}
								onChange={(event) => this.setState({INombre: event.target.value})}
								disabled={this.state.persona_existente}
								autoComplete="off"
								required
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p className="justify-start">
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="sex" value={this.state.IGenero} onChange={(event) => this.setState({IGenero: event.target.value})} required>
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<div className="justify-start">
								<p>
									<b>Correo:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="email"
								name="EmailEdit"
								value={this.state.email}
								onChange={(event) => this.setState({email: event.target.value})}
								maxLength="64"
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="id" id="idEddit" value={this.state.iId} onChange={(event) => this.setState({iId: event.target.value})}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="numIdEddit"
								value={this.state.iNumId}
								onChange={(event) => this.setState({iNumId: event.target.value})}
								maxLength="24"
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="address"
								value={this.state.IDireccion}
								onChange={(event) => this.setState({IDireccion: event.target.value})}
								maxLength="150"
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="municipality"
								value={this.state.IMunicipio}
								onChange={(event) => this.setState({IMunicipio: event.target.value})}
								maxLength="80"
								required
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								value={this.state.ICp}
								onChange={(event) => this.setState({ICp: event.target.value})}
								name="zip"
								maxLength="10"
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="state"
								value={this.state.IEstado}
								onChange={(event) => this.setState({IEstado: event.target.value})}
								required
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" value={this.state.IPais} onChange={(event) => this.setState({IPais: event.target.value})} name="country" required autoComplete="off" />
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								id="city"
								name="city"
								value={this.state.city}
								onChange={(event) => this.setState({city: event.target.value})}
								required
								autoComplete="off"
							/>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="row justify-center">{this.state.saveMessage}</div>
				</div>
			</div>
		);
	}

	//traer Propietarios del fraccionaiento
	async getPropietarioFraccionamiento() {
		this.setState({loadingPropietarios: true});
		let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem("frac");

		let data = {IdSub, idFrac};

		const response = await request.post("/fraccionamientos/get/propietarios", data);

		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				this.setState({
					propietarios: response.propietario,
					filterData: response.propietario,
				});
				////console.log(response.propietario);
			} else {
				this.setState({
					propietarios: [],
					empty: true,
					message: response.message,
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
			});
		}
		this.setState({loadingPropietarios: false});
	}

	async handledSubmit(event) {
		event.preventDefault();
		this.setState({loading: true});
		/**Validar Vivienda */
		if (!(this.state.pidViv > 0)) {
			this.setState({loadingSave: false});
			cogoToast.warn("Debes seleccionar una vivienda.", {
				position: "bottom-right",
			});
			return;
		}
		/**Validar Telefono */
		if (!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({telefonoError: "Número inválido", loading: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
		let jsons = [];
		jsons.push([parseInt(this.state.pidViv)]);

		let data = {
			viviendasJSON: JSON.stringify(jsons),
			Nombre: this.state.pNombre,
			Direccion: this.state.pDireccion,
			Municipio: this.state.pMunicipio,
			Estado: this.state.pEstado,
			Cp: this.state.pCp,
			Telefono: this.state.pTelefono,
			Pais: this.state.pPais,
			Genero: parseInt(this.state.pGenero),
			TipoId: parseInt(this.state.pId),
			NumId: this.state.pNumId,
			Vigencia: this.state.pVigencia,
			FechaCompra: this.state.pFechaCompra,
			FechaVenta: this.state.pFechaVenta,
			idPersona: this.state.pIdPersona,
			email: this.state.email,
			idFraccionamiento: localStorage.getItem("frac") || "0",
			subf: parseInt(this.state.subf),
		};

		const response = await request.post("/propietarios/propietario/create", data);

		this.setState({showModalOwners: false});
		if (response && !response.error) {
			if (response.created && !response.empty) {
				this.setState({
					reload: true,
					loading: false,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loading: false,
				});
				cogoToast.warn("El número de identificación ya está registrado.", {
					position: "bottom-right",
				});
				return;
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loading: false,
			});
		}
		this.getPropietarioFraccionamiento();
	}

	handleOpen() {
		this.setState({showModalOwners: true});
	}

	handleClose() {
		this.setState({
			showModalOwners: false,
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: "",
			pId: 0,
			pNumId: "",
			pVigencia: "",
			pFechaCompra: "",
			pIdPersona: 0,
			edditProp: false,
			pviv: "",
			email: "",
		});
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem("frac")});
		if (Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem("frac")});
		if (Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}

	async getFechas() {
		const res = await request.post("/propietarios/get/fechas", {IdPersona: this.state.pIdPersona, IdViv: this.state.pidViv});
		if (res.dates) {
			//console.log(res.dates);
			this.setState({
				pFechaCompra: res.dates.fecha_compra || "",
				pFechaVenta: res.dates.fecha_venta || "",
			});
		}
	}
}

export default Owners;
