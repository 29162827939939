/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de todos los propietarios
 */

import React, {Component} from "react";
import Request from "../../core/httpClient";
import PhoneInput, {isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";
import cogoToast from "cogo-toast";

const request = new Request();

class Owners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: {
				idUsuario: 0,
				idPerfil: 0,
				idFraccionamiento: 0,
			},
			houses: [],
			error: false,
			reload: false,
			fraccionamientoNombre: "",
			propietarios: [],
			idSubfrac: 0,
			searchHouse: false,
			nombre: "",
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: 0,
			pId: 0,
			pNumId: "",
			pVigencia: "",
			pFechaCompra: "",
			pFechaVenta: "",
			pIdPersona: 0,
			pviv: "",
			pidViv: 0,
			page: 1,
			filterData: [],
			filter: "",
			keys: [],
			email: "",
			city: "",
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			subfraccionamientos: [],
			fraccionamiento: localStorage.getItem("frac") || 0,
			pDireccion: this.props.direccion,
			residente: false,
			contacto: false,
			persona_existente: true,
			phoneValidated: false,
			es_residente:false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state !== prevState) {
			this.props.setInfo(this.state);
			this.props.validPhone(this.state.phoneValidated);
		}
		if (this.state.pIdPersona !== prevState.pIdPersona || this.state.pidViv !== prevState.pidViv) {
			this.getFechas();
		}
		if (this.props.showNewModal !== prevProps.showNewModal) {
			this.setState({
				showModalOwners: false,
				pNombre: "",
				pDireccion: "",
				pMunicipio: "",
				pEstado: "",
				pCp: "",
				pTelefono: "",
				pPais: "",
				pGenero: "",
				pId: 0,
				pNumId: "",
				pVigencia: "",
				pFechaCompra: "",
				pFechaVenta: "",
				pIdPersona: 0,
				edditProp: false,
				pviv: "",
				email: "",
				city: "",
				es_residente:false
			});
		}
	}

	reload() {
		this.getSubFraccionamiento();
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({page});
	}

	reloadOwners() {
		this.getPropietarioFraccionamiento();
	}
	change() {
		this.getPropietarioFraccionamiento();
	}

	propietario = (data) => {
		this.setState({
			pNombre: data.nombre,
			pDireccion: data.direccion,
			pMunicipio: data.municipio,
			pEstado: data.estado,
			pCp: data.cp,
			pTelefono: data.telefono,
			pPais: data.pais,
			pGenero: data.genero,
			pId: parseInt(data.tipo_identificacion),
			pNumId: data.num_identificacion,
			pVigencia: data.vigencia_identificacion,
			pIdPersona: data.id_persona,
			search: false,
			city: data.ciudad,
		});
		//console.log(data);
	};

	vivienda = (data) => {
		this.setState({
			pviv: data.numero_registro,
			pidViv: data.id_vivienda,
			searchHouse: false,
		});
	};

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevoPropietario() {
		this.setState({showModalOwners: true});
	}

	setFilter(filter) {
		this.setState({filter});
	}

	async completeForm(item) {
		this.setState({pNombre: item.label});
		if (item.value > 0) {
			const res = await request.get("/inquilinos/getbypersona", {id_persona: item.value, id_fraccionamiento: localStorage.getItem("frac")});
			if (res.inquilino) {
				const phone = parsePhoneNumber(`+${res.inquilino.telefono}`);
				this.setState({
					pIdPersona: item.value,
					pTelefono: phone.number,
					email: res.inquilino.email || "",
					pId: res.inquilino.tipo_identificacion,
					pNumId: res.inquilino.num_identificacion,
					pGenero: res.inquilino.genero,
					pDireccion: res.inquilino.direccion,
					pMunicipio: res.inquilino.municipio,
					pCp: res.inquilino.cp,
					pEstado: res.inquilino.estado,
					pPais: res.inquilino.pais,
					city: res.inquilino.ciudad,
				});
			}
		}
	}

	componentDidMount() {
		this.getPropietarioFraccionamiento();
		this.getPersonas();
		this.getViviendas();
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	async validarTelefono(telefono) {
		this.setState({loadingVlidatePhone: true, phoneValidated: false});
		if (isValidPhoneNumber(telefono)) {
			const data = {
				telefono,
				id_fraccionamiento: this.state.fraccionamiento,
			};
			const res = await request.post("/personas/getbyphone", data);
			//console.log(res);
			const {persona} = res;
			if (persona) {
				this.setState({
					pNombre: persona.nombre || "",
					email: persona.email || "",
					pGenero: persona.genero || 0,
					pDireccion: persona.direccion || "",
					pCp: persona.cp || "",
					city: persona.ciudad || "",
					pMunicipio: persona.municipio || "",
					pEstado: persona.estado || "",
					pPais: persona.pais || "",
					pId: persona.tipo_identificacion || 0,
					pNumId: persona.num_identificacion || "",
					pIdPersona: persona.id_persona || 0,
					persona_existente: persona.encontrado,
				});
			} else {
				this.setState({
					pNombre: "",
					email: "",
					pGenero: 0,
					pDireccion: "",
					pCp: "",
					city: "",
					pMunicipio: "",
					pEstado: "",
					pPais: "",
					pId: 0,
					pNumId: "",
					pIdPersona: 0,
					persona_existente: false,
				});
			}
			this.setState({phoneValidated: true});
		} else {
			cogoToast.warn("Número de teléfono inválido.", {
				position: "bottom-right",
			});
		}
		this.setState({loadingVlidatePhone: false});
	}

	render() {
		let list_personas = [];
		let viviendas = [];
		if (Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map((persona) => {
				return {label: persona.nombre, value: persona.id_persona};
			});
		}
		if (Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Subfraccionamiento:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" disabled name="subfraccionamiento" value={this.props.subname} className="input input-modals" autoComplete={"off"} />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Unidad:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								name="vivienda"
								disabled={this.props.editVivienda ? false : true}
								value={this.props.editVivienda ? this.state.pidViv : this.props.idVivienda}
								onChange={(event) => this.setState({pidViv: event.target.value})}
							>
								{viviendas.map((viv) => (
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Teléfono: </b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="row full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								defaultCountry="MX"
								value={this.state.pTelefono}
								onChange={(value) => this.setState({pTelefono: value, telefonoError: undefined, phoneValidated: false})}
								error={this.state.telefonoError}
								className={"input-modals"}
								required
								maxLength="18"
								autoComplete={"off"}
							/>
							<button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white" onClick={this.validarTelefono.bind(this, this.state.pTelefono)}>
								{this.state.loadingVlidatePhone ? <i className="fas fa-spinner fa-spin"></i> : "Validar"}
							</button>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column  label-medium">
							<p>
								<b> Nombre completo: </b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="fullName"
								id="ownername"
								value={this.state.pNombre}
								onChange={(event) => this.setState({pNombre: event.target.value})}
								className="input input-modals"
								maxLength="64"
								required
								disabled={this.state.persona_existente}
								autoComplete={"off"}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p className="justify-start">
								<b> Género: </b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.pGenero} required onChange={(event) => this.setState({pGenero: event.target.value})} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<div className="justify-start">
								<p>
									<b>Correo:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<input
								type="email"
								name="EmailEdit"
								value={this.state.email}
								/* required */
								onChange={(event) => this.setState({email: event.target.value})}
								className="input input-modals"
								autoComplete={"off"}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b> Identificación: </b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="id" id="idEddit" value={this.state.pId} onChange={(event) => this.setState({pId: event.target.value})}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="numIdEddit"
								value={this.state.pNumId}
								onChange={(event) => this.setState({pNumId: event.target.value})}
								autoComplete={"off"}
								/* required */
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="address"
								value={this.state.pDireccion}
								onChange={(event) => this.setState({pDireccion: event.target.value})}
								className="input input-modals"
								maxLength="150"
								/* required */
								autoComplete={"off"}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								maxLength="80"
								type="text"
								name="municipality"
								value={this.state.pMunicipio}
								onChange={(event) => this.setState({pMunicipio: event.target.value})}
								/* required */
								autoComplete={"off"}
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
						<input type="text" value={this.state.city} onChange={(event) => this.setState({city: event.target.value})} className="input input-modals" id="city" /* required */ autoComplete={"off"} />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
						<input
								type="text"
								value={this.state.pCp}
								onChange={(event) => this.setState({pCp: event.target.value})}
								name="zip"
								className="input input-modals"
								maxLength="10"
								/* required */
								autoComplete={"off"}
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
						<input
								type="text"
								name="state"
								value={this.state.pEstado}
								onChange={(event) => this.setState({pEstado: event.target.value})}
								className="input input-modals"
								maxLength="24"
								autoComplete={"off"}
								/* required */
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big ">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
						<input
								type="text"
								value={this.state.pPais}
								onChange={(event) => this.setState({pPais: event.target.value})}
								name="country"
								className="input input-modals"
								maxLength="10"
								/* required */
								autoComplete={"off"}
							/>
						</div>
						<div className="column label-duo-normal space">
							
						</div>
						<div className="column full">
							
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big ">
							<p>
								<b>Fecha de compra:</b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="date"
								value={this.state.pFechaCompra}
								onChange={(event) => this.setState({pFechaCompra: event.target.value})}
								id="fcompra"
								required
								autoComplete={"off"}
							/>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Fecha de venta:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="date" value={this.state.pFechaVenta} onChange={(event) => this.setState({pFechaVenta: event.target.value})} id="fventa" />
						</div>
					</div>	
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Comentarios:</b>
							</p>
						</div>
						<div className="column full">
						<textarea 
                            name="comentarios"
                            id="comentarios_inquilinos"
                            value={this.state.comentarios}
                            cols="30"
                            rows="3"
                            maxLength="500"
                            className='textarea font full'					
						 onChange={(event) => this.setState({comentarios: event.target.value})} >
                        </textarea>
						</div>
						<div className="column label-duo-normal space">
						<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="esPropietario" id="esPropietario" disabled="disabled"  checked={this.state.es_residente ? true : false}  />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Es residente </b>{" "}
							</h5>
						</div>
					</div>
						</div>
						<div className="column full">
						
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input
									type="checkbox"
									id="residente"
									checked={this.state.residente === 1 ? true : false}
									name="residente"
									onChange={(event) => {
										//console.log(this.state.residente);
										this.setState({residente: event.target.checked});
									}}
								/>
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Añadir propietario como residente</b>{" "}
							</h5>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							<b style={{color:'red'}}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
						
					</div>
				</div>
			</div>
		);
	}

	//traer Propietarios del fraccionaiento
	async getPropietarioFraccionamiento() {
		this.setState({loadingPropietarios: true});
		let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem("frac");

		let data = {IdSub, idFrac};

		const response = await request.post("/fraccionamientos/get/propietarios", data);

		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				this.setState({
					propietarios: response.propietario,
					filterData: response.propietario,
				});
				////console.log(response.propietario);
			} else {
				this.setState({
					propietarios: [],
					empty: true,
					message: response.message,
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
			});
		}
		this.setState({loadingPropietarios: false});
	}

	async handledSubmit(event) {
		event.preventDefault();
		this.setState({loading: true});
		/**Validar Vivienda */
		if (!(this.state.pidViv > 0)) {
			this.setState({loadingSave: false});
			cogoToast.warn("Debes seleccionar una vivienda.", {
				position: "bottom-right",
			});
			return;
		}
		/**Validar Telefono */
		if (!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({telefonoError: "Número inválido", loading: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
		let city = document.getElementById("city").value;

		let jsons = [];
		jsons.push([parseInt(this.state.pidViv)]);
		//console.log(jsons);
		let data = {
			viviendasJSON: JSON.stringify(jsons),
			Nombre: this.state.pNombre,
			Direccion: this.state.pDireccion,
			Municipio: this.state.pMunicipio,
			Estado: this.state.pEstado,
			Cp: this.state.pCp,
			Telefono: this.state.pTelefono,
			Pais: this.state.pPais,
			Genero: parseInt(this.state.pGenero),
			TipoId: parseInt(this.state.pId),
			NumId: this.state.pNumId,
			Vigencia: this.state.pVigencia,
			FechaCompra: this.state.pFechaCompra,
			FechaVenta: this.state.pFechaVenta,
			idPersona: this.state.pIdPersona,
			email: this.state.email,
			idFraccionamiento: localStorage.getItem("frac") || "0",
			city: city,
			id_usuario_add :    localStorage.getItem("user_id")

		};

		const response = await request.post("/propietarios/propietario/create", data);

		this.setState({showModalOwners: false});
		if (response && !response.error) {
			if (response.created && !response.empty) {
				this.setState({
					reload: true,
					loading: false,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loading: false,
				});
				this.getPropietarioFraccionamiento();
				cogoToast.warn("El número de identificación ya está registrado.", {
					position: "bottom-right",
				});
				return;
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loading: false,
			});
		}
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem("frac")});
		if (Array.isArray(response.personas)) {
			await this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem("frac")});
		if (Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}

	async getFechas() {
		const res = await request.post("/propietarios/get/fechas", {IdPersona: this.state.pIdPersona, IdViv: this.state.pidViv});
		if (res.dates) {
			//console.log(res.dates);
			await this.setState({
				pFechaCompra: res.dates.fecha_compra || "",
				pFechaVenta: res.dates.fecha_venta || "",
			});
		}
	}
}

export default Owners;
