import Request from '../core/httpClient';
import pluralize from 'pluralize';
const request = new Request();

export function menuSidebar(data, event) {
    if (Array.isArray(data)) {
        const catalogos_menus = data.filter(item => item.pantalla === -1).map(data => data);
		const administracion_menus = data.filter(item => item.pantalla === -2).map(data => data);
		const operacion_menus = data.filter(item => item.pantalla === -3).map(data => data);
		//console.log('catalogos_menus -->', catalogos_menus);
		//console.log('administracion_menus -->',administracion_menus);
		//console.log('operacion_menus -->', operacion_menus);

        window.localStorage.setItem('catalogos_menus', JSON.stringify(catalogos_menus));
		window.localStorage.setItem('administracion_menus', JSON.stringify(administracion_menus));
		window.localStorage.setItem('operacion_menus', JSON.stringify(operacion_menus));
		window.localStorage.setItem('submenu', '');
		window.localStorage.removeItem('submenu_name');
		window.localStorage.setItem('submenu_admin', '');
		window.localStorage.removeItem('submenu_name_admin');
		window.localStorage.setItem('submenu_ope', '');
		window.localStorage.removeItem('submenu_name_ope');
		//event.setState({menusCatalogos: catalogos_menus, menusAdministacion: administracion_menus});
    } else {
        window.localStorage.setItem('catalogos_menus', '');
		window.localStorage.setItem('administracion_menus', '');
		window.localStorage.setItem('operacion_menus', '');
        window.localStorage.setItem('submenu', '');
		window.localStorage.removeItem('submenu_name');
		window.localStorage.setItem('submenu_admin', '');
		window.localStorage.removeItem('submenu_name_admin');
		window.localStorage.setItem('submenu_ope', '');
		window.localStorage.removeItem('submenu_name_ope');
	}
	/** Reload */
	//window.location.reload();
};

export async function getMenus(id_fraccionamiento, perfil) {
	// MENU USUARIO PERMISOS
	//console.log("BUSCANDO MENUS DEL NAV");
	const data = { idFraccionamiento: id_fraccionamiento, perfil };
	const response = await request.post("/fraccionamientos/get/menus/parents", data);
	if (response.parents) {
		//console.log(response.parents);
		menuSidebar(response.parents, this);
		return response.parent;
	}
	
}

export async function menuCatalogo() {

}

export async function menuAdministracion() {

}

export async function getSubmenuName() {
	let name = window.localStorage.getItem('submenu_name');
	if (name) {
		this.setState({ submenuName: name });
	} else {
		let menus = window.localStorage.getItem('catalogos_menus');
		if (menus) {
			menus = JSON.parse(menus);
			if (menus.length !== 0) {
				//Label subfraccionamiento
				name = menus[0].recordset[0].nombre;
				let singular = pluralize.isPlural(name);
				if (singular) {
					name = pluralize.singular(name);
				}
				window.localStorage.setItem('subLabel', name);

				const loc = window.localStorage.getItem('submenu_loc');
				if (loc) {
					let submenu = [];
					let subMenuName = menus.map(menu => {
						const x = menu.recordset.filter((re, i) => re.ruta === loc);
						if (x.length !== 0) {
							submenu = x[0];
						}
					});
					console.log("SMN: ", submenu);

					if (submenu.length !== 0) {
						window.localStorage.setItem('submenu', submenu.id_menu);
						window.localStorage.setItem('submenu_name', submenu.nombre);

						this.setState({ submenuName: submenu.nombre });

						return submenu.nombre;
					} else {

						window.localStorage.setItem('submenu', menus[0].recordset[0].id_menu);
						window.localStorage.setItem('submenu_name', menus[0].recordset[0].nombre);
						window.location = `${menus[0].recordset[0].ruta}`;

						return menus[0].recordset[0].nombre;
					}
				} else {

					window.localStorage.setItem('submenu', menus[0].recordset[0].id_menu);
					window.localStorage.setItem('submenu_name', menus[0].recordset[0].nombre);

					return menus[0].recordset[0].nombre;
				}
			}
		}
	}
	
	return name;
}

export async function getSubmenuAdminName() {
	let name = window.localStorage.getItem('submenu_name_admin');
	if (!name) {
		let catalogos_menus = window.localStorage.getItem('catalogos_menus');
		if (catalogos_menus) {
			catalogos_menus = JSON.parse(catalogos_menus);
			if (catalogos_menus.length !== 0) {
				//Label subfraccionamiento
				name = catalogos_menus[0].recordset[0].nombre;
				let singular = pluralize.isPlural(name);
				if (singular) {
					name = pluralize.singular(name);
				}
				window.localStorage.setItem('subLabel', name);
			}
		}

		let menus = window.localStorage.getItem('administracion_menus');
		if (menus) {
			menus = JSON.parse(menus);
			if (menus.length !== 0) {
				const loc = window.localStorage.getItem('submenu_loc_admin');
				if (loc) {
					let submenu = [];
					let subMenuName = menus.map(menu => {
						const x = menu.recordset.filter((re, i) => re.ruta === loc);
						if (x.length !== 0) {
							submenu = x[0];
						}
					});
					console.log("SMN: ", submenu);

					if (submenu.length !== 0) {
						window.localStorage.setItem('submenu_admin', submenu.id_menu);
						window.localStorage.setItem('submenu_name_admin', submenu.nombre);

						this.setState({ submenuAdminName: submenu.nombre });

						return submenu.nombre;
					} else {

						window.localStorage.setItem('submenu_admin', menus[0].recordset[0].id_menu);
						window.localStorage.setItem('submenu_name_admin', menus[0].recordset[0].nombre);
						window.location = `${menus[0].recordset[0].ruta}`;

						return menus[0].recordset[0].nombre;
					}
				} else {

					window.localStorage.setItem('submenu_admin', menus[0].recordset[0].id_menu);
					window.localStorage.setItem('submenu_name_admin', menus[0].recordset[0].nombre);

					return menus[0].recordset[0].nombre;
				}
			}
		}
	} 
	
	this.setState({ submenuAdminName: name });

	return name;
}

export const getSubmenuAdminName2 = (route) => {

	let name = window.localStorage.getItem('submenu_name_admin');

    if(!name) { 
        //Reset Sublabel
        let catalogos_menus = window.localStorage.getItem('catalogos_menus');

		if (catalogos_menus) {
			catalogos_menus = JSON.parse(catalogos_menus);
			if (catalogos_menus.length !== 0) {
				//Label subfraccionamiento
				name = catalogos_menus[0].recordset[0].nombre;
				let singular = pluralize.isPlural(name);
				if (singular) {
					name = pluralize.singular(name);
				}
				window.localStorage.setItem('subLabel', name);
			}
		}

        //Load Submenu Admin Name
        let menus = window.localStorage.getItem('administracion_menus');
        menus = JSON.parse(menus);

        if (menus.length !== 0) {

            let submenu;

            for (let index = 0; index < menus.length; index++) {
                const subs = menus[index].recordset;
                console.log("Subs: ", subs);
                const foundSub = subs.find(sub => sub.ruta == route);

                if(foundSub){
                    submenu = foundSub;
                    break;
                }
            }

            if (submenu) {
                window.localStorage.setItem('submenu_admin', submenu.id_menu);
                window.localStorage.setItem('submenu_name_admin', submenu.nombre);

                name = submenu.nombre;

            } else {
                window.localStorage.setItem('submenu_admin', menus[0].recordset[0].id_menu);
                window.localStorage.setItem('submenu_name_admin', menus[0].recordset[0].nombre);
                window.location = `${menus[0].recordset[0].ruta}`;

                name = menus[0].recordset[0].nombre;
            }
        }
    }

    return name;
}

export async function getSubmenuOpeName() {
	let name = window.localStorage.getItem('submenu_name_ope');
	if (!name) {
		let catalogos_menus = window.localStorage.getItem('catalogos_menus');
		if (catalogos_menus) {
			catalogos_menus = JSON.parse(catalogos_menus);
			if (catalogos_menus.length !== 0) {
				//Label subfraccionamiento
				name = catalogos_menus[0].recordset[0].nombre;
				let singular = pluralize.isPlural(name);
				if (singular) {
					name = pluralize.singular(name);
				}
				window.localStorage.setItem('subLabel', name);
			}
		}

		let menus = window.localStorage.getItem('operacion_menus');
		if (menus) {
			menus = JSON.parse(menus);
			if (menus.length !== 0) {
				const loc = window.localStorage.getItem('submenu_loc_ope');
				if (loc) {
					let submenu = [];
					let subMenuName = menus.map(menu => {
						const x = menu.recordset.filter((re, i) => re.ruta === loc);
						if (x.length !== 0) {
							submenu = x[0];
						}
					});
					console.log("SMN: ", submenu);

					if (submenu.length !== 0) {
						window.localStorage.setItem('submenu_ope', submenu.id_menu);
						window.localStorage.setItem('submenu_name_ope', submenu.nombre);

						this.setState({ submenuOpeName: submenu.nombre });

						return submenu.nombre;
					} else {

						window.localStorage.setItem('submenu_ope', menus[0].recordset[0].id_menu);
						window.localStorage.setItem('submenu_name_ope', menus[0].recordset[0].nombre);
						window.location = `${menus[0].recordset[0].ruta}`;

						return menus[0].recordset[0].nombre;
					}
				} else {

					window.localStorage.setItem('submenu_ope', menus[0].recordset[0].id_menu);
					window.localStorage.setItem('submenu_name_ope', menus[0].recordset[0].nombre);

					return menus[0].recordset[0].nombre;
				}
			}
		}
	} 

	this.setState({ submenuOpeName: name });

	return name;
}