import React, { Component } from "react";
import { Consumer } from "../../context";
import { Link } from "react-router-dom";

class Sidebar extends Component {
    state = {
        active: 0,
        user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
        menus: [],
        activeSub: 0,
    };

    componentDidMount() {
        this.load();
        this.menusLocal();
        let submenu = window.localStorage.getItem("submenu");
        if (submenu) {
            this.setState({ activeSub: JSON.parse(submenu) });
        }
    }

    menusLocal() {
        const menusLocal = window.localStorage.getItem("catalogos_menus");
        let parentsOpenLocal = JSON.parse(window.localStorage.getItem("parents_open"));
        if (menusLocal) {
            let menusObject = JSON.parse(menusLocal);
            menusObject = menusObject.map(menuList => ({
                ...menuList,
                open: parentsOpenLocal?.some(p => p.menu.toString() === menuList.id_menu.toString()),
            }));
            this.setState({ menus: menusObject });
        }
    }

    parentClick(item) {
        const menus = this.state.menus.map(menu => ({
            ...menu,
            open: item.id_menu === menu.id_menu ? !menu.open : false,
        }));

        this.setState({ menus, active: item.id_menu });

        window.localStorage.setItem("catalogos_menus", JSON.stringify(menus));
        window.localStorage.setItem(
            "parents_open",
            JSON.stringify(menus.filter(m => m.open).map(m => ({ menu: m.id_menu }))),
        );
    }

    childrenClick(list) {
        this.setState({ activeSub: list.id_menu });
        window.localStorage.setItem("submenu", list.id_menu.toString());
        window.localStorage.setItem("submenu_loc", list.ruta);
        window.localStorage.setItem("submenu_name", list.nombre);
    }

    async load() {
        try {
            const user = await this.props.context.loadUser();
            if (user && user.id) {
                this.setState({
                    user: {
                        idUsuario: user.id.id_usuario || 0,
                        idPerfil: user.id.id_perfil || 0,
                        idFraccionamiento: user.id.id_fraccionamiento || 0,
                        idAdministracion: user.id.id_administracion || 0,
                    },
                });
            } else {
                console.error("Failed to load user details.");
            }
            this.getPermissions();
        } catch (error) {
            console.error("Error loading user details: ", error);
        }
    }

    async getPermissions() {}

    render() {
        const { menus } = this.state;

        return (
            <div className="sidebar row">
                <div className="column content-side">
                    {menus.map((item, index) => (
                        <React.Fragment key={index}>
                            <button
                                className={`accordion color-white ${
                                    this.state.active.toString() === item.id_menu.toString() ? "active" : ""
                                }`}
                                onClick={() => this.parentClick(item)}
                            >
                                {item.parent_name}
                                <i
                                    className={`fas fa-angle-left font-regular color-white icon-rotate ${
                                        item.open ? "icon-down" : ""
                                    }`}
                                ></i>
                            </button>
                            {item.recordset &&
                                item.recordset.map((list, ind) => (
                                    <div className={`panel ${item.open ? "maxHeight" : ""}`} key={ind}>
                                        <Link
                                            to={list.ruta}
                                            className={`row nav-item auto ${
                                                this.state.activeSub.toString() === list.id_menu.toString()
                                                    ? "active-item"
                                                    : ""
                                            }`}
                                            onClick={() => this.childrenClick(list)}
                                        >
                                            <div className="font-regular color-white">
                                                <span className="color-white">{list.nombre}</span>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }
}

export default Consumer(Sidebar);
