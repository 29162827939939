import React, {Component} from "react";
import moment from "moment-timezone";
import Request from "../../core/httpClient";
import PhoneInput, {isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import cogoToast from "cogo-toast";

const request = new Request();

class InquilinoPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inquilinos: [],
			INombre: "",
			IDireccion: this.props.direccion,
			IMunicipio: "",
			IEstado: "",
			ICp: "",
			iTelefono: "",
			IPais: "",
			IGenero: 0,
			IId: 0,
			iNumId: "",
			iVigencia: "",
			IFechaIngreso: "",
			IFechaSalida: "",
			IIdPersona: 0,
			IidViv: 0,
			page: 1,
			code: "",
			email: "",
			fraccionamiento: localStorage.getItem("frac") || "0",
			subfraccionamiento: localStorage.getItem("subfrac") || "0",
			subfraccionamientos: [],
			filter: "",
			personas: [],
			saveMessage: "",
			city: "",
			persona_existente: true,
			phoneValidated: false,
			comentarios:"",
			es_propietario:false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state !== prevState) {
			this.props.setInfo(this.state);
			this.props.validPhone(this.state.phoneValidated);
		}
		if (this.props.showNewModal !== prevProps.showNewModal) {
			this.setState({
				IIdPersona: 0,
				iTelefono: "",
				INombre: "",
				IDireccion: "",
				email: "",
				IId: 0,
				iNumId: "",
				IGenero: 0,
				IDireccion: "",
				IMunicipio: "",
				ICp: "",
				IEstado: "",
				IPais: "",
				city: "",
				IFechaSalida: "",
				IFechaIngreso: "",
				es_propietario:false
			});
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({page});
	}

	reload() {
		this.getInquilinos();
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	openModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(inquilinos) {
		inquilinos = inquilinos.filter((inq) => {
			if (inq.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inq.telefono.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inq.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return inquilinos;
	}

	async completeForm(item) {
		this.setState({INombre: item.label});
		if (item.value > 0) {
			const res = await request.get("/inquilinos/getbypersona", {id_persona: item.value, id_fraccionamiento: localStorage.getItem("frac")});
			if (res.inquilino) {
				const phone = parsePhoneNumber(`+${res.inquilino.telefono}`);

				this.setState({
					IIdPersona: item.value,
					iTelefono: phone.number,
					IDireccion: res.inquilino.direccion || "",
					email: res.inquilino.email || "",
					IId: res.inquilino.tipo_identificacion,
					iNumId: res.inquilino.num_identificacion,
					IGenero: res.inquilino.genero,
					IDireccion: res.inquilino.direccion,
					IMunicipio: res.inquilino.municipio,
					ICp: res.inquilino.cp,
					IEstado: res.inquilino.estado,
					IPais: res.inquilino.pais,
					city: res.inquilino.ciudad,
				});
			}
		}
	}

	async validarTelefono(telefono) {
		this.setState({loadingVlidatePhone: true, phoneValidated: false});
		if (isValidPhoneNumber(telefono)) {
			const data = {
				telefono,
				id_fraccionamiento: this.state.fraccionamiento,
			};
			const res = await request.post("/personas/getbyphone", data);
			const {persona} = res;
			if (persona) {
				this.setState({
					INombre: persona.nombre || "",
					email: persona.email || "",
					IGenero: persona.genero || 0,
					IDireccion: persona.direccion || "",
					ICp: persona.cp || "",
					city: persona.ciudad || "",
					IMunicipio: persona.municipio || "",
					IEstado: persona.estado || "",
					IPais: persona.pais || "",
					IId: persona.tipo_identificacion || 0,
					iNumId: persona.num_identificacion || "",
					IIdPersona: persona.id_persona || 0,
					persona_existente: persona.encontrado,
					comentarios:persona.comentarios|| "",
					es_propietario:persona.es_propietario|| false,
				});
			} else {
				this.setState({
					INombre: "",
					email: "",
					IGenero: 0,
					IDireccion: "",
					ICp: "",
					city: "",
					IMunicipio: "",
					IEstado: "",
					IPais: "",
					IId: 0,
					iNumId: "",
					IIdPersona: 0,
					persona_existente: false,
					es_propietario:false,
				});
			}
			this.setState({phoneValidated: true});
		} else {
			cogoToast.warn("Número de teléfono inválido.", {
				position: "bottom-right",
			});
		}
		this.setState({loadingVlidatePhone: false});
	}

	render() {
		let list_personas = [];
		let viviendas = [];
		if (Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map((persona) => {
				return {label: persona.nombre, value: persona.id_persona};
			});
		}
		if (Array.isArray(this.state.viviendas)) {
			viviendas = this.state.viviendas;
		}
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> {this.props.subLabel}: </b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" name="sub" value={this.props.subname} disabled autoComplete="off" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Vivienda: </b>
							</p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								name="vivienda"
								disabled={this.props.editVivienda ? false : true}
								value={this.props.editVivienda ? this.state.IidViv : this.props.idVivienda}
								onChange={(event) => this.setState({IidViv: event.target.value})}
							>
								{/* <option value="0">SELECCIONAR VIVIENDA</option> */}
								{viviendas.map((viv) => (
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Teléfono: </b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="row full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								defaultCountry="MX"
								value={this.state.iTelefono}
								onChange={(value) => this.setState({iTelefono: value, telefonoError: undefined, phoneValidated: false})}
								error={this.state.telefonoError}
								className={"input-modals"}
								maxLength="18"
								required
								autoComplete="off"
							/>
							<button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white" onClick={this.validarTelefono.bind(this, this.state.iTelefono)}>
								{this.state.loadingVlidatePhone ? <i className="fas fa-spinner fa-spin"></i> : "Validar"}
							</button>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Nombre completo: </b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="fullName"
								value={this.state.INombre}
								onChange={(event) => this.setState({INombre: event.target.value})}
								className="input input-modals"
								maxLength="64"
								id="inqname"
								disabled={this.state.persona_existente}
								required
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p className="justify-start">
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="sex" id="" value={this.state.IGenero} onChange={(event) => this.setState({IGenero: event.target.value})} required>
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<div className="justify-start">
								<p>
									<b>Correo:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="email"
								name="fullName"
								value={this.state.email}
								onChange={(event) => this.setState({email: event.target.value})}
								maxLength="64"
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b> Identificación: </b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="id" id="idEddit" value={this.state.iId} onChange={(event) => this.setState({iId: event.target.value})}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="numIdEddit"
								value={this.state.iNumId}
								onChange={(event) => this.setState({iNumId: event.target.value})}
								className="input input-modals"
								maxLength="24"
								autoComplete="off"
								//required
							/>
						</div>
					</div>

					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="address"
								value={this.state.IDireccion}
								onChange={(event) => this.setState({IDireccion: event.target.value})}
								className="input input-modals"
								maxLength="150"
								autoComplete="off"
								//required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="municipality"
								value={this.state.IMunicipio}
								onChange={(event) => this.setState({IMunicipio: event.target.value})}
								className="input input-modals"
								maxLength="80"
								autoComplete="off"
								//required
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" value={this.state.city} onChange={(event) => this.setState({city: event.target.value})} id="city" autoComplete="off" /* required */ />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								value={this.state.ICp}
								onChange={(event) => this.setState({ICp: event.target.value})}
								name="zip"
								className="input input-modals"
								maxLength="10"
								//required
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="state"
								value={this.state.IEstado}
								onChange={(event) => this.setState({IEstado: event.target.value})}
								className="input input-modals"
								//required
								maxLength="24"
								autoComplete="off"
							/>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big ">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
						<input
								className="input input-modals"
								type="text"
								value={this.state.IPais}
								onChange={(event) => this.setState({IPais: event.target.value})}
								name="country"
								maxLength="10"
								//required
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal">
							
						</div>
						<div className="column full">
					
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Fecha de ingreso:</b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="column full">
						<input type="date" value={this.state.IFechaIngreso} onChange={(event) => this.setState({IFechaIngreso: event.target.value})} className="input input-modals" id="fcompra" required />
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Fecha de salida:</b>
							</p>
						</div>
						<div className="column full">
						<input className="input input-modals" type="date" value={this.state.IFechaSalida} onChange={(event) => this.setState({IFechaSalida: event.target.value})} id="fcompra" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Comentarios:</b>
							</p>
						</div>
						<div className="column full">
						<textarea 
                            name="comentarios"
                            id="comentarios_inquilinos"
                            value={this.state.comentarios}
                            cols="30"
                            rows="3"
                            maxLength="500"
                            className='textarea font full'					
						 onChange={(event) => this.setState({comentarios: event.target.value})} >
                        </textarea>
						</div>
						<div className="column label-duo-normal space">
						<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="esPropietario" id="esPropietario" disabled="disabled"  checked={this.state.es_propietario ? true : false} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Es propietario </b>{" "}
							</h5>
						</div>
					</div>
						</div>
						<div className="column full">
						
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							<b style={{color:'red'}}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
						
					</div>
					<div className="row justify-center">{this.state.saveMessage}</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.getInquilinos();
		this.getPersonas();
		this.getViviendas();
	}

	// async handleSubmit(event) {
	//     event.preventDefault();
	// 	this.setState({loadingSave: true,saveMessage: ''});
	// 	/**Validar Vivienda */
	// 	if (!(this.state.IidViv > 0)) {
	// 		this.setState({loadingSave: false});
	//         alert("Para continuar debes seleccionar una vivienda")
	//         return;
	// 	}
	// 	/**Validar Telefono */
	// 	if(!isValidPhoneNumber(this.state.iTelefono)) {
	// 		this.setState({telefonoError: 'Número inválido', loadingSave: false});
	// 		return;
	// 	} else {
	// 		this.setState({telefonoError: undefined});
	// 	}

	//     if (this.state.IIdPersona < 1) {
	//         let data = {
	//             idVivienda:			 this.state.IidViv,
	//             Nombre:				 this.state.INombre,
	//             Direccion:			 this.state.IDireccion,
	//             Municipio:			 this.state.IMunicipio,
	//             Estado:				 this.state.IEstado,
	//             cp:					 this.state.ICp,
	//             Telefono:			 this.state.iTelefono,
	//             pais:				 this.state.IPais,
	//             Genero:				 this.state.IGenero,
	//             Id:					 this.state.IId,
	//             NumId:				 this.state.iNumId,
	//             Vigencia:			 this.state.iVigencia,
	// 			Fechaingreso:		 this.state.IFechaIngreso,
	// 			FechaSalida:		 this.state.IFechaSalida,
	//             IdPersona:			 0,
	//             Code:				 this.state.code,
	//             email:				 this.state.email,
	// 			idFraccionamiento:	 this.state.fraccionamiento
	//         }

	//         const response = await request.post("/viviendas/inquilino/crear", data);

	//         if (response && !response.error) {
	//             if (response.status && !response.empty) {
	//                 if (response.status === 1) {
	//                     this.setState({ add: false, saveMessage: response.message });
	//                     this.getInquilinos();
	//                 } else {
	//                     alert("EL NÚMERO ESTA REGISTRADO PARA OTRO USUARIO, FAVOR DE CAMBIARLO");
	//                     this.setState({ message: response.message });
	//                 }
	//             } else {
	//                 this.setState({
	//                     empty: true,
	//                     message: response.message,
	//                     loading: false
	//                 });
	//             }
	//         } else {
	//             this.setState({
	//                 error: true, saveMessage: response.error, loading: false
	//             });
	//         }
	//     } else {
	//         let data = {
	//             idVivienda:					 this.state.IidViv,
	//             Nombre:						 this.state.INombre,
	//             Direccion:					 this.state.IDireccion,
	//             Municipio:					 this.state.IMunicipio,
	//             Estado:						 this.state.IEstado,
	//             cp:							 this.state.ICp,
	//             Telefono:					 this.state.iTelefono,
	//             pais:						 this.state.IPais,
	//             Genero:						 this.state.IGenero,
	//             Id:							 this.state.IId,
	//             NumId:						 this.state.iNumId,
	//             Vigencia:					 this.state.IVigencia,
	// 			Fechaingreso:				 this.state.IFechaIngreso,
	// 			FechaSalida:				 this.state.IFechaSalida,
	//             IdPersona:					 this.state.IIdPersona,
	//             Code:						 this.state.code,
	// 			email:						 this.state.email,
	// 			idFraccionamiento:			 this.state.fraccionamiento
	//         }

	//         const response = await request.post("/viviendas/inquilino/crear", data);

	//         if (response && !response.error) {
	//             if (response.status && !response.empty) {
	//                 if (response.status === 1) {
	//                     this.getInquilinos();
	//                 } else {
	//                     alert("EL NÚMERO ESTA REGISTRADO PARA OTRO USUARIO, FAVOR DE CAMBIARLO");
	//                     this.setState({ message: response.message });
	//                 }
	//             } else {
	//                 this.setState({
	// 					reload: true,
	// 					mesagge: "INQUILINO AGREGADO",
	//                 	loading: false
	//                     // empty: true,
	//                     // message: response.message,
	//                     // loading: false
	//                 });
	//             }
	//         } else {
	//             this.setState({
	//                 error: true, message: response.message, loading: false
	//             });
	//         }
	// 	}
	// 	this.setState({loadingSave: false});
	// }

	async getInquilinos() {
		this.setState({inquilinos: [], loadingInquilinos: true});
		let IdSub = this.state.subfraccionamiento;
		const idFrac = localStorage.getItem("frac");

		let data = {IdSub, idFrac};

		const response = await request.post("/inquilinos/get/inquilinos", data);
		if (response && !response.error) {
			if (response.inquilinos && !response.empty) {
				this.setState({
					inquilinos: response.inquilinos,
					filterData: response.inquilinos,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loading: false,
					inquilinos: [],
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loading: false,
			});
		}
		this.setState({loadingInquilinos: false});
	}

	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem("frac")});
		if (Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
		}
	}

	async getViviendas() {
		const response = await request.post("/viviendas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem("frac")});
		if (Array.isArray(response.viviendas)) {
			this.setState({viviendas: response.viviendas});
		} else {
			this.setState({viviendas: []});
		}
	}
}

export default InquilinoPage;
