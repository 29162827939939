import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import cogoToast from "cogo-toast";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();

const HolidayDates = ({ context }) => {

    const [diasFeriados, setDiasFeriados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadRemoving, setLoadRemoving] = useState(false);

    useEffect(() => {

        (async () => {

            await getDiasFeriados();

        })()

    }, []);

    async function addDiaFeriado(e) {

        try {

            e.preventDefault();

            setLoading(true);

            const form = e.target;

            let user = await context.loadUser();

            if (user) {

                const data = {
                    id_fraccionamiento: localStorage.getItem("frac"),
                    dia_feriado: form.fecha.value,
                    descripcion: form.descripcion.value,
                    usuario: user.id.nombre
                };

                const response = await request.post("/facturacion/reglas/fechasFeriadas/add", data);

                if (response) {

                    if (response.error) {
                        cogoToast.error(response.message, {
                            position: "bottom-right",
                        });
                    };

                    if (response.updated) {

                        cogoToast.success("Fecha feriada agregada correctamente.", {
                            position: "bottom-right"
                        });

                        await colocaValor("fecha", "");
                        await colocaValor("descripcion", "");

                        await getDiasFeriados();
                    };

                    if (response.existe) {
                        cogoToast.error(response.message, {
                            position: "bottom-right"
                        });
                    };
                };
            };

            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        };
    };

    async function colocaValor(id, valor) {
        const inputs = document.getElementsByName(id);
        if (inputs) {
            inputs.forEach((input) => {
                input.value = valor;
            });
        };
    };

    async function getDiasFeriados() {

        try {

            setLoadingData(true);

            const data = {
                id_fraccionamiento: localStorage.getItem("frac")
            };

            const response = await request.get("/facturacion/reglas/fechasFeriadas/GetAll", data);

            if (response) {

                if (response.error) {

                    cogoToast.error(response.message, {
                        position: "bottom-right",
                    });
                };

                if (response.empty) {
                    setDiasFeriados([]);
                };

                if (response.dias_feriados) {

                    console.log(response.dias_feriados);

                    let arrayDiasFeriados = [];

                    response.dias_feriados.map(row => {
                        arrayDiasFeriados = [...arrayDiasFeriados, { fecha: row.dia_feriado, descripcion: row.descripcion, id_dia_feriado: row.id_dia_feriado }];
                    });

                    setDiasFeriados(arrayDiasFeriados);
                };
            };

            setLoadingData(false);

        } catch (error) {
            console.log(error);
        };
    };

    async function removeDiaFeriado(item) {

        try {

            setLoadRemoving(item.id_dia_feriado);

            let user = await context.loadUser();

            if (user) {

                const data = {
                    id_dia_feriado: item.id_dia_feriado,
                    usuario: user.id.nombre
                };

                const response = await request.post("/facturacion/reglas/fechasFeriadas/delete", data);

                if (response) {

                    if (response.error) {
                        cogoToast.error(response.message, {
                            position: "bottom-right",
                        });
                    };

                    if (response.deleted) {

                        cogoToast.success("Fecha feriada eliminada correctamente.", {
                            position: "bottom-right"
                        });

                        await getDiasFeriados();
                    };
                };
            };

            setLoadRemoving(null);

        } catch (error) {
            console.log(error);
            setLoadRemoving(null);
        };
    };

    return (
        <div className="card-table card-owners column" id="card-owners">
            <div className="column">
                <div className="align-center row full">
                    <div className="row full">
                        <form
                            className="width-45"
                            onSubmit={addDiaFeriado.bind(this)}
                        >
                            <div className="column">
                                <p>Día feriado:</p>
                                <input className="input" type="date" name="fecha" required />
                            </div>
                            <div className="white-space-8"></div>
                            <div className="column">
                                <p>Descripción: </p>
                                <input type="text" className="input" name="descripcion" required />
                            </div>
                            <div className="white-space-8"></div>
                            <div>
                                <button className="btn btn-primary color-white full" type="submit">
                                    {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Agregar'}
                                </button>
                            </div>
                        </form>
                        <div className="dias-feriados full table-responsiv column">
                            <table>
                                <thead className="sticky-th">
                                    <tr>
                                        <th className="text-left">Día feriado</th>
                                        <th className="text-left">Descripción</th>
                                        <th className="text-center">Quitar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingData ?
                                        <tr>
                                            <td colSpan="3">
                                                <div className="white-space-8"></div>
                                                <div className="justify-center">
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </div>
                                                <div className="white-space-8"></div>
                                            </td>
                                        </tr> :
                                        diasFeriados.length !== 0 ? diasFeriados.map((item, i) => {
                                            return <tr key={i}>
                                                <td>{moment(item.fecha).format("DD/MM/YYYY")}</td>
                                                <td>{item.descripcion}</td>
                                                <td
                                                    className="text-center">
                                                    {loadRemoving === item.id_dia_feriado
                                                        ? <i className="fas fa-spinner fa-spin"></i>
                                                        : <i className="fas fa-times-circle color-red cursor-pointer space-4"
                                                            onClick={removeDiaFeriado.bind(this, item)}>
                                                        </i>}
                                                </td>
                                            </tr>
                                        }) :
                                            <tr>
                                                <td colSpan="3">
                                                    <div className="justify-center">
                                                        <h4>No se encontraron resultados.</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Consumer(HolidayDates);