import React, { Component } from 'react';
import moment from 'moment-timezone'

class ReservationHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    getMoneyFormat(number) {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number)
    };

    render() {
        let reservas = []

        if (this.props.reservas) {
            reservas = this.props.reservas;
        };

        return (
            <div>
                <div className="table-responsiv column">
                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">
                                    <div className="row">
                                        Área reservada &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">
                                    <div className="row">
                                        Fecha &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'fecha_reserva' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fecha_reserva')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fecha_reserva')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fecha_reserva')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="row">
                                        Vivienda &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="row">
                                        Horario &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'descripcion' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-left">Cancelada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservas.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength).map((reserva, key) => (
                                <tr key={key}>
                                    <td>{reserva.nombre}</td>
                                    <td>{moment(reserva.fecha_reserva).tz('GMT').format("DD/MM/YYYY")}</td>
                                    <td>{reserva.numero_registro}</td>
                                    <td>{reserva.descripcion}</td>
                                    <td className="text-center">
                                        <label className="container-check" >
                                            <input
                                                type="checkbox"
                                                checked={reserva.cancelada ? true : false}
                                                readOnly
                                                disabled />
                                            <span className="checkmark cursor-default " ></span>
                                        </label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ReservationHistoryTable;