import React, { Component } from "react";
import cogoToast from "cogo-toast";
import NumberFormat from "react-number-format";

import Request from "../../core/httpClient";
import ExitConfigurations from './ExitConfiguration';

const request = new Request();

class EnvironmentTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disabled: true,
			edditNetPay: false,
			edditClip: false,
			disabledNetPay: true,
			disabledClip: true,
			openpayId: "",
			openpayLlavePrivada: "",
			openpayLlavePublica: "",
			clipLlaveApi: "",
			clipLlaveSecreta: "",
			clipComision: 0,
			clipIvaComision: 0,
			appmospheraLlaveApi: "",
			appmospheraLlaveSecreta: "",
			frecuentesValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
			id_config: this.props.id_config,
			t_invitaciones1: this.props.t_invitaciones1,
			t_invitaciones2: this.props.t_invitaciones2,
			foto: this.props.foto,
			comentario: this.props.comentario,
			num_invitados: this.props.num_invitados,
			num_empleados: this.props.num_empleados,
			num_vacacionales: this.props.num_vacacionales,
			vigencia: this.props.vigencia,
			user: this.props.user,
			salida_automatica: this.props.salida_automatica,
			desactivar_notificaciones: this.props.desactivar_notificaciones,
			hora_desactivacion: this.props.hora_desactivacion,
			hora_activacion: this.props.hora_activacion,
		};
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.id_config != this.props.id_config) {
			await this.setState({ id_config: this.props.id_config });
		}
		if (prevProps.t_invitaciones1 != this.props.t_invitaciones1) {
			await this.setState({ t_invitaciones1: this.props.t_invitaciones1 });
		}
		if (prevProps.t_invitaciones2 != this.props.t_invitaciones2) {
			await this.setState({ t_invitaciones2: this.props.t_invitaciones2 });
		}
		if (prevProps.comentario != this.props.comentario) {
			await this.setState({ comentario: this.props.comentario });
		}
		if (prevProps.num_invitados != this.props.num_invitados) {
			await this.setState({ num_invitados: this.props.num_invitados });
		}
		if (prevProps.num_empleados != this.props.num_empleados) {
			await this.setState({ num_empleados: this.props.num_empleados });
		}
		if (prevProps.num_vacacionales != this.props.num_vacacionales) {
			await this.setState({ num_vacacionales: this.props.num_vacacionales });
		}
		if (prevProps.foto != this.props.foto) {
			await this.setState({ foto: this.props.foto });
		}
		if (prevProps.vigencia != this.props.vigencia) {
			await this.setState({ vigencia: this.props.vigencia });
		}
		if (prevProps.user != this.props.user) {
			await this.setState({ user: this.props.user });
		}
		if (prevProps.openpayId != this.props.openpayId) {
			await this.setState({ openpayId: this.props.openpayId });
		}
		if (prevProps.openpayLlavePrivada != this.props.openpayLlavePrivada) {
			await this.setState({ openpayLlavePrivada: this.props.openpayLlavePrivada });
		}
		if (prevProps.openpayLlavePublica != this.props.openpayLlavePublica) {
			await this.setState({ openpayLlavePublica: this.props.openpayLlavePublica });
		}
		if (prevProps.clipLlaveApi != this.props.clipLlaveApi) {
			await this.setState({ clipLlaveApi: this.props.clipLlaveApi });
		}
		if (prevProps.clipLlaveSecreta != this.props.clipLlaveSecreta) {
			await this.setState({ clipLlaveSecreta: this.props.clipLlaveSecreta });
		}
		if (prevProps.clipComision != this.props.clipComision) {
			await this.setState({ clipComision: this.props.clipComision });
		}
		if (prevProps.clipIvaComision != this.props.clipIvaComision) {
			await this.setState({ clipIvaComision: this.props.clipIvaComision });
		}
		if (prevProps.salida_automatica !== this.props.salida_automatica) {
			await this.setState({ salida_automatica: this.props.salida_automatica });
		}
		if (prevProps.desactivar_notificaciones !== this.props.desactivar_notificaciones) {
			await this.setState({ desactivar_notificaciones: this.props.desactivar_notificaciones });
		}
		if (prevProps.hora_desactivacion !== this.props.hora_desactivacion) {
			await this.setState({ hora_desactivacion: this.props.hora_desactivacion });
		}
		if (prevProps.hora_activacion !== this.props.hora_activacion) {
			await this.setState({ hora_activacion: this.props.hora_activacion });
		}
		if (prevProps.appmospheraLlaveApi != this.props.appmospheraLlaveApi) {
			await this.setState({ appmospheraLlaveApi: this.props.appmospheraLlaveApi });
		}
		if (prevProps.appmospheraLlaveSecreta != this.props.appmospheraLlaveSecreta) {
			await this.setState({ appmospheraLlaveSecreta: this.props.appmospheraLlaveSecreta });
		}
	}

	handleEddit() {
		this.setState({

			disabled: !this.state.disabled,

			edditNetPay: false,
			disabledNetPay: true,
			edditClip: false,
			disabledClip: true,

			id_config: this.props.id_config,
			t_invitaciones1: this.props.t_invitaciones1,
			t_invitaciones2: this.props.t_invitaciones2,
			foto: this.props.foto,
			comentario: this.props.comentario,
			num_invitados: this.props.num_invitados,
			num_empleados: this.props.num_empleados,
			num_vacacionales: this.props.num_vacacionales,
			vigencia: this.props.vigencia,
		});
	}

	handleEdditOpen() {
		this.setState({
			edditNetPay: !this.state.edditNetPay,
			disabledNetPay: !this.state.disabledNetPay,
			edditClip: false,
			disabledClip: true,
			disabled: true,
		});
	}

	handleEdditOpenClip() {
		this.setState({
			edditClip: !this.state.edditClip,
			disabledClip: !this.state.disabledClip,
			edditNetPay: false,
			disabledNetPay: true,
			disabled: true,
		});
	}

	setOptionComentary(event) {
		this.setState({ comentario: event.target.checked });
	}

	setOptionPhoto(event) {
		this.setState({ foto: event.target.checked });
	}

	validar() {
		if (this.props.IdConfig === 0) {
			cogoToast.warn("Este fraccionamiento no cuenta una configuración inicial, favor de actualizarla antes de cambiar los datos de NetPay.", {
				position: "bottom-right",
			});

			//alert("Este fraccionamiento no cuenta una configuración inicial, favor de actualizarla antes de cambiar los datos de OpenPay")
			this.setState({
				// openpayId: "",
				// openpayLlavePublica: "",
				// openpayLlavePrivada: "",
				edditNetPay: false,
				disabledNetPay: true,
			});
		}
		this.actualizarOpenpay();
	}

	async actualizarOpenpay() {
		let data = {
			id: this.state.id_config,
			openpayId: this.state.openpayId,
			openpayLlavePublica: this.state.openpayLlavePublica,
			openpayLlavePrivada: this.state.openpayLlavePrivada,
			idUsuario: this.props.id,
		};
		const response = await request.post("/environments/update/openpay", data);
		if (response && !response.error) {
			if (response.updated && !response.empty) {
				this.setState({
					edditNetPay: false,
					disabledNetPay: true,
				});
				cogoToast.success("Datos NetPay actualizados.", {
					position: "bottom-right",
				});
			}
		} else {
			this.setState({
				openpayId: "",
				openpayLlavePublica: "",
				openpayLlavePrivada: "",
				error: true,
				message: response.message,
			});
			cogoToast.error("Error al actualizar datos NetPay, inténtalo nuevamente.", {
				position: "bottom-right",
			});
		}
	}

	validarClip() {

		if (this.props.IdConfig === 0) {

			cogoToast.warn("Este fraccionamiento no cuenta una configuración inicial, favor de actualizarla antes de cambiar los datos de Clip.", {
				position: "bottom-right",
			});

			this.setState({
				edditClip: false,
				disabledClip: true,
			});
		};

		this.actualizarClip();
	}

	async actualizarClip() {

		let data = {
			id: this.state.id_config,
			clipLlaveApi: this.state.clipLlaveApi,
			clipLlaveSecreta: this.state.clipLlaveSecreta,
			clipComision: this.state.clipComision,
			clipIvaComision: this.state.clipIvaComision,
			idUsuario: this.props.id,
		};

		const response = await request.post("/environments/update/clip", data);

		if (response && !response.error) {

			if (response.updated && !response.empty) {

				this.setState({
					edditClip: false,
					disabledClip: true,
				});

				cogoToast.success("Datos de Clip actualizados.", {
					position: "bottom-right",
				});
			}

		} else {

			this.setState({
				clipLlaveApi: "",
				clipLlaveSecreta: "",
				clipComision: 0,
				clipIvaComision: 0,
				error: true,
				message: response.message,
			});

			cogoToast.error("Error al actualizar datos Clip, inténtalo nuevamente.", {
				position: "bottom-right",
			});
		}
	}

	async handleSubmit() {
		let observable = JSON.parse(localStorage.getItem("frac"));

		let data = {
			IdConfig: this.state.id_config,
			IdFraccionamiento: parseInt(observable),
			TolInvitaciones: this.state.t_invitaciones1,
			Registros: this.state.t_invitaciones2,
			Comentarios: this.state.comentario ? 1 : 0,
			Foto: this.state.foto ? 1 : 0,
			InvVip: parseInt(this.state.num_invitados),
			IdUuario: this.state.user.idUsuario,
			numEmpleados: parseInt(this.state.num_empleados),
			numVacacionales: parseInt(this.state.num_vacacionales),
			vigencia: this.state.vigencia,
		};

		const response = await request.post("/environments/save/configuration", data);

		if (response && !response.error) {
			if (response.created && !response.empty) {
				cogoToast.success("Configuración actualizada correctamente.", {
					position: "bottom-right",
				});
			}
		} else {
			cogoToast.warn("No se pudo actualizar configuración.", {
				position: "bottom-right",
			});
		}

		this.props.getConfig();

		this.setState({
			disabled: true,
		});
	}

	render() {
		return (
			<div>
				<div className="card-table card-owners column" id="card-owners">
					<form className="column align-center" onSubmit={this.handleSubmit.bind(this)}>
						<div className="white-space-24"></div>
						<div className="container align-center column justify-center">
							<div className="info-content column">
								<div className="edit-button justify-start">
									<h3>Invitaciones</h3>
									<div className="justify-end">
										<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.handleEddit.bind(this)} disabled={!this.props.escritura}>
											<i className="fas fa-pen"></i> Editar
										</button>
									</div>
								</div>
								<div className="">
									<div className="configuration-rows invitations row">
										<div className="justify-start">
											<h4>Primera tolerancia</h4>
										</div>
										<div className="justify-start">
											<select
												name=""
												id=""
												className="description input-select-configuration"
												disabled={this.state.disabled}
												value={this.state.t_invitaciones1}
												onChange={(event) => {
													this.setState({ t_invitaciones1: event.target.value });
												}}
											>
												<option value="1">1 hr</option>
												<option value="2">2 hr</option>
												<option value="3">3 hr</option>
												<option value="4">4 hr</option>
												<option value="5">5 hr</option>
												<option value="6">6 hr</option>
												<option value="7">7 hr</option>
												<option value="8">8 hr</option>
												<option value="9">9 hr</option>
												<option value="10">10 hr</option>
												<option value="11">11 hr</option>
												<option value="12">12 hr</option>
											</select>
										</div>
									</div>
									<div className="configuration-rows subsecuentes row">
										<div className="justify-start">
											<h4>Segunda tolerancia</h4>
										</div>
										<div className="justify-start">
											<select
												name=""
												id=""
												className="description input-select-configuration"
												disabled={this.state.disabled}
												value={this.state.t_invitaciones2}
												onChange={(event) => {
													this.setState({ t_invitaciones2: event.target.value });
												}}
											>
												<option value="1">1 hr</option>
												<option value="2">2 hr</option>
												<option value="3">3 hr</option>
												<option value="4">4 hr</option>
												<option value="5">5 hr</option>
												<option value="6">6 hr</option>
												<option value="7">7 hr</option>
												<option value="8">8 hr</option>
												<option value="9">9 hr</option>
												<option value="10">10 hr</option>
												<option value="11">11 hr</option>
												<option value="12">12 hr</option>
											</select>
										</div>
									</div>
								</div>
								<div className="white-space-32"></div>
								<div className="edit-button justify-start">
									<h3>Invitados frecuentes</h3>
								</div>
								<div className="">
									<div className="configuration-rows personal row">
										<div className="justify-start">
											<h4>Número de personal</h4>
										</div>
										<div className="justify-start">
											<select
												name=""
												id=""
												className="description input-select-configuration"
												disabled={this.state.disabled}
												value={this.state.num_empleados}
												onChange={(event) => {
													this.setState({ num_empleados: event.target.value });
												}}
											>
												{this.state.frecuentesValues.map((ii) => {
													return <option value={ii}>{ii}</option>;
												})}
											</select>
										</div>
									</div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Número de familiares</h4>
										</div>
										<div className="justify-start">
											<select
												name=""
												id=""
												className="description input-select-configuration"
												disabled={this.state.disabled}
												value={this.state.num_invitados}
												onChange={(event) => {
													this.setState({ num_invitados: event.target.value });
												}}
											>
												{this.state.frecuentesValues.map((ii) => {
													return <option value={ii}>{ii}</option>;
												})}
											</select>
										</div>
									</div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Número de vacacionales</h4>
										</div>
										<div className="justify-start">
											<select
												name=""
												id=""
												className="description input-select-configuration"
												disabled={this.state.disabled}
												value={this.state.num_vacacionales}
												onChange={(event) => {
													this.setState({ num_vacacionales: event.target.value });
												}}
											>
												{this.state.frecuentesValues.map((ii) => {
													return <option value={ii}>{ii}</option>;
												})}
											</select>
										</div>
									</div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Vigencia (en días):</h4>
										</div>
										<div className="justify-start">
											<input
												className="input  input-select-configuration "
												type="number"
												min={0}
												maxLength={3}
												max={999}
												value={this.state.vigencia}
												onChange={(event) => {
													this.setState({ vigencia: event.target.value });
												}}
												disabled={this.state.disabled}
											/>
										</div>
									</div>
								</div>
								<div className="white-space-32"></div>
								<div className="edit-button justify-start">
									<h3>Control seguridad</h3>
								</div>
								<div className="configuration-rows commments row">
									<div className="justify-start">
										<h4>Obligatorio comentarios</h4>
									</div>
									<div className="justify-start align-center">
										<label className="container-check">
											<input
												type="checkbox"
												name=""
												id=""
												disabled={this.state.disabled}
												checked={this.state.comentario}
												onChange={(event) => this.setState({ comentario: event.target.checked == 1 ? true : false })}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
								<div className="configuration-rows photo row">
									<div className="justify-start">
										<h4>Obligatorio fotografía al entrar</h4>
									</div>
									<div className="justify-start align-end">
										<label className="container-check">
											<input
												type="checkbox"
												name=""
												id=""
												disabled={this.state.disabled}
												checked={this.state.foto}
												onChange={(event) => this.setState({ foto: event.target.checked == 1 ? true : false })}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
								<div className="white-space-24"></div>
								{this.state.disabled ? (
									<>
										<div className="white-space-32"></div>
										<div className="white-space-4"></div>
									</>
								) : (
									<div className="btn-container justify-end">
										<button type="button" className="btn btn-primary color-white" onClick={this.handleSubmit.bind(this)}>
											<i className="fas fa-check" />
											&nbsp; ACTUALIZAR
										</button>
										<button type="button" className="btn btn-secondary color-white" onClick={this.handleEddit.bind(this)}>
											<i className="fas fa-times" />
											&nbsp; CANCELAR
										</button>
									</div>
								)}
								<div className="white-space-24"></div>
								{/*SALIDAS Y NOTIFICACIONES */}
								<ExitConfigurations
									escritura={this.props.escritura}
									id_config={this.props.id_config}
									id_usuario={this.props.id}
									salida_automatica={this.state.salida_automatica}
									desactivar_notificaciones={this.state.desactivar_notificaciones}
									hora_desactivacion={this.state.hora_desactivacion}
									hora_activacion={this.state.hora_activacion}
								/>
								<div className="white-space-24"></div>

								<div className="column">
									<div className="edit-button justify-end">
										<div className="justify-start">
											<h3>Datos NETPAY</h3>
										</div>
										<div className="justify-end">
											<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.handleEdditOpen.bind(this)} disabled={!this.props.escritura}>
												<i className="fas fa-pen"></i> Editar
											</button>
										</div>
									</div>
									<div className="white-space-16"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>ID:</h4>
										</div>
										<div className="justify-start">
											<input
												className="input input-modals input-large text-center"
												type="text"
												value={this.state.openpayId}
												onChange={(event) => this.setState({ openpayId: event.target.value })}
												placeholder="Id"
												disabled={this.state.disabledNetPay}
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Llave pública:</h4>
										</div>
										<div className="justify-start">
											<input
												type="text"
												placeholder="Llave pública"
												value={this.state.openpayLlavePublica}
												onChange={(event) => this.setState({ openpayLlavePublica: event.target.value })}
												className="input input-modals input-large text-center"
												disabled={this.state.disabledNetPay}
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Llave privada:</h4>
										</div>
										<div className="justify-start">
											<input
												type="text"
												className="input input-modals input-large text-center"
												value={this.state.openpayLlavePrivada}
												onChange={(event) => this.setState({ openpayLlavePrivada: event.target.value })}
												placeholder="Llave privada"
												disabled={this.state.disabledNetPay}
											/>
										</div>
									</div>
									<div className="white-space-8"></div>

									<div className="white-space-48"></div>
									{this.state.edditNetPay ? (
										<div className="btn-container justify-end">
											<button type="button" className="btn btn-primary color-white" onClick={this.validar.bind(this)}>
												<i className="fas fa-check" />
												&nbsp; ACTUALIZAR
											</button>
											<button type="button" className="btn btn-secondary color-white" onClick={this.handleEdditOpen.bind(this)}>
												<i className="fas fa-times" />
												&nbsp; CANCELAR
											</button>
										</div>
									) : (
										<>
											<div className="white-space-32"></div>
											<div className="white-space-4"></div>
										</>
									)}
								</div>
								<div className="white-space-24"></div>

								<div className="column">
									<div className="edit-button justify-end">
										<div className="justify-start">
											<h3>Datos CLIP</h3>
										</div>
										<div className="justify-end">
											<button
												className="btn btn-mini btn-primary color-white"
												type="button"
												id="btn-modal-edit"
												onClick={this.handleEdditOpenClip.bind(this)}
												disabled={!this.props.escritura}
											>
												<i className="fas fa-pen"></i> Editar
											</button>
										</div>
									</div>
									<div className="white-space-16"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Llave api:</h4>
										</div>
										<div className="justify-start">
											<input
												type="text"
												placeholder="Llave api"
												value={this.state.clipLlaveApi}
												onChange={(event) => this.setState({ clipLlaveApi: event.target.value })}
												className="input input-modals input-large text-center"
												disabled={this.state.disabledClip}
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Llave secreta:</h4>
										</div>
										<div className="justify-start">
											<input
												type="text"
												className="input input-modals input-large text-center"
												value={this.state.clipLlaveSecreta}
												onChange={(event) => this.setState({ clipLlaveSecreta: event.target.value })}
												placeholder="Llave secreta"
												disabled={this.state.disabledClip}
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Comisión por transacción:</h4>
										</div>
										<div className="justify-start">
											<div className="column" style={{ justifyContent: 'center', paddingRight: '5px' }}											>
												<h5 className="color-black">%</h5>
											</div>
											<NumberFormat
												className="input input-select-configuration text-right"
												value={this.state.clipComision}
												onChange={(event) => { this.setState({ clipComision: event.target.value }); }}
												displayType={"number"}
												thousandSeparator={true}
												decimalScale={2}
												fixedDecimalScale={true}
												disabled={this.state.disabledClip}
												isAllowed={(values) => {
													const { formattedValue, floatValue } = values;
													return formattedValue === "" || floatValue <= 99.99;
												}}
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>IVA de la comisión:</h4>
										</div>
										<div className="justify-start">
											<div className="column" style={{ justifyContent: 'center', paddingRight: '5px' }}											>
												<h5 className="color-black">%</h5>
											</div>
											<NumberFormat
												className="input input-select-configuration text-right"
												value={this.state.clipIvaComision}
												onChange={(event) => { this.setState({ clipIvaComision: event.target.value }); }}
												displayType={"number"}
												thousandSeparator={true}
												decimalScale={2}
												fixedDecimalScale={true}
												disabled={this.state.disabledClip}
												isAllowed={(values) => {
													const { formattedValue, floatValue } = values;
													return formattedValue === "" || floatValue <= 99.99;
												}}
											/>
										</div>
									</div>

									<div className="white-space-48"></div>
									{this.state.edditClip ? (
										<div className="btn-container justify-end">
											<button
												type="button"
												className="btn btn-primary color-white"
												onClick={this.validarClip.bind(this)}
											>
												<i className="fas fa-check" />
												&nbsp; ACTUALIZAR
											</button>
											<button
												type="button"
												className="btn btn-secondary color-white"
												onClick={this.handleEdditOpenClip.bind(this)}
											>
												<i className="fas fa-times" />
												&nbsp; CANCELAR
											</button>
										</div>
									) : (
										<>
											<div className="white-space-32"></div>
											<div className="white-space-4"></div>
										</>
									)}
								</div>

								<div className="column">
									<div className="edit-button justify-end">
										<div className="justify-start">
											<h3>Datos Desarrollador</h3>
										</div>
									</div>
									<div className="white-space-16"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Llave api:</h4>
										</div>
										<div className="justify-start">
											<input
												type="text"
												placeholder="Llave api"
												value={this.state.appmospheraLlaveApi}
												className="input input-modals input-large text-center"
												disabled
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="configuration-rows vip row">
										<div className="justify-start">
											<h4>Llave secreta:</h4>
										</div>
										<div className="justify-start">
											<input
												type="text"
												className="input input-modals input-large text-center"
												value={this.state.appmospheraLlaveSecreta}
												placeholder="Llave secreta"
												disabled
											/>
										</div>
									</div>
								</div>

							</div>

							<div className="white-space-8"></div>
						</div>
						<div className="white-space-24"></div>
					</form>
				</div>
			</div>
		);
	}
}

export default EnvironmentTable;
