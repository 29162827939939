import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from "moment";
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import FormNewOwner from "../propietarios/formNewOwner";
import FormEditOwner from "../propietarios/formEditOwner";
import { isValidPhoneNumber } from 'react-phone-number-input';
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

class PropietarioModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: 				false,
			startDate: 				new Date(),
			propietarios: 			[],
			propietario:			{},
			idViv: 					0,
			add: 					false,
			edditProp: 				false,
			search: 				false,
			pNombre: 				'',
			pDireccion: 			'',
			pMunicipio: 			'',
			pEstado: 				'',
			pCp: 					'',
			pTelefono: 				'',
			pPais: 					'',
			pGenero: 				0,
			pId: 					0,
			pNumId: 				'',
			pVigencia: 				'',
			pFechaCompra: 			'',
			pIdPersona: 			0,
			isChecked: 				false,
			isPrincipal: 			false,
			viewOwners: 			true,
			email:					'',
			id: 					0,
            subfraccionamientos:	this.props.subfraccionamientos,
            phoneValidated:         false,
            showNewModal:           false,
			comentarios: '',
			direction: {
				nombre: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			subLabel: ''
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.getPropietariosVivienda();
		}
	}

	openModal(idModal, prop) {
		this.setState({message: null, propietario: prop});
		
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
            //Refresca datos
            this.setState({showNewModal: true});
		}
		switch(idModal){
			case 'addOwner':
					document.getElementById('ownername').focus();
				break;
			case 'editOwner':
					document.getElementById('editownername').focus();
				break;
		}
		
	}

	delete = id => {
		this.handleDelete(id);
	}

	propietario = data => {
		this.setState({
			pNombre: 			data.nombre,
			pDireccion: 		data.direccion,
			pMunicipio: 		data.municipio,
			pEstado: 			data.estado,
			pCp: 				data.cp,
			pTelefono: 			data.telefono,
			pPais: 				data.pais,
			pGenero: 			data.genero,
			pId: 				parseInt(data.tipo_identificacion),
			pNumId: 			data.num_identificacion,
			pVigencia: 			data.vigencia_identificacion,
			pFechaCompra: 		moment(data.fecha_compra).format("DD-MM-YYYY"),
			pIdPersona: 		data.id_persona,
			email: 				data.email,
			pDireccion:			this.props.direccion
		});
	}

	componentDidMount() {
		this.getPropietariosVivienda();
		this.getSubLabel();
	}

	getSubLabel() {
		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	setOwnersInfo(state) {
		this.setState({
			IdViv: 				this.props.id,
			pNombre: 			state.pNombre,
			pDireccion: 		state.pDireccion,
			pMunicipio: 		state.pMunicipio,
			pEstado: 			state.pEstado,
			pCp: 				state.pCp,
			pTelefono: 			state.pTelefono,
			pPais: 				state.pPais,
			pGenero: 			state.pGenero,
			pId: 				state.pId,
			pNumId: 			state.pNumId,
			pVigencia: 			state.pVigencia || '',
			pFechaCompra: 		state.pFechaCompra,
			idPersona: 			state.pIdPersona,
			principal: 			state.isPrincipal,
			email: 				state.email,
			comentarios:        state.comentarios
		});
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.propietarios, this.state.direction[key]);

		this.setState({
			propietarios: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

  	render() {
		let id = 0
		if (this.props.id) {
			id = this.props.id;
		}
    	return (
			<div className="full row align-center">
				<div className="column full">
					{/*<div className="">
						<div className="justify-end">
							{this.props.escritura && this.props.total_propietarios < 1 &&
							<button className="btn btn-mini btn-primary color-white" 
								type		=	"button" 
								id			=	"btn-modal-edit" 
								onClick		=	{this.openModal.bind(this,"addOwner",id)} 
								disabled	=	{!this.props.escritura}
							>
								<i className="fas fa-plus"/> Añadir
							</button>
							}
						</div>
					</div> */}
					<div className="column card-table">
						<div className="table-responsiv column">
							<table>
								<tbody>
									<tr className="pad-bot row-without-border">
										<th colSpan="3" className="text-left">
											<h4 className="weight-semi">Propietarios</h4>
										</th>
										{this.props.escritura && this.props.total_propietarios < 1 && <th>
											<button className="btn btn-mini btn-primary color-white"
												type="button"
												id="btn-modal-edit"
												onClick={this.openModal.bind(this, "addOwner", id)}
												disabled={!this.props.escritura}
											>
												<i className="fas fa-plus" /> Añadir
											</button>
										</th>}
									</tr>
									<tr className="text-left">
										<th className="">Nombre</th>
										<th className="text-left">Teléfono</th>
										<th className="text-center th-options-small">Editar</th>
										<th className="text-center th-options-small">Borrar</th>
									</tr>
								{	this.state.loadingPropietarios? 
									<tr>
										<td colSpan="4">
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									this.state.propietarios.map((propietario, key) => (
									<tr key={key}>
										<td className="text-left">
												{propietario.nombre}
										</td>
										<td className="text-left">{this.props.getNumberFormat(propietario.telefono)}</td>
										
										<td className="text-center ">
											<div className="aling-center justify-center">
												<button type="button" className="btn-full justify-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this, 'editOwner', propietario)}>
													<i className="font-small fas fa-pen" />
												</button>
											</div>
										</td>
										<td className="text-center">
											<div className="aling-center justify-center">
												<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type		=	'button' 
														onClick		=	{this.openModalDelete.bind(this, propietario)} 
														style		=	{{ padding: '5px' }} 
														disabled	=	{!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
												</button>
											</div>
										</td>    
									</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				{/* botones para enviar el formulario */}
				</div>

			<ModalBorrar 
				icono	=	{true}
				id		=	{this.state.id}
				delete	=	{this.delete.bind(this, this.state.id)} 
			/>

			<DinamicModal 
				idModal				=	{'addOwner'}
				sizeModalContainer	=	{'big'}
				title				=	{'Añadir propietarios asociados a la vivienda'} 
				success				=	{this.handleSubmit.bind(this)} 
				showBtnSuccess		=	{true}
				btnTextSuccess		=	{"GUARDAR"}
				showBtnCancel		=	{true}
				btnTextCancel		=	{"CANCELAR"}
				loadingSave			=	{this.state.loadingSave}
				message				=	{this.state.message}
				disabledBtnSuccess  =   {!this.state.phoneValidated}
                closeModal          =   {() => this.setState({showNewModal: false})}>
				<FormNewOwner 
					setInfo			=	{this.setOwnersInfo.bind(this)} 
					editVivienda	=	{this.props.editVivienda} 
					direccion		=	{this.props.direccion}
					idVivienda		=	{this.props.id}
					subname  		= 	{this.props.subname}
                    validPhone      =   {(valid) => this.setState({phoneValidated: valid})}
                    showNewModal    =   {this.state.showNewModal}
				/>
			</DinamicModal>

			<DinamicModal
				idModal				=	{'editOwner'}
				sizeModalContainer	=	{'big'}
				title				=	{'Editar propietario'} 
				success				=	{this.handleUpdate.bind(this)} 
				showBtnSuccess		=	{this.props.escritura}
				btnTextSuccess		=	{"GUARDAR"}
				showBtnCancel		=	{true}
				btnTextCancel		=	{"CANCELAR"}
				loadingSave			=	{this.state.loadingSave}
                message				=	{this.state.message}
                closeModal          =   {() => this.setState({propietario: {}})}
				>
				<FormEditOwner 
					telefonoError	=	{this.state.telefonoError}
					setInfo			=	{this.setOwnersInfo.bind(this)} 
					propietario		=	{this.state.propietario}
                    subname  		= 	{this.props.subname}
					subLabel		= 	{this.state.subLabel}
				/>
			</DinamicModal>
      </div>
    );
  }

	openModalDelete(prop) {
		this.setState({id: prop.id_propietario});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	handleChecked(id) {
		this.makePrincipal(id);
	}

	showSearch() {
		this.setState({ search: !this.state.search });
	}

	makePrincipal(id) {
		let check = document.getElementById("check");
	}

	handleOpen(id) {
		this.setState({ showModal: !this.state.showModal, viewOwners: true });
		this.getPropietariosVivienda(id);
	}

	handleAddProp() {
		this.setState({ add: !this.state.add, edditProp: false, viewOwners: false });
		this.limpiarState();
	}

	handleCancel() {
		this.setState({ add: false, viewOwners: true });
		this.limpiarState();
	}

	limpiarState() {
		this.setState({
		pNombre: "",
		pDireccion: "",
		pMunicipio: "",
		pEstado: "",
		pCp: "",
		pTelefono: "",
		pPais: "",
		pGenero: 0,
		pId: 0,
		pNumId: "",
		pVigencia: 0,
		pFechaCompra: "",
		pIdPersona: 0,
		edditProp: false,
		search: false,
		isPrincipal: false,
		edditProp:false,
		email:''
		});
	}

	handleDateChange(date, event) {
		this.setState({ startDate: date });
	}

	handleChangeEddit(date) {
		this.setState({ pFechaCompra: document.getElementById("fecha").value });
	}

	handleClose() {
		this.setState({
		showModal: !this.state.showModal,
		pNombre: "",
		pDireccion: "",
		pMunicipio: "",
		pEstado: "",
		pCp: "",
		pTelefono: "",
		pPais: "",
		pGenero: 0,
		pId: 0,
		pNumId: "",
		pVigencia: 0,
		pFechaCompra: "",
		pIdPersona: 0,
		edditProp: false,
		search: false,
		viewOwners: true,
		add: false,
		email:''
		});
	}

	async handleDelete(id) {
		let data = {
			id_propietario: id,
			id_usuario_del: localStorage.getItem("user_id")
		};
		const response = await request.post("/viviendas/vivienda/delete/propietario", data);
		if(response.deleted) {
			let modal = document.getElementById("deleteModal");
			if(modal) {
				modal.classList.add('hide-modal');
				modal.classList.remove('show-modal');
			}
			cogoToast.success('Propietario eliminado.', {
				position: 'bottom-right',
			});
		} else {
			cogoToast.error('No se pudo eliminar propietario.', {
				position: 'bottom-right',
			});
		}
		this.getPropietariosVivienda();
		this.props.reload();
	}

	showSearch() {
		this.setState({ search: !this.state.search })
	}

	edditProp(idPersona) {
		this.setState({ edditProp: true, add: false, viewOwners: false });
		
		this.handlePropietarioDetalle(idPersona);
	}

  	// TRAE TODOS LOS PROPIETARIOS DE LA VIVIENDA

	async getPropietariosVivienda() {
		this.setState({loadingPropietarios: true});
    	let data = { idVivienda: this.props.id };

		const response = await request.post("/viviendas/vivienda/propietarios", data);
		

    	if (response && !response.error) {
			if (response.propietariosVivienda && !response.empty) {
				this.setState({
				propietarios: response.propietariosVivienda,
				isPrincipal: response.propietariosVivienda[0].principal
				});
				

			} else {
				this.setState({
				empty: true,
				message: response.message,
				propietarios: []
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({loadingPropietarios: false,loading: false});
	}

	/**CREA UN NUEVO PROPIETARIO */
	async handleSubmit(event) {
    	event.preventDefault();
		this.setState({loadingSave: true});
		let venta 		= document.getElementById('fventa').value;
		let city 		= document.getElementById("city").value;
		let resident 	= document.getElementById('residente').checked ? 1 : 0;
		let contact 	= 0;//document.getElementById('contacto').checked ? 1 : 0;
		
    	if (this.state.pIdPersona === 0) {
      		let data = {
				viviendasJSON:		JSON.stringify([parseInt(this.props.id)]),
				Nombre: 			this.state.pNombre,
				Direccion: 			this.state.pDireccion,
				Municipio: 			this.state.pMunicipio,
				Estado: 			this.state.pEstado,
				Cp: 				this.state.pCp,
				Telefono: 			this.state.pTelefono,
				Pais: 				this.state.pPais,
				Genero: 			this.state.pGenero,
				TipoId: 			this.state.pId,
				NumId: 				this.state.pNumId,
				Vigencia: 			this.state.pVigencia,
				FechaCompra: 		this.state.pFechaCompra,
				FechaVenta: 		venta,
				idPersona: 			this.state.idPersona,
				principal: 			this.state.isPrincipal,
				email:				this.state.email,
				idFraccionamiento: 	localStorage.getItem('frac'),
				city:				city,
				residente: 			resident,
				contacto:			contact,
				id_usuario_add :    localStorage.getItem("user_id"),
				comentarios:        this.state.comentarios
				
			  };
			  

			const response 		= await request.post("/propietarios/propietario/create", data);
			if(response.created){
				//this.setState({message: "SE HA AÑADIDO NUEVO PROPIETARIO CORRECTAMENTE"});
				this.getPropietariosVivienda();
				this.props.reload();
				cogoToast.success('Propietario añadido.', {
					position: 'bottom-right',
				});
			}else{
				//this.setState({message: "NO SE HA PODIDO AÑADIR NUEVO PROPIETARIO"});
				cogoToast.error('No se pudo añadir propietario.', {
					position: 'bottom-right',
				});
			}
			this.setState({loadingSave: false});
    	}
  	}

	/**ACTUALIZA UN PROPIETARIO */
	async handleUpdate(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
		let venta 	= document.getElementById('fventaedit').value;
		let city 	= document.getElementById('citye').value;  
		let data = {
			idVivienda: 		this.state.IdViv,
			Nombre: 			this.state.pNombre,
			Direccion: 			this.state.pDireccion,
			Municipio: 			this.state.pMunicipio,
			Estado: 			this.state.pEstado,
			cp: 				this.state.pCp,
			Telefono: 			this.state.pTelefono,
			Pais: 				this.state.pPais,
			Genero: 			this.state.pGenero,
			TipoId: 			this.state.pId,
			NumId: 				this.state.pNumId,
			Vigencia: 			this.state.pVigencia,
			FechaCompra: 		this.state.pFechaCompra,
			FechaVenta: 		venta ,
			Code: 				this.state.code,
			IdPersona: 			this.state.idPersona,
			email: 				this.state.email,
			idFraccionamiento: 	localStorage.getItem('frac') || 0,
			city:				city,
			id_usuario_upd :    localStorage.getItem("user_id"),
			comentarios: this.state.comentarios
		}
		const response = await request.post("/propietarios/update", data)
		if(response.updated){
			this.getPropietariosVivienda();
			this.props.reload();
			cogoToast.success('Datos de propietario actualizados.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({message: "NO SE PUDO EDITAR PROPIETARIO"});
			cogoToast.error('No se pudieron actualizar datos de propietario.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});
	}

  // MUESTRA LOS DATOS DE UN ÚNICO propietario EN FORMULARIO
  async handlePropietarioDetalle(idPersona) {

    let data = { idPersona: idPersona };

    const response = await request.post("/viviendas/vivienda/get/propietario", data);


    if (response && !response.error) {
      if (response.propietarioVivienda && !response.empty) {
        this.setState({
          pNombre: 			response.propietarioVivienda[0].nombre,
          pDireccion:		response.propietarioVivienda[0].direccion,
          pMunicipio: 		response.propietarioVivienda[0].municipio,
          pEstado: 			response.propietarioVivienda[0].estado,
          pCp: 				response.propietarioVivienda[0].cp,
          pTelefono: 		response.propietarioVivienda[0].telefono,
          pPais: 			response.propietarioVivienda[0].pais,
          pGenero: 			response.propietarioVivienda[0].genero,
          pId: 				response.propietarioVivienda[0].tipo_identificacion,
          pNumId: 			response.propietarioVivienda[0].num_identificacion,
          pVigencia: 		response.propietarioVivienda[0].vigencia_identificacion,
		  pFechaCompra: 	moment(response.propietarioVivienda[0].fecha_compra).format('MM-DD-YYYY'),
		  pFechaVenta:		moment(response.propietarioVivienda[0].fecha_venta).format('MM-DD-YYYY'),
          pIdPersona: 		response.propietarioVivienda[0].id_persona,
		  email: 			response.propietarioVivienda[0].email,
		  citye:			response.propietarioVivienda[0].ciudad,
		});
		
		
      } else {
        this.setState({ empty: true, message: response.message });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }

  async guardarPropietario() {
    let pPrincipal = this.state.isPrincipal
    if (pPrincipal === true) {
      pPrincipal = 1
    }
    if (pPrincipal === false || pPrincipal === null) {
      pPrincipal = 0
    }

    let data = {
      IdPersona: 	this.state.pIdPersona,
      Nombre: 		this.state.pNombre,
      Direccion: 	this.state.pDireccion,
      Municipio: 	this.state.pMunicipio,
      Estado: 		this.state.pEstado,
      cp: 			this.state.pCp,
      Telefono: 	this.state.pTelefono,
      Pais: 		this.state.pPais,
      Genero: 		this.state.pGenero,
      TipoId: 		this.state.pId,
      NumId: 		this.state.pNumId,
      Vigencia: 	this.state.pVigencia,
	  FechaCompra: 	this.state.pFechaCompra,
	  FechaVenta: 	this.state.pFechaVenta,
      principal: 	pPrincipal,
      IdViv: 		this.props.id,
      email:		''
	};
	
    const response = await request.post("/propietarios/update", data);
    

    if (response && !response.error) {
      if (response.updated && !response.empty) {
        this.setState({
          reload: true,
          loading: false,
          edditProp: false,
          viewOwners: true
        });


      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false
      });
    }
    this.getPropietariosVivienda(this.props.id)


  }

}
export default PropietarioModal;