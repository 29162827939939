import React from 'react';
import { Helmet } from "react-helmet";
import Header from '../components/header/header';
import ReactModal from 'react-modal'
import SidebarOperacion from '../components/header/sidebarOperacion';
import SearchHouse from "../components/propietarios/search-house";
import { Consumer } from "../context";
import ReservationTable from '../components/commonAreasReservation/reservationTable2'
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import moment from 'moment-timezone';
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import Core from './../components/core/SuperCore';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
/**Socket */
import Socket from '../core/socket';

registerLocale('es', es)

const request = new Request();


class CommonAreasReservation2 extends Core {

    constructor(props){
        super(props);
        this.state = {
            lectura:    false,
            escritura:  false,
            logo:		require ("../img/logo-AppMosphera-r.png"),
            filter:     '',

            reservas:                       [],
            subfraccionamientos:        [],
            id_subfraccionamiento:      0,
            id_unidad:                  0,
            unidades:                   [],
            areas:                      [],
            id_area:                    0,
            id_subfraccionamiento_traer_viviendas: localStorage.getItem('subfrac'),

            fecha:                      null,
            min_day:                    null,
            horarios:                   [],
            usuario:                    {},
            fianza:                     null,
			comentarios:                '',
			pageLength: 			20, 
			submenu: '',
			subLabel: '',
			personas: 1,
			fecha_reserva_creacion:     null 
        }
        this.validarPermisos();
    }

    async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura,lectura: permisosInfo.lectura});
	}

    setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

    async subChange(sub) {	
        await this.setState({id_subfraccionamiento: sub,id_subfraccionamiento_traer_viviendas: sub});
        await this.getNuevaReservacionInfo();
    }

    setPage(page) {
		this.setState({page});
	}

	async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
	}

    async getNuevaReservacionInfo(){
        await this.getReservas();
        await this.getSubfraccionamientos();
        await this.getUnidades();
        await this.getAreasComunes();
        await this.getHorarios(true);
	}

    async componentDidMount(){
		this.getSubmenu();
        await this.getNuevaReservacionInfo();

        // obtener info del usuario
		let user = await this.getUserInfo();
		await this.setState({usuario: user.info});
		
		Socket.on('newReservaAreaComun', (data) => {
			this.getReservas();
		});
    }

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuOpeName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}
    
    async componentDidUpdate(){

    }

    // RESERVAS

    async getReservas() {
		this.setState({loading: true});
        let data = {
            Id: 	parseInt(localStorage.getItem('frac') || 0),
			fecha: 	moment().format('YYYY-MM-DD'),
			IdSub: 	parseInt(localStorage.getItem('subfrac') || 0)
        };

        const response = await request.post("/commonArea/get/reservas", data);

        if(response.error || response.empty){
            await this.setState({reservas: [], loading: false});
            cogoToast.info('No se encontraron reservas.',{
				position: 'bottom-right',
            });
            return;
        }

        if(response.info){
            await this.setState({reservas: response.info});
			// console.log(response.info);
		}
		this.setState({loading: false});
    }

    async getSubfraccionamientos() {
        const fraccionamiento = localStorage.getItem('frac');
		const subfraccionamiento = localStorage.getItem('subfrac');
        if(!fraccionamiento) {
			return;
        }
        let data = { Idsub: fraccionamiento, filter:1 }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

        if(response.error || response.empty){
            await this.setState({subfraccionamientos: []});
            return;
        }

        if(response.subfraccionamiento){
            await this.setState({
                subfraccionamientos: response.subfraccionamiento,
				id_subfraccionamiento: subfraccionamiento
            });
        }

    }

    async changeSubfraccionamiento(event){
        let id_sub = event.target.value;
        await this.setState({unidades:[], areas:[], id_unidad: 0, id_area: 0, horarios:[], id_horario:0, fecha: null});
        await this.setState({
            id_subfraccionamiento:                  id_sub, 
            id_subfraccionamiento_traer_viviendas:  id_sub
        })
        await this.getUnidades();
        await this.getAreasComunes();
        await this.getHorarios();
    }

    async getUnidades(){
        await this.setState({unidades: [], id_unidad: 0});
        let data = {	
            id_fraccionamiento: 	localStorage.getItem('frac'),
            id_subfraccionamiento:  this.state.id_subfraccionamiento_traer_viviendas
        }
        const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);
        if(response.empty || response.error){
            await this.setState({unidades: []});
            cogoToast.info('No se encontraron unidades en este fraccionamiento.',{
				position: 'bottom-right',
            });
            return;
        }

        if(response.viviendas){
            await this.setState({
                unidades: response.viviendas,
                id_unidad:  response.viviendas[0].id_vivienda
            })
        }
	}

    async changeUnidad(event){
        let id_unidad =  event.target.value;
        await this.setState({id_unidad});
    }

    async getAreasComunes() {
        await this.setState({id_area: 0, areas:[]})
        let data = {
			id:         localStorage.getItem('frac'),
			idsubfrac:  this.state.id_subfraccionamiento
        }

        const response = await request.post("/commonArea/get/info", data);
        
        if(response.empty || response.error){
            await this.setState({areas: []});
            cogoToast.info('No se encontraron áreas comunes en este subfraccionamiento.',{
				position: 'bottom-right',
            });
        }

        if(response.info){
            let primer_area = response.info[0];
            await this.setState({
                areas:      response.info, 
                id_area:    primer_area.id_espacio
            });

            // DELIMITAR FECHAS
            this.delimitarSeleccionFechas(primer_area);
        }

    }

    async delimitarSeleccionFechas(areaSeleccionada){
        let min_day = moment();
        min_day = min_day.add(parseInt(areaSeleccionada.dias_anticipacion),'days');
        
        await this.setState({
            min_day:    min_day.toDate(), 
            fecha:      min_day.toDate()
        });
    }

    async changeAreaComun(event){

        let id_area = event.target.value;
		await this.setState({ fecha: null, horarios: [], id_horario: 0, aforo: false, maxAforo: null, maxReservasAforo: null });

        let area = this.state.areas.filter(area=> area.id_espacio == id_area);

        await this.setState({id_area});
        await this.delimitarSeleccionFechas(area[0]);
        await this.getHorarios();
        
    }

    async getHorarios(didmount){
        this.setState({ horarios: [],id_horario: 0 });

		let f = this.state.fecha;
		if (f) {
			let fecha = `${f.getFullYear()}`;
			//  si es menor a 10 agregar un cero para acompletar el formato de la fecha
			fecha += `-${f.getMonth()+1 < 10 ? `0${f.getMonth()+1}` : f.getMonth()+1}`;
			fecha += `-${f.getDate()}`;

			/*let data = {
				id: 		this.state.id_area,
				fecha: 		moment(`${fecha} ${moment().format('HH:mm:ss')}`).format('YYYY-MM-DD HH:mm:ss'),
				dif:		1
			}*/
			let data = {
				id_espacio: parseInt(this.state.id_area),
				fecha: moment(`${fecha} ${moment().format('HH:mm:ss')}`).format('YYYY-MM-DD HH:mm:ss'),
				id_vivienda: parseInt(this.state.id_unidad),
				fecha_actual: moment().format('YYYY-MM-DD HH:mm:ss')
			}
			
			const response = await request.post("/commonArea/get/available/aforo", data);
			/*if(response.error || response.empty){
				await this.setState({ horarios: [], aforo: false, maxAforo: null, maxReservasAforo: null });
				if(!didmount){
					cogoToast.info('No se encontraron horarios para el área común seleccionada',{
						position: 'bottom-right',
					});
				}
				return;
			}*/

			if(response.commonSchedules){
				// console.log("commonSchedules", response.commonSchedules);
				if (response.commonSchedules.length !== 0) {
					if (response.commonSchedules[0].error !== null) {
						await this.setState({ horarios: [], aforo: false, maxAforo: null, maxReservasAforo: null });
						if (!didmount) {
							cogoToast.info(response.commonSchedules[0].message, {
								position: 'bottom-right',
							});
						}
						return;
					} else {
						const aforo = response.commonSchedules[0].maxAforo !== 0 && response.commonSchedules[0].maxAforo !== null ? true : false;

						const maxReservasAforo = response.commonSchedules[0].maxReservasAforo;
						const disponible = response.commonSchedules[0].disponible;

						await this.setState({
							horarios: response.commonSchedules,
							id_horario: response.commonSchedules[0].id_horario,
							aforo: aforo,
							maxAforo: disponible,
							maxReservasAforo: aforo ? maxReservasAforo !== null ? (maxReservasAforo < disponible ? maxReservasAforo : disponible) : disponible : null
						});
					}
				} else {
					await this.setState({ horarios: [], aforo: false, maxAforo: null, maxReservasAforo: null });
					if (!didmount) {
						cogoToast.info('No se encontraron horarios para el área común seleccionada.', {
							position: 'bottom-right',
						});
					}
					return;
				}
			}
		}
    }

    async changeDate(fecha){
		await this.setState({ fecha, horarios: [], id_horario: 0, aforo: false, maxAforo: null, maxReservasAforo: null});
        await this.getHorarios();
	}

    async changeHorario(event){
		this.setState({
			aforo: false, 
			maxAforo: null, 
			maxReservasAforo: null 
		});

		const horario = this.state.horarios.filter(h => h.id_horario === parseInt(event.target.value));
		// console.log("Horario", horario);
		let aforo = false, maxReservasAforo = null, disponible = null;

		if (horario.length !== 0) {
			aforo = horario[0].maxAforo !== 0 && horario[0].maxAforo !== null ? true : false;

			maxReservasAforo = horario[0].maxReservasAforo;
			disponible = horario[0].disponible;
		}

        let id_horario = event.target.value;
        await this.setState({
			id_horario,
			aforo: aforo,
			maxAforo: disponible,
			maxReservasAforo: aforo ? maxReservasAforo !== null ? (maxReservasAforo < disponible ? maxReservasAforo : disponible) : disponible : null
		});
    }

    async handleSubmit(event){
        event.preventDefault();
        await this.setState({loadingSave: true, messageSave: ''});

        let validado = true;

        if(this.state.id_horario == null || this.state.id_horario == 0){
            cogoToast.warn('Debes elegir un horario',{
                position: 'bottom-right',
            });
            validado = false;
        }

        if(this.state.id_unidad == null || this.state.id_unidad == 0){
            cogoToast.warn('Debes elegir una unidad.',{
                position: 'bottom-right',
            });
            validado = false;
        }

        if(this.state.id_area == null || this.state.id_area == 0){
            cogoToast.warn('Debes elegir una unidad.',{
                position: 'bottom-right',
            });
            validado = false;
		}

        if(!validado){
            await this.setState({loadingSave: false});
            return;
        }


        let f = this.state.fecha;
        let fecha = `${f.getFullYear()}`;
        //  si es menor a 10 agregar un cero para acompletar el formato de la fecha
        fecha += `-${f.getMonth()+1 < 10 ? `0${f.getMonth()+1}` : f.getMonth()+1}`;
        fecha += `-${f.getDate()}`;

        let data = {
            idFracc:  		localStorage.getItem('frac'),
            idHorario:      parseInt(this.state.id_horario),
            area: 			parseInt(this.state.id_area),
            fecha: 			fecha,
            idviv:  		parseInt(this.state.id_unidad),
            idUsuario:  	parseInt(this.state.usuario.id_usuario),
            fianza: 		this.state.fianza,
            comentarios: 	this.state.comentarios,
			diferencia:		0, // es necesario ya que el sp funciona por rango de fechas
			fecha_local:	moment().format('YYYY-MM-DD HH:mm:ss'),
			personas: 		this.state.aforo ? this.state.personas : null,
        }


        const response = await request.post("/commonArea/save/reservation", data);

        if(response.error) {
			let msj = response.message || 'No se pudo agregar nueva reserva.';
			if (response.status) {
				if(response.status.status == -1) msj = 'El límite de reservas anual ha sido superado.';
				if(response.status.status == -2) msj = 'El límite de reservas consecutivas ha sido superado.';
				if(response.status.status == -3) msj = 'El horario del área seleccionada ya se encuentra reservado.';
				if(response.status.status == -4) msj = 'El límite de reservas por categoría se ha superado.';
				if(response.status.status == -5) msj = 'Esta vivienda ya cuenta con una reserva para este horario.';
				if(response.status.status == -6) msj = 'Ya no hay disponibilidad para este horario, por favor seleccione otro.';
			}

            cogoToast.error(msj, {
                position: 'bottom-right',
            });

            await this.setState({loadingSave: false});
            return;
        }

        if(response.created) {
            this.closeModal("newReservation");
            await this.clearData();
            cogoToast.success('Área reservada correctamente.', {
                position: 'bottom-right',
            });
            await this.getNuevaReservacionInfo();
        }

        await this.setState({loadingSave: false});
	}

    async clearData(){
        await this.setState({
            comentarios: '',
            fianza: null
        })
    }

	filter(CommonAreas) {
		CommonAreas = CommonAreas.filter(com => {
			if(com.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (com.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (com.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return CommonAreas;
	}

    render() {
        return this.renderReservationPage();
    }

    renderReservationPage() {
		let reservas = [];
		if (Array.isArray(this.state.reservas)) {
			reservas = this.filter(this.state.reservas);
		}
        return ( 
            <div className="houses column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header 
					sidebar		=	{true} 
					active		=	{'reservas'} 
					parent		=	{'areas'} 
					panel		=	{'panel3'} 
					nav			=	{"operacion"} 
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura?
								<React.Fragment>
								<Controls title={this.state.submenu}
									nuevo		=	{this.openModal.bind(this,"newReservation")}
									dataSet		=	{reservas}
									dateFormat 	= 	'dd/mm/yyyy'
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
									newButton	=	{this.state.escritura}
								/>
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<ReservationTable 
										reservas	=	{reservas} 
										page		=	{this.state.page} 
										user        =   {this.state.usuario}
									//     reserva		=	{this.reservas.bind(this)} 
										reload		=	{this.getNuevaReservacionInfo.bind(this)} 
										escritura	=	{this.state.escritura}
										pageLength = {this.state.pageLength} 
									/>
									
								}
								<div className="white-space-16"></div>
								<Paginador 
									pages		=	{Math.ceil(reservas.length / this.state.pageLength)} 
									setPage		=	{this.setPage.bind(this)}
									pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}
								/>
							
								</React.Fragment>:
								null
								}
							</div>
						</div>
					</div>
				</div>

				<DinamicModal 
					idModal					=	{'newReservation'}
					sizeModalContainer		=	{'big'}
					title					=	{'Añadir reserva'} 
					success					=	{this.handleSubmit.bind(this)} 
					btnTextSuccess			=	{"GUARDAR"}
					btnTextCancel			= 	{"CANCELAR"}
					showBtnSuccess			=	{true}
					showBtnCancel			=	{true}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.messageSave}
				>
				{this.renderNewReservation()}
				</DinamicModal>

			</div>
        )
    }

    renderNewReservation() {
		// console.log("Sub: ", this.state.subfraccionamientos);
        return(
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>{this.state.subLabel}:</b></p></div>
						<div className="column full">
							<select 
								className   =   "input input-modals"
								value       =   {this.state.id_subfraccionamiento}
								disabled    =   {(localStorage.getItem('subfrac') || 0)== 0? false:true}
								onChange    =   {this.changeSubfraccionamiento.bind(this)}
								required
							>
								{
									this.state.subfraccionamientos.map((subfraccionamiento, key) => (
										<option value={subfraccionamiento.id_subfraccionamiento} key={key}>
											{subfraccionamiento.nombre}
										</option>))
								}
							</select>
						</div>
					</div>

					<div className="white-space-16"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>Unidad:</b></p></div>
						<div className="column full">
							<select 
								className   =   "input input-modals"
								value       =   {this.state.id_unidad}
								onChange    =   {this.changeUnidad.bind(this)}
								required
							>
								{  
									this.state.unidades.map((unidad, key) => (

									<option value   =   {unidad.id_vivienda} key  =   {key}>
										{unidad.numero_registro}
									</option>)) 
								}
							</select>
						</div>
					</div>


					<div className="white-space-16"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>Áreas:</b></p></div>
						<div className="column full">
							<select 
								className   =   "input input-modals"
								value       =   {this.state.id_area}
								onChange    =   {this.changeAreaComun.bind(this)}
								required
							>
								{  
									this.state.areas.map((area, key) => (

									<option value   =   {area.id_espacio} key  =   {key}>
										{area.nombre}
									</option>)) 
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-duo-big"><p><b>Fecha:</b></p></div>
						<div className="column full">
							<DatePicker
								className   =   "input input-modals"
								selected    =   {this.state.fecha}
								onChange    =   {this.changeDate.bind(this)}
								dateFormat  =   "dd/MM/yyyy"
								locale      =   "es"
								minDate     =   {this.state.min_day}
								required
								// disabled    =   {this.state.fecha_dia}
								// showMonthYearPicker
							/>
						</div>

						<div className="column label-duo-normal space"><p><b>Horario:</b></p></div>
						<div className="column full">
							<select 
								className   =   "input input-modals"
								value       =   {this.state.id_horario}
								onChange    =   {this.changeHorario.bind(this)}
								required
							>
								{  
									this.state.horarios.map((horario, key) => (

									<option value   =   {horario.id_horario} key  =   {key}>
										{horario.descripcion}
									</option>)) 
								}
							</select>
						</div>
					</div>
					{this.state.aforo &&
						<React.Fragment>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big"><p><b>Aforo disponible:</b></p></div>
								<div className="column full">
									<input
										className="input input-modals"
										disabled
										type="number"
										value={this.state.maxAforo}
									/>
								</div>
								<div className="column label-duo-normal space"><p><b>No. personas:</b></p></div>
								<div className="column full">
									<input
										className="input input-modals"
										type="number"
										// required
										min={1}
										max={this.state.maxReservasAforo}
										value={this.state.personas}
										onChange={(event) => { this.setState({ personas: parseInt(event.target.value) }) }}
									/>
								</div>
							</div>
							<div className="justify-end">
								<p className="weight-regular color-red font-mini text-center">Máximo a reservar: {this.state.maxReservasAforo} persona(s)</p>
							</div>
						</React.Fragment>
					}
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big"><p><b>Depósito fianza:</b></p></div>
						<div className="column full">
							<input 
								className   = "input input-modals"
								type        =   "number"
								// required
								min         =   {0}
								value       = {this.state.fianza}
								onChange    = {(event)=>{this.setState({fianza: event.target.value})}}
							/>
						</div>

						<div className="column label-duo-normal space"><p><b>Recibido por:</b></p></div>
						<div className="column full">
							<input 
								className   = "input input-modals"
								type        =   "text"
								disabled
								value       =   {this.state.usuario.usuario}
							/>
						</div>
					</div>
					
					<div className="white-space-16"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>Comentarios:</b></p></div>
						
					</div>
					<div className="white-space-16"></div>

					<div className="input-form-content row full align-center">
						<div className="column full">
							<textarea 
								className   =   'textarea input input-modals'
								cols        =   "30" 
								rows        =   "3"
								value       = {this.state.comentarios}
								onChange    = {(event)=>{this.setState({comentarios: event.target.value})}}

							></textarea>
						</div>
						
					</div>
				</div>
			</div>
        );
    }
} //end Class

export default Consumer(CommonAreasReservation2);