import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import UsersTable from '../components/users/usersTable';
import { Consumer } from "../context";
import moment from "moment-timezone";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Controls from '../components/controls/controls';
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';

const request = new Request();

class UsersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            showModal: false,
            name: '',
            newUser: '',
            rol: '',
            password: '',
            valid: false,
            valids: true,
            idFraccionamiento: 0,
            nombreFrac: '',
            fraccionamientoNombre: true,
            existe: false,
            perfiles: [],
            page: 1,
            filter: '',
            loadingSave: false,
            logo: require("../img/logo-AppMosphera-r.png"),
            escritura: false,
            lectura: false,
            pageLength: 20,
            direction: {},
            filterColumn: {},
            submenu: '',
            idSubfraccionamiento: '',
            listSubfraccionamiento: [],
            dissableListSubfracionamiento: false
        };
    };

    async componentDidMount() {
        await this.validarPermisos();
        await this.load();
        await this.loadPerfil();
        await this.getSubFraccionamientos();
    };

    componentDidUpdate(prevProps, prevState) {

        // if () {

        // }
    };

    async validarPermisos() {
        const permisos = new Permissions();
        const permisosInfo = await permisos.getResult()
        this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
    };

    openModal(idModal) {
        this.setState({ mesagge: null });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };
        document.getElementById('fullName').focus();
    };

    closeModal(idModal) {
        this.setState({ mesagge: null });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };
    };

    reload() {
        this.getUsers();
    };

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    };

    paginatorChange(length) {
        this.setState({ pageLength: length });
        this.setPage(1);
    };

    async changeFrac() {
        await this.getFraccionamiento();
        await this.getUsers();
    };

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    };

    nuevoUsuario() {
        this.setState({ showModal: true });
    };

    setFilter(filter) {
        this.setState({ filter: filter.toLowerCase() });
    };

    filter(users) {
        users = users.filter(per => {
            if (per.nombre.toLowerCase().indexOf(this.state.filter) !== -1 || per.usuario.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else {
                return false;
            }
        })
        return users;
    };

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.users, this.state.direction[key]);

        this.setState({
            users: items,
            direction: { [key]: direction },
            filterColumn: { col: key, filt: direction }
        });
    };

    async load() {
        const submenu = await this.props.context.getSubmenuName();
        if (submenu) {
            this.setState({ submenu });
        };

        await this.getFraccionamiento();
        await this.getUsers();
    };

    async loadPerfil() {
        try {
            const data = { IdFrac: localStorage.getItem('frac') };

            const response = await request.post("/users/perfiles/nuevo", data)

            if (response && !response.error) {
                if (response.perfiles && !response.empty) {
                    this.setState({
                        perfiles: response.perfiles
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                };
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            };
        } catch (error) {
            console.log(error);
        };
    };

    async handleOpen() {
        this.setState({
            showModal: !this.state.showModal,
            name: '',
            newUser: '',
            rol: '',
            password: '',
            valid: false,
            valids: true,
            idFraccionamiento: 0,
            nombreFrac: '',
            existe: false
        });

        await this.getFraccionamiento();
    };

    async getSubFraccionamientos() {
        try {
            const fraccionamiento = localStorage.getItem("frac");
            if (!fraccionamiento) {
                return;
            };

            const data = { Idsub: fraccionamiento, filter: 1 };
            const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

            if (response.subfraccionamiento) {
                this.setState({ listSubfraccionamiento: response.subfraccionamiento });
            };

        } catch (error) {
            console.log(error);
        };
    };

    //comprobar que los campos de password sean iguales en editar usuario
    passValidate() {
        let pass1 = document.getElementById('new_pass').value;
        let pass2 = document.getElementById('new_pass2').value;

        if (pass1 === pass2 && pass1.length >= 4) {
            this.setState({ valid: true });
            return true;
        }

        this.setState({ valid: false });
        return false;
    };

    async handleSubmit(event) {
        event.preventDefault();
        try {
            this.setState({ loadingSave: true });

            const form = event.target;

            const data = {
                Nombre: form.fullName.value,
                Usuario: form.usertext.value,
                Fecha: moment(new Date()).format('YYYY-MM-DD'),
                Password: form.new_pass.value,
                IdFraccionamiento: this.state.idFraccionamiento,
                Perfil: parseInt(form.rol.value),
                idSubfraccionamiento: this.state.idSubfraccionamiento || null
            };

            if (!this.passValidate()) {
                this.setState({ loadingSave: false });
                return;
            };

            const response = await request.post("/users/validate/user/name", { usuario: data.Usuario });

            if (parseInt(response.validate.repeticiones) > 0) {
                cogoToast.warn('El usuario ya existe, por favor intenta con otro usuario.', {
                    position: 'bottom-right',
                });
                this.setState({ loadingSave: false });
                return;
            };

            const res = await request.post("/users/user/save/user", data);

            if (!res.created || res.error) {
                cogoToast.error('No se pudo crear un nuevo usuario.', {
                    position: 'bottom-right',
                });
                this.setState({ loadingSave: false });
                return;
            };

            if (res.created) {
                cogoToast.success('Usuario creado con éxito.', {
                    position: 'bottom-right',
                });

                this.closeModal('newUser');
                this.resetValue();
                await this.getUsers();
            };

            this.setState({ loadingSave: false });
        } catch (error) {
            console.log(error);
        };
    };

    async resetValue() {
        document.getElementById('new_pass2').value = '';
        document.getElementById('new_pass').value = '';
        document.getElementById('usertex').value = '';
        document.getElementById('fullName').value = '';

        this.setState({ newUser: '', rol: '', name: '' });
    };

    async getFraccionamiento() {
        try {
            const data = { IdFraccionamiento: parseInt(localStorage.getItem('frac')) };

            const response = await request.post("/admin/administracion/get/fraccionamiento", data);

            if (response && !response.error) {
                if (response.fraccionamiento && !response.empty) {
                    this.setState({
                        nombreFrac: response.fraccionamiento[0].nombre,
                        idFraccionamiento: response.fraccionamiento[0].id_fraccionamiento
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                };
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            };
        } catch (error) {
            console.log(error);
        };
    };

    async getUsers() {
        try {
            this.setState({ loading: true });
            await this.getFraccionamiento();

            const data = { IdFraccionamiento: localStorage.getItem('frac') };

            const response = await request.post("/users/get/fraccionamiento/users", data);

            if (response && !response.error) {
                if (response.users && !response.empty) {
                    this.setState({
                        users: response.users
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                };
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            };
            this.setState({ loading: false });
        } catch (error) {
            console.log(error);
        };
    };

    closeModal(idModal) {
        const modal = document.getElementById(idModal)
        if (modal) {
            modal.classList.remove('show-dinamic-modal');
            modal.classList.add('hide-dinamic-modal');
        };
    };

    restart() {
        document.getElementById('pass').value = "";
        this.setState({ password: "" });
    };

    render() {
        let users = [];

        if (Array.isArray(this.state.users)) {
            users = this.state.users;
            users = this.filter(users);
        };

        return (
            <div className="owners column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} change={this.changeFrac.bind(this)} sidebar={true} active={'usuarios'} parent={'config'} panel={'panel3'} nav={"catalogos"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                {this.state.lectura ?
                                    <React.Fragment>
                                        <Controls
                                            title={this.state.submenu}
                                            nuevo={this.openModal.bind(this, "newUser")}
                                            dataSet={users}
                                            dateFormat='dd/mm/yyyy'
                                            setFilter={this.setFilter.bind(this)}
                                            subChange={this.subChange.bind(this)}
                                            selectSub={false}
                                            newButton={this.state.escritura}
                                        />
                                        {this.state.loading ?
                                            <div className="row justify-center">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </div>
                                            :
                                            <UsersTable
                                                users={users}
                                                reload={this.reload.bind(this)}
                                                page={this.state.page}
                                                perfiles={this.state.perfiles}
                                                listSubfraccionamiento={this.state.listSubfraccionamiento}
                                                escritura={this.state.escritura}
                                                pageLength={this.state.pageLength}
                                                sortBy={this.sortableTable.bind(this)}
                                                filterColumn={this.state.filterColumn}
                                            />
                                        }
                                        <div className="white-space-16" />
                                        <Paginador
                                            pages={Math.ceil(users.length / this.state.pageLength)}
                                            setPage={this.setPage.bind(this)}
                                            pageLength={this.state.pageLength}
                                            paginatorChange={this.paginatorChange.bind(this)}
                                            showPageLenght={true}
                                        />
                                    </React.Fragment> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <DinamicModal
                    idModal={'newUser'}
                    sizeModalContainer={'normal'}
                    title={'Nuevo usuario'}
                    success={this.handleSubmit.bind(this)}
                    showBtnSuccess={true}
                    btnTextSuccess={"GUARDAR"}
                    showBtnCancel={true}
                    btnTextCancel={"CANCELAR"}
                    closeModal={this.resetValue.bind(this)}
                    loadingSave={this.state.loadingSave}
                    message={this.state.message}
                >
                    {this.renderNewUser()}
                </DinamicModal>

            </div>

        );
    };

    renderNewUser() {
        return (
            <div className="full row align-center">
                <div className="column full">
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Nombre:</b></p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals"
                                type="text"
                                name="fullName"
                                value={this.state.name}
                                id="fullName"
                                onChange={event => this.setState({ name: event.target.value })}
                                required
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Usuario:</b></p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals"
                                type="text"
                                name="usertext"
                                value={this.state.newUser}
                                id="usertex"
                                onChange={event => this.setState({ newUser: event.target.value })}
                                required
                                maxLength={15}
                                minLength={8}
                                autocomplete="off"
                            />
                            {this.state.existe ? (<p style={{ color: 'red' }}>Este usuario ya está registrado.</p>) : null}
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Perfil:</b></p>
                        </div>
                        <div className="column full">
                            <select name="rol" id="" className="input input-modals"
                                value={this.state.rol}
                                onChange={event => this.setState({ rol: event.target.value })}>
                                {this.state.perfiles.map((perfil, key) => (
                                    <option value={perfil.id_perfil} key={key}>{perfil.nombre}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center ">
                        <div className="column label-medium">
                            <p><b>Subfraccionamiento:</b></p>
                        </div>
                        <div className="column full">
                            <select name="idSubfraccionamiento" id="" className="input input-modals"
                                value={this.state.idSubfraccionamiento}
                                onChange={event => this.setState({ idSubfraccionamiento: event.target.value })}
                                disabled={this.state.dissableListSubfracionamiento}
                            >
                                <option value="0">Todos</option>
                                {
                                    this.state.listSubfraccionamiento.map((subfraccionamiento, key) => (
                                        <option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="white-space-32"></div>
                    <p style={{ textAlign: 'center' }}>
                        Asignar contraseña temporal.<br /> Se le solicitará al usuario cambiarla al ingresar a la plataforma.
                    </p>
                    <div className="white-space-32"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-duo-big mr-10"  >
                            <p >
                                <b>Contraseña:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals container"
                                type="password"
                                name="new_pass"
                                id="new_pass"
                                onKeyUp={this.passValidate.bind(this)}
                                required
                                maxLength="64"
                                autocomplete="new-password"
                            />
                        </div>
                        <div className="column label-duo-normal space">
                            <p >
                                <b>Confirmar Contraseña:</b>
                            </p>
                        </div>
                        <div className="column full space">
                            <input
                                className="input input-modals container"
                                type="password"
                                name="new_pass2"
                                id='new_pass2'
                                onKeyUp={this.passValidate.bind(this)}
                                autoComplete='off'
                                required
                                maxLength="64"
                            />
                        </div>
                    </div>

                    <div className="white-space-32"></div>
                    <div className="column full text-center">
                        {this.state.valid ? (null) : (<p style={{ color: 'red' }}>Las contraseñas deben coincidir y tener un tamaño mínimo de 4 caracteres.</p>)}
                    </div>
                    <div className="white-space-32"></div>
                </div>
            </div>

        )

    };
};

export default Consumer(UsersPage);