import React, { Component } from 'react';
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
import DocumentsTableSub from './documents-table-sub';
const request = new Request();

class DocumentsTable extends Component{
    constructor(props){
        super(props);
        this.state =   {
            // documentos:     Object.values(this.props.documentos),
            escritura:      this.props.escritura,
            id_carpeta:         0,
            id_carpetaActivo:   0,

            folders: this.props.folders,
            documentos: this.props.documentos,
            documentos_filtrados: []
        }
    }

    openModal(idModal) {
		this.setState({saveMessage: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
    
    closeModal(idModal) {
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
    }

    async openModalDelete(folder) {
        await this.setState({ id_folder: folder.id, id_folder_row: folder.id });
		let modal = document.getElementById("deleteModal-document");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
    }
    async handleDelete() {
        let data = {
            id_carpeta: this.state.id_folder,
		}
		const response = await request.post("/documents/eliminar/carpeta", data);
        if (response) {
			if (response.deleted) {
                this.props.reload();
                cogoToast.success('Carpeta eliminada.', {
                    position: 'bottom-right',
                });
			} else {
                cogoToast.error('No se pudo eliminar carpeta.', {
                    position: 'bottom-right',
                });
				//alert(response.message);
			}
        }
        this.props.reload();
    }
    
    
    render(){
        return(this.renderTable());
    }


    renderTable(){
        let folders = [];
		if (Array.isArray(this.props.folders)) {
			folders = this.props.folders;
		}
        
        return(
            <div className="card-table card-owners column" id="card-owners">
                
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-center">
                                <th className="th-options-huge text-left">
                                    <div className="row">
                                        Carpeta &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'path' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'path')}></i>
												:
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'path')}></i>
												:
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'path')}></i>
											}
										</div>
									</div>
                                </th>
                                <th className="th-options-huge text-left"></th>
                                <th className="th-options-huge text-left"></th>
                                <th className="th-options-huge text-left"></th>
                                <th className="th-options-huge">Documentos</th>
                                <th className="th-options-huge">Editar</th>
                                <th className="th-options-huge">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                        {folders.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((folder, key) => (
                                <React.Fragment>
                                <tr className={this.state.id_folder_row === folder.id ? 'row-active' : ''} key={key}>
                                    <td colSpan="4">
                                        {folder.path}
                                    </td>
                                    <td className="text-center">
                                            <button 
                                                className   =   "btn-full btn-mini btn-secondary color-white"
                                                type        =   "button "
                                                onClick     =   {this.openRow.bind(this,folder.id)} 
                                            >
                                            <i className="font-small fas fa-bars" />
                                            </button>
                                    </td>
                                    <td>
                                         <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                type        =   'button' 
                                                onClick     =   {()=>{
                                                    this.setState({
                                                        path_folder:        folder.path,
                                                        id_folder_edit :    folder.id,
                                                        id_folder_row:      folder.id    
                                                    });
                                                    this.openModal('carpeta_editar')
                                                }} 
                                                disabled    =   {!this.props.escritura} 
                                        >
											<i className="font-text fas fa-pen " />
										</button> 
									</td>
                                    <td>
                                         <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                type        =   'button' 
                                                onClick     =   {this.openModalDelete.bind(this, folder)} 
                                                // style       =   {{ padding: '5px' }}
                                                disabled    =   {!this.props.escritura} 
                                        >
											<i className="font-text fas fa-trash-alt " />
										</button> 
									</td>
                                </tr>
                                {this.state.id_carpeta ===  folder.id? 
                                   <tr className="acordeon-row">
                                        <td className="acordeon" colSpan="8">
                                            <div className="row full">
                                                <DocumentsTableSub
                                                    documentos={this.state.documentos_filtrados}
                                                    escritura={this.props.escritura}
                                                    reload={this.props.reload.bind(this)}
                                                ></DocumentsTableSub>
                                            </div>
                                        </td>
                                    </tr>
                                :null}
                                </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                </div>


                <DinamicModal 
					idModal					=	{'carpeta_editar'}
                    enforceFocus		    =	{true}
					sizeModalContainer		=	{'small'}
					title					=	{'Editar carpeta'} 
                    success					=	{this.editFolder.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
					closeModal				=	{this.closeModal.bind(this)}
				>
                    {this.renderCarpeta()}
				</DinamicModal>

                <ModalBorrar
					name		= 	'deleteModal-document'
					id          =   {this.state.id_folder}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>

        );
    }

    async editFolder(e){
        e.preventDefault();

        //console.log(this.state.id_folder_edit);
        //console.log(this.state.path_folder);

        const response = await request.post('/documents/editar/carpeta',{
            id_carpeta:     this.state.id_folder_edit,
            path:           this.state.path_folder
        });

        if(response.error){
            cogoToast.error('No se pudo editar carpeta',{
                position: 'bottom-right'
            });
            
            return;
        }


        if(response.eddited){
            cogoToast.success('Carpeta editada con éxito.',{
                position: 'bottom-right'
            });
            this.props.reload();
        }

    }

    renderCarpeta(){
        
        return(

            
            <div className="full row align-center">
				<div className="column full">
                    <div className="input-form-content row full align-center"> 
                        <div className="column label-medium">
                            <p>
                                <b>Carpeta:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                className       = "input input-modals"
                                type            = 'text'
                                autoComplete    = 'off'
                                required
                                value           =   {this.state.path_folder}      
                                onChange        =   {(e)=>{this.setState({path_folder: e.target.value})}}
                            ></input>
                        </div>
                    </div>
				</div>
			</div>

        );
    }

    async componentDidUpdate(prevProps, prevState){

        if(prevProps.documentos != this.props.documentos){
            await this.setState({documentos: this.props.documentos});
        }

        if(prevProps.folders != this.props.folders){
            await this.setState({folders: this.props.folders});
        }

    }

    async componentDidMount(){

    }
    async filterDocuments(id_carpeta){
        await this.setState({documentos_filtrados:[]});

        let documentos_filtrados = this.state.documentos.filter((d)=>d.id_carpeta == id_carpeta);
        await this.setState({documentos_filtrados});
    }

    async openRow(id){
		if(id == this.state.id_carpeta){
			await this.setState({id_carpeta: 0});
		}else{
			await this.setState({id_carpeta: id, id_folder_row: id});
			await this.filterDocuments(id);
        }
    }
}

export default DocumentsTable;