// React and Third-party
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from "react-helmet";
import pluralize from 'pluralize';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';

// Context
import { Consumer } from "../../context";

// Components
import Header from "../../components/header/header";
import Controls from '../../components/controls/controls';
import SidebarAdministracion from '../../components/header/sidebarAdministracion';
import HistorialFilterBar from './components/HistorialFilterBar';
import BalancesTable from './components/BalancesTable';
import MovimientosTable from './components/MovimientosTable';

// Requests
import { getInquilinosBalance, getMovimientos } from "./requests";

const loadingType = {
    none: "NONE",
    movimientosGeneral: "MOVS",
    movimientosInquilino: "MOVS-INQ",
    balances: "BALANCES"
}

const initParameters = {
    textFilter: "",
    fraccionamientoFilter: 0,
}

const initSearchParameters = {
    byBalances: false,
    inquilino: 0,
    vivienda: 0,
    catalogo: 0,
    desdeFecha: moment().add(-1, "month").format("YYYY-MM-DD"),
    hastaFecha: moment().format("YYYY-MM-DD")
}

const excelConfigBalances = {
    excelColumnsShow: "inquilino,numero_registro,subfraccionamiento,saldo",
    excelColumnsName: "Residente,Vivienda,Subfraccionamiento,Balance",
    excelColumnsType: "string,string,string,money"
}

const excelConfigMovimientos = {
    excelColumnsShow: "inquilino,vivienda,Subfraccionamiento,cantidad,tipo,descripcion,fecha",
    excelColumnsName: "Residente,Vivienda,Subfraccionamiento,Cantidad,Tipo,Detalle,Fecha",    
    excelColumnsType: "string,string,string,money,string,string,date"
}

function TarjetaVirtual(props) {

    const { context, location } = props;

    const [subMenu, setSubMenu] = useState("");
    
    const [balances, setBalances] = useState([]);
    const [movimientos, setMovimientos] = useState([]);

    const [loadingData, setLoadingData] = useState(loadingType.none);
    
    const [localParameters, setLocalParameters] = useState(initParameters);
    const [fetchParameters, setFetchParameters] = useState(initSearchParameters);

    const byBalancesSearch = useRef(initSearchParameters.byBalances);

    const loadInquilinosBalance = useCallback(async () => {

        setLoadingData(loadingType.balances);

        byBalancesSearch.current = true;

        const response = await getInquilinosBalance(localStorage.getItem("frac"), 0); // Todos

        console.log("Inquilinos balances response: ", response);

        if(response.error) {
            cogoToast.error(response.error, {
                position: "bottom-right",
                hideAfter: 5,
            });
        }

        if(response.balances) {
            const balances = response.balances;
            Object.assign(balances[0], { ...excelConfigBalances });

            setBalances(balances);
        }

        setLoadingData(loadingType.none);

    }, [setLoadingData, setBalances]);

    const loadMovimientos = useCallback(async (loadType, { id_fraccionamiento, inquilino, vivienda, catalogo, desdeFecha, hastaFecha }) => {

        setLoadingData(loadType);

        byBalancesSearch.current = loadType === loadingType.movimientosInquilino;

        const { error, movimientos } = await getMovimientos(id_fraccionamiento, inquilino, vivienda, catalogo, desdeFecha, hastaFecha);

        if(error) {

            if(movimientos && movimientos.length === 0) {
                cogoToast.warn(error, {
                    position: "bottom-right",
                    hideAfter: 5,
                });
            } else {
                cogoToast.error(error, {
                    position: "bottom-right",
                    hideAfter: 5,
                });
            }
        }

        console.log("Vivienda Movs: ", movimientos);

        if(movimientos) {
            if(movimientos.length > 0) Object.assign(movimientos[0], { ...excelConfigMovimientos });
            setMovimientos(movimientos);
        }

        setLoadingData(loadingType.none);

    }, [setLoadingData, setMovimientos]);

    const fetchData = useCallback((parameters, loadType = loadingType.movimientosGeneral) => {

        if (parameters.byBalances) {
            loadInquilinosBalance();
        } else {
            loadMovimientos(loadType, { id_fraccionamiento: localStorage.getItem("frac"), ...parameters });
        }

    }, [loadMovimientos, loadInquilinosBalance]);

    useEffect(() => {
      
        const subname = getSubmenuAdminName(location.pathname);
        setSubMenu(subname);

    }, [location]);

    useEffect(() => {
      
        fetchData(initSearchParameters);

    }, [fetchData]);

    const onParametersChanged = (setParameters, key, value) => {

        setParameters(prevParameters => {
            const parametersCopy = {...prevParameters}

            parametersCopy[key] = value;

            return parametersCopy;
        });
    };

    const filterByText = (data, filter) => {

        if(filter === "") return data;

        if (byBalancesSearch.current) {
            return data.filter((mov) =>
                mov.numero_registro.toLowerCase().includes(filter)
                || mov.inquilino.toLowerCase().includes(filter)
                || mov.subfraccionamiento.toLowerCase().includes(filter));
        }
        else {
            return data.filter((mov) =>
                mov.inquilino.toLowerCase().includes(filter)
                || mov.Subfraccionamiento.toLowerCase().includes(filter)
                || mov.vivienda.toLowerCase().includes(filter)
                || mov.descripcion.toLowerCase().includes(filter));
        }
    }

    const filterbyFraccionamiento = (data, filter) => {

        if(filter === 0) return data;

        return data.filter((mov) => mov.id_subfraccionamiento === filter);
    }

    const applyFilters = (unfilteredData, parameters) => {

        const filterText = filterByText(unfilteredData, parameters.textFilter);
        const filterFraccionamientos = filterbyFraccionamiento(filterText, parameters.fraccionamientoFilter);

        return filterFraccionamientos;
    };

    const getData = (balances, movimientos) => {

        return byBalancesSearch.current ? balances : movimientos
    }

    const data = useCallback(getData(balances, movimientos), [balances, movimientos]);
    const filteredData = useCallback(applyFilters(data, localParameters), [localParameters, data]);

    const Historial = useCallback((props) => (
        <MovimientosTable
            {...props}
            movimientos={movimientos}
            header={true}
            byVivienda={true}
            fraccionamiento={context.fraccionamiento}
            usuario={context.usuario}
        />
    ), [context, movimientos]);

    return (
        <div className="column" /*admin*/>
            <Helmet>
                <title>{process.env.REACT_APP_NAME} - {subMenu}</title>
            </Helmet>
            <Header
                id='header'
                reload={() => console.log("Add reload")}
                change={() => console.log("Add change")}
                sidebar={true}
                nav={"administracion"}
            />
            <div className="row full">
                <div className="column" id="sidebar">
                    <SidebarAdministracion />
                </div>
                <div className="column align-center" id="content">
                    <div className="container column">
                        <Controls
                            title={"Tarjeta Virtual - Historial"}
                            dataSet={data}
                            dateFormat={"dd/mm/yyyy HH:mm:ss"}
                            setFilter={(value) => onParametersChanged(setLocalParameters, "textFilter", value.toLowerCase())}
                            subChange={(sub) => onParametersChanged(setLocalParameters, "fraccionamientoFilter", +sub)}
                        />
                        <HistorialFilterBar
                            parameters={fetchParameters}
                            onParameterChanged={(key, value) => onParametersChanged(setFetchParameters, key, value)}
                            onSearch={() => fetchData(fetchParameters)}
                        />
                        {byBalancesSearch.current ?
                            <BalancesTable
                                balances={loadingData === loadingType.none || loadingData === loadingType.movimientosInquilino ? filteredData : []}
                                loadingBalances={loadingData === loadingType.balances}
                                loadingInquilinosMovimientos={loadingData === loadingType.movimientosInquilino}
                                desdeFecha={fetchParameters.desdeFecha}
                                hastaFecha={fetchParameters.hastaFecha}
                                id_catalogo={fetchParameters.catalogo}
                                onMovimientosSearch={(inquilino) =>  fetchData({ ...fetchParameters, inquilino, byBalances: false }, loadingType.movimientosInquilino )}
                                onAbonoCompleted={() => fetchData(fetchParameters)}
                                HistorialComponent={Historial}
                            />
                            :
                            <MovimientosTable
                                movimientos={loadingData === loadingType.none ? filteredData : []}
                                loadingMovimientos={loadingData === loadingType.movimientosGeneral}
                                byVivienda={false}
                                fraccionamiento={context.fraccionamiento}
                                usuario={context.usuario}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumer(TarjetaVirtual);

const getSubmenuAdminName = (route) => {

	let name = window.localStorage.getItem('submenu_name_admin');

    if(!name) { 
        //Reset Sublabel
        let catalogos_menus = window.localStorage.getItem('catalogos_menus');

		if (catalogos_menus) {
			catalogos_menus = JSON.parse(catalogos_menus);
			if (catalogos_menus.length !== 0) {
				//Label subfraccionamiento
				name = catalogos_menus[0].recordset[0].nombre;
				let singular = pluralize.isPlural(name);
				if (singular) {
					name = pluralize.singular(name);
				}
				window.localStorage.setItem('subLabel', name);
			}
		}

        //Load Submenu Admin Name
        let menus = window.localStorage.getItem('administracion_menus');
        menus = JSON.parse(menus);

        if (menus.length !== 0) {

            let submenu;

            for (let index = 0; index < menus.length; index++) {
                const subs = menus[index].recordset;
                console.log("Subs: ", subs);
                const foundSub = subs.find(sub => sub.ruta == route);

                if(foundSub){
                    submenu = foundSub;
                    break;
                }
            }

            if (submenu) {
                window.localStorage.setItem('submenu_admin', submenu.id_menu);
                window.localStorage.setItem('submenu_name_admin', submenu.nombre);

                name = submenu.nombre;

            } else {
                window.localStorage.setItem('submenu_admin', menus[0].recordset[0].id_menu);
                window.localStorage.setItem('submenu_name_admin', menus[0].recordset[0].nombre);
                window.location = `${menus[0].recordset[0].ruta}`;

                name = menus[0].recordset[0].nombre;
            }
        }
    }

    return name;
}
