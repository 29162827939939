import React, {Component} from 'react';
import Request from "./../../core/httpClient";
import { AsYouType } from 'libphonenumber-js'

const request = new Request();

class SuperCore extends Component {
    constructor(props){
        super(props);
        this.state= {

        };
    };

    // obtenie la informacion del usuario igate que esta encriptado en el local storage
    async getUserInfo(){
        let iGateAdmin =  JSON.parse(localStorage.getItem('iGateAdmin'));
		const response = await request.post('/admin/administracion/get/decodetoken', {
			token: iGateAdmin.id
		});
        return response;
    };

    getMoneyFormat(number){
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number)
    };

    getNumberFormat(cell_phone){
        try {
            if(cell_phone.includes('+')){
                return new AsYouType('MXN').input(cell_phone);
            } else {
                return new AsYouType('MXN').input('+'+cell_phone);
            };
        } catch (error) {
            return null;
        };
    };

/**
 *    ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗███████╗
 *    ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝██╔════╝
 *    ██╔████╔██║██║   ██║██║  ██║███████║██║     █████╗  ███████╗
 *    ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ██╔══╝  ╚════██║
 *    ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████╗███████║
 *    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝
 *  Funciones para modales nuevos y viejos
 */
    //##### MODALES NUEVOS #####

/**
 * 
 * @param {id Modal} id 
 */
    async superOpenModal(id){

        let modal = document.getElementById(id);
        if(modal){
            modal.classList.remove('hide-super-modal');
            modal.classList.add('show-super-modal');
        }
        
    }
/**
 * 
 * @param {id Modal} id 
 */
    async superCloseModal(id){
        let modal =  document.getElementById(id);
        if(modal){
            modal.classList.remove('show-super-modal');
            modal.classList.add('hide-super-modal');
        };
    };

    //##### MODALES VIEJOS #####

/**
 * 
 * @param {id modal} idModal
 * Abre los modales antiguos DinamicModal
 */
    async openModal(idModal) {
        
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
    };
/**
 * 
 * @param {id modal} idModal
 * Cierra los modales antiguos DinamicModal 
 */
    async closeModal(idModal){
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		};
    };

/**
 * 
 * @param {id Modal detele} idModal 
 * Abre modal de eliminar antiguo
 */
    async openModalDelete(idModal = 'deleteModal') {
		let modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		};
    };
/**
 * 
 * @param {id Modal detele} idModal 
 * Cierra modal de eliminar antiguo
 */
    async closeModalDelete(idModal = 'deleteModal') {
		let modal = document.getElementById(idModal) 
		
		if(modal) {
			modal.classList.remove('show-modal');
			modal.classList.add('hide-modal');
		};
    };

};

export default SuperCore;
