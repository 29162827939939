import React, { Component } from 'react';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import TurnoForm from './formTurno';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';

const request = new Request();


class TurnosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			turno: {},
			loadingSave: false
		}
    }

    delete = id => {
        this.handleDelete(id)
    }

    render() {

        let turnos = []
        if (this.props.turnos) {
            turnos = this.props.turnos
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
                                    <div className="row">
                                        Turno &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'turno' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'turno')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'turno')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'turno')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {turnos.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((turno, key) => (
                                <tr className={this.state.id_turno_row === turno.id_personal_turno && 'row-active'} key={key}>
                                    <td>
                                        {turno.turno}
                                    </td>
									<td className="text-center">
                                        <button
											type        =   "button justify-center" 
											onClick     =   {this.openModal.bind(this, 'modal-edit-turno', turno)}
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
                                    <td className="text-center">
										<button 
											className = "btn-full justify-center align-center btn-mini btn-secondary color-white"
											type = 'button' 
											onClick = {this.openModalDelete.bind(this, turno)}
											style = {{ padding: '5px' }}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
				<DinamicModal 
					idModal				=	{'modal-edit-turno'}
					sizeModalContainer	=	{'small'}
					title				=	{'Editar turno'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					closeModal			=	{this.closeModal.bind(this)}
				>
					<TurnoForm turno={this.state.turno} turnoInput={this.props.turno} setTurno={this.props.setTurno} />
				</DinamicModal>
				<ModalBorrar
					id          =   {this.state.id_personal_turno}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	closeModal(idModal) {
		this.setState({turno: {}});

		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add("hide-modal");
			modal.classList.remove("show-modal");
		}

        this.props.setTurno('');
	}

	openModal(idModal, data) {
        this.setState({turno: data, id_turno_row: data.id_personal_turno});
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
	}

	openModalDelete(turno) {
        this.setState({ id_personal_turno: turno.id_personal_turno, id_turno_row: turno.id_personal_turno});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const form = event.target;
		const data = {
			id_personal_turno: this.state.turno.id_personal_turno,
			turno: form.turno_title.value
		}

		const response = await request.post('/personal/turnos/update', data);

		if (response.updated) {
            cogoToast.success('Turno actualizado.', {
                position: 'bottom-right',
            });
            this.props.reload();
        } else {
            cogoToast.error(response.message || 'No se pudo actualizar el turno.', {
                position: 'bottom-right',
            });
        }

        this.props.setTurno('');
		this.setState({loadingSave: false});
	}

    async handleDelete(id) {
        this.setState({loadingSave: true});

        let data = {
            id_personal_turno: id,
        }

        const response = await request.post('/personal/turnos/delete', data);

		if (response.delete) {
            cogoToast.success('Turno eliminado.', {
                position: 'bottom-right',
            });
			this.props.reload();
        } else {
            cogoToast.error('No se pudo eliminar el turno.', {
                position: 'bottom-right',
            });
        }
		this.props.setTurno('');
		this.setState({loadingSave: false});
	}
}

export default TurnosTable;