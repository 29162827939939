import React, { Component } from "react";
import ReactModal from "react-modal";
import DinamicModal from "../dinamicModal/dinamicModal";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";

const request = new Request();

class BudgetPagos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fecha: "",
      banco: "",
      num_movimiento: "",
      monto: 0,
      formasPagos: ["Efectivo", "Transferencia"],
      forma_pago: "Efectivo",
    };
  }

  render() {
    return (
      <div>
        <DinamicModal
          idModal={"BudgetPago"}
          sizeModalContainer={"small"}
          title={"Añadir pago"}
          success={this.handleSubmit.bind(this)}
          showBtnSuccess={this.props.escritura}
          btnTextSuccess={"GUARDAR"}
          showBtnCancel={true}
          btnTextCancel={"CANCELAR"}
          loadingSave={this.state.loadingSave}
          message={this.state.message}
        >
          {this.renderBudget()}
        </DinamicModal>
      </div>
    );
  }

  renderBudget() {
    return (
      <div className="full row align-center" style={{ padding: "20px" }}>
        <div className="column full">
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-duo-big">
              <p>
                <b>Fecha:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="date"
                name="fecha"
                className="input input-modals"
                onChange={(event) =>
                  this.setState({ fecha: event.target.value })
                }
                placeholder="Fecha"
                required
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>F.Pago:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <div className="column full">
                <select
                  className={"input input-modals"}
                  name="forma_pago"
                  value={this.state.formasPagos.findIndex(
                    (fpago) => fpago === this.state.forma_pago
                  )}
                  onChange={(event) => {
                    this.setState({
                      forma_pago: this.state.formasPagos[event.target.value],
                    });
                  }}
                >
                  {this.state.formasPagos.map((fpago, key) => (
                    <option value={key} key={key}>
                      {fpago}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            {" "}
            <div className="column label-duo-big">
              <p>
                <b>Banco:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="text"
                name="banco"
                id="banco"
                className="input input-modals"
                autoComplete="off"
                maxLength="50"
                required
                onChange={(event) => {
                  this.setState({ banco: event.target.value });
                }}
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>N° Mov:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <div className="column full">
                <input
                  type="text"
                  name="num_movimiento"
                  id="num_movimiento"
                  className="input input-modals"
                  autoComplete="off"
                  maxLength="50"
                  onChange={(event) => {
                    this.setState({ num_movimiento: event.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            {" "}
            <div className="column label-duo-big">
              <p>
                <b>Monto:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="number"
                name="monto"
                className="input input-modals"
                onChange={(event) =>
                  this.setState({ monto: event.target.value })
                }
                autoComplete="off"
                required
                minLength="2"
                maxLength="4"
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p></p>
              </div>
            </div>
            <div className="column full">
              <div className="column full"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async handleSubmit(event) {
    event.preventDefault();

    let data = {
      id_factura: this.props.id_factura,
      fecha: this.state.fecha,
      forma_pago: this.state.forma_pago,
      banco: this.state.banco,
      num_movimiento: this.state.num_movimiento,
      monto: this.state.monto,
    };
    const response = await request.post("/facturas/pago/create", data);
    if (response.created && !response.empty) {
      this.props.reload();
      cogoToast.success("Pago agregado.", {
        position: "bottom-right",
      });
    } else {
      this.setState({
        empty: true,
      });
      cogoToast.error("No se pudo agregar el pago.", {
        position: "bottom-right",
      });
    }
  }
}

export default BudgetPagos;