import React from "react";
import moment from "moment-timezone";

import ModalBorrar from "../modals/modalDelete";
import Request from "../../core/httpClient";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import cogoToast from "cogo-toast";
import Core from "./../../components/core/SuperCore";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

registerLocale("es", es);

const request = new Request();

class ReservationTable2 extends Core {
    constructor(props) {
        super(props);
        this.state = {
            reservas: this.props.reservas,
            page: this.props.page,
            comentarios: "",
            fianza: null,
            fecha: new Date(),
            min_day: new Date(),
            horarios: [],
            id_horario: 0,
            user: this.props.user,
            horario_ocupado: {}, // horario de la reserva actual
            id_reserva: 0,
            fecha_reserva_creacion: null,
            fecha_reserva_cancelacion: null,
            reserva_creacion: "",
            cancelada: "",
            reserva_cancelacion: "",
            isFianzaDevolucionSet: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.page != this.props.page) {
            this.setState({ page: this.props.page });
        }

        if (prevProps.reservas != this.props.reservas) {
            this.setState({ reservas: this.props.reservas });
        }

        if (prevProps.user != this.props.user) {
            this.setState({ user: this.props.user });
        }
    }

    async delimitarSeleccionFechas(areaSeleccionada) {
        let min_day = moment();
        min_day = min_day.add(parseInt(areaSeleccionada.dias_anticipacion), "days");

        await this.setState({
            min_day: min_day.toDate(),
        });
    }

    async setReservaData(reserva) {
        // dias_anticipacion
        await this.delimitarSeleccionFechas(reserva);
        let fecha_zona = moment(reserva.fecha_reserva).tz("GMT").format("YYYY/MM/DD");
        let fecha_reserva_creacion = moment(reserva.fecha_reserva_creacion).tz("GMT").format("HH:mm:ss");
        let fecha_reserva_cancelacion = moment(reserva.fecha_reserva_cancelacion).tz("GMT").format("DD/MM/YY HH:mm:ss");
        fecha_zona = new Date(fecha_zona);

        //console.log(reserva);
        await this.setState({
            area: reserva.nombre,
            unidad: reserva.numero_registro,
            fecha: fecha_zona,
            fecha_original: fecha_zona,
            horario: reserva.IdHorario,
            fianza: reserva.fianza_cobrada ? reserva.fianza_cobrada : 0,
            fianza_devolucion: reserva.fianza_devolucion ? reserva.fianza_devolucion : null,
            isFianzaDevolucionSet: reserva.fianza_devolucion > 0, // Set the boolean state
            comentarios: reserva.comentarios,
            id_area: reserva.id_espacio,

            recibido: reserva.nombre_recibido,
            devuelto: reserva.nombre_devolucion,
            horario_ocupado: {
                id_espacio: reserva.id_espacio,
                id_horario: reserva.IdHorario,
                descripcion: reserva.descripcion,
            },
            id_horario: reserva.IdHorario,
            id_reserva: reserva.id_reserva,
            fecha_devolucion: reserva.fecha_devolucion,
            cantidad: reserva.cantidad,
            fecha_reserva_creacion,
            fecha_reserva_cancelacion,
            reserva_creacion: reserva.reserva_creacion,
            cancelada: reserva.cancelada,
            reserva_cancelacion: reserva.reserva_cancelacion,
        });

        await this.setState({
            fianza_devolucion_disabled: !(this.state.fianza_devolucion === 0 || this.state.fianza_devolucion === null),
            fianza_disabled: !(this.state.fianza === 0 || this.state.fianza === null),
        });
    }

    async beforeOpenModal(reserva) {
        await this.setReservaData(reserva);
        await this.getHorarios();
        this.openModal("editReservation");
    }

    render() {
        let reservas = this.state.reservas;

        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="table-responsiv column">
                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">Área reservada</th>
                                <th className="">Fecha</th>
                                <th className="text-left">Vivienda</th>
                                <th className="text-left">Horario</th>
                                <th className="text-left">Cancelada</th>
                                <th className="text-center th-options-small">Ver</th>
                                <th className="text-center th-options-small">Cancelar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservas
                                .slice(
                                    (this.state.page - 1) * this.props.pageLength,
                                    this.state.page * this.props.pageLength,
                                )
                                .map((reserva, key) => (
                                    <tr key={key}>
                                        <td>{reserva.nombre}</td>
                                        <td>{moment(reserva.fecha_reserva).tz("GMT").format("DD/MM/YYYY")}</td>
                                        <td>{reserva.numero_registro}</td>
                                        <td>{reserva.descripcion}</td>
                                        <td>
                                            <label className="container-check">
                                                <input
                                                    type="checkbox"
                                                    checked={reserva.cancelada ? true : false}
                                                    readOnly
                                                    disabled
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td className="text-center">
                                            <button
                                                className="btn-full justify-center btn-mini btn-secondary color-white"
                                                type="button"
                                                onClick={this.beforeOpenModal.bind(this, reserva)}
                                            >
                                                <i className="font-small fas fa-eye" />
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                type="button"
                                                onClick={this.beforeOpenModalDelete.bind(this, reserva)}
                                                style={{ padding: "5px" }}
                                                disabled={!this.props.escritura}
                                            >
                                                <i className="font-text fas fa-ban " />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <DinamicModal
                    idModal={"editReservation"}
                    sizeModalContainer={"big"}
                    title={"Ver reserva"}
                    success={this.handleSubmit.bind(this)}
                    // closeModal			=	{this.resetData.bind(this)}
                    btnTextSuccess={"ACTUALIZAR"}
                    btnTextCancel={"CANCELAR"}
                    showBtnSuccess={this.props.escritura}
                    showBtnCancel={true}
                    loadingSave={this.state.loadingSave}
                    message={this.state.message}
                >
                    {this.renderModalEdit()}
                </DinamicModal>

                <ModalBorrar id={this.state.id_reserva} delete={this.deleteReservation.bind(this)} />
            </div>
        );
    }
    async beforeOpenModalDelete(reserva) {
        let user = await this.getUserInfo();
        await this.setState({ usuario: user.info });
        // id_reserva
        await this.setState({ id_reserva: reserva.id_reserva });
        this.openModalDelete("deleteModal");
    }

    async deleteReservation(id) {
        const { usuario } = this.state;
        let data = {
            Idreserva: id,
            id_usuario: usuario.id_usuario,
        };
        const response = await request.post("/commonArea/delet/reservation", data);

        if (response.error || response.empty) {
            cogoToast.error("No se pudo eliminar la reservación.", {
                position: "bottom-right",
            });
        }

        if (response.deleted) {
            cogoToast.success("Reservación eliminada.", {
                position: "bottom-right",
            });
        }
        this.closeModalDelete("deleteModal");
        this.props.reload();
    }

    convertFecha(fecha_no_convertida) {
        let f = fecha_no_convertida;
        let fecha = `${f.getFullYear()}`;
        fecha += `-${f.getMonth() + 1 < 10 ? `0${f.getMonth() + 1}` : f.getMonth() + 1}`;
        fecha += `-${f.getDate()}`;

        return fecha;
    }

    async getHorarios() {
        this.setState({ horarios: [], id_horario: 0 });

        let f = this.state.fecha;
        let fecha = `${f.getFullYear()}`;
        fecha += `-${f.getMonth() + 1 < 10 ? `0${f.getMonth() + 1}` : f.getMonth() + 1}`;
        fecha += `-${f.getDate()}`;

        let data = {
            id: this.state.id_area,
            fecha: fecha,
            dif: 1,
        };

        const response = await request.post("/commonArea/get/available/schedule", data);
        if (response.error || response.empty) {
            await this.setState({ horarios: [] });

            cogoToast.info("No se encontraron horarios para la fecha seleccionada.", {
                position: "bottom-right",
            });
        }

        let horarios_incompletos = [];

        // si no hay más horarios solo se agrega el que tiene reservado
        if (response.commonSchedules) {
            horarios_incompletos = response.commonSchedules;

            this.setState({ id_horario: horarios_incompletos[0].id_horario });
        }

        if (this.convertFecha(this.state.fecha) == this.convertFecha(this.state.fecha_original)) {
            horarios_incompletos.unshift(this.state.horario_ocupado);
            await this.setState({
                id_horario: this.state.horario_ocupado.id_horario,
            });
        }
        await this.setState({
            horarios: horarios_incompletos,
        });
    }

    async changeDate(fecha) {
        await this.setState({ fecha });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loadingSave: true });

        let validado = true;
        if (this.state.fianza_devolucion > this.state.fianza) {
            cogoToast.warn("La cantidad devuelta debe ser igual o menor al depósito de la fianza.", {
                position: "bottom-right",
            });
            validado = false;
        }

        if (!validado) {
            this.setState({ loadingSave: false });
            return;
        }

        const data = {
            fechaActualizacion: moment().format("YYYY-MM-DD"),
            comentarios: this.state.comentarios,
            idReserva: this.state.id_reserva,
            idUserDev: parseInt(this.state.user.id_usuario),
            fianzaDev: this.state.fianza_devolucion,
            fechaDev: this.state.fecha_devolucion
                ? this.state.fecha_devolucion
                : this.state.fianza_devolucion
                ? moment().format("YYYY-MM-DD")
                : "",
        };

        const response = await request.post("/commonArea/update/reservation", data);

        if (response.error) {
            cogoToast.error("No se pudo editar reserva.", {
                position: "bottom-right",
            });
            this.setState({ loadingSave: false });
            return;
        }

        if (response.created) {
            this.closeModal("editReservation");
            cogoToast.success("Datos actualizados correctamente.", {
                position: "bottom-right",
            });

            this.props.reload();
        }

        this.setState({ loadingSave: false });
    }

    async changeHorario(event) {
        let id_horario = event.target.value;
        await this.setState({ id_horario });
    }

    renderModalEdit() {
        return (
            <div className="full row align-center">
                <div className="column full">
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p>
                                <b>Área reservada:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input className="input input-modals" type="text" value={this.state.area} disabled />
                        </div>
                    </div>

                    <div className="white-space-16"></div>

                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p>
                                <b>Unidad:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input className="input input-modals" type="text" value={this.state.unidad} disabled />
                        </div>
                    </div>

                    <div className="white-space-16"></div>

                    <div className="white-space-16"></div>

                    <div className="input-form-content row full align-center">
                        <div className="column label-duo-big">
                            <p>
                                <b>Fecha:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals"
                                value={moment(this.state.fecha).format("DD/MM/YYYY")}
                                // onChange    =   {this.changeDate.bind(this)}
                                minDate={this.state.min_day}
                                required
                                disabled
                            />
                        </div>
                        <div className="column label-duo-normal space">
                            <p>
                                <b>Horario:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <select
                                className="input input-modals"
                                value={this.state.id_horario}
                                onChange={this.changeHorario.bind(this)}
                                disabled
                                required
                            >
                                {this.state.horarios.map((horario, key) => (
                                    <option value={horario.id_horario} key={key}>
                                        {horario.descripcion}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p>
                                <b>Reservas por vivienda:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input className="input input-modals" type="text" value={this.state.cantidad} disabled />
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    {this.state.fianza > 0 && (
                        <div>
                            <div className="input-form-content row full align-center">
                                <div className="column label-duo-big">
                                    <p>
                                        <b>Depósito fianza:</b>
                                    </p>
                                </div>
                                <div className="column full">
                                    <input
                                        className="input input-modals"
                                        type="number"
                                        required
                                        min={0}
                                        value={this.state.fianza}
                                        onChange={event => {
                                            this.setState({ fianza: event.target.value });
                                        }}
                                        disabled={this.state.fianza_disabled}
                                    />
                                </div>

                                <div className="column label-duo-normal space">
                                    <p>
                                        <b>Recibido por:</b>
                                    </p>
                                </div>
                                <div className="column full">
                                    <input
                                        className="input input-modals"
                                        type="text"
                                        disabled
                                        value={this.state.recibido}
                                    />
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="input-form-content row full align-center">
                                <div
                                    className={`column ${
                                        this.state.isFianzaDevolucionSet ? "label-duo-big" : "label-medium"
                                    }`}
                                >
                                    <p>
                                        <b>Devolución fianza:</b>
                                    </p>
                                </div>
                                <div className="column full">
                                    <input
                                        className={`input input-modals ${
                                            this.state.isFianzaDevolucionSet ? "text-left" : "text-right"
                                        }`}
                                        type="number"
                                        required
                                        min={0}
                                        value={this.state.fianza_devolucion}
                                        onChange={event => {
                                            this.setState({ fianza_devolucion: event.target.value });
                                        }}
                                        disabled={this.state.fianza_devolucion_disabled}
                                    />
                                </div>

                                {this.state.isFianzaDevolucionSet && (
                                    <>
                                        <div className="column label-duo-normal space">
                                            <p>
                                                <b>Devuelto por:</b>
                                            </p>
                                        </div>
                                        <div className="column full">
                                            <input
                                                className="input input-modals"
                                                type="text"
                                                disabled
                                                value={
                                                    this.state.devuelto ? this.state.devuelto : this.state.user.usuario
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                    )}

                    <div className="input-form-content row full align-center">
                        <div className="column label-duo-big">
                            <p>
                                <b>Fecha de Creación:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals"
                                type="text"
                                value={this.state.fecha_reserva_creacion}
                                disabled
                            />
                        </div>

                        <div className="column label-duo-normal space">
                            <p>
                                <b>Creado por:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                className="input input-modals"
                                type="text"
                                disabled
                                value={this.state.reserva_creacion}
                            />
                        </div>
                    </div>

                    <div className="white-space-16"></div>

                    {this.state.cancelada === "Cancelada" && (
                        <>
                            <div className="input-form-content row full align-center">
                                <div className="column label-duo-big">
                                    <p>
                                        <b>Fecha de Cancelación:</b>
                                    </p>
                                </div>
                                <div className="column full">
                                    <input
                                        className="input input-modals"
                                        type="text"
                                        value={this.state.fecha_reserva_cancelacion}
                                        disabled
                                    />
                                </div>

                                <div className="column label-duo-normal space">
                                    <p>
                                        <b>Cancelado por:</b>
                                    </p>
                                </div>
                                <div className="column full">
                                    <input
                                        className="input input-modals"
                                        type="text"
                                        disabled
                                        value={this.state.reserva_cancelacion}
                                    />
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                        </>
                    )}

                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p>
                                <b>Comentarios:</b>
                            </p>
                        </div>
                    </div>
                    <div className="white-space-16"></div>

                    <div className="input-form-content row full align-center">
                        <div className="column full">
                            <textarea
                                className="textarea input input-modals"
                                cols="30"
                                rows="3"
                                value={this.state.comentarios}
                                onChange={event => {
                                    this.setState({ comentarios: event.target.value });
                                }}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReservationTable2;
