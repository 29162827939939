import React, { Fragment, useRef, useState } from "react";
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import Request from "../../core/httpClient";
import {
  NumberCustomCell,
  NumberCustomCellTemplate,
} from "./NumberCustomCellTemplate";

const request = new Request();

const meses = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

//transformar el arreglo al formato que admite la tabla para poder mostrarlo como un arbol
const transformLogsToModel = (logs) => {
  return logs.map((log, idx) => ({
    rowId: log.id_concepto,
    cells: [
      log.id_parent !== 0
        ? getTextCellAndParent(log.nombre, log.id_parent)
        : getTextCell(log.nombre),
      getNumberCell(log.mes1),
      getNumberCell(log.mes2),
      getNumberCell(log.mes3),
      getNumberCell(log.mes4),
      getNumberCell(log.mes5),
      getNumberCell(log.mes6),
      getNumberCell(log.mes7),
      getNumberCell(log.mes8),
      getNumberCell(log.mes9),
      getNumberCell(log.mes10),
      getNumberCell(log.mes11),
      getNumberCell(log.mes12),
      getNumberCell(0),
    ],
  }));
};

//ordenar los datos recibidos a formato: arbol con sus ramas
const TransformRowsToTreeGridFormat = (lista) => {
  let listaFinal = [];
  let arboles = lista.filter((item) => item.id_parent === 0);
  for (let index = 0; index < arboles.length; index++) {
    listaFinal.push(arboles[index]);
    lista
      .filter((item) => item.id_parent === arboles[index].id_concepto)
      .forEach((element) => {
        listaFinal.push(element);
        lista
          .filter((item) => item.id_parent === element.id_concepto)
          .forEach((elemento) => {
            listaFinal.push(elemento);
          });
      });
  }
  return listaFinal;
};

const getTextCell = (text, id) => ({
  type: "chevron",
  text: text || "",
  isExpanded: true,
});
const getTextCellAndParent = (text, id) => ({
  type: "chevron",
  text: text || "",
  parentId: id,
  isExpanded: true,
});
const getNumberCell = (value) => ({
  type: "number_custom",
  value: value || 0,
  nanToZero: true,
  format: Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }),
});
const getTotalNumberCell = (value) => ({
  type: "number_custom",
  value: value || 0,
  nanToZero: true,
  // style: { background: "rgba(0,0,0,0.05)" },
  nonEditable: true,
  format: Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }),
});

const getDirectChildRows = (rows, parentRow) =>
  rows.filter(
    (row) =>
      !!row.cells.find(
        (cell) => cell.type === "chevron" && cell.parentId === parentRow.rowId
      )
  );

//busca una celda de tipo 'chevron' dada una fila
const findChevronCell = (row) =>
  row.cells.find((cell) => cell.type === "chevron");

//verifica si la fila tiene hijos
const hasChildren = (rows, row) =>
  rows.some((r) => {
    const foundChevronCell = findChevronCell(r);
    return foundChevronCell ? foundChevronCell.parentId === row.rowId : false;
  });

const assignIndentAndHasChildren = (rows, parentRow, indent = 0, nivel) => {
  ++indent;
  getDirectChildRows(rows, parentRow).forEach((row) => {
    const foundChevronCell = findChevronCell(row);
    const hasRowChildrens = hasChildren(rows, row);
    if (foundChevronCell) {
      foundChevronCell.indent = indent;
      foundChevronCell.hasChildren = hasRowChildrens;
    }
    if (indent >= nivel - 1) {
      foundChevronCell.isExpanded = false;
    }
    if (hasRowChildrens) assignIndentAndHasChildren(rows, row, indent, nivel);
  });
};

//busca el padre de una fila dada
const findParentRow = (rows, row) =>
  rows.find((r) => {
    const foundChevronCell = findChevronCell(row);
    return foundChevronCell ? r.rowId === foundChevronCell.parentId : false;
  });

//verifica si la fila está expandida
const isRowFullyExpanded = (rows, row) => {
  const parentRow = findParentRow(rows, row);
  if (parentRow) {
    const foundChevronCell = findChevronCell(parentRow);
    if (foundChevronCell && !foundChevronCell.isExpanded) return false;
    return isRowFullyExpanded(rows, parentRow);
  }
  return true;
};

//obtener las filas expandidas
const getExpandedRows = (rows) =>
  rows.filter((row) => {
    const areAllParentsExpanded = isRowFullyExpanded(rows, row);
    return areAllParentsExpanded !== undefined ? areAllParentsExpanded : true;
  });

//construir el arbol de conceptos
const buildTree = (rows, nivel) =>
  rows.map((row) => {
    const foundChevronCell = findChevronCell(row);
    if (foundChevronCell && !foundChevronCell.parentId) {
      const hasRowChildrens = hasChildren(rows, row);
      if (nivel === 1) {
        foundChevronCell.isExpanded = false;
      }
      foundChevronCell.hasChildren = hasRowChildrens;
      if (hasRowChildrens) assignIndentAndHasChildren(rows, row, 0, nivel);
    }
    if (foundChevronCell) {
      const hasRowChildrens = hasChildren(rows, row);
      if (hasRowChildrens) {
        for (let index = 0; index < row.cells.length; index++) {
          const cell = row.cells[index];
          // cell["style"] = { background: "rgba(0,0,0,0.05)" };
          if (index !== 0) cell.nonEditable = true;
        }
      }
    }

    //aplicar un color a cada nivel de profundidad
    for (let index = 0; index < row.cells.length; index++) {
      const cell = row.cells[0];
      switch (cell.indent) {
        case 1:
          row.cells[index].style = { background: "rgba(161,94,189,0.2)", "flex-direction": "row-reverse" };
          if (index === 0)
            row.cells[index].style = { background: "rgba(161,94,189,0.2)" };
          break;
        case 2:
          row.cells[index].style = { background: "rgba(161,94,189,0.07)", "flex-direction": "row-reverse" };
          if (index === 0)
            row.cells[index].style = { background: "rgba(161,94,189,0.07)" };
          break;

        //0
        default:
          if (cell.type !== 'header') {
            //padres nivel 1
            row.cells[index].style = { background: "rgba(161,94,189,0.5)", "flex-direction": "row-reverse" };

            if (index === 0)
              row.cells[index].style = { background: "rgba(161,94,189,0.5)" };
          }
          else {
            //header
            row.cells[index].style = { background: "rgba(161,94,189,1)", color: "rgba(255,255,255,1" };
          }
          //footer
          if (row.rowId === "Total") {
            row.cells[index].style = { background: "rgba(161,94,189,0.5)", "font-weight": "bold", "flex-direction": "row-reverse" };
            if (index === 0)
              row.cells[index].style = { background: "rgba(161,94,189,0.5)", "font-weight": "bold" };

          }
          break;
      }
    }

    return row;
  });

//------------------------------------------------

const PresupuestoGrid = (props) => {
  const [columns, setColumns] = useState(() => [
    { columnId: "Conceptos", width: 200, resizable: true },
    //    { columnId: "Monto", width: 100},
    { columnId: meses[props.presupuesto.mes], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 1], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 2], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 3], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 4], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 5], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 6], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 7], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 8], width: 100, resizable: true },
    { columnId: meses[props.presupuesto.mes + 9], width: 100, resizable: true },
    {
      columnId: meses[props.presupuesto.mes + 10],
      width: 100,
      resizable: true,
    },
    {
      columnId: meses[props.presupuesto.mes + 11],
      width: 100,
      resizable: true,
    },
    { columnId: "Suma", width: 150, resizable: true },
  ]);

  //encabezados de la tabla
  const headerrow = {
    rowId: 0,
    cells: [
      { type: "header", text: "Conceptos" },
      { type: "header", text: meses[props.presupuesto.mes] },
      { type: "header", text: meses[props.presupuesto.mes + 1] },
      { type: "header", text: meses[props.presupuesto.mes + 2] },
      { type: "header", text: meses[props.presupuesto.mes + 3] },
      { type: "header", text: meses[props.presupuesto.mes + 4] },
      { type: "header", text: meses[props.presupuesto.mes + 5] },
      { type: "header", text: meses[props.presupuesto.mes + 6] },
      { type: "header", text: meses[props.presupuesto.mes + 7] },
      { type: "header", text: meses[props.presupuesto.mes + 8] },
      { type: "header", text: meses[props.presupuesto.mes + 9] },
      { type: "header", text: meses[props.presupuesto.mes + 10] },
      { type: "header", text: meses[props.presupuesto.mes + 11] },
      { type: "header", text: "Suma" },
    ],
  };
  const footerrow = {
    rowId: "Total",
    cells: [
      {
        type: "chevron",
        text: "Total",
        nonEditable: true,
      },
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
      getTotalNumberCell(0),
    ],
  };
  const ordererdArray = TransformRowsToTreeGridFormat(
    props.presupuestos_importe[0]
  );
  const formattedArray = transformLogsToModel(ordererdArray);
  // console.log("%o", mixArray);
  formattedArray.unshift(headerrow);
  formattedArray.push(footerrow);
  const [rows, setRows] = useState(() =>
    buildTree(formattedArray, props.presupuesto.nivel)
  );
  const [rowsToRender, setRowsToRender] = useState(() =>
    getExpandedRows(formattedArray)
  );

  const ref = useRef();

  useEffect(() => {
    handleChanges(rows);
  }, []);

  //controlar los cambios de una celda
  const handleChanges = (changes) => {
    let total = 0;
    let total_parent = 0;
    let total_parent_general = 0;
    const newRows = [...rows];
    const SumaColumnIdx = columns.findIndex((el) => el.columnId === "Suma");
    const TotalRownIdx = rows.findIndex((el) => el.rowId === "Total");

    changes.forEach((change) => {
      const changeRowIdx = newRows.findIndex((el) => el.rowId === change.rowId);
      const changeColumnIdx = columns.findIndex(
        (el) => el.columnId === change.columnId
      );

      //guardar el nuevo valor en la celda q se quiere modificar
      newRows[changeRowIdx].cells[changeColumnIdx] = change.newCell;

      //la primera vez que se modifica el importe del primer mes, se modifican los demas meses
      if (changeColumnIdx === 1 && change.previousCell.value === 0) {
        newRows[changeRowIdx].cells.forEach((row, index) => {
          if (row.type === "number_custom" && index !== SumaColumnIdx)
            newRows[changeRowIdx].cells[index].value = change.newCell.value;
        });
      }

      //calcular el importe  anual por cada concepto
      newRows[changeRowIdx].cells.forEach((row, index) => {
        if (row.type === "number_custom" && index !== SumaColumnIdx)
          total += row.value;
      });

      //guardar dicho valor en la celda correspondiente al total
      newRows[changeRowIdx].cells[SumaColumnIdx].value = total;
      total = 0;
      if (changeColumnIdx !== -1) {
        //------------------------------------------------------------------------------------------
        var idx = changeRowIdx;

        while (newRows[idx].cells[0].hasOwnProperty("parentId")) {
          //obtener el idRow del padre de dicha celda
          const parent_idRow = newRows[idx].cells[0].parentId;

          //encontrar la fila donde se encuentra el padre
          const parentRowIdx = newRows.findIndex(
            (el) => el.rowId === parent_idRow
          );

          //encontrar  todos sus hijos
          const childRows = newRows.filter(
            (el) => el.cells[0].parentId === parent_idRow
          );

          //limpiar el valor q tenga el padre
          newRows[parentRowIdx].cells[changeColumnIdx].value = 0;

          //actualizar el importe del padre sumandole el importe de los hijos
          childRows.forEach((child) => {
            newRows[parentRowIdx].cells[changeColumnIdx].value +=
              child.cells[changeColumnIdx].value;
          });

          //la primera vez que se modifica el importe del primer mes, se modifica ademas el total del padre por cada mes
          if (changeColumnIdx === 1 && change.previousCell.value === 0) {
            newRows[changeRowIdx].cells.forEach((row, index) => {
              //primero se limpia el valor del padre
              newRows[parentRowIdx].cells[index].value = 0;
              childRows.forEach((child) => {
                newRows[parentRowIdx].cells[index].value +=
                  child.cells[index].value;
              });
            });
          }

          //calcular el importe  anual para cada padre modificado
          newRows[parentRowIdx].cells.forEach((row, index) => {
            if (row.type === "number_custom" && index !== SumaColumnIdx)
              total_parent += row.value;
          });

          //guardar dicho valor en la celda correspondiente al total anual del padre
          newRows[parentRowIdx].cells[SumaColumnIdx].value = total_parent;
          total_parent = 0;
          idx = parentRowIdx;
        }

      }
    });
    const parents = newRows.filter((item) => !item.cells[0].hasOwnProperty("parentId"));

    for (let mes = 1; mes <= 13; mes++) {
      //sumar el valor de todos los conceptos padres por meses
      for (let index = 1; index < parents.length - 1; index++) {
        total_parent_general += parents[index].cells[mes].value;
      }
      newRows[TotalRownIdx].cells[mes].value = total_parent_general;
      total_parent_general = 0;
    }

    setRows((newRows) => newRows);
    setRowsToRender(() => getExpandedRows(newRows));
  };

  //cambiar el ancho de la columnas
  const handleColumnResize = (ci, width) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  //guardar el importe mensual por cada concepto
  const GuardarImportes = async () => {
    ref.current.className = "fas fa-spinner fa-spin";
    let data = [];

    rows.forEach((row) => {
      if (row.rowId !== 0 && row.rowId !== 'Total') {
        data.push({
          id_presupuesto: props.presupuesto.id_presupuesto,
          id_concepto: row.rowId,
          mes1: row.cells[1].value,
          mes2: row.cells[2].value,
          mes3: row.cells[3].value,
          mes4: row.cells[4].value,
          mes5: row.cells[5].value,
          mes6: row.cells[6].value,
          mes7: row.cells[7].value,
          mes8: row.cells[8].value,
          mes9: row.cells[9].value,
          mes10: row.cells[10].value,
          mes11: row.cells[11].value,
          mes12: row.cells[12].value,
        });
      }
    });

    const response = await request.post(
      "/presupuestos/presupuesto/importe/update",
      data
    );
    ref.current.className = "fa fa-save font-text";
    if (response.updated) {
      cogoToast.success("Importes guardados");
    } else {
      cogoToast.error("No se pudieron guardar los importes");
    }
  };

  return (
    <div>
      <div className="buttons-div justify-end" style={{ paddingRight: "7px" }}>
        <button
          className="btn btn-primary btn-small color-white"
          onClick={GuardarImportes}
        >
          <i
            ref={ref}
            className="fa fa-save font-text"
            style={{ padding: "8px" }}
          ></i>
          Guardar
        </button>
      </div>
      <div className="white-space-8" />
      <div
        className="test-grid-container"
        style={{
          width: "67vw",
          height: "40vh",
          overflow: "auto",
        }}
      >
        <ReactGrid
          rows={rowsToRender}
          columns={columns}
          enableRowSelection
          enableRangeSelection
          enableColumnSelection
          onCellsChanged={handleChanges}
          onColumnResized={handleColumnResize}
          stickyTopRows={1}
          stickyBottomRows={1}
          stickyLeftColumns={1}
          stickyRightColumns={1}
          enableFillHandle
          customCellTemplates={{
            number_custom: new NumberCustomCellTemplate(),
          }}
        />
      </div>
    </div>
  );
};

export default PresupuestoGrid;