import React from 'react';

import SuperCore from './../../components/core/SuperCore';

import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import Sidebar from "../../components/header/sidebar";
import Modal1 from '../../components/pruebas/modal1';
import ComponenteChido from './../../components/pruebas/ComponenteChido';
import ComponenteChido2 from './../../components/pruebas/ComponenteChido2';
import ComponenteChido3 from './../../components/pruebas/ComponenteChido3';

class Pruebas extends SuperCore{

    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        return(
            <div className="admin column">
            <Helmet>
                <title>{process.env.REACT_APP_NAME} - Operación</title>
            </Helmet>
            <Header/>
            <div className="row">
                <div className="column" id="sidebar">
                    <Sidebar />
                </div>
                <div className="column" id="content">
                    <div className="justify-center">
                        <div className="container column">
                            <div className="white-space-32" />
                            <div className="card-table card-owners column" id="card-owners">
                                <div className="row">
                                <h1>Pruebas</h1>
                                </div>
				                <div className="row">
                                    {/* <div className      =   "container "> */}
                                        <button className   =   "btn  btn-primary btn-small color-white btn-action-success"
                                            onClick     =   {(event)=>{
                                            this.superOpenModal('modal1')
                                            }}
                                        >Modal 1</button>
                                    {/* </div> */}
                                    &nbsp;
                                        <button className   =   "btn  btn-primary btn-small color-white btn-action-success"
                                            onClick     =   {(event)=>{
                                            this.superOpenModal('modal2')
                                            }}
                                        >Modal 2</button>

                                    &nbsp;
                                        <button className   =   "btn  btn-primary btn-small color-white btn-action-success"
                                            onClick     =   {(event)=>{
                                            this.superOpenModal('modal3')
                                            }}
                                        >Modal 3</button>
                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal1
                id              = 'modal1'
                title           = 'Agregar invitado'
                prefijo         = ''

                id_menu_activo  = {0}
                menus           = {['Información','Imágenes']}
                screens         ={[<ComponenteChido></ComponenteChido>,<ComponenteChido2></ComponenteChido2>]}

                nexFooter = {true}
                onSubmit = {()=>{
                    //console.log("enviando");
                }}

                showSubmit = {true}
                showClose = {true}
            > </Modal1>

               <Modal1
                id              = 'modal2'
                title           = 'Agregar invitado'
                prefijo         = 'DOS'

                id_menu_activo  = {0}
                menus           = {['Información','Imágenes']}
                screens         ={[<ComponenteChido></ComponenteChido>,<ComponenteChido2></ComponenteChido2>]}

                nexFooter = {true}
                onSubmit = {()=>{
                    //console.log("enviando");
                }}

                showSubmit = {true}
                showClose = {true}
            > </Modal1>

            <Modal1
                id              = 'modal3'
                title           = 'Agregar invitado'
                prefijo         = 'TRES'

                id_menu_activo  = {0}
                menus           = {['Información','Imágenes']}
                screens         ={[<ComponenteChido3></ComponenteChido3>,<ComponenteChido2></ComponenteChido2>]}

                nexFooter = {true}
                onSubmit = {()=>{
                    //console.log("enviando");
                }}

                showSubmit = {true}
                showClose = {true}
            > </Modal1>
            
        </div>
        );
    }



}

export default Pruebas;