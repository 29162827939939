// React and third-parties
import React from "react";

// Components
import { selectDoc, getAllDocsSelected } from "./timbradoDocsSelection";

// HTTP
import Request from '../../core/httpClient';

const request = new Request();

const docSelectionKey = "Id_DocTimbrado";

const Timbrado = (props) => {

	const { docsStatus, selectedDocs, textFilter, loadingTimbrado, loadingSubmition,
		setTextFilter, setSelectedDocs, onStartSubmitting, onFinishedSubmitting } = props;

	const submitStatus = async (event) => {
		event.preventDefault();

		const setStatus = async () => {

			if (selectedDocs.length <= 0) {
				return { warning: "Debes seleccionar al menos factura con error." };
			}

			let id_doc_status = "";
			selectedDocs.forEach((id) => {
				id_doc_status += id + ",";
			});
			id_doc_status = id_doc_status.substring(0, id_doc_status.length - 1);

			const res = await request.post("/documentos/facturas/validar/timbrado", {
				ids: id_doc_status,
			});

			if (res.error) {
				return { error: "No se pudieron validar los documentos." };
			}

			if (res.validated) {
				return { successful: "Se validaron de manera correcta las facturas." };
			}
		};

		onStartSubmitting();

		const response = await setStatus();

		onFinishedSubmitting(response);
	}

	const filterDocumentos = () => {

		if (!docsStatus) return [];

		if (textFilter === "") return docsStatus;

		const filterLowerCased = textFilter.toLowerCase();

		return docsStatus.filter(doc =>
			doc.NumFolio.toLowerCase().includes(filterLowerCased)
			|| doc.NumDoc.toLowerCase().includes(filterLowerCased)
			|| doc.numero_registro.toLowerCase().includes(filterLowerCased)
			|| doc.RazonSocial.toLowerCase().includes(filterLowerCased)
		)
	}

	const selectNewTimbradoStatus = (docs, allDocsSelected, id_doc = 0) => {

		const newDocsSelected = selectDoc(id_doc, selectedDocs, allDocsSelected, docs, docSelectionKey);
		setSelectedDocs(newDocsSelected);
	}

	const filteredDocumentos = filterDocumentos();
	const allDocsSelected = textFilter !== "" ?
		getAllDocsSelected(selectedDocs, filteredDocumentos, docSelectionKey)
		: !loadingTimbrado ? selectedDocs.length === filteredDocumentos.length && filteredDocumentos.length > 0
			: false;

	return (
		<div className="container" style={{ width: "95%", height: "100%" }}>
			<div className="column full">
				<div className="container container-control">
					<div className="filts column" style={{ paddingBlock: 8 }}>
						<div className="row align-center">
							<div className="search-div">
								<div className="input-clean">
									<input
										className="input input-filter"
										type="text"
										name="textFilter"
										id="filterData"
										placeholder="Buscar"
										value={textFilter}
										onChange={(event) => setTextFilter(event.target.value)}
									/>
									<i className="fas fa-search font-small" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="table-history table-responsive-sat" id="card-owners" style={{ flexGrow: 1 }}>
					<table className="full">
						<thead>
							<tr>
								<th className="td-transparent text-center th-options" style={{ minWidth: 50, paddingBlock: 4 }}>
									<label className="container-check" style={{ paddingLeft: 0 }}>
										<input
											type="checkbox"
											checked={allDocsSelected}
											name="viviendas-check"
											onChange={() => selectNewTimbradoStatus(filteredDocumentos, allDocsSelected)}
										/>
										<span className="checkmark"></span>
									</label>
								</th>
								<th className="th-sticky text-left">N° Folio</th>
								<th className="th-sticky text-left">Fecha</th>
								<th className="th-sticky text-left">N° Doc.</th>
								<th className="th-sticky text-left">Vivienda</th>
								<th className="th-sticky text-left">Receptor</th>
								<th className="th-sticky text-left">Error</th>
							</tr>
						</thead>
						<tbody>
							{loadingTimbrado ? (
								<tr>
									<td colSpan="7" className="td-transparent text-center">
										<div className="white-space-16"></div>
										<i className="fas fa-spinner fa-spin"></i>
										<div className="white-space-16"></div>
									</td>
								</tr>
							) : filteredDocumentos.length == 0 ? (
								<tr>
									<td colSpan="7" className="td-transparent text-center">
										Sin datos que mostrar.
									</td>
								</tr>
							) : (
								filteredDocumentos.map((doc) => {
									return (
										<tr key={doc.Id_DocTimbrado}>
											<td className="td-transparent text-center no-border" style={{ paddingBlock: 2 }}>
												<label className="container-check" style={{ paddingLeft: 0 }} >
													<input
														type="checkbox"
														checked={selectedDocs.includes(doc.Id_DocTimbrado)}
														name="viviendas-check"
														onChange={() => selectNewTimbradoStatus(filteredDocumentos, allDocsSelected, doc.Id_DocTimbrado)}
													/>
													<span className="checkmark"></span>
												</label>
											</td>
											<td className="text-left">{doc.NumFolio}</td>
											<td className="text-left">{doc.Fecha_Creacion}</td>
											<td className="text-left">{doc.NumDoc}</td>
											<td className="text-left">{doc.numero_registro}</td>
											<td className="text-left">{doc.RazonSocial}</td>
											<td className="text-left">{doc.sError}</td>
										</tr>
									);
								})
							)}
						</tbody>
					</table>
				</div>
				<div className="justify-center align-center" style={{ height: 60 }}>
					{loadingTimbrado ?
						<div></div>
						: loadingSubmition ?
							<i className="fas fa-spinner fa-spin"></i>
							:
							<div className="row full justify-end">
								{selectedDocs.length > 0 &&
									<p style={{ paddingRight: 8 }}>
										{selectedDocs.length + " documento(s) a validar"}
									</p>
								}
								<button className="btn btn-primary btn-small color-white" onClick={submitStatus}>
									&nbsp;Validar Factura
								</button>
							</div>
					}
				</div>
			</div>
		</div>
	);
};

export default Timbrado;
