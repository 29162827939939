/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de todos los propietarios
 */

import React, {Component} from "react";
import moment from "moment-timezone";
import PhoneInput, {parsePhoneNumber} from "react-phone-number-input";

class Owners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalOwners: false,
			user: {
				idUsuario: 0,
				idPerfil: 0,
				idFraccionamiento: 0,
			},
			houses: [],
			error: false,
			reload: false,
			fraccionamientoNombre: "",
			propietarios: [],
			idSubfrac: 0,
			searchHouse: false,
			nombre: "",
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: 0,
			pId: 0,
			pNumId: "",
			pVigencia: "",
			pFechaCompra: "",
			pFechaVenta: "",
			pIdPersona: 0,
			pviv: "",
			pidViv: 0,
			page: 1,
			filterData: [],
			filter: "",
			keys: [],
			email: "",
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			subfraccionamientos: [],
			citye: "",
			subLabel: ''
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if (this.props.telefonoError !== prevProps.telefonoError) {
			this.setState({telefonoError: this.props.telefonoError});
		}
		if (this.props.contacto !== prevProps.contacto) {
			let phone = "";
			if (this.props.contacto.telefono) {
				phone = parsePhoneNumber(`+${this.props.contacto.telefono}`) || "";
				phone = phone.number;
			}
			this.setState({
				IIdPersona: this.props.contacto.id_persona,
				INombre: this.props.contacto.nombre || "",
				IDireccion: this.props.contacto.direccion || "",
				IMunicipio: this.props.contacto.municipio || "",
				IEstado: this.props.contacto.estado || "",
				ICp: this.props.contacto.cp || "",
				iTelefono: phone || "",
				IPais: this.props.contacto.pais || "",
				IGenero: this.props.contacto.genero || 0,
				IId: this.props.contacto.tipo_identificacion || 0,
				iNumId: this.props.contacto.num_identificacion || "",
				IVigencia: this.props.contacto.vigencia_identificacion || "",
				IFechaIngreso: moment(this.props.contacto.fecha_entrada).format("YYYY-MM-DD"),
				IFechaSalida: moment(this.props.contacto.fecha_salida).format("YYYY-MM-DD"),
				code: this.props.contacto.code || "",
				Iviv: this.props.contacto.numero_registro || "",
				IdViv: this.props.contacto.id_vivienda || 0,
				email: this.props.contacto.email || "",
				citye: this.props.contacto.ciudad
			});

			const principal = document.getElementById("contacto-principal");
			if (principal) {
				principal.checked = this.props.contacto.principal;
			}
		}
	}

	render() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> {this.props.subLabel}: </b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="sub" value={this.props.subname} className="input input-modals" autoComplete="off" disabled />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="vivienda"
								value={this.state.Iviv}
								onChange={(event) => this.setState({Iviv: event.target.value})}
								autoComplete="off"
								required
								disabled
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-cente">
						<div className="column label-medium">
							<p>
								<b> Teléfono: </b>
							</p>
						</div>
						<div className="column full">
							<PhoneInput
								name="phoneNumber"
								defaultCountry="MX"
								value={this.state.iTelefono}
								onChange={(value) => this.setState({iTelefono: value, telefonoError: undefined})}
								error={this.state.telefonoError}
								disabled={true}
								readOnly
								autoComplete="off"
								maxLength="18"
								displayInitialValueAsLocalNumber
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b> Nombre completo:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								className="input input-modals"
								id="namef"
								name="fullName"
								value={this.state.INombre}
								onChange={(event) => this.setState({INombre: event.target.value})}
								autoComplete="off"
								required
								disabled
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p className="justify-start">
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="sex" value={this.state.IGenero} onChange={(event) => this.setState({IGenero: event.target.value})} required>
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Correo :</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="email" name="fullName" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} autoComplete="off" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="id" id="idEddit" value={this.state.IId} onChange={(event) => this.setState({IId: event.target.value})}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="numIdEddit"
								value={this.state.iNumId}
								onChange={(event) => this.setState({iNumId: event.target.value})}
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="address"
								value={this.state.IDireccion}
								onChange={(event) => this.setState({IDireccion: event.target.value})}
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="municipality"
								onChange={(event) => this.setState({IMunicipio: event.target.value})}
								value={this.state.IMunicipio}
								required
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								value={this.state.ICp}
								onChange={(event) => this.setState({ICp: event.target.value})}
								name="zip"
								required
								maxLength="10"
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name="state"
								value={this.state.IEstado}
								onChange={(event) => this.setState({IEstado: event.target.value})}
								required
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" value={this.state.IPais} onChange={(event) => this.setState({IPais: event.target.value})} name="country" required autoComplete="off" />
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start">
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								value={this.state.citye}
								onChange={(event) => this.setState({citye: event.target.value})}
								name="citye"
								id="citye"
								required
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-small space">
							<p>
								<b>&nbsp;</b>
							</p>
						</div>
						<div className="column">
							{/* <input 
								className	=	"input input-modals"  
								type		=	"checkbox" 
								name		=	"principal" 
								id			=	"contacto-principal" 
							/> */}
							<label className="container-check">
								<input
									type="checkbox"
									id="contacto-principal"
									// checked	    =	{this.state.residente == 1 ? (true) : (false)}
									name="principal"
									// onChange	=	{(event) => {
									// 	//console.log(this.state.residente);
									// 	this.setState({ residente: event.target.checked })
									// }}
								/>
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<p>
								<b>Marcar como contacto principal</b>
							</p>
						</div>
					</div>
					<div className="white-space-8" />
				</div>
			</div>
		);
	}
}

export default Owners;
