import React from "react";
import Request from "../../core/httpClient";

import ConceptosTable from "./conceptosTable";
import moment from "moment-timezone";
import SuperCore from "../../components/core/SuperCore";
import SuperModal from "../../components/coreModal/SuperModal";
import ModalBorrar from "../modals/modalDelete";
import EditRule from "../../components/auxiliar/editRule";
import cogoToast from "cogo-toast";

const request = new Request();

const conceptsTablePropsInit = {
	recargos_aplica: [],
	conceptsList: [],
	recargosList: [],
	frecListReglas: [],
	frecList: [],
};

class RulesTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			rules: [],
			rule: [],
			id_ruleActivo: [],
			conceptos: [],
			ruleData: [],
			subfraccionamientos: this.props.subfraccionamientos,
			frac_name: this.props.frac_name,
			id_regla_edit: 0,
			name_rule: null,
			comentarios: null,
			id_sub: 0,
			id_rule: 0,
			nombre_sub: "",
			editSubFraccionamiento: false,
			clearData: false,
			loadingConceptos: false,
			saving: false,
			subLabel: this.props.subLabel,
			...conceptsTablePropsInit,
			loadingConceptsTableProps: true
		};
	};

	async getRecargosAplica() {
		try {

			const response = await request.post("/facturacion/recargo/frecuencia/aplica", {});

			if (response.error || response.empty) {
				return [];
			};

			if (response.recargo) {
				return response.recargo;
			};

		} catch (error) {
			console.log(error);
			return [];
		};
	};

	async getListConceptsRecargos() {
		try {

			const fraccionamiento = localStorage.getItem("frac");
	
			let data = {
				id_frac: fraccionamiento,
				filtro: 2, // recargos
			};
	
			const response = await request.post("/facturacion/productos", data);
	
			if (response.error || response.empty) {
	
				return [];
			};
	
			if (response.productos) {
	
				return response.productos;
			};
		} catch (error) {
			console.log(error);
			return [];
		}
	};

	async getListConceptsCargos() {
		try {

			const fraccionamiento = localStorage.getItem("frac");
	
			let data = {
				id_frac: fraccionamiento,
				filtro: 1, // cargos
			};
	
			const response = await request.post("/facturacion/productos", data);
	
			if (response.error || response.empty) {
	
				return [];
			};
	
			if (response.productos) {
				return response.productos;
			};
		} catch (error) {
			console.log(error);
			return [];
		}
	};

	async getFrecuency() {
		try {
			
			const response = await request.post("/facturacion/recargo/frecuencia", []);
	
			if (response.error || response.empty) {
				return [];
			};
	
			if (response.frecuencias) {
				return response.frecuencias;
			};
		} catch (error) {
			console.log(error);
			return [];
		}
	};

	async getRulesFrecuency() {
		try {
			
			const response = await request.post("/facturacion/reglas/frecuencia", {});
	
			if (response.error || response.empty) {
				return [];
			};
	
			if (response.frecuencias) {
				return response.frecuencias;
			};
		} catch (error) {
			console.log(error);
			return [];
		}
	};

	async componentDidMount() {

		this.setState({ loadingConceptsTableProps: true });

		const [recargos_aplica, conceptsList, recargosList, frecListReglas, frecList] =
			await Promise.all([
				this.getRecargosAplica(),
				this.getListConceptsCargos(),
				this.getListConceptsRecargos(),
				this.getRulesFrecuency(),
				this.getFrecuency(),
			]);

		this.setState({
			recargos_aplica, conceptsList, recargosList, frecListReglas, frecList,
			loadingConceptsTableProps: false
		});
	}

	async componentDidUpdate(prevProps, prevState) {

		if (prevProps.rules != this.props.rules) {
			this.setState({ rules: this.props.rules });
		};

		if (this.props.subfraccionamientos != prevProps.subfraccionamientos) {
			this.setState({ subfraccionamientos: this.props.subfraccionamientos, });
		};

		if (this.props.frac_name != prevProps.frac_name) {
			this.setState({ frac_name: this.props.frac_name, });
		};

		if (prevState.ruleData != this.state.ruleData) {
			this.setState({
				name_rule: this.state.ruleData.Nombre,
				comentarios: this.state.ruleData.Comentarios,
			});
		};

		if (this.props.subLabel != prevProps.subLabel) {
			this.setState({ subLabel: this.props.subLabel });
		};
	};

	async openRow(id) {

		if (id == this.state.id_ruleActivo) {

			this.setState({ id_ruleActivo: 0 });
			this.setState({ conceptos: [] });

		} else {

			await this.setState({
				id_ruleActivo: id,
				id_regla_row: id
			});

			await this.getProductRules();

		};

	};

	async getProductRules() {

		try {

			this.setState({ loadingConceptos: true });

			const data = { id_Rule: this.state.id_ruleActivo };

			const response = await request.post("/facturacion/reglas/producto", data);

			if (response.empty || response.error) {
				this.setState({ conceptos: [] });
			};

			if (response.rulesProduct) {
				this.setState({ conceptos: response.rulesProduct });
			};

			this.setState({ loadingConceptos: false });

		} catch (error) {
			console.log(error);
		};
	};

	async editModal(id, data) {
		this.setState({
			clearData: false,
			id_regla_edit: data.Id_Regla,
			id_sub: data.Id_Subfraccionamiento == null ? 0 : data.Id_Subfraccionamiento,
			name_rule: data.Nombre,
			comentarios: data.Comentarios,
			editSubFraccionamiento: data.used == 0,
			id_regla_row: data.Id_Regla,
		});
		await this.superOpenModal(id);
	};

	updateData(json) {
		this.setState(json);
	};

	async cloneRule(id_rule) {
		try {
			const usuario = await this.getUserInfo();

			const data = {
				id_regla: id_rule,
				usuario: usuario.info.usuario,
				fecha: moment().format("YYYY-MM-DD"),
			};

			const response = await request.post("/facturacion/reglas/duplicar", data);

			if (response.empty || response.error) {
				cogoToast.error("No fue posible duplicar regla.", {
					position: "bottom-center",
					// heading:	'Crear nuevo producto'
				});
				return;
			};

			if (response.regla[0].EXITO != 1) {
				cogoToast.error("No fue posible duplicar regla.", {
					position: "bottom-center",
					// heading:	'Crear nuevo producto'
				});
				return;
			};

			if (response.regla[0].EXITO == 1) {
				cogoToast.success(`Regla creada con éxito.`, {
					position: "bottom-center",
					// heading:	'Crear nuevo producto'
				});
				this.props.reload();
			};

		} catch (error) {
			console.log(error);
		};
	};

	async beforeOpenModalDelete(idModal, rule) {
		this.setState({ id_rule: rule.Id_Regla, id_regla_row: rule.Id_Regla });
		await this.openModalDelete(idModal);
	};

	async delete(id) {
		try {
			const data = { id_rule: id };

			const response = await request.post("/facturacion/reglas/delete", data);

			if (!response || response.error) {
				cogoToast.error("No fue posible eliminar registro.", {
					position: "bottom-center",
					// heading:	'Crear nuevo producto'
				});
			};

			if (response.updated) {
				cogoToast.success("Registro eliminado con éxito.", {
					position: "bottom-center",
					// heading:	'Crear nuevo producto'
				});
				await this.props.reload();
			};

			await this.closeModalDelete("deleteModal");

		} catch (error) {
			console.log(error);
		};
	};

	triggerCloseModal() {
		this.setState({ clearData: true });
	};

	async submit() {
		try {
			this.setState({ saving: true });

			const user = await this.getUserInfo();

			const data = {
				id_regla: this.state.id_regla_edit,
				nombre: this.state.name_rule,
				comentarios: this.state.comentarios,
				id_subfraccionamiento: parseInt(this.state.id_sub) === 0 ? null : parseInt(this.state.id_sub),
				usuario_name: user.info.usuario,
				fecha_update: moment().format("YYYY-MM-DD HH:mm:ss")
			};

			const response = await request.post("/facturacion/reglas/update", data);

			if (response.error || !response.created) {
				cogoToast.error("No se pudo editar la información.", {
					position: "bottom-center",
				});
				this.setState({ saving: false });
				return;
			} else {
				cogoToast.success("Registro creado con éxito.", {
					position: "bottom-center",
				});
				this.setState({ clearData: true });
				await this.superCloseModal("editRule");
				await this.props.reload();
			};

			this.setState({ saving: false });
		} catch (error) {
			console.log(error);
		};
	};

	render() {
		const screen = [
			<EditRule
				subfraccionamientos={this.state.subfraccionamientos}
				id_sub={this.state.id_sub}
				name_rule={this.state.name_rule}
				comentarios={this.state.comentarios}
				updateData={this.updateData.bind(this)}
				editSubFraccionamiento={this.state.editSubFraccionamiento}
				clearData={this.state.clearData}
				subLabel={this.state.subLabel}
			// subfrac_name
			/>,
		];

		let rules = []
		if (Array.isArray(this.props.rules)) {
			rules = this.props.rules;
		}

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="row">
					<table className="full">
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="text-start">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "Nombre" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "Nombre")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "Nombre")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "Nombre")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-start">
									<div className="row">
										Fecha &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "Fecha_Add" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "Fecha_Add")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "Fecha_Add")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "Fecha_Add")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-center">Conceptos</th>
								<th className="text-center th-options">Editar</th>
								<th className="text-center th-options">Eliminar</th>
								<th className="text-center th-options">Duplicar</th>
							</tr>
						</thead>
						<tbody>
							{this.props.loading ?
								<tr>
									<td colSpan="6">
										<div className="row">
											<div className="white-space-8"></div>
										</div>
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
										<div className="row">
											<div className="white-space-8"></div>
										</div>
									</td>
								</tr> 
								:
								rules.length == 0 ? (
									<tr>
										<td colSpan="6" className="text-center">
											Sin datos que mostrar.
										</td>
									</tr>
								) : (
									rules.map((rule) => (
										<React.Fragment key={rule.Id_Regla}>
											<tr className={this.state.id_regla_row === rule.Id_Regla ? "row-active" : ""}>
												<td className="text-start">{rule.Nombre}</td>
												<td className="text-start">
													{moment(rule.Fecha_Add).locale('es').format("MMMYY").toLowerCase().replace(".", "-")}
												</td>
												<td>
													<button
														className="btn-full btn-mini btn-secondary color-white"
														type="button "
														onClick={this.openRow.bind(this, rule.Id_Regla)}
													>
														<i className="font-small fas fa-info-circle" />
													</button>
												</td>
												<td>
													<button
														className="btn-full btn-mini btn-secondary color-white"
														type="button "
														onClick={this.editModal.bind(this, "editRule", rule)}
													>
														<i className="font-small fas fa-pen" />
													</button>
												</td>
												<td className="text-center">
													<button
														className="btn-full btn-mini btn-secondary color-white"
														type="button "
														onClick={this.beforeOpenModalDelete.bind(this, "deleteModal", rule)}
													>
														<i className="font-small fas fa-trash-alt" />
													</button>
												</td>
												<td>
													<button
														className="btn-full btn-mini btn-secondary color-white"
														type="button "
														onClick={this.cloneRule.bind(this, rule.Id_Regla)}
													>
														<i className="font-small fas fa-copy" />
													</button>
												</td>
											</tr>
											{this.state.id_ruleActivo === rule.Id_Regla ? (
												<tr className="acordeon-row">
													<td className="acordeon" colSpan="8">
														<div className="row full">
															<ConceptosTable
																conceptos={this.state.conceptos}
																id_frac={rule.Id_Fraccionamiento}
																id_subfrac={rule.Id_Subfraccionamiento}
																id_regla={rule.Id_Regla}
																getProductRules={this.getProductRules.bind(this)}
																recargos_aplica={this.state.recargos_aplica}
																conceptsList={this.state.conceptsList}
																recargosList={this.state.recargosList}
																frecListReglas={this.state.frecListReglas}
																frecList={this.state.frecList}
																loading={
																	this.state.loadingConceptos 
																	|| this.state.loadingConceptsTableProps 
																}
															/>
														</div>
													</td>
												</tr>
											) : null}
										</React.Fragment>
									))
							)}
						</tbody>
					</table>
				</div>

				<SuperModal
					id={"editRule"}
					title={"Editar regla de facturación"}
					screens={screen}
					size={"small"}
					onSubmit={this.submit.bind(this)}
					triggerCloseModal={this.triggerCloseModal.bind(this)}
					loading={this.state.saving}
				/>

				<ModalBorrar
					id={"deleteModal"}
					delete={this.delete.bind(this, this.state.id_rule)}
				/>
			</div>
		);
	};
};

export default RulesTable;
