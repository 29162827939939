import React, { Component } from 'react';
import moment from "moment";
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import Request from "../../core/httpClient";
const request = new Request();

class PropietarioEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 0,
            pNombre: '',
            IDireccion: '',
            IMunicipio: '',
            IEstado: '',
            ICp: '',
            pTelefono: '',
            IPais: '',
            IGenero: 0,
            IId: '',
            INumId: '',
            IVigencia: '',
            IFechaIngreso: '',
            code: '',
            Iviv: '',
            IIdViv: 0,
			email: '',
			pFechaVenta: '',
			citye:			'',
			comentarios:        '',
			es_residente:''
		}
		
	}
	

	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if(this.props.telefonoError !== prevProps.telefonoError) {
			this.setState({telefonoError: this.props.telefonoError});
		}
		
		if(this.props.propietario !== prevProps.propietario) {
            let phone = '';
            const { telefono } = this.props.propietario;
			if(telefono) {
				phone = parsePhoneNumber(`+${telefono}`);
				if(phone) {
					phone = phone.number;
				}
			}
			this.setState({ 
				pIdPersona: 		this.props.propietario.id_persona,
				pNombre: 			this.props.propietario.nombre,
				pDireccion: 		this.props.propietario.direccion,
				pMunicipio: 		this.props.propietario.municipio,
				pEstado: 			this.props.propietario.estado,
				pCp: 				this.props.propietario.cp,
				pTelefono: 			phone,
				pPais: 				this.props.propietario.pais,
				pGenero: 			this.props.propietario.genero,
				pId: 				this.props.propietario.tipo_identificacion,
				pNumId: 			this.props.propietario.num_identificacion,
				pVigencia: 			this.props.propietario.vigencia_identificacion,
				pFechaCompra: 		moment(this.props.propietario.fecha_compra).format("YYYY-MM-DD"),
				pFechaVenta: 		moment(this.props.propietario.fecha_venta).format("YYYY-MM-DD"),
				code: 				this.props.propietario.code,
				Iviv: 				this.props.propietario.numero_registro,
				pIdViv: 			this.props.propietario.id_vivienda,
				email: 				this.props.propietario.email,
				citye:				this.props.propietario.ciudad || '',
				comentarios: 	    this.props.propietario.comentarios || '',
				es_residente:       this.props.propietario.es_residente|| ''
			});
			//console.log(this.props.propietario);
			// this.refs['input' + this.props.line ].focus();
		}
	}


    render() {
        return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> {this.props.subLabel}:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="subfraccionamiento" disabled value={this.props.subname}
								className="input input-modals" placeholder="Subfraccionamiento" 
							/>
						</div>
					</div>
                    <div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> Unidad:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="vivienda" disabled value={this.state.Iviv}
								onChange={event => this.setState({ Iviv: event.target.value })}
                                className="input input-modals"
                                placeholder="N° de vivienda"
                                required
                                maxLength="64"/>
						</div>
					</div>
					<div className="white-space-8"></div>
                    <div className="input-form-content row full align-cente">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b><b style={{color:'red'}}> * </b></p>
						</div>
						<div className="column full">
						<PhoneInput
							displayInitialValueAsLocalNumber
							name="phoneNumber"
							defaultCountry="MX"
							maxLength = "18"
							placeholder="Número de teléfono"
							value={this.state.pTelefono}
							disabled={true}
							onChange={ value => this.setState({ pTelefono: value, telefonoError: undefined }) }
							error={ this.state.telefonoError }
                            readOnly/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b><b style={{color:'red'}}> * </b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.pNombre}
							id="editownername"
                                onChange={event => this.setState({ pNombre: event.target.value })}
                                className="input input-modals"
                                placeholder="Nombre completo del propietario"
                                required
                                maxLength="64"
                                disabled/>
						</div>
					</div>
                    <div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
                        <div className="column label-duo-big">
                            <p className="justify-start" style={{  paddingLeft: '2px' }}>
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.pGenero} required
								onChange={event => this.setState({ pGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p style={{ paddingLeft: '20px', paddingRight: '0px' }}>
								<b>Correo :</b>
							</p>
						</div>
						<div className="column full">
							<input type="email" name="fullName" value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.pId} onChange={event => this.setState({ pId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numIdEddit" value={this.state.pNumId}
									onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' /* required */ maxLength="24" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.pDireccion}
								onChange={event => this.setState({ pDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" /* required */ maxLength="150" />
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								type		=	"text" 
								name		=	"municipality" 
								value		=	{this.state.pMunicipio}
								onChange	=	{event => this.setState({ pMunicipio: event.target.value })} 
								className	=	"input input-modals" 
								placeholder	=	'Municipio' 
								/* required */		 
								maxLength	=	"80" 
							/>
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								type		=	"text" 
								value		=	{this.state.citye}
								onChange	=	{event => this.setState({ citye: event.target.value })} 
								className	=	'input input-modals' 
								id			=	'citye' 
								placeholder =	'Ciudad'
								/* required */
							/>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p style={{ paddingLeft: '2px', paddingRight: '0px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
						<input type="text" value={this.state.pCp}
								onChange={event => this.setState({ pCp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' /* required */ maxLength="10" />
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.pEstado}
								onChange={event => this.setState({ pEstado: event.target.value })} className="input input-modals" placeholder='Estado' /* required */ maxLength="24" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.pPais}
								onChange={event => this.setState({ pPais: event.target.value })} name="country" className="input input-modals" placeholder='País' /* required */ maxLength="10" />
						</div>
						<div className="column label-duo-normal space">
							
						</div>
						<div className="column full">
							
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Fecha de compra:</b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.pFechaCompra}
								onChange={event => this.setState({ pFechaCompra: event.target.value })} required className='input input-modals' id='fcompra' />
						</div>
						<div className="column label-duo-normal space">
							<p>
								<b>Fecha de venta:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								type		=	"date" 
								value		=	{this.state.pFechaVenta}
								onChange	=	{event => this.setState({ pFechaVenta: event.target.value })} 
								className	=	'input input-modals' 
								id			=	'fventaedit' 
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Comentarios:</b>
							</p>
						</div>
						<div className="column full">
						<textarea 
                            name="comentarios"
                            id="comentarios_inquilinos"
                            value={this.state.comentarios}
                            cols="30"
                            rows="3"
                            maxLength="500"
                            className='textarea font full'					
						 onChange={(event) => this.setState({comentarios: event.target.value})} >
                        </textarea>
						</div>
						<div className="column label-duo-normal space">
						<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="esInquilino" id="esInquilino" disabled="disabled"  checked={this.state.es_residente ? true : false} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Es residente </b>{" "}
							</h5>
						</div>
					</div>
						</div>
						<div className="column full">
						
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							<b style={{color:'red'}}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
						
					</div>

				</div>
			</div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }
}

export default PropietarioEditarModal;