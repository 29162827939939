import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import moment from 'moment-timezone';
import PersonasTable from "../components/personas/personasTable";
import Header from "../components/header/header";
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Sidebar from "../components/header/sidebar";
import Request from "../core/httpClient";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Permissions from '../middlewares/Permissions';
import StringHelper from '../helpers/StringHelper';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
const request = new Request();


class Personas extends Component {
    constructor(props) {
        super(props);
        this.state = {
			fraccionamiento: 		localStorage.getItem('frac') || 0,
			subfraccionamiento: 	localStorage.getItem('subfrac') || 0,
			subfraccionamientos: 	[],
			filter: 				'',
			personas: 				[],
			escritura:				false,
			subf:					localStorage.getItem('subfrac') || 0,
			filter2:				'',
			phoneValidated:         false,
			escritura:				false,
			lectura:				false,
			logo:					require ("../img/logo-AppMosphera-r.png"),
			pageLength: 			20, 
			direction: {
				nombre: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: ''
		}
		
		this.validarPermisos();
    }

    componentDidMount() {
		this.getPersonas();
		this.getSubFraccionamientos();
		this.getSubmenu();
    }

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura:permisosInfo.lectura});
		
		 //console.log(permisosInfo);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getPersonas();
		}
	}

    async getPersonas() {
        this.setState({loadingPersonas: true});
		const response = await request.post("/personas/getbyfraccionamiento", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.personas)) {
			this.setState({personas: response.personas});
		} else {
			this.setState({personas: []});
        }
        this.setState({loadingPersonas: false});
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    reload() {
        this.getPersonas();
	}

	subChange(sub) {
		this.setState({subfraccionamiento: sub, subf:sub});
		this.getPersonas();
	}

	async openModal(idModal) {
		this.setState({message: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	async clearCheckboxs(){
		let checks = document.getElementsByName("viviendas-check");
		checks.forEach(check => {
			check.checked = false;
		});	
		
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
	}


	filter(personas) {
		personas = personas.filter(inq => {
			if(inq.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if(inq.telefono.toLowerCase().indexOf(this.state.filter) !== -1){
				return true;
			} else {
				return false;
			}
		})
		return personas;
	}

	async closeModal(id) {
		
		const modal = document.getElementById(id);
		if( modal){
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
		this.setState({
			IidViv: 			0,
			IIdPersona: 		0,
			INombre: 			'',
			iTelefono: 			'',
			IDireccion: 		'',
			email: 				'',
			IId: 				0,
			iNumId: 			'',
			IGenero: 			0,
			IDireccion: 		'',
			IMunicipio: 		'',
			ICp: 				'',
			IEstado: 			'',
			IPais: 				'',
			saveMessage: 		'',
			city:				'',
			viviendasJSON:		[],
            viviendas_json_db:	[],
            phoneValidated:     false
		});
		await this.clearCheckboxs();
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.personas, this.state.direction[key]);

		this.setState({
			personas: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

    render() {
		let personas = [];
		if(Array.isArray(this.state.personas)) {
			personas = this.state.personas;
			personas = this.filter(personas)
		}
        return (
            <div className="owners column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header reload={this.reload.bind(this)} sidebar={true} active={'inquilinos'} parent={'admin'} panel={'panel1'} nav={"catalogos"} />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
								{this.state.lectura ?
								<React.Fragment>
									<Controls 
									title		=	{this.state.submenu}
									nuevo		=	{this.openModal.bind(this, 'modal-personas')}
									dataSet		=	{personas}
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
									newButton   =   {false}
									selectSub   =   {false}
								/>
								{this.state.loadingPersonas?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<PersonasTable 
										personas	=	{personas} 
										page		=	{this.state.page} 
										reload		=	{this.reload.bind(this)} 
										escritura	=	{this.state.escritura}
										pageLength	=   {this.state.pageLength}
										sortBy		=	{this.sortableTable.bind(this)}
										filterColumn=	{this.state.filterColumn}
									/>
                                }
								<div className="white-space-16"></div>
								<Paginador 
									pages={Math.ceil(personas.length / this.state.pageLength)} 
									setPage={this.setPage.bind(this)} 
									pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}/>
                           		<div className="white-space-16"></div>
								</React.Fragment>:
								null
								}
						    </div>
                        </div>
                    </div>
                </div>
				<DinamicModal 
					idModal				=	{'modal-persona'}
					sizeModalContainer	=	{'super'}
					title				=	{'INFORMACION DE PERSONA'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{this.state.escritura}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
                    closeModal			=	{this.closeModal.bind(this)}
                    disabledBtnSuccess  =   {!this.state.phoneValidated}
				>
					{this.personaForm()}
				</DinamicModal>
            </div>
        );
	}

    async validarTelefono(telefono) {
        this.setState({loadingVlidatePhone: true, phoneValidated: false});
        if (isValidPhoneNumber(telefono)) {
            const data = {
                telefono,
                id_fraccionamiento: this.state.fraccionamiento
            }
            const res = await request.post('/personas/getbyphone', data);
            const { persona } = res;
            if (persona) {
                this.setState({
                    INombre: persona.nombre || '',
                    email: persona.email || '',
                    IGenero: persona.genero || 0,
                    IDireccion: persona.direccion || '',
                    ICp: persona.cp || '',
                    city: persona.ciudad || '',
                    IMunicipio: persona.municipio || '',
                    IEstado: persona.estado || '',
                    IPais: persona.pais || '',
                    IId: persona.tipo_identificacion || 0,
                    iNumId: persona.num_identificacion || '',
                    IIdPersona: persona.id_persona || 0,
                    persona_existente: persona.encontrado
                });
            } else {
                this.setState({
                    INombre: '',
                    email: '',
                    IGenero: 0,
                    IDireccion: '',
                    ICp: '',
                    city: '',
                    IMunicipio: '',
                    IEstado: '',
                    IPais: '',
                    IId: 0,
                    iNumId: '',
                    IIdPersona: 0,
                    persona_existente: false
                });
            }
            this.setState({phoneValidated: true});
        } else {
			cogoToast.warn('El teléfono no es válido.', {
				position: 'bottom-right',
			});
            //alert('El teléfono no es válido');
        }
        this.setState({loadingVlidatePhone: false});
    }

	personaForm() {
		let subfraccionamientos = [];
		if(Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}
		return (
			<div className="full row align-center">
				<div className="column full">	
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
                        <div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="row full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								defaultCountry="MX"
								autoComplete = 'off'
								maxLength = "18"
								value={this.state.iTelefono}
								onChange={ value => this.setState({ iTelefono: value, telefonoError: undefined, phoneValidated: false }) }
								error={ this.state.telefonoError }
								className={'input-modals'}
                                required/>
                            <button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white" onClick={this.validarTelefono.bind(this, this.state.iTelefono)}>
                                {this.state.loadingVlidatePhone? <i className="fas fa-spinner fa-spin"></i>: 'Validar'}  
                            </button>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
                        <div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
                            <input 
                                type		=	"text" 
                                name		=	"fullName" 
                                id			=	"fullName"
                                value		=	{this.state.INombre}
                                onChange	=	{event => this.setState({ INombre: event.target.value })}
								className	=	"input input-modals" 
								autoComplete = 'off'
                                maxLength	=	"64" 
                                required
                                disabled    = {this.state.persona_existente}
                            />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
                        <div className="column label-duo-big">
							<p className="justify-start">
								<b>  Género:	</b>
							</p>
						</div>
						<div className="column full">
							<select name="sex" id="" value={this.state.IGenero} required
								onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
                            <div className="justify-start" style={{  paddingLeft: '20px' }}>
							    <p><b>  Correo:	</b></p>
                            </div>
						</div>
						<div className="column full">
							<input type="email" name="fullName" value={this.state.email}
								onChange={event => this.setState({ email: event.target.value })} className="input input-modals" placeholder="Email" maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Identificación:		</b></p>
						</div>
						<div className="column full">
							<select name="id" id="idEddit" className="input input-modals" value={this.state.iId} onChange={event => this.setState({ iId: event.target.value })}>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>  ID :	</b>
							</p>
						</div>
						<div className="column full">
						<	input type="text" name="numIdEddit" value={this.state.iNumId}
							onChange={event => this.setState({ iNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" />
					
						</div>
					</div>
					
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="address" value={this.state.IDireccion}
								onChange={event => this.setState({ IDireccion: event.target.value })} className="input input-modals" placeholder="Dirección de contacto" required maxLength="150" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="municipality" value={this.state.IMunicipio}
								onChange={event => this.setState({ IMunicipio: event.target.value })} className="input input-modals" placeholder='Municipio' required maxLength="80" />
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" value={this.state.ICp}
								onChange={event => this.setState({ ICp: event.target.value })} name="zip" className="input input-modals" placeholder='Código postal' required maxLength="10" />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="state" value={this.state.IEstado}
								onChange={event => this.setState({ IEstado: event.target.value })} className="input input-modals" placeholder='Estado' required maxLength="24" />
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start" style={{  paddingLeft: '20px' }}>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
						<input type="text" value={this.state.IPais}
								onChange={event => this.setState({ IPais: event.target.value })} name="country" className="input input-modals" placeholder='País' required maxLength="10" />
					</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big ">
							<p>
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input className='input input-modals'  placeholder ="Ciudad" required
								type		=	"text" 
								value		=	{this.state.city}
								onChange	=	{event => this.setState({ city: event.target.value })} 
								id			=	'city' 
							/>
						</div>
						<div className="column label-duo-normal">
							<p>
								<b>Fecha de ingreso:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.IFechaIngreso}
								onChange={event => this.setState({ IFechaIngreso: event.target.value })} required className='input input-modals' id='fcompra' />
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium ">
							<p>
								<b>Fecha de salida:</b>
							</p>
						</div>
						<div className="column full">
							<input type="date" value={this.state.IFechaSalida}
								onChange={event => this.setState({ IFechaSalida: event.target.value })} className='input input-modals' id='fcompra' />
						</div>
					</div>
					<div className="white-space-8" />
					<div className="row justify-center">
						{this.state.saveMessage}
					</div>
				</div>
			</div>
		)
	}

	getChecksChecked(checks){
		let checksChecked = [];
		checks.forEach( (check)=>{
			let subfrac = parseInt(this.state.subf);
			if(!check.subf || parseInt(check.subf) === subfrac){
				try{
					// {id_vivienda: 80, subf: 25}
					checksChecked.push(check.id_vivienda);
					//console.log(`${check.id_vivienda}`)
				}catch(err){
					//console.log(err);
				}
			}
		});
		return checksChecked;
		
	}

    async handleSubmit(event) {
        event.preventDefault();
		this.setState({loadingSave: true, message: ''});

		let checks 		= this.state.viviendas_json_db;
		//console.log(checks);
		let checksJson 	= this.getChecksChecked(checks); 

		let jsonSTR = this.filtrarViviendasSeleccionadas(checksJson);

		/**Validar Vivienda */
		if (!(checksJson.length > 0)) {
			this.setState({loadingSave: false});
			cogoToast.warn('Para continuar debes seleccionar una vivienda.', {
				position: 'bottom-right',
			});
            //alert("Para continuar debes seleccionar una vivienda")
            return;
		}
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.iTelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
        
            let data = {
				// idVivienda: 		this.state.IidViv,
				viviendasJSON:		JSON.stringify(jsonSTR),
                Nombre: 			this.state.INombre,
                Direccion: 			this.state.IDireccion,
                Municipio: 			this.state.IMunicipio,
                Estado: 			this.state.IEstado,
                cp: 				this.state.ICp,
                Telefono: 			this.state.iTelefono,
                pais: 				this.state.IPais,
                Genero: 			this.state.IGenero,
                Id: 				this.state.IId,
                NumId: 				this.state.iNumId,
                Vigencia: 			this.state.iVigencia,
				Fechaingreso: 		this.state.IFechaIngreso,
				FechaSalida: 		this.state.IFechaSalida,
                IdPersona: 			this.state.IIdPersona || 0, //era 0
                Code: 				this.state.code,
                email: 				this.state.email,
				idFraccionamiento: 	this.state.fraccionamiento,
				city:				this.state.city
            }
			
			//console.log(data);
			const response = await request.post("/viviendas/inquilino/crear", data);
			//console.log(response);
			if (response.created) {
				this.setState({ add: false, message: "RESIDENTE CREADO CORRECTAMENTE" });
				this.reload();
				this.closeModal("modal-inquilino");
				this.setState({IIdPersona: 0});
			} else {
				this.setState({
					empty: 		true,
					message: 	"NO SE PUDO AGREGAR RESIDENTE",
					loading: 	false
				});
			}
			
        
		this.setState({loadingSave: false});
    }

	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento, filter:1 }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({	subfraccionamientos: [] });
		}
	}
}

export default Consumer(Personas);