import React, {Component} from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

class NewDoc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			monthStart: this.props.monthStart,
			fracName: this.props.fracName,
			subfraccionamientos: this.props.subfracs,
			id_sub: this.props.subfrac,
			id_sub_init: this.props.subfrac,
			userName: this.props.userName,
			generation: this.props.generation,
			dataDocs: this.props.dataDocs,
			confirmDocs: this.props.confirmDocs,
			loadingDataGeneracion: this.props.loadingDataGeneracion,
		};
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.props.subfracs != prevProps.subfracs) {
			await this.setState({
				subfraccionamientos: this.props.subfracs,
			});
		}

		if (this.props.confirmDocs != prevProps.confirmDocs) {
			await this.setState({
				confirmDocs: this.props.confirmDocs,
			});
		}

		if (this.props.fracName != prevProps.fracName) {
			await this.setState({
				fracName: this.props.fracName,
			});
		}

		if (this.props.subfrac != prevProps.subfrac) {
			await this.setState({
				id_sub: this.props.subfrac,
			});
		}

		if (this.props.subfrac != prevProps.subfrac) {
			await this.setState({
				id_sub_init: this.props.subfrac,
			});
		}

		if (this.props.userName != prevProps.userName) {
			await this.setState({
				userName: this.props.userName,
			});
		}

		if (this.props.monthStart != prevProps.monthStart) {
			await this.setState({
				monthStart: this.props.monthStart,
			});
		}

		if (this.props.generation != prevProps.generation) {
			await this.setState({
				generation: this.props.generation,
			});
		}

		if (this.props.dataDocs != prevProps.dataDocs) {
			await this.setState({
				dataDocs: this.props.dataDocs,
			});
		}

		if (this.props.totalDocs != prevProps.totalDocs) {
			await this.setState({
				totalDocs: this.props.totalDocs,
			});
		}

		if (this.props.loadingDataGeneracion != prevProps.loadingDataGeneracion) {
			await this.setState({
				loadingDataGeneracion: this.props.loadingDataGeneracion,
			});
		}
	}

	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};

	handleChangeMonth = (date) => {
		this.updateData({monthStart: date});
	};

	async updateData(data) {
		this.props.updateData(data);
		await this.setState(data);
	}

	render() {
		let subfrac = [];
		if (this.state.subfraccionamientos) {
			subfrac = this.state.subfraccionamientos;
		}

		let dataTable = [];
		if (this.state.dataDocs) {
			dataTable = this.state.dataDocs;
		}

		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-16"></div>
					<div className="row label-medium justify-start align-start">
						<p>
							<b>Reglas:</b>
						</p>
					</div>
					<div className="white-space-8"></div>
					<div className="row full card-table">
						<table className=" full">
							<thead>
								<tr>
									<th className="th-options text-center">Mes</th>
									<th className="th-options">Regla</th>
									<th className="th-options text-center">N° de viviendas</th>
								</tr>
							</thead>
							<tbody>
								{this.state.loadingDataGeneracion ? (
									<tr>
										<td colSpan="3" className="text-center">
											<div className="row">
												<div className="white-space-8"></div>
											</div>
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											<div className="row">
												<div className="white-space-8"></div>
											</div>
										</td>
									</tr>
								) : dataTable.length == 0 ? (
									<tr>
										<td colSpan="3" className="text-center">
											Sin datos que mostrar.
										</td>
									</tr>
								) : (
									dataTable.map((doc, key) => {
										let dataRes = Object.values(doc);
										return (
											<tr key={key}>
												<td className="text-center">{dataRes[1]}</td>
												<td>{dataRes[2]}</td>
												<td className="text-center">{dataRes[4]}</td>
											</tr>
										);
									})
								)}
							</tbody>
						</table>
					</div>
					<div className="white-space-16"></div>
					<label className="container-check container-check-billing">
						{this.props.disabledConfirm ? (
							<p>
								<b>Se podrán generar documentos a partir del día {moment(this.props.disableConfirmDate).tz("GMT").format("DD/MM/YYYY")}</b>
							</p>
						) : (
							<>
								<input
									type="checkbox"
									name="numDocs"
									id="numDocs"
									className="check-option"
									checked={this.state.confirmDocs ? true : false}
									onChange={(event) => {
										this.updateData({confirmDocs: !this.state.confirmDocs});
									}}
								/>
								<span className="checkmark"></span>
								<p>
									<b>Se van a generar {this.state.totalDocs} documentos de cobro</b>
								</p>
							</>
						)}
					</label>
				</div>
			</div>
		);
	}
}

export default NewDoc;
