import React, { Component } from "react";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment";
import ModalBorrar from "../modals/modalDelete";
import Impuestos from "./impuestosTable";
import SuperCore from "./../core/SuperCore";
import { isNumber } from "react-simple-timefield/dist";

const request = new Request();

class ProductTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			unidadesSAT: [],
			tipos: [],
			productos: this.props.productos,
			nombre: "",
			clave: "",
			descripcion: "",
			claveSAT: "",
			id_unidadSAT: 0,
			id_tipo: 0,
			id_producto: 0,
			producto: null,
			impuestos: [],
			id_productoActivo: 0,
			productoImpuesto: [],
			Prelacion: 0,
			multifacturar: false,
		};
	}
	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.unidadesSAT != this.props.unidadesSAT) {
			await this.setState({ unidadesSAT: this.props.unidadesSAT });
		}

		if (prevProps.tipos != this.props.tipos) {
			await this.setState({ tipos: this.props.tipos });
		}

		if (prevProps.productos != this.props.productos) {
			await this.setState({ productos: this.props.productos });
		}
		if (prevState.producto != this.state.producto) {
			let p = this.state.producto;
			//console.log(p);
			await this.setState({
				id_producto: p.Id_Producto,
				nombre: p.Nombre,
				clave: p.Clave,
				descripcion: p.Descripcion,
				claveSAT: p.ClaveSAT,
				id_unidadSAT: p.ClaveUnidad,
				id_tipo: p.Id_ProductoTipo,
				multifacturar: p.multifactura == 1,
				Prelacion: p.Prelacion,
			});
		}

		if (prevProps.impuestos != this.props.impuestos) {
			await this.setState({ impuestos: this.props.impuestos });
		}
	}

	async beforeOpenModalDelete(idModal, product) {
		await this.setState({ producto: product, id_producto_row: product.Id_Producto });
		this.openModalDelete(idModal);
	}

	async openRow(id) {
		if (id == this.state.id_productoActivo) {
			await this.setState({ id_productoActivo: 0 });
		} else {
			await this.setState({ id_productoActivo: id, id_producto_row: id });
			await this.getProductosImpuestos();
		}
	}

	render() {
		let productos = this.state.productos;
		//console.log(productos);
		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="row">
					<table className="full">
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="text-center">
									<div className="row">
										Código &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'Clave' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'Clave')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'Clave')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'Clave')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-left th-options-md">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'Nombre' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'Nombre')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'Nombre')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'Nombre')}></i>
											}
										</div>
									</div>
								</th>
								{/* <th className="text-center th-options">Clave SAT</th> */}
								<th className="text-left description">
									<div className="row">
										Descripción &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'Descripcion' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'Descripcion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'Descripcion')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'Descripcion')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options">
									<div className="row">
										Tipo &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'Tipo' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'Tipo')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'Tipo')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'Tipo')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small">Impuestos</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{this.props.loadingProductos ?

								<tr>
									<td className="text-center" colSpan={7} style={{ height: 200 }}>
										<i className="fas fa-spinner fa-spin"></i>
									</td>
								</tr>
							:
							productos.map((product, key) => (
								<React.Fragment key={key}>
									<tr className={this.state.id_producto_row === product.Id_Producto ? 'row-active' : ''}>
										<td className="text-center">{product.Clave}</td>
										<td className="text-left">{product.Nombre}</td>
										<td className="text-left">{product.Descripcion}</td>
										<td className="text-left">{product.Tipo}</td>
										{/* <td className="text-center">{product.ClaveUnidad}</td> */}
										<td>
											<button className="btn-full btn-mini btn-secondary color-white" type="button " onClick={this.openRow.bind(this, product.Id_Producto)}>
												<i className="font-small fas fa-file-alt" />
											</button>
										</td>
										<td>
											<button
												className="btn-full btn-mini btn-secondary color-white"
												type="button "
												onClick={(event) => {
													this.setState({ producto: product, id_producto_row: product.Id_Producto });
													this.openModal("editProduct");
												}}
											>
												<i className="font-small fas fa-pen" />
											</button>
										</td>
										<td className="text-center">
											<button className="btn-full btn-mini btn-secondary color-white" type="button " onClick={this.beforeOpenModalDelete.bind(this, "deleteModal", product)}>
												<i className="font-small fas fa-trash-alt" />
											</button>
										</td>
									</tr>
									{this.state.id_productoActivo === product.Id_Producto ? (
										<tr className="acordeon-row">
											<td className="acordeon" colSpan="8">
												<div className="row full">
													<Impuestos
														impuestos={this.props.impuestos}
														escritura={this.props.escritura}
														id_productoActivo={this.state.id_productoActivo}
														tipos={this.props.tipos}
														productoImpuesto={this.state.productoImpuesto}
														getProductosImpuestos={this.getProductosImpuestos.bind(this)}
													></Impuestos>
												</div>
											</td>
										</tr>
									) : null}
								</React.Fragment>
							))}
						</tbody>
					</table>
				</div>

				<DinamicModal
					idModal={"editProduct"}
					sizeModalContainer={"small"}
					title={"Editar producto"}
					success={this.handledSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
				>
					{this.renderEditProduct()}
				</DinamicModal>

				<ModalBorrar id={"modalEliminar"} delete={this.delete.bind(this, this.state.id_producto)} />
			</div>
		);
	}

	async getProductosImpuestos() {
		let data = { id_producto: this.state.id_productoActivo };
		const response = await request.post("/facturacion/productos/impuestos", data);

		if (response.empty || response.error) {
			await this.setState({ productoImpuesto: [] });
		}

		if (response.impuestos) {
			await this.setState({ productoImpuesto: response.impuestos });
		}
	}

	async delete(id) {
		let data = { id_product: id };
		const response = await request.post("/facturacion/productos/delete", data);
		if (!response || response.error) {
			cogoToast.error("No fue posible eliminar registro.", {
				position: "bottom-center",
				// heading:	'Crear nuevo producto'
			});
		}

		if (response.updated) {
			cogoToast.success("Registro eliminado con éxito.", {
				position: "bottom-center",
				// heading:	'Crear nuevo producto'
			});
			this.props.getProducts();
		}
		this.closeModalDelete("deleteModal");
	}
	renderEditProduct() {
		return (
			<div className="full row ">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								autoComplete="off"
								type="text"
								name="nombre"
								id="nombre"
								required
								value={this.state.nombre}
								onChange={(event) => {
									this.setState({ nombre: event.target.value });
								}}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Código:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								autoComplete="off"
								type="text"
								name="Codigo"
								id="Codigo"
								required
								value={this.state.clave}
								onChange={(event) => {
									this.setState({ clave: event.target.value });
								}}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Descripción:</b>
							</p>
						</div>
						<div className="column full">
							<textarea
								className="input input-modals"
								name="description"
								required
								value={this.state.descripcion}
								onChange={(event) => {
									this.setState({ descripcion: event.target.value });
								}}
								autoComplete="off"
							></textarea>
						</div>
					</div>
					<div className="white-space-8"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Clave SAT:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								autoComplete="off"
								type="text"
								name="clave"
								id="clave"
								minLength={8}
								maxLength={8}
								required
								value={this.state.claveSAT}
								onChange={(event) => {
									this.setState({ claveSAT: event.target.value });
								}}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Unidad SAT:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="unidades" value={this.state.id_unidadSAT} onChange={(event) => this.setState({ id_unidadSAT: event.target.value })} required>
								<option value={0} disabled>
									Selecciona una vivienda
								</option>
								{this.state.unidadesSAT.map((unidad, key) => (
									<option value={unidad.ClaveUnidad} key={key}>
										{unidad.Descripcion}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>

					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Tipo:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="tipos" value={this.state.id_tipo} onChange={(event) => this.setState({ id_tipo: event.target.value })} required>
								<option value={0} disabled>
									Selecciona un tipo de producto
								</option>
								{this.state.tipos.map((tipo, key) => (
									<option value={tipo.Id_TipoImpuesto} key={key}>
										{tipo.Nombre}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Prelación:</b>
							</p>
						</div>
						<div className="row full">
							<div className="column tooltip bottom" data-tooltip="Modificar en ventana de Prelaciones">
								<input
									className="input input-modals"
									autoComplete="off"
									type="number"
									name="prioridad"
									id="prioridad"
									minLength={8}
									maxLength={8}
									disabled={true}
									value={this.state.Prelacion}
								/>
							</div>

							<div className="column label-normal space">
								<p>
									<b>Multifacturar:</b>
								</p>
							</div>
							<div className="column  space container-check">
								<label>
									<input
										type="checkbox"
										name="multifacturar"
										id="multifacturar"
										checked={this.state.multifacturar}
										onChange={(event) => {
											this.setState({ multifacturar: event.target.checked });
										}}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
				</div>
			</div>
		);
	}

	async componentDidMount() { }

	async handledSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });

			let datosValidados = true;

			if (this.state.id_unidadSAT === 0) {
				datosValidados = false;
				cogoToast.warn("Debes de elegir una vivienda SAT.", {
					position: "bottom-center",
				});
			}

			if (this.state.id_tipo === 0) {
				datosValidados = false;

				cogoToast.warn("Debes elegir un tipo de producto.", {
					position: "bottom-center",
				});
			}

			if (!datosValidados) {
				this.setState({ loadingSave: false });
				return;
			}

			const user = await this.getUserInfo();

			const data = {
				id_frac: parseInt(localStorage.getItem("frac")) || 0,
				id_product: this.state.id_producto,
				nombre: this.state.nombre,
				clave: this.state.clave,
				descripcion: this.state.descripcion,
				claveSAT: this.state.claveSAT,
				claveUnidad: this.state.id_unidadSAT,
				id_producto_tipo: this.state.id_tipo,
				usuario: user.info.usuario,
				date: moment().tz("GMT-5").format("YYYY-MM-DD HH:mm:ss"),
				Prelacion: this.state.Prelacion,
				multifacturar: this.state.multifacturar ? 1 : 0,
			};

			const response = await request.post("/facturacion/productos/update", data);

			if (!response || response.error) {
				cogoToast.error(response.message || "No se pudo editar producto.", {
					position: "bottom-center",
					heading: "Editar producto",
				});
			};

			if (response.updated) {
				cogoToast.success("Producto editado con éxito.", {
					position: "bottom-center",
				});
				this.closeModal("editProduct");
				await this.props.getProducts();
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};
};

export default ProductTable;
