import React, { Component } from "react";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import Request from "../../core/httpClient";
import ReactExport from "react-export-excel";
import NumberFormat from "react-number-format";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

registerLocale("es", es);
const request = new Request();

class PaymentHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subfraccionamientos: this.props.subfraccionamientos,
			fracName: this.props.fracName,
			id_sub: this.props.id_sub,
			Id_Vivienda: this.props.Id_Vivienda,
			unidad: this.props.unidad,
			Descuento: 0,
			Saldo: 0,
			Saf: 0,
			Recargos: 0,
			desde: null,
			hasta: null,
			loadingDataHistory: false,
			edosCuenta: [],
			subLabel: this.props.subLabel
		};
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.subfraccionamientos != this.props.subfraccionamientos) {
			await this.setState({
				subfraccionamientos: this.props.subfraccionamientos,
			});
		}

		if (prevProps.fracName != this.props.fracName) {
			await this.setState({fracName: this.props.fracName});
		}

		if (prevProps.id_sub != this.props.id_sub) {
			await this.setState({id_sub: this.props.id_sub});
		}

		if (prevProps.Id_Vivienda != this.props.Id_Vivienda) {
			let desde = new Date();
			desde.setFullYear(desde.getFullYear() - 1);
			await this.setState({
				Id_Vivienda: this.props.Id_Vivienda,
				desde: desde,
				hasta: new Date(),
			});
			await this.getEdoCuenta();
		}
		if (prevState.unidad != this.props.unidad) {
			await this.setState({unidad: this.props.unidad});
		}

		if (this.props.subLabel != prevProps.subLabel) {
			await this.setState({ subLabel: this.props.subLabel });
		}
	}

	async getEdoCuenta() {
		await this.setState({loadingDataHistory: true});

		const response = await request.post("/documentos/estados/cuenta", {
			id_vivienda: parseInt(this.state.Id_Vivienda),
			desde: this.state.desde,
			hasta: this.state.hasta,
		});

		if (!response.empty && !response.error) {
			const Estado_Cuenta = JSON.parse(response.data.Estado);
			const Descuentos = JSON.parse(response.data.Descuentos);
			this.setState({
				edosCuenta: Estado_Cuenta,
				Descuento: Descuentos[0].Descuento,
				Saldo: Descuentos[0].Saldo,
				Recargos: Descuentos[0].Recargos,
				Saf: Descuentos[0].Saf,
			});
		} else {
			this.setState({edosCuenta: []});
		};
		this.setState({loadingDataHistory: false});
	};

	async updateDate(json) {
		this.setState(json);
		await this.getEdoCuenta();
	};

	render() {

		let datasetExcel = [], columns = [];

		if (this.state.edosCuenta) {
			if (this.state.edosCuenta.length !== 0) {
				columns = Object.keys(this.state.edosCuenta[0]);

				datasetExcel = [
					{
						columns,
						data: this.state.edosCuenta.map((row) => {
							return columns.map((column) => row[column]);
						}),
					},
				];
			};
		};

		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-24"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-big">
							<p>
								<b>{this.state.subLabel}:</b>
							</p>
						</div>
						<div className="column full">
							<select className="input input-modals" name="conceptos" value={this.state.id_sub} required disabled={true}>
								{this.state.subfraccionamientos.map((subfraccionamiento, key) => (
									<option value={subfraccionamiento.id_subfraccionamiento} key={key}>
										{subfraccionamiento.nombre}
									</option>
								))}
							</select>
						</div>
						<div className="column space label-medium">
							<p>
								<b>Vivienda :</b>
							</p>
						</div>
						<div className="column full">
							<input className="input input-modals" type="text" name="name" id="name" value={this.state.unidad} disabled />
						</div>
						<div className="column space label-medium">
							<ExcelFile
								element={
									<button type="button" className="btn btn-primary color-white">
										<i className="far fa-file-excel font-text" />
										&nbsp; Excel
									</button>
								}
								filename={"Estado de Cuenta"}
							>
								<ExcelSheet dataSet={datasetExcel} name={"Reporte"} />
							</ExcelFile>
						</div>
					</div>
					<div className="white-space-24"></div>
					<div className="row full table-history-container">
						<table className="table-history full">
							<thead>
								<tr className="row-space">
									<th className="text-left">Descripción</th>
									<th className="text-center">Mes</th>
									<th className="text-right">Cargo</th>
									<th className="text-right">Abono</th>
									<th className="text-right">Saldo</th>
								</tr>
							</thead>
							<tbody>
								{this.state.loadingDataHistory ? (
									<tr>
										<td className="text-center" colSpan="5">
											<div className="white-space-24"></div>
											<i className="fas fa-spinner fa-spin"></i>
											<div className="white-space-24"></div>
										</td>
									</tr>
								) : this.state.edosCuenta.length == 0 ? (
									<tr>
										<td className="text-center" colSpan="5">
											Sin datos que mostrar.
										</td>
									</tr>
								) : (
									this.state.edosCuenta.map((doc, key) => (
										<tr className="row-space" key={key}>
											<td className="text-left">{doc.descripcion}</td>
											<td className="text-center">{doc.MesAnyo}</td>
											<td className="text-right">
												<NumberFormat value={doc.cargo} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
											<td className="text-right">
												<NumberFormat value={doc.abono} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
											<td className="text-right">
												<NumberFormat value={doc.saldo} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
										</tr>
									))
								)}

								{this.state.loadingDataHistory ? null : (
									<React.Fragment>
										<tr className="row-space">
											<td></td>
											<td></td>
											<td></td>
											<td className="weight-bold">Saldo</td>
											<td className="text-right">
												<NumberFormat value={this.state.Saldo ? this.state.Saldo : 0} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
										</tr>
										<tr className="row-space">
											<td></td>
											<td></td>
											<td></td>
											<td className="weight-bold">Recargos al día de hoy</td>
											<td className="text-right">
												<NumberFormat value={this.state.Recargos ? this.state.Recargos : 0} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
										</tr>
										<tr className="row-space">
											<td></td>
											<td></td>
											<td></td>
											<td className="weight-bold">Descuentos al día de hoy</td>
											<td className="text-right">
												<NumberFormat value={this.state.Descuento ? this.state.Descuento : 0} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
										</tr>
										<tr className="row-space">
											<td className="weight-bold">Saldo a favor</td>
											<td className="text-right">
												<NumberFormat value={this.state.Saf ? this.state.Saf : 0} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
											<td></td>
											<td className="weight-bold">Gran Total</td>
											<td className="text-right">
												<NumberFormat
													value={(this.state.Saldo ? this.state.Saldo : 0) + (this.state.Recargos ? this.state.Recargos : 0) - (this.state.Descuento ? this.state.Descuento : 0)}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</td>
										</tr>
									</React.Fragment>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentHistory;
