/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header
 */

 import React, { Component, Fragment } from "react";
 // import Logo from "../../img/logo.png";
 import { Link } from "react-router-dom";
 import { Consumer } from "../../context";
 import Request from "../../core/httpClient";
 import { PassThrough } from "stream";
 import Rightbar from './rightbar';
 
 const request = new Request();
 
 class Header extends Component {
	 constructor(props) {
		 super(props);
		 this.state = {
			 user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
			 denied: false,
			 fraccionamientos: [],
			 subfraccionamientos: [],
			 fraccionamiento: 0,
			 fraccionamientoNombre: localStorage.getItem('fracName'),
			 subfraccionamiento: 0,
			 hidden: false,
			 Logo: process.env.REACT_APP_NAME === "iGate" ? require("../../img/logo.png") : require("../../img/LogoWebAdministracionSmaller.png"),
			 /*loc: "/catalogos/subfraccionamientos",
			 loc_admin: "/catalogos/presupuestos/concepto",
			 loc_ope: "/administracion/emergencias"*/
		 };
	 }
 
	 componentDidUpdate(prevProps) {
		 if (this.props.context.fracActual !== prevProps.context.fracActual) {
			 this.setState({ fraccionamientoNombre: localStorage.getItem('fracName') });
		 }
	 }
 
	 openNav() {
		 let sidebar = document.getElementById('sidebar');
		 if (sidebar.classList.contains('active')) {
			 sidebar.classList.remove('active');
			 sidebar.style.minWidth = "0%";
		 }
		 else {
			 sidebar.classList.add('active');
			 sidebar.style.minWidth = "20%";
		 }
	 }
 
	 render() {
		 return (
			 <React.Fragment>
				 <header id={this.props.id ? this.props.id : ''} className="justify-center header-slide">
					 <div className="row full">
						 {/*<div className="justify-between">*/}
						 <div className="options row align-center justify-center">
							 <div className="logo align-center">
								 <Link to="/tablero" className="responsive-img auto">
									 <img
										 src={this.state.Logo}
										 alt={`Logo ${process.env.REACT_APP_NAME}`}
										 title={`Logo ${process.env.REACT_APP_NAME}`}
									 />
								 </Link>
								 <p className="font-large weight-medium color-white">
									 &nbsp; {process.env.REACT_APP_NAME === "iGate" ? 'iGate' : ''}
								 </p>
							 </div>
							 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							 {
								 this.props.sidebar ?
 
									 <i className="fas fa-stream icon-main" onClick={this.openNav.bind()}></i>
									 : null
							 }
						 </div>
						 <div className="row full justify-end full ">
							 <div className=" justify-start align-center nav-size">
								 <i className="fas fa-building color-white"></i>
								 <p className="font-regular color-white full">
									 &nbsp;
									 {this.props.context.fraccionamiento}
								 </p>
							 </div>
							 <nav className="align-center justify-end full">
								 {this.state.denied ? (
									 <div className="row justify-end">
										 <div className="nav-item auto">
											 <button
												 href="#one"
												 className="font-regular color-white"
												 onClick={this.handleLogout.bind(
													 this
												 )}>
												 <i className="fas fa-sign-out-alt font-text" />Salir
											 </button>
										 </div>
									 </div>
								 ) : (
 
									 <div className="row justify-end">
 
										 <div className="nav-item auto text-center" id="catalogos">
											 <Link
												 to={window.localStorage.getItem('submenu_loc') ? window.localStorage.getItem('submenu_loc') : '/catalogos/subfraccionamientos'}
												 className="font-regular color-white">
												 <i className="fas fa-address-card font-text" />{" "}
												 Catálogos
											 </Link>
										 </div>
 
 
										 <div className="nav-item auto text-center" id="administracion">
											 <Link
												 to={window.localStorage.getItem('submenu_loc_admin') ? window.localStorage.getItem('submenu_loc_admin') : '/catalogos/presupuestos/concepto'}
												 className="font-regular color-white">
												 <i className="fas fa-home font-text" />{" "}
												 Administración
											 </Link>
										 </div>
										 <div className="nav-item auto text-center" id="operacion">
											 <Link
												 to={window.localStorage.getItem('submenu_loc_ope') ? window.localStorage.getItem('submenu_loc_ope') : '/administracion/emergencias'}
												 className="font-regular color-white">
												 <i className="fas fa-home font-text" />{" "}
												 Operación
											 </Link>
										 </div>
 
										 <div className="nav-item auto text-center" id="reportes">
											 <Link
												 to="/reportes"
												 className="font-regular color-white">
												 <i className="fas fa-warehouse font-text" />{" "}
												 Reportes
											 </Link>
										 </div>
 
										 {/* <div className="nav-item nav-exit">
												 <a
													 href="#one"
													 className="font-regular color-white"
													 onClick={this.handleLogout.bind(
														 this
													 )}>
													 <i className="fas fa-sign-out-alt font-text" />{" "}
													 Salir
												 </a>
											 </div> */}
										 <div className="nav-item auto">
											 <a onClick={() => {
												 const leftbar = document.getElementById('rightsidebar');
												 if (leftbar) {
													 leftbar.classList.toggle('open');
												 }
											 }}
												 href="#settings"
												 className="font-regular color-white">
												 <i className="fas fa-cogs font-text" />
											 </a>
										 </div>
										 &nbps;
									 </div>
								 )}
							 </nav>
						 </div>
						 {/*</div>*/}
					 </div>
				 </header>
				 <Rightbar />
			 </React.Fragment>
		 );
	 }
 
	 componentDidMount() {
		 this.load();
 
		 ////console.log("Location: ", window.location.pathname);
		 let final = { val: window.location.pathname };
 
		 let location = final.val === "/catalogos/subfraccionamientos" || final.val === '/tablero' ||
			 final.val === '/catalogos/presupuestos/concepto' || final.val === '/administracion/areas/instalaciones' ||
			 final.val === '/administracion/areas/reservacion' || final.val === '/administracion/areas/historial' ||
			 final.val === '/entornos' || final.val === '/perfiles' || final.val === '/users';
		 if (location) {
			 this.setState({ hidden: true })
 
		 } else {
			 this.setState({ hidden: false })
		 }
 
		 if (document.getElementById('sidebar')) {
			 document.getElementById('sidebar').classList.add('active');
			 document.getElementById('sidebar').style.minWidth = "20%";
		 }
 
		 const allMenuItems = document.getElementsByClassName('nav-item');
		 for (let index = 0; index < allMenuItems.length; index++) {
			 if (allMenuItems[index].classList.contains('nav-active')) {
				 allMenuItems[index].classList.remove('nav-active');
			 }
			 if (allMenuItems[index].classList.contains('active-item')) {
				 allMenuItems[index].classList.remove('active-item');
			 }
		 }
 
		 switch (this.props.nav) {
			 case 'catalogos':
				 document.getElementById(this.props.nav).classList.add('nav-active');
				 break;
			 case 'administracion':
				 document.getElementById(this.props.nav).classList.add('nav-active');
				 break;
			 case 'operacion':
				 document.getElementById(this.props.nav).classList.add('nav-active');
				 break;
			 case 'reportes':
				 document.getElementById(this.props.nav).classList.add('nav-active');
				 break;
			 default:
				 break;
		 }
 
		 {/*switch (this.props.active) {
			 case 'subfraccionamientos':
				 document.getElementById('admin').classList.add('active');
				 document.getElementById('panel1').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
 
				 break;
			 case 'viviendas':
				 document.getElementById('admin').classList.add('active');
				 document.getElementById('panel1').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
			 case 'propietarios':
				 document.getElementById('admin').classList.add('active');
				 document.getElementById('panel1').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'presupuestoConcepto':
				 document.getElementById('presup').classList.add('active');
				 document.getElementById('panel2').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'presupuestoTeorico':
				 document.getElementById('presup').classList.add('active');
				 document.getElementById('panel2').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'presupuestoGastos':
				 document.getElementById('presup').classList.add('active');
				 document.getElementById('panel2').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'EstadosCuenta':
				 document.getElementById('EstCuenta').classList.add('active');
				 document.getElementById('panel14').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'perfiles':
				 document.getElementById('config').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "160px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'usuarios':
				 document.getElementById('config').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "160px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'entorno':
				 document.getElementById('config').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "160px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'tipoN':
				 document.getElementById('config').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "160px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'tipoR':
				 document.getElementById('config').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "160px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'inquilinos':
				 document.getElementById('admin').classList.add('active');
				 document.getElementById('panel1').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'pendientes':
				 document.getElementById('quejas').classList.add('active');
				 document.getElementById('panel4').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'historial':
				 document.getElementById('emergencias').classList.add('active');
				 document.getElementById('panel1').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'definicion':
				 document.getElementById('notificaciones').classList.add('active');
				 document.getElementById('panel2').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'historiald':
				 document.getElementById('notificaciones').classList.add('active');
				 document.getElementById('panel2').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'historialp':
				 document.getElementById('quejas').classList.add('active');
				 document.getElementById('panel4').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'activas':
				 document.getElementById('areas').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'reservas':
				 document.getElementById('areas').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'historiala':
				 document.getElementById('areas').classList.add('active');
				 document.getElementById('panel3').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 case 'historialb':
				 document.getElementById('bitacora').classList.add('active');
				 document.getElementById('panel5').style.maxHeight = "136px";
				 document.getElementById(this.props.active).classList.add('active-item');
				 break;
 
			 default:
				 break;
		 }*/}
 
		 let acc = document.getElementsByClassName("accordion");
		 let i;
 
		 for (i = 0; i < acc.length; i++) {
			 acc[i].addEventListener("click", function () {
				 this.classList.toggle("active");
				 var panel = this.nextElementSibling;
				 if (panel.style.maxHeight) {
					 panel.style.maxHeight = null;
				 } else {
					 panel.style.maxHeight = panel.scrollHeight + "px";
				 }
			 });
		 }
	 }
 
	 async load() {
		 const user = await this.props.context.loadUser();
 
		 if (user) {
			 if (user.auth) {
				 this.setState({
					 user: {
						 idUsuario: user.id.id_usuario,
						 idPerfil: user.id.id_perfil,
						 idFraccionamiento: user.id.id_fraccionamiento,
						 idAdministracion: user.id.id_administracion
					 }
				 });
			 } else {
				 this.props.context.logout();
				 window.location = '/login';
			 }
		 }
	 }
 
	 /*async getMenu() {
		 this.setState({ loadingDiarios: true });
		 let data = {
			 idUsuario: this.state.user.idUsuario,
			 idPerfil: this.state.user.idPerfil
		 };
		 const response = await request.post("/users/get/menu", data);
 
		 if (response && !response.error) {
			 if (response.menus && !response.denied) {
				 this.setState({
					 menus: response.menus,
					 denied: false
				 });
			 } else {
				 this.setState({
					 denied: true
				 });
			 }
		 } else {
			 this.setState({
				 error: true,
				 message: response.message,
				 denied: true
			 });
		 }
	 }*/
 
	 handleLogout() {
		 this.props.context.logout();
		 window.location = "/login";
	 }
 
	 handleChange(event) {
 
		 if (!event.target.value) {
			 return
 
		 }
		 this.setState({ subfraccionamiento: event.target.value });
 
		 let data = {
 
			 if: parseInt(document.getElementById("fraccionamiento").value),
			 isf: parseInt(document.getElementById("subfrac").value)
		 }
		 localStorage.setItem('objectKey', JSON.stringify(data));
		 this.props.reload()
		 this.props.change()
 
 
 
	 }
	 // traer subfraccionamiento
 
	 async getFraccionamientosMenus() {
		 const user = await this.props.context.loadUser();
		 if (user) {
			 const data = { idFraccionamiento: user.id.id_fraccionamiento };
			 const response = await request.post("/fraccionamientos/get/menus", data);
			 if (response) {
				 //console.log(response);
			 }
		 }
	 }
 }
 
 export default Consumer(Header);
 