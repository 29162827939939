import React, { Component } from 'react';
import moment from "moment";

class NotificationHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="column">

                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th>
                                    <div className="row">
                                        Tipo &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'tipo' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">
                                    <div className="row">
                                        Mensaje &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'notificacion_texto' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'notificacion_texto')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'notificacion_texto')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'notificacion_texto')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="">Usuario</th>
                                <th className="text-left">Fecha de envío</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.notificaciones.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((notificacion, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{notificacion.tipo}</td>
                                            <td>
                                                <p className="">{notificacion.notificacion_texto}</p>
                                            </td>
                                            <td>{notificacion.usuario}</td>
                                            <td>{moment(notificacion.fecha_envio).tz('GMT').format("DD/MM/YYYY")}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default NotificationHistoryTable;