import React, {Component} from 'react';
import { Consumer } from "../../../context";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/header/sidebar";
import Permissions from '../../..//middlewares/Permissions';
import Request from "../../../core/httpClient";
import cloneDeep from 'lodash/cloneDeep';
import toaster from "toasted-notes";


import ConfigTable from '../../../components/Billing/configTable';
import DescriptorTable from '../../../components/Billing/descriptorTable';
import { load } from 'webfontloader';

const request = new Request();


class Params extends Component{

    constructor(props){
        super(props);
        this.state = {
            loadingParams:          false,
            escritura:              false,
            parameters:             [],
            configs:                [],
            respaldoConfigs:        [],
            disabled:               [],
            enabledEditButton:      [],
            loading:                [],
            descriptores:           [],
            respaldoDescriptores:   [],
            descriptoresLoading:    true,
            descriptoresDisabled:   true,
            generalLoading:         true,
            submenu: ''
        }
        
        this.validarPermisos();
    }
    change(){}
    async validarPermisos() {
        let permisos = new Permissions();
        let permisosInfo = await permisos.getResult()
        this.setState({ escritura: permisosInfo.escritura });
        

    }
   

    /*
        //  
        // CONFIGURACIONES
        //
    */

   // get Parameters
    async getParameters(){
        await this.setState({loadingParams: true});
        let data = {
            id_fraccionamiento: parseInt(localStorage.getItem('frac')) || 0
        }

        const response = await request.post("/facturacion/config", data);
        if(!response || response.error || response.empty){
            await this.setState({parameters: [],loadingParams: false});
            return;
        }
        if(response.config){
            await this.setState({parameters: response.config});
        }
        await this.setState({loadingParams: false});
        
    }
    filterChanges(id_tabla){
        /*
            id_origen, 
            id_campo,
        */
        let aux = [];
        let configs = this.state.configs;
        let con     = configs.filter((config)=>config.id_tabla === id_tabla)[0];
        con.campos.forEach((camp)=>{
            aux.push({
                id_reg: camp.id_reg,
                valor:  camp.valor,
                id_origen:  parseInt(localStorage.getItem('frac') || 0),
                id_campo:   camp.id_campo
            });
        });
        return JSON.stringify(aux);
    }
    async updateConfig(event, id_tabla){
        event.preventDefault();

        await this.setLoading(id_tabla, true);
        

        let jsonValues = this.filterChanges(id_tabla);
        
        let data = {
            jsonVal:    jsonValues

        }

        const response = await request.post("/facturacion/config/update", data);
        if (!response || response.error) {
                this.setNotification('Los datos no se pudieron actualizar',true);
        } 

        if(response && response.updated){
            
            await this.disabledThis(id_tabla);
            
            let copy = cloneDeep(this.state.configs);
            await this.setState({respaldoConfigs: copy});
            this.setNotification('Datos actualizados correctamente',false);
        }

        await this.setLoading(id_tabla, false);
    }
    async setLoading(id_tabla, boo){
        let loading         = this.state.loading;
        loading[id_tabla]   = boo;
        await this.setState({loading});
    }

    async getConfigs(){

        let aux     = [];
        let params  = this.state.parameters;
        let campos  = [];
        let lista   = [];

        params.forEach(param => {
            
            //Por cada campo obtener su valor, asignarle un index y separar la lista de valores definidos 
            
            if(param.Campos){
                JSON.parse(param.Campos).forEach(campo =>{
                    lista = this.getLista(campo.Lista);
                    
                    campos.push({
                        id_reg:     campo.Valor[0].id_reg,
                        nombre:     campo.nombre,
                        lista,
                        valor:      campo.Valor[0].valor,
                        index:      this.getIndexByValue(campo.Valor[0].valor,lista),
                        id_campo:   campo.id_campo,
                        id_origen:  campo.Valor[0].id_origen,
                        id_tipo:    campo.id_tipo
                    });
                    
                    
                });
                aux.push({id_tabla: param.id_tabla,nombre: param.nombre, campos});
            }
            campos = [];
            
        });
        let copy = cloneDeep(aux);
        await this.setState({configs: aux,respaldoConfigs: copy });
    }

    // Separar string por |
    getLista(lista){
        return lista.split('|');
    }
    // Obtener el index del valor
    getIndexByValue(value,lista){
        if(Array.isArray(lista)){
            for (let i = 0; i < lista.length; i++) {
                if(lista[i] === value){
                    return i;
                }
                
            }
        }
        return -1;
    }
    // Preparar estados de configuracion
    async prepareConfigStates(){
        let configs             = this.state.configs;
        let disabled            = [];
        let enabledEditButton   = [];
        let loading             = [];
        // DISABLED Inputs

        configs.forEach(config => {
            disabled[config.id_tabla]               = true;
            enabledEditButton[config.id_tabla]      = true;

            loading[config.id_tabla]                =   false;

        });




        await this.setState({disabled,enabledEditButton,loading})
        
    }

    async disabledThis(id_tabla){
        let disabled        = this.state.disabled;
        disabled[id_tabla]  = !disabled[id_tabla];
        await this.setState({disabled})
    }

    async handleEdit(id_tabla){
        

        let disabled        = this.state.disabled;
        
        disabled.forEach((di, index) => {
            if(index == id_tabla){
                disabled[id_tabla]  = !disabled[id_tabla]; 
            }else{
                disabled[index] = true;
            }
        });
        
        let copy = cloneDeep(this.state.respaldoConfigs);
        await this.setState({disabled, configs: copy});
        
        
        
    }
    
    getValueByIndex(lista, index){
        for(let i = 0; i < lista.length; i++){
            if(i === index)
                return lista[i];
        }
        return 0;
    }

    async changeConfig(id_reg,  index,  id_tabla){
        let configs = this.state.configs;

        configs.forEach(config => {
            if(config.id_tabla === id_tabla){
                
                config.campos.forEach(campo => {
                    
                    if(campo.id_reg === id_reg){
                        campo.index = parseInt(index);
                        campo.valor = this.getValueByIndex(campo.lista,parseInt(index));
                    }
                });

            }
        });

        await this.setState({configs});

    }

    async changeConfigValue(id_reg,  valor,  id_tabla){
        let configs = this.state.configs;

        configs.forEach(config => {
            if(config.id_tabla === id_tabla){
                
                config.campos.forEach(campo => {
                    
                    if(campo.id_reg === id_reg){
                        campo.valor = valor;
                    }
                });

            }
        });
        await this.setState({configs});
    }

    render(){
        return(
            <div className="billing column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header sidebar={true} active={'entorno'} parent={'config'} panel={'panel4'} nav={"catalogos"} change={this.change.bind(this)} />

                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <div className="navigation align-center">
                                    <div className="justify-start">
                                        <h3 className="weight-semi">{this.state.submenu}</h3>
                                    </div>
                                </div>
                                <div className="white-space-16" />
                                {
                                    this.state.configs ? 
                                    this.state.configs.map((data, key) =>{
                                            return(
                                            <ConfigTable
                                                key                 =   {key}
                                                escritura           =   {this.state.escritura}
                                                config              =   {data}
                                                disabled            =   {this.state.disabled[data.id_tabla]}
                                                enabledEditButton   =   {this.state.enabledEditButton[data.id_tabla]}
                                                handleEdit          =   {this.handleEdit.bind(this)}
                                                changeConfig        =   {this.changeConfig.bind(this)}
                                                changeConfigValue   =   {this.changeConfigValue.bind(this)}
                                                updateConfig        =   {this.updateConfig.bind(this)}
                                                loading             =   {this.state.loading[data.id_tabla]}
                                                generalLoading      =   {this.state.generalLoading}
                                            ></ConfigTable>
                                            );
                                        })
                                    : null
                                }
                                {/* DESCRIPTORES */}
                                <div className="navigation align-center">
                                    <div className="justify-start">
                                        <h3 className="weight-semi">Descriptores</h3>
                                    </div>
                                </div>
                                <div className="white-space-16" />
                                
                                <DescriptorTable
                                    escritura           = {this.state.escritura}
                                    descriptores        = {this.state.descriptores}
                                    loading             = {this.state.descriptoresLoading}  
                                    disabled            = {this.state.descriptoresDisabled}
                                    changeDescriptor    = {this.changeDescriptor.bind(this)}
                                    handleEditDescriptores  =   {this.handleEditDescriptores.bind(this)}
                                    updateDescriptores  =   {this.updateDescriptores.bind(this)}
                                    generalLoading      =   {this.state.generalLoading}
                                ></DescriptorTable>

                                {/* END DESCRIPTORES */}
                                <div className="white-space-48" />
                                </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount(){
        this.getSubmenu();
        await this.setState({generalLoading: true});
        await this.getParameters();
        await this.getConfigs();
        await this.prepareConfigStates();
        await this.getDescriptores();
        await this.setState({generalLoading: false});
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuName();
        if (submenu) {
            this.setState({ submenu });
        }
    }

    getPreparedDescriptores(){
        let descriptores    = this.state.descriptores;
        let aux             = [];

        descriptores.forEach(de => {
            
            aux.push({
                id_descriptor:  de.id_descriptor,
                serie:          de.Serie,
                numero:         de.Numero,
                prefijo:        de.Prefijo
            });

        }); 
        return JSON.stringify(aux);
    }
    async updateDescriptores(event){
        event.preventDefault();
        await this.setState({descriptoresLoading: true});

        let json = this.getPreparedDescriptores();
        
        let data = {
            json
        }

        const response = await request.post("/facturacion/descriptores/update", data);
        if(!response || response.error){
            this.setNotification('Los datos no se pudieron actualizar',true);
        }

        if(response.updated){
            let copy = this.state.descriptores;
            await this.setState({descriptoresDisabled: true, respaldoDescriptores: copy});
            this.setNotification('Datos actualizados correctamente',false);
        }


        await this.setState({descriptoresLoading: false});
    }
    setNotification(MSJ, error){

        let ICON    = "fa-check-circle";
        let COLOR   = "green";
        if(error){
            ICON    = "fa-times";
            COLOR   = "red";
        }

        toaster.notify(
            <React.Fragment>
                <span className={`fas ${ICON}`}style={{"color":`${COLOR}`}}></span>
                &nbsp;
                <p>{MSJ}</p>
            </React.Fragment>, {
            duration: 5000,
            position: 'bottom-right'
        });
    }
    async handleEditDescriptores(){
        let copy = cloneDeep(this.state.respaldoDescriptores);

        await this.setState({descriptoresDisabled: !this.state.descriptoresDisabled, descriptores: copy});

    }
    async changeDescriptor(event, id_descriptor){
        let descriptores = this.state.descriptores;

        descriptores.forEach(des => {
            if(des.id_descriptor === id_descriptor){
                
                switch (event.target.name) {
                    case "PREFIJO":
                        des.Prefijo = event.target.value;
                        break;
                    case "NUMERO":
                        des.Numero = event.target.value;
                        break;
                    case "SERIE":
                        des.Serie = event.target.value;
                    break;
                    default:
                        break;
                }
            }
        });

        await this.setState({descriptores});
    }

    async getDescriptores(){
        await this.setState({descriptoresLoading: true});
        let data = {
            id_fraccionamiento: parseInt(localStorage.getItem('frac') || 0)
        }

        const response = await request.post("/facturacion/descriptores", data);
        if(response.error || response.empty){
            await this.setState({descriptores: [], respaldoDescriptores:[],descriptoresLoading: false});
            return;
        }

        if(response.descriptores){
            let copy = cloneDeep(response.descriptores);
            await this.setState({descriptores: response.descriptores, respaldoDescriptores: copy})
        }

        await this.setState({descriptoresLoading: false});

    }
}

export default Consumer(Params);