import React, { Component, Fragment } from "react";
import NumberFormat from "react-number-format";
import ModalBorrar from "../modals/modalDelete";
import DinamicModal from "../dinamicModal/dinamicModal";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import BudgetFacturaEdit from "./budgetFacturaEdit";
import moment from "moment";
import BudgetPagosEdit from "./budgetPagosEdit";
import BudgetPagos from "./budgetPago";
const request = new Request();

class BudgetTableFactura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_factura: "",
      factura: "",
      id_pago: 0,
      pago: "",
    };
  }

  recargar() {
    this.props.reload();
  }
  delete = (id) => {
    this.handleDelete(id);
  };
  deletePago = (id) => {
    this.handleDeletePago(id);
  };

  openModalDelete() {
    let modal = document.getElementById("deleteModal");
    if (modal) {
      modal.classList.remove("hide-modal");
      modal.classList.add("show-modal");
    }
  }

  render() {
    let facturas = [];
    let total = 0;
    if (this.props.facturas) {
      facturas = this.props.facturas;
      total = facturas.reduce(
        (accumulator, factura) => accumulator + factura.importe,
        0
      );
    }
    let proveedores = [];
    if (this.props.proveedores) {
      proveedores = this.props.proveedores;
    }
    let tipos = [];
    if (this.props.tipos) {
      tipos = this.props.tipos;
    }
    let conceptos = [];
    if (this.props.conceptos) {
      conceptos = this.props.conceptos;
    }
    let estados = [];
    if (this.props.estados) {
      estados = this.props.estados;
    }

    return (
      <div
        className="card-table card-table-budget card-owners column"
        id="card-owners"
      >
        <div className="table-responsiv column">
          <table>
            <thead className="sticky-th">
              <tr className="text-left">
                <th className="">
                  <div className="row">
                    Folio &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "num_factura" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(
                              this,
                              "num_factura"
                            )}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(
                              this,
                              "num_factura"
                            )}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "num_factura")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="">
                  <div className="row">
                    Fecha &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "fecha" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(this, "fecha")}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(this, "fecha")}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "fecha")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="">
                  <div className="row">
                    Proveedor &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "razon_social" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(
                              this,
                              "razon_social"
                            )}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(
                              this,
                              "razon_social"
                            )}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "razon_social")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="">
                  <div className="row">
                    Concepto &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "nombre_concepto" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(
                              this,
                              "nombre_concepto"
                            )}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(
                              this,
                              "nombre_concepto"
                            )}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(
                            this,
                            "nombre_concepto"
                          )}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="">
                  <div className="row">
                    Tipo &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "tipo" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(this, "tipo")}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(this, "tipo")}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "tipo")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="">
                  <div className="row">
                    Estado &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "estado" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(this, "estado")}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(this, "estado")}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "estado")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="text-center th-options-small">
                  <div className="row">
                    Importe &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "importe" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(this, "importe")}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(this, "importe")}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "importe")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="text-center th-options-small">
                  <div className="row">
                    Saldo &nbsp;
                    <div className="align-center">
                      {this.props.filterColumn.col === "saldo" ? (
                        this.props.filterColumn.filt === "asc" ? (
                          <i
                            className="fas fa-sort-down"
                            onClick={this.props.sortBy.bind(this, "saldo")}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-sort-up"
                            onClick={this.props.sortBy.bind(this, "saldo")}
                          ></i>
                        )
                      ) : (
                        <i
                          className="fas fa-sort"
                          onClick={this.props.sortBy.bind(this, "saldo")}
                        ></i>
                      )}
                    </div>
                  </div>
                </th>
                <th className="text-center">Pagos</th>
                <th className=" text-center">Editar</th>
                <th className=" text-center">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {facturas
                .slice(
                  (this.props.page - 1) * this.props.pageLength,
                  this.props.page * this.props.pageLength
                )
                .map((factura, key) => {
                  return (
                    <Fragment>
                      {" "}
                      <tr
                        className={
                          this.state.id_factura_row === factura.id_factura
                            ? "row-active"
                            : ""
                        }
                        key={key}
                      >
                        <td>{factura.num_factura}</td>
                        <td>{moment(factura.fecha).format("DD-MM-YYYY")}</td>
                        <td>{factura.razon_social}</td>
                        <td>{factura.nombre_concepto}</td>
                        <td>{factura.tipo}</td>
                        <td>
                          {factura.status ? (
                            "Activa"
                          ) : (
                            <span style={{ color: "red" }}>{"Cancelada"}</span>
                          )}
                        </td>
                        <td className="text-right">
                          {this.getMoneyFormat(factura.importe)}
                        </td>
                        <td className="text-right">
                          {this.getMoneyFormat(factura.saldo)}
                        </td>

                        <td className="text-center">
                          <button
                            className="btn-full btn-mini btn-secondary color-white tooltip" /*tooltip */
                            onClick={this.openRow.bind(
                              this,
                              "factura",
                              factura.id_factura
                            )}
                          >
                            &nbsp;
                            <i
                              className={`font-small fa fa-list  ${this.state.rowType === "factura" &&
                                this.state.id_factura_row === factura.id_factura
                                ? "color-red"
                                : ""
                                }`}
                            />
                            {/* <span className="tooltiptext">{house.total_propietarios}</span>  */}
                          </button>
                        </td>

                        <td className="justify-arround text-center">
                          <button
                            className="btn-full justify-center btn-mini btn-secondary color-white"
                            type="button justify-center"
                            onClick={this.on_showEditDialog.bind(this, factura)}
                          >
                            <i className="font-small fas fa-pen" />
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                            type="button"
                            onClick={this.openModalDelete.bind(
                              this,
                              factura.id_factura
                            )}
                            style={{ padding: "5px" }}
                            disabled={!this.props.escritura}
                          >
                            <i className="font-text fas fa-trash-alt " />
                          </button>
                        </td>
                      </tr>
                      {this.state.id_factura === factura.id_factura &&
                        this.state.rowType === "factura" ? (
                        this.state.loading_pagos ? (
                          <tr>
                            <td className="text-center" colSpan="8">
                              <i className="fas fa-spinner fa-spin"></i>
                            </td>
                          </tr>
                        ) : (
                          <tr className="acordeon-row">
                            <td className="acordeon" colSpan="12">
                              <div className="full row align-center">
                                <div className="column full">
                                  <div className="column card-table">
                                    <div className="table-responsiv column">
                                      <table>
                                        <tbody>
                                          <tr className="pad-bot row-without-border">
                                            <th
                                              colSpan="5"
                                              className="text-left"
                                            >
                                              <h4 className="weight-semi">
                                                Pagos
                                              </h4>
                                            </th>
                                            {this.props.escritura && (
                                              <th>
                                                <button
                                                  className="btn btn-mini btn-primary color-white"
                                                  type="button"
                                                  id="btn-modal-add"
                                                  onClick={this.openModal.bind(
                                                    this,
                                                    "BudgetPago",
                                                    factura
                                                  )}
                                                >
                                                  <i className="fas fa-plus" />{" "}
                                                  Añadir
                                                </button>
                                              </th>
                                            )}
                                          </tr>
                                          <tr className="text-left">
                                            <th className="">Fecha</th>
                                            <th className="">Banco</th>
                                            <th className="">Forma de pago</th>
                                            <th className="text-center th-options-small">
                                              Importe
                                            </th>
                                            <th className=" text-center th-options-small">
                                              Editar
                                            </th>
                                            <th className=" text-center th-options-small">
                                              Eliminar
                                            </th>
                                          </tr>
                                          {this.state.factura_pagos.map(
                                            (pago, key) => (
                                              <tr key={key}>
                                                <td className="text-left">
                                                  {moment(pago.fecha).format(
                                                    "DD-MM-YYYY"
                                                  )}
                                                </td>
                                                <td className="text-left">
                                                  {pago.banco}
                                                </td>
                                                <td className="text-left">
                                                  {pago.forma_pago}
                                                </td>
                                                <td className="text-right">
                                                  <NumberFormat
                                                    value={pago.monto}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                  />
                                                </td>
                                                <td className="text-center">
                                                  <button
                                                    className="btn-full justify-center btn-mini btn-secondary color-white"
                                                    type="button justify-center"
                                                    onClick={this.on_showPagoEditDialog.bind(
                                                      this,
                                                      pago
                                                    )}
                                                  >
                                                    <i className="font-small fas fa-pen" />
                                                  </button>
                                                </td>
                                                <td className="text-center">
                                                  <button
                                                    className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                    type="button"
                                                    onClick={this.openPagoModalDelete.bind(
                                                      this,
                                                      pago.id_pago
                                                    )}
                                                    style={{ padding: "5px" }}
                                                    disabled={
                                                      !this.props.escritura
                                                    }
                                                  >
                                                    <i className="font-text fas fa-trash-alt " />
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </td>
                          </tr>
                        )
                      ) : null}
                    </Fragment>
                  );
                })}
              <tr>
                <td className="weight-bold" colSpan={6}>
                  {" "}
                  <p className="label-red">Total</p>
                </td>
                <td className="weight-bold text-right label-red" colSpan={1}>
                  <NumberFormat
                    value={total}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <BudgetFacturaEdit
            factura={this.state.factura}
            estados={estados}
            reload={this.recargar.bind(this)}
            escritura={this.props.escritura}
            proveedores={proveedores}
            tipos={tipos}
            conceptos={conceptos}
            subfraccionamientos={this.props.subfraccionamientos}
          />

          <BudgetPagos
            id_factura={this.state.id_factura}
            reload={this.recargar.bind(this)}
            escritura={this.props.escritura}
          />

          <BudgetPagosEdit
            pago={this.state.pago}
            reload={this.recargar.bind(this)}
            id_factura={this.state.id_factura}
            escritura={this.props.escritura}
          />

          <ModalBorrar
            id={this.state.id_factura}
            delete={this.delete.bind(this)}
          />

          <ModalBorrar
            name={"pagoDeleteModal"}
            id={this.state.id_pago}
            delete={this.deletePago.bind(this)}
          />
        </div>
      </div>
    );
  }

  openRow(type, id_factura) {
    const data = { type, id_factura };

    window.localStorage.setItem("openRow", JSON.stringify(data));

    if (this.state.id_factura === id_factura && type === this.state.rowType) {
      window.localStorage.removeItem("openRow");
      this.setState({ rowType: "", id_factura: 0 });
      return;
    }

    this.setState({
      rowType: type,
      id_factura: id_factura,
      id_factura_row: id_factura,
    });
    this.getFacturaPagos(id_factura);
  }

  async getFacturaPagos(id_factura) {
    this.setState({ concepto_facturas: [], loading_pagos: true });

    let data = {
      id_factura: id_factura,
    };

    const response = await request.post("/facturas/get/pagos", data);
    if (!response.empty && !response.error) {
      await this.setState({
        factura_pagos: response.pagos,
      });
    } else {
      await this.setState({ factura_pagos: [] });
    }
    console.log(response.factura_pagos);

    this.setState({ loading_pagos: false });
  }

  getMoneyFormat(number) {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(number);
  }

  openModalDelete(id) {
    this.setState({ id_factura: id, id_factura_row: id });
    let modal = document.getElementById("deleteModal");
    if (modal) {
      modal.classList.remove("hide-modal");
      modal.classList.add("show-modal");
    }
  }

  openPagoModalDelete(id) {
    this.setState({ id_pago: id, id_pago_row: id });
    let modal = document.getElementById("pagoDeleteModal");
    if (modal) {
      modal.classList.remove("hide-modal");
      modal.classList.add("show-modal");
    }
  }

  openModal(idModal, factura) {
    this.setState({
      mesagge: null,
      factura: factura,
      id_factura_row: factura.id_factura,
    });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove("hide-dinamic-modal");
      modal.classList.add("show-dinamic-modal");
    }

    // document.getElementById('conceptName').focus();
  }

  openPagoModal(idModal, pago) {
    this.setState({
      mesagge: null,
      pago: pago,
    });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove("hide-dinamic-modal");
      modal.classList.add("show-dinamic-modal");
    }

    // document.getElementById('conceptName').focus();
  }

  async handleDelete(id) {
    let data = {
      id_factura: id,
    };
    const response = await request.post("/facturas/factura/delete", data);

    if (response && !response.error) {
      if (response.deleted && !response.empty) {
        this.props.reload();

        this.setState({
          showModal: false,
        });
        cogoToast.success("Factura eliminada.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          //message: response.message
        });
        cogoToast.error("No se pudo eliminar la factura.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        //message: response.message
      });
      cogoToast.error("No se pudo eliminar la factura.", {
        position: "bottom-right",
      });
    }
  }

  async handleDeletePago(id) {
    let data = {
      id_pago: id,
    };
    const response = await request.post("/facturas/pago/delete", data);

    if (response && !response.error) {
      if (response.deleted && !response.empty) {
        this.props.reload();

        this.setState({
          showModal: false,
        });
        cogoToast.success("Pago eliminado.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          //message: response.message
        });
        cogoToast.error("No se pudo eliminar el pago.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        //message: response.message
      });
      cogoToast.error("No se pudo eliminar el pago.", {
        position: "bottom-right",
      });
    }
  }

  on_showEditDialog(factura) {
    this.openModal("editBudgetFactura", factura);
  }
  on_showPagoEditDialog(pago) {
    this.openPagoModal("editBudgetPago", pago);
  }
}

export default BudgetTableFactura;