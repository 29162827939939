import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarOperacion from "../components/header/sidebarOperacion";
import VacacionalTable from "../components/invitadosFrecuentes/vacacionalTable";
import Paginador from "../components/paginador/paginador";
import Controls from '../components/controls/controls';
import Request from "../core/httpClient";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import moment from 'moment-timezone';
import Permissions from '../middlewares/Permissions';
import Resizer from 'react-image-file-resizer';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
import { Consumer } from "../context";

const request = new Request();


class vacacionalHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invitadosFrecuentes: [],
			keys: [],
			invitados: [],
			showModal: false,
			page: 1,
			filter: '',
			horarios: { dia: [], mañana: [], tarde: [] },
			subfraccionamiento: localStorage.getItem('subfrac'),
			subf: localStorage.getItem('subfrac') || 0,
			min: moment().format('YYYY-MM-DD'),
			fecha: moment().format('YYYY-MM-DD'),
			fecha2: moment().format('YYYY-MM-DD'),
			idfrac: localStorage.getItem('frac') || 0,
			escritura: false,
			ine1: null,
			ine2: null,
			rotate1: 0,
			rotate2: 0,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			pageLength: 20,
			direction: {
				invitado: 'desc',
				numero_registro: 'desc',
				acceso: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			subLabel: ''
		}

		this.validarPermisos();

	}

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
		////console.log(permisosInfo);

	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.invitadosfrecuentes();
		}

	}
	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	async paginatorChange(length) {
		await this.setState({ pageLength: length });
		this.setPage(1);
	}
	/* funcion para el filtro generico */

	filterGeneric(e) {
		e.preventDefault();

		const form = e.target;

		const param1 = form.filterIncidents.value;
		const param2 = form.textFilter.value;

		let filterData = this.state.filterData;



		if (param1 === "todo") {
			this.setState({ invitadosFrecuentes: this.state.filterData });
			form.textFilter.value = "";
		}
		else {
			const key = param1;
			filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
			this.setState({ invitadosFrecuentes: filterData });
		}

	}
	reload() {
		this.invitadosfrecuentes();
	}

	changeFrac() {
		this.invitadosFrecuentes()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub, subf: sub });
		this.getViviendas();
	}

	async openModal(idModal) {
		await this.setState({ message: null });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		document.getElementById('viv').focus();
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(invitadosFrecuentes) {
		invitadosFrecuentes = invitadosFrecuentes.filter(inv => {
			if ((inv.acceso || '').toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if ((inv.invitado || '').toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (inv.numero_registro) {
				if (inv.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
					return true;
				}
			} else {
				return false;
			}
		});
		return invitadosFrecuentes;
	}
	async completeForm(item) {
		this.setState({ INombre: item.label });
		if (item.value > 0) {
			const res = await request.get('/inquilinos/getbypersona', { id_persona: item.value, id_fraccionamiento: localStorage.getItem('frac') });
			if (res.inquilino) {
				const telefono = parsePhoneNumber(`+${res.inquilino.telefono}`);
				this.setState({
					IIdPersona: item.value,
					iTelefono: telefono.number
				});
			}
		}
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.invitados, this.state.direction[key]);

		this.setState({
			invitados: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let invitadosFrecuentes = [];

		if (Array.isArray(this.state.invitados)) {
			invitadosFrecuentes = this.state.invitados;
			invitadosFrecuentes = this.filter(invitadosFrecuentes);
		}
		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					reload={this.reload.bind(this)}
					change={this.changeFrac.bind(this)}
					sidebar={true} active={'historiala'}
					nav={'operacion'} />
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls title={this.state.submenu}
											nuevo={this.openModal.bind(this, 'newVacacional')}
											dataSet={invitadosFrecuentes}
											newButton={this.state.escritura}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}

										/>
										<div className="card-table card-owners column" id="card-owners">
											{this.state.loading ?
												<div className="row justify-center">
													<i className="fas fa-spinner fa-spin"></i>
												</div>
												:
												<VacacionalTable
													invitados={invitadosFrecuentes}
													page={this.state.page}
													reload={this.reload.bind(this)}
													escritura={this.state.escritura}
													pageLength={this.state.pageLength}
													sortBy={this.sortableTable.bind(this)}
													filterColumn={this.state.filterColumn}
												/>
											}
										</div>
										<div className="white-space-16"></div>
										<Paginador
											pages={Math.ceil(invitadosFrecuentes.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>
									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={'newVacacional'}
					sizeModalContainer={'big'}
					title={'Añadir vacacional'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					closeModal={this.resetValue.bind(this)}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.renderNewVacacional()}
				</DinamicModal>
			</div>
		);
	}

	async changeSubFrac(event) {
		let subf = event.target.value;
		await this.setState({ subf: subf });
	}

	filtarViviendas() {
		let viviendas = this.state.viviendas;
		let vivs = [];

		if (this.state.subf == 0) {
			return [];
		}
		viviendas.map((viv) => {
			if (viv.id_subfraccionamiento == this.state.subf) {
				vivs.push(viv);
			}
		});

		return vivs;
	}

	renderNewVacacional() {
		let list_personas = [];
		let viviendas = [];
		let subfraccionamientos = [];
		if (Array.isArray(this.state.personas)) {
			list_personas = this.state.personas.map(persona => { return { label: persona.nombre, value: persona.id_persona } });
		}
		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}
		if (Array.isArray(this.state.viviendas)) {
			viviendas = this.filtarViviendas();
		}
		return (
			<div className="full row align-start">
				<div className="column form-colum full">

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>{this.state.subLabel}:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								name="subfraccionamiento"
								value={this.state.subf}
								onChange={(event) => this.changeSubFrac(event)}
							>
								<option value="0">SELECCIONAR {this.state.subLabel.toUpperCase()}</option>
								{subfraccionamientos.map(viv =>
									<option key={viv.id_subfraccionamiento} value={viv.id_subfraccionamiento}>
										{viv.nombre}
									</option>
								)}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> Vivienda:</b></p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								name="vivienda"
								value={this.state.IidViv}
								onChange={(event) => this.setState({ IidViv: event.target.value })}
								id="viv"
							>
								<option value="0">SELECCIONAR VIVIENDA</option>
								{viviendas.map(viv =>
									<option key={viv.id_vivienda} value={viv.id_vivienda}>
										{viv.numero_registro}
									</option>
								)}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.INombre}
								onChange={event => this.setState({ INombre: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								maxLength="64" />
						</div>
					</div>
					<div className="white-space-8"></div>


					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phoneNumber"
								defaultCountry="MX"
								maxLength="18"
								autoComplete='off'
								value={this.state.iTelefono}
								onChange={value => this.setState({ iTelefono: value, telefonoError: undefined })}
								error={this.state.telefonoError}
								className={'input-modals'}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Acceso:</b></p>
						</div>
						<div className="column full">
							<select name="acceso" id="acceso" className="input input-modals" value={this.state.acceso} enable="true"
								onChange={event => this.setState({ acceso: event.target.value })}>
								<option value="PENDIENTE">PENDIENTE</option>
								<option value="CONFIRMADO">CONFIRMADO</option>
							</select>
						</div>
					</div>

					<div className="white-space-8"></div>
					{/*  */}
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Desdes el día:</b></p>
						</div>
						<div className="column full">
							<input
								type="date"
								name="fecha"
								id="fecha"
								className="input input-modals"
								value={this.state.fecha}
								onChange={this.cambioFecha.bind(this)}
								min={this.state.min}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Hasta el día:</b></p>
						</div>
						<div className="column full">
							<input
								type="date"
								name="fecha2"
								id="fecha2"
								className="input input-modals"
								value={this.state.fecha2}
								onChange={this.cambioFecha.bind(this)}
								min={this.state.fecha}
							/>
						</div>
					</div>

					{/*  */}
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p><b>  Tipo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="tipo" value="Vacacional"
								onChange={event => this.setState({ tipo: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								required maxLength="24" disabled
							/>
						</div>

						<div className="column label-duo-normal space">

						</div>
						<div className="column full">

						</div>
					</div>
					<div className="white-space-8" />
					<div className="row justify-center">
						{this.state.saveMessage}
					</div>
				</div>
				<div className="column auto images-ine align-end">
					<div className="responsive-img column  ">
						<div className="img-column align-center img-width-small">
							{this.state.ine1 &&
								<button type="button" onClick={this.rotateImg.bind(this, 1)} >
									<i className="fas fa-redo-alt"></i>
								</button>
							}
							<div className="container-img">
								<img src={this.state.ine1 ? this.state.ine1 : require('../img/empty_image.png')} className="img-duo-preview" id="previewArea" />

								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="ine1" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 1)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir parte frontal de la INE</p>
									</label>
								</div>
							</div>
						</div>
						<div className="img-column align-center img-width-small">
							{this.state.ine2 &&
								<button type="button" onClick={this.rotateImg.bind(this, 2)} >
									<i className="fas fa-redo-alt"></i>
								</button>
							}
							<div className="container-img">
								<img
									src={this.state.ine2 ? this.state.ine2 : require('../img/empty_image.png')}
									className="img-duo-preview"
									id="previewArea"
								/>


								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="ine2" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 2)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir parte trasera de la INE</p>
									</label>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>

		)
	}


	async rotateImg(id) {

		if (this.state.rotate1 === 360) {
			await this.setState({ rotate1: 0 });
		}
		if (this.state.rotate2 === 360) {
			await this.setState({ rotate2: 0 });
		}

		if (id === 1) {
			await this.setState({ rotate1: (this.state.rotate1 + 90) })
		} else {
			await this.setState({ rotate2: (this.state.rotate2 + 90) })
		}
		await this.changeImg(id);

	}

	async changeImg(id) {

		let rotate = 0;
		if (id === 1) {
			rotate = this.state.rotate1;
		} else {
			rotate = this.state.rotate2;
		}

		let file = document.getElementById(`ine${id}`);
		if (file) {
			if (file.files[0]) {

				Resizer.imageFileResizer(
					file.files[0],
					337,//337
					180,//180
					'JPEG',
					100,
					rotate,//270
					async uri => {
						if (id === 1) {

							await this.setState({ ine1: uri });
						} else {
							await this.setState({ ine2: uri });
						}

					},
					'base64'
				);
			}
		}
	}
	handleOpen() {
		this.setState({ showModal: true })
	}
	handleClose() {
		this.setState({ showModal: false })
	}
	async cambioFecha(event) {
		let inputDate = event.target;
		inputDate.id === 'fecha' ? this.setState({ fecha: inputDate.value, fecha2: inputDate.value }) : this.setState({ fecha2: inputDate.value });
	}
	//Guardar Invitado Frecuente
	async handleSubmit(event) {
		try {

			event.preventDefault();
			this.setState({ loadingSave: true, message: '' });

			if (parseInt(this.state.subf) === 0) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes elegir un subfraccionamiento.", {
					position: "bottom-right",
				});
				return;
			}

			if (parseInt(this.state.IidViv) === 0) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes elegir una vivienda.", {
					position: "bottom-right",
				});
				return;
			};

			if (!this.state.INombre) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes ingresar el nombre.", {
					position: "bottom-right",
				});
				return;
			}

			if (!this.state.iTelefono) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes ingresar el teléfono.", {
					position: "bottom-right",
				});
				return;
			}

			if (this.state.acceso === 'CONFIRMADO') {
				if ((!this.state.ine1 &&  this.state.ine2) || 
					( this.state.ine1 && !this.state.ine2)  || 
				    (!this.state.ine1 && !this.state.ine2)) {
				    cogoToast.warn('Debes elegir las dos imágenes', {
					   position: 'bottom-right',
				    });
				   this.setState({ loadingSave: false });
				   return;
			    };
			}

			const user = this.props.context.user.id.usuario;

			const data = {
				acceso: this.state.acceso || 'PENDIENTE',
				idfrac: this.state.idfrac,
				nombre: this.state.INombre,
				telefono: this.state.iTelefono,
				tipo: 3,
				vivienda: parseInt(this.state.IidViv),
				persona: this.state.IIdPersona,
				id_frecuente: this.state.id_frecuente,
				IdSub: parseInt(this.state.subf),
				fecha: this.state.fecha,
				fecha2: this.state.fecha2,
				ine1: this.state.ine1,
				ine2: this.state.ine2,
				id_fraccionamiento: localStorage.getItem('frac'),
				usuario: user
			};

			const response = await request.post("/invitadosFrecuentes/invitados/agregar", data);

			if (response.error) {
				if (parseInt(response.registrado) == 0) {
					cogoToast.warn('La persona ya está agregada como un invitado frecuente.', {
						position: 'bottom-right',
					});
				} else {
					cogoToast.error('No se pudo crear vacacional.', {
						position: 'bottom-right',
					});
				};
			} else {
				if (response.created) {
					this.invitadosfrecuentes();
					this.closeModal('newVacacional');
					this.resetValue();
					cogoToast.success('Vacacional agregado.', {
						position: 'bottom-right',
					});
				} else {
					cogoToast.error('No se pudo crear vacacional.', {
						position: 'bottom-right',
					});
				}
			};

			this.setState({ loadingSave: false });

		} catch (error) {
			console.log(error);
		};
	};


	closeModal(idModal) {

		const modal = document.getElementById(idModal)
		if (modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	async resetValue() {
		this.setState(
            {
                subf: 0,
                IidViv: 0,
                acceso: "",
                INombre: "",
                iTelefono: "",
                ine1: null,
                ine2: null,
                fecha: moment().format("YYYY-MM-DD"),
                fecha2: moment().format("YYYY-MM-DD"),
            },
            () => {
                document.getElementById("ine1").value = "";
                document.getElementById("ine2").value = "";
            },
        );
	}

	componentDidMount() {
		this.getSubmenu();
		this.invitadosfrecuentes();
		this.getPersonas();
		this.getSubFraccionamientos();
		this.getViviendas();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuOpeName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	async invitadosfrecuentes() {
		this.setState({ invitados: [], loading: true });
		const idSub = this.state.subfraccionamiento || 0;
		let data = {
			idfrac: localStorage.getItem('frac'),
			tipo: 3,
			idSub
		};

		const response = await request.post("/invitadosFrecuentes/get/invitados", data)

		if (response && !response.error) {
			if (response.invitadosfrec && !response.empty) {
				this.setState({ invitados: response.invitadosfrec })
			} else {
				this.setState({
					error: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});

		}
		this.setState({ loading: false });
	}
	async getPersonas() {
		const response = await request.post("/personas/getbyfraccionamiento", { id_fraccionamiento: localStorage.getItem('frac') });
		if (Array.isArray(response.personas)) {
			this.setState({ personas: response.personas });
		} else {
			this.setState({ personas: [] });
		}
	}
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if (!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento, filter: 1 }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento });
		} else {
			this.setState({ subfraccionamientos: [] });
		}
	}

	async getViviendas() {
		let data = {
			id_fraccionamiento: localStorage.getItem('frac'),
			id_subfraccionamiento: localStorage.getItem('subfrac')
		}

		const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);

		if (Array.isArray(response.viviendas)) {
			await this.setState({ viviendas: response.viviendas, subname: response.viviendas[0].nombre_subfrac });

		} else {
			this.setState({ viviendas: [] });
		}
	}
}

export default Consumer(vacacionalHistory);