import React, { useEffect } from 'react';

const PersonalForm = ({turno, turnoInput, setTurno}) => {
	useEffect(() => {
		if (turno) {
			const input = document.getElementById('turno_title');
			if (input) {
				input.value = turno.turno
			}
		}
	}, [turno]);

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p><b>Turno:</b></p>
					</div>
					<div className="column full">
						<input
							id				=	"turno_title"
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"turno_title" 
							autoComplete 	=	'off'
							value			=	{turnoInput}
							onChange		=	{(e) => setTurno(e.target.value)}
							required
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonalForm;
