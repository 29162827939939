import React, { Component } from "react";
import RulesRender from "./../../../components/rules/rulesRender";
import Permissions from "../../../middlewares/Permissions";

import SuperCore from "../../../components/core/SuperCore";
import SuperModal from "../../../components/coreModal/SuperModal";
import NewRule from "../../../components/auxiliar/editRule";
import Request from "../../../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";
import StringHelper from "../../../helpers/StringHelper";

const request = new Request();

class Rules extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			filter: "",
			escritura: false,
			id_menu_activo: 0,
			menus: ["General", "Importes", "Cargos", "Exclusiones"],
			showBtnsDefault: false,
			inicio: true,
			subfraccionamiento: 0,
			rules: [],
			frac_name: "Fraccionamiento.",
			id_sub: localStorage.getItem("subfrac") || 0,
			name_rule: null,
			comentarios: null,
			subfraccionamientos: [],
			modalIsOpen: false,
			loading: true,
			clearData: false,
			pageLength: 20,
			saving: false,
			subLabel: ''
		};
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.filter != this.state.filter) {

		};
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.getSubLabel();
		await this.getRules();
		await this.getFraccionamiento();
		await this.getSubFraccionamientos();
		let sfh = new StringHelper();
	};

	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();
		this.setState({ escritura: permisosInfo.escritura });
	};

	async getSubLabel() {
		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		};
	};

	async getRules() {
		try {
			this.setState({ loading: true, rules: [] });

			const data = {
				id_fraccionamiento: parseInt(localStorage.getItem("frac") || 0),
				id_subfraccionamiento: parseInt(localStorage.getItem("subfrac") || 0),
			};

			const response = await request.post("/facturacion/reglas", data);

			if (response.rules) {
				this.setState({ rules: response.rules });
			};

			this.setState({ loading: false });

		} catch (error) {
			console.log(error);
		};
	};

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		try {

			const fraccionamiento = localStorage.getItem("frac");
			const data = { Idsub: fraccionamiento, filter: 1 };

			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response && !response.error) {
				if (response.subfraccionamiento && !response.empty) {
					this.setState({
						subfraccionamientos: response.subfraccionamiento,
					});
				} else {
					this.setState({
						empty: true,
						message: response.message,
						subfraccionamientos: [],
					});
				};
			} else {
				this.setState({
					error: true,
					message: response.message,
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	async getFraccionamiento() {
		try {
			const idFraccionamiento = localStorage.getItem("frac");
			const data = { IdFraccionamiento: idFraccionamiento };

			const response = await request.post("/admin/administracion/get/fraccionamiento", data);

			if (response && !response.error) {
				if (response.fraccionamiento && !response.empty) {
					this.setState({
						frac_name: response.fraccionamiento[0].nombre,
					});
				};
			};

		} catch (error) {
			console.log(error);
		};
	};

	async reload() {
		await this.getRules();
	};

	async subChange(val) {
		this.setState({
			subfraccionamiento: parseInt(val) || 0,
			id_sub: parseInt(val) || 0,
		});
		await this.getRules();
	};

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	async paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	async triggerOpenModal() {
		this.setState({ modalIsOpen: true, clearData: false });
	};

	async triggerCloseModal() {
		this.setState({ modalIsOpen: false, clearData: true });
	};

	async submit(event) {
		event.preventDefault();
		try {
			let todoValidado = true;
			this.setState({ saving: true });

			if (this.state.id_sub == null) {
				cogoToast.warn("Debes seleccionar un subfraccionamiento.", {
					position: "bottom-center",
				});
				todoValidado = false;
			};

			if (this.state.name_rule.trim() === "" || this.state.name_rule == null || typeof this.state.name_rule == "undefined") {
				cogoToast.warn("Debes agregar un nombre.", {
					position: "bottom-center",
				});
				todoValidado = false;
			};

			if (!todoValidado) {
				this.setState({ saving: false });
				return;
			};

			// const response = await request.post('/admin/administracion/get/decodetoken', {});
			const user = await this.getUserInfo();

			const data = {
				id_fraccionamiento: parseInt(localStorage.getItem("frac")),
				id_subfraccionamiento: parseInt(this.state.id_sub) === 0 ? null : parseInt(this.state.id_sub),
				nombre: this.state.name_rule,
				comentarios: this.state.comentarios ? this.state.comentarios : "",
				usuario_name: user.info.usuario,
				fecha_update: moment().format("YYYY-MM-DD HH:mm:ss"),
			};

			const response = await request.post("/facturacion/reglas/create", data);

			if (response.error || !response.created) {
				cogoToast.error("No se pudo crear nueva regla.", {
					position: "bottom-center",
				});
				this.setState({ saving: false });
				return;
			};

			if (response.created) {
				cogoToast.success("Registro creado con éxito.", {
					position: "bottom-center",
				});
				this.superCloseModal("newRule");
				this.triggerCloseModal(); //limpia la info
				await this.getRules();
			};

			this.setState({ saving: false });

		} catch (error) {
			console.log(error);
		};
	};

	updateData(json) {
		this.setState(json);
	};

	filter(rules) {
		rules = rules.filter(rul => {
			if (rul.Nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			};
		});
		return rules;
	};

	render() {
		const screens = [
			<NewRule
				subfraccionamientos={this.state.subfraccionamientos}
				updateData={this.updateData.bind(this)}
				id_sub={this.state.id_sub}
				name_rule={this.state.name_rule}
				comentarios={this.state.comentarios}
				editSubFraccionamiento={true}
				clearData={this.state.clearData}
				subLabel={this.state.subLabel}
			/>,
		];

		let rules = [];

		if (this.state.rules) {
			rules = this.state.rules;
			rules = this.filter(rules);
		};

		return (
			<>
				<RulesRender
					reload={this.reload.bind(this)}
					changeFrac={this.reload.bind(this)}
					setFilter={this.setFilter.bind(this)}
					subChange={this.subChange.bind(this)}
					escritura={this.state.escritura}
					triggerOpenModal={this.triggerOpenModal.bind(this)}
					rules={rules}
					loading={this.state.loading}
					subfraccionamientos={this.state.subfraccionamientos}
					frac_name={this.state.frac_name}
					pageLength={this.state.pageLength}
					paginatorChange={this.paginatorChange.bind(this)}
				/>
				<SuperModal
					id={"newRule"}
					title={"Nueva regla de facturación"}
					screens={screens}
					onSubmit={this.submit.bind(this)}
					triggerCloseModal={this.triggerCloseModal.bind(this)}
					size={"small"}
					showSubmit={true}
					showClose={true}
					id_menu_activo={0}
					loading={this.state.saving}
				/>
			</>
		);
	};
};

export default Rules;
