import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarOperacion from "../components/header/sidebarOperacion";
import NotificationHistoryTable from "../components/notications/notificationHistoryTable";
import Paginador from "../components/paginador/paginador";
import Controls from "../components/controls/controls";
import Request from "../core/httpClient";
import Permissions from "../middlewares/Permissions";
import sortableData from "../helpers/sortableDataTable";
import { Consumer } from "../context";

const request = new Request();

class NotificacionHistory extends Component {
    constructor(props) {
        const today = new Date();
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        super(props);
        this.state = {
            notificaciones: [],
            filterData: [],
            keys: [],
            page: 1,
            filter: "",
            pageLength: 20,
            direction: {},
            filterColumn: {},
            submenu: "",
            startDate: lastMonth.toISOString().split("T")[0],
            endDate: today.toISOString().split("T")[0],
        };
    }

    async componentDidMount() {
        await this.validarPermisos();
        await this.getNotificaciones();
        await this.getSubmenu();
    }

    async validarPermisos() {
        let permisos = new Permissions();
        //  permisos.getResult();
        let permisosInfo = await permisos.getResult();
        this.setState({ escritura: permisosInfo.escritura });
    }

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.subfraccionamiento !== prevState.subfraccionamiento ||
            this.state.startDate !== prevState.startDate ||
            this.state.endDate !== prevState.endDate
        ) {
            this.getNotificaciones();
        }
    }

    /* funcion para el filtro generico */

    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        ////console.log('filtros', this.state.filterData);

        if (param1 === "todo") {
            this.setState({ notificaciones: this.state.filterData });
            form.textFilter.value = "";
        } else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ notificaciones: filterData });
        }
    }

    async reload() {
        await this.getNotificaciones();
    }

    async changeFrac() {
        await this.getNotificaciones();
    }

    async paginatorChange(length) {
        this.setState({ pageLength: length });
        this.setPage(1);
    }

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    }

    nuevaDefinicion() {
        this.setState({ showModal: true });
    }

    setFilter(filter) {
        this.setState({ filter: filter.toLowerCase() });
    }

    filter(notificaciones) {
        notificaciones = notificaciones.filter(not => {
            if (not.notificacion_texto.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (not.tipo.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (not.usuario.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else {
                return false;
            }
        });
        return notificaciones;
    }

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.notificaciones, this.state.direction[key]);

        this.setState({
            notificaciones: items,
            direction: {
                [key]: direction,
            },
            filterColumn: {
                col: key,
                filt: direction,
            },
        });
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        }
    }

    async getNotificaciones() {
        try {
            this.setState({ loading: true });

            const idSub = this.state.subfraccionamiento || "0";
            const data = {
                idFraccionamiento: localStorage.getItem("frac"),
                idSub: idSub,
                desde: this.state.startDate,
                hasta: this.state.endDate,
            };
            const response = await request.post("/notificaciones/get/all/notifications/sent", data);
            if (response && !response.error) {
                if (response.notifications && !response.empty) {
                    this.setState({
                        notificaciones: response.notifications,
                        filterData: response.notifications,
                        keys: Object.keys(response.notifications[0]).filter(key => key.indexOf("id") === -1),
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message,
                        loading: false,
                        showModal: false,
                        notificaciones: [],
                    });
                }
            } else {
                this.setState({
                    error: true,
                    message: response.message,
                    loading: false,
                    showModal: false,
                });
            }
            this.setState({ loading: false });
        } catch (error) {
            console.log(error);
        }
    }

    handleStartDateChange(event) {
        this.setState({ startDate: event.target.value });
    }

    handleEndDateChange(event) {
        this.setState({ endDate: event.target.value });
    }

    render() {
        let notificaciones = [];

        if (this.state.notificaciones) {
            notificaciones = this.state.notificaciones;
            notificaciones = this.filter(notificaciones);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME} - {this.state.submenu}
                    </title>
                </Helmet>
                <Header
                    reload={this.reload.bind(this)}
                    change={this.changeFrac.bind(this)}
                    sidebar={true}
                    active={"historiald"}
                    parent={"notificaciones"}
                    panel={"panel2"}
                    nav={"operacion"}
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="justify-center">
                                    <div className="column full">
                                        <Controls
                                            title={this.state.submenu}
                                            nuevo={this.nuevaDefinicion.bind(this)}
                                            dataSet={notificaciones}
                                            newButton={false}
                                            setFilter={this.setFilter.bind(this)}
                                            subChange={this.subChange.bind(this)}
                                        />
                                        <div
                                            id="second_controls"
                                            className="row-responsive full"
                                            style={{ backgroundColor: "white", marginBottom: "16px" }}
                                        >
                                            <div className="data-from column full">
                                                <h5 className="color-black">DESDE:</h5>
                                                <input
                                                    type="date"
                                                    id="startdate"
                                                    name="startdate"
                                                    className="input input-text"
                                                    placeholder="Desde"
                                                    value={this.state.startDate}
                                                    onChange={this.handleStartDateChange.bind(this)}
                                                />
                                            </div>
                                            <div style={{ width: "16px" }}></div>
                                            <div className="data-to column full">
                                                <h5 className="color-black">HASTA:</h5>
                                                <input
                                                    type="date"
                                                    id="enddate"
                                                    name="enddate"
                                                    className="input input-text"
                                                    placeholder="Hasta"
                                                    value={this.state.endDate}
                                                    onChange={this.handleEndDateChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        {this.state.loading ? (
                                            <div className="row justify-center">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </div>
                                        ) : (
                                            <NotificationHistoryTable
                                                notificaciones={notificaciones}
                                                page={this.state.page}
                                                pageLength={this.state.pageLength}
                                                sortBy={this.sortableTable.bind(this)}
                                                filterColumn={this.state.filterColumn}
                                            />
                                        )}
                                        <div className="white-space-16"></div>
                                        <Paginador
                                            pages={Math.ceil(notificaciones.length / this.state.pageLength)}
                                            setPage={this.setPage.bind(this)}
                                            pageLength={this.state.pageLength}
                                            paginatorChange={this.paginatorChange.bind(this)}
                                            showPageLenght={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Consumer(NotificacionHistory);
