import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import PersonasTable from "../components/personas/personasTable";
import Header from "../components/header/header";
import Paginador from "../components/paginador/paginador";
import Controls from "../components/controls/controls";
import Sidebar from "../components/header/sidebar";
import Request from "../core/httpClient";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import Permissions from "../middlewares/Permissions";
import StringHelper from "../helpers/StringHelper";
import cogoToast from "cogo-toast";
import sortableData from "../helpers/sortableDataTable";
import BudgetTableTeoric from "../components/budgetTeoric/budgetTeoric";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import BudgetTableProveedor from "../components/budgetProveedor/budgetProveedor";
import BudgetTableFactura from "../components/budgetFactura/budgetFactura";
import ReactDatePicker from "react-datepicker";
const request = new Request();

class FacturaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      subfraccionamientos: [],
      subfraccionamientoNombre: "",
      subfrac_id: 0,
      subfraccionamiento: localStorage.getItem("subfrac") || 0,
      idSub: 0,
      facturas: [],
      id: 0,
      total: 0,
      totalReal: 0,
      idYear: 0,
      totales: [],
      disabled: false,
      page: 1,
      year: parseInt(moment().format("YYYY")),
      IdFactura: 0,
      filter: "",
      escritura: false,
      lectura: false,
      logo: require("../img/logo-AppMosphera-r.png"),
      pageLength: 20,
      direction: {
        num_factura: "desc",
        importe: "desc",
        fecha: "desc",
        status: "desc",
        razon_social: "desc",
        nombre_concepto: "desc",
        tipo: "desc",
      },
      filterColumn: {
        col: "",
        filt: "",
      },
      submenu: "",
      subLabel: "",
      idfrac: localStorage.getItem("frac") || 0,
      razon_social: "",
      importe: 0,
      num_factura: "",
      fecha: "",
      status: 1,
      tipo: 1,
      id_concepto: 1,
      id_proveedor: 1,
      observaciones: "",
      nombre_concepto: "",
      paises: [],
      conceptos: [],
      tipos: ["Egreso", "Ingreso"],
      estados: [
        { id: 1, valor: "Activa" },
        { id: 0, valor: "Cancelada" },
      ],
      proveedores: [],
    };

    this.validarPermisos();
  }

  async validarPermisos() {
    let permisos = new Permissions();
    let permisosInfo = await permisos.getResult();
    await this.setState({
      escritura: permisosInfo.escritura,
      lectura: permisosInfo.lectura,
    });
    //console.log(permisosInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
      this.getFacturas();
    }

    if (this.state.year !== prevState.year) {
      this.getFacturas();
    }
  }

  /*funcion del paginador*/
  setPage(page) {
    this.setState({ page });
  }

  async paginatorChange(length) {
    await this.setState({ pageLength: length });
    this.setPage(1);
  }

  reload() {
    this.getFacturas();
  }

  changeFrac() {
    this.setState({
      subfraccionamientos: [],
      idSub: 0,
      facturas: [],
      total: 0,
      conceptos: [],
      idYear: 0,
    });
    this.getFacturas();
  }

  subChange(sub) {
    this.setState({ subfraccionamiento: sub });
  }

  openModal(idModal, factura) {
    this.clearValues();
    const { fraccionamiento } = this.props.context;
    this.setState({
      formMessage: null,
      vivienda: { fraccionamiento },
      factura: factura,
    });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove("hide-dinamic-modal");
      modal.classList.add("show-dinamic-modal");
    }
    //document.getElementById('sunfrac').focus();
  }
  clearValues() {
    //document.getElementById('importe').value = '';
    //document.getElementById('observaciones').value = '';
  }
  closeModal(idModal) {
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.add("hide-dinamic-modal");
      modal.classList.remove("show-dinamic-modal");
    }
  }

  setFilter(filter) {
    this.setState({ filter: filter.toLowerCase() });
  }

  setYear(event) {
    this.setState({ year: event.target.value });
  }

  filter(facturas) {
    facturas = facturas.filter((con) => {
      if (con.num_factura.toLowerCase().indexOf(this.state.filter) !== -1) {
        return true;
      } else {
        return false;
      }
    });
    return facturas;
  }

  filterIndex(facturas) {
    let aux = [];
    // //console.log(presupuestos);

    facturas.forEach((item) => {
      aux.push({
        num_factura: item.num_factura,
        id_factura: item.id_factura,
        importe: item.importe,
        fecha: item.fecha,
        status: item.status,
        razon_social: item.razon_social,
        nombre_concepto: item.nombre_concepto,
        tipo: item.tipo,
      });
    });
    return aux;
  }

  sortableTable(key) {
    let sd = new sortableData();
    const { items, direction } = sd.sortBy(
      key,
      this.state.facturas,
      this.state.direction[key]
    );

    this.setState({
      facturas: items,
      direction: {
        [key]: direction,
      },
      filterColumn: {
        col: key,
        filt: direction,
      },
    });
  }

  render() {
    let facturas = [];
    let subfraccionamientos = [];
    let conceptos = [];
    let tipos = [];
    let estados = [];
    let proveedores = [];

    if (Array.isArray(this.state.subfraccionamientos)) {
      subfraccionamientos = this.state.subfraccionamientos;
    }

    if (Array.isArray(this.state.facturas)) {
      facturas = this.state.facturas;
      // //console.log(dataSet);
      facturas = this.filter(facturas);
    }

    if (Array.isArray(this.state.estados)) {
      conceptos = this.state.conceptos;
    }

    if (Array.isArray(this.state.tipos)) {
      tipos = this.state.tipos;
    }

    if (Array.isArray(this.state.estados)) {
      estados = this.state.estados;
    }

    if (Array.isArray(this.state.proveedores)) {
      proveedores = this.state.proveedores;
    }

    return (
      <div className="owners column">
        <Helmet>
          <title>
            {process.env.REACT_APP_NAME} - {this.state.submenu}
          </title>
        </Helmet>
        <Header
          sidebar={true}
          active={"presupuestoFactura"}
          parent={"presup"}
          panel={"panel2"}
          nav={"administracion"}
        />
        <div className="row">
          <div className="column" id="sidebar">
            <SidebarAdministracion />
          </div>
          <div className="column" id="content">
            <div className="justify-center">
              <div className="container column">
                {this.state.lectura ? (
                  <React.Fragment>
                    <Controls
                      title={this.state.submenu}
                      nuevo={this.openModal.bind(this, "newBudget")}
                      dataSet={facturas}
                      setFilter={this.setFilter.bind(this)}
                      subChange={this.subChange.bind(this)}
                      newButton={this.state.escritura}
                    />
                    <div className="row full align-center justify-start">
                      <div className="column ">
                        <input
                          className="input input-modals input-file"
                          id="file-input"
                          type="file"
                          webkitdirectory="true"
                          directory="true"
                          onChange={this.uploadFolder.bind(this)}
                        // disabled		=	{!this.state.escritura}
                        ></input>
                        <label
                          htmlFor="file-input"
                          className="btn btn-primary btn-small color-white label-input-file"
                        >
                          <i className="fas fa-file-upload"></i> &nbsp; Importar
                        </label>
                      </div>
                    </div>
                    <div className="white-space-16"></div>
                    {this.state.loadingFacturas ? (
                      <div className="row justify-center">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      <BudgetTableFactura
                        facturas={facturas}
                        page={this.state.page}
                        reload={this.reload.bind(this)}
                        escritura={this.state.escritura}
                        pageLength={this.state.pageLength}
                        sortBy={this.sortableTable.bind(this)}
                        filterColumn={this.state.filterColumn}
                        proveedores={this.state.proveedores}
                        tipos={this.state.tipos}
                        estados={this.state.estados}
                        conceptos={this.state.conceptos}
                        subfraccionamientos={this.state.subfraccionamientos}
                      ></BudgetTableFactura>
                    )}
                    <div className="white-space-16" />
                    <Paginador
                      pages={Math.ceil(facturas.length / this.state.pageLength)}
                      setPage={this.setPage.bind(this)}
                      pageLength={this.state.pageLength}
                      paginatorChange={this.paginatorChange.bind(this)}
                      showPageLenght={true}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {
          <DinamicModal
            idModal={"newBudget"}
            sizeModalContainer={"normal"}
            title={"Añadir factura"}
            success={this.handleSubmit.bind(this)}
            showBtnSuccess={true}
            btnTextSuccess={"GUARDAR"}
            showBtnCancel={true}
            btnTextCancel={"CANCELAR"}
            loadingSave={this.state.loadingSave}
            message={this.state.formMessage}
          >
            <div className="full row align-center">
              <div className="column full">
                <div className="white-space-16" />
                <div className="input-form-content row full align-center ">
                  <div className="column label-medium">
                    {/* <p><b>Subfraccionamiento:	</b></p> */}
                  </div>

                  <div className="column full">
                    {/* <input 
							type			=	"text" 
							name			=	"razon_social" 
							id				=	"razon_social" 
							className		=	"input input-modals" 
							autoComplete = 'off' 
							maxLength		=	"64"
							required 
							value={this.state.razon_social}
							onChange={event => this.setState({ razon_social: event.target.value })} 
						/> */}
                  </div>
                </div>
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Subfraccionamiento:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <select
                      className="input input-modals"
                      name="subfraccionamiento"
                      required
                      //value		=	{this.state.subfrac_id}
                      onChange={(event) =>
                        this.setState({ subfrac_id: event.target.value })
                      }
                    >
                      <option value="0">Todos</option>
                      {subfraccionamientos.map((sub) => (
                        <option
                          key={sub.id_subfraccionamiento}
                          value={sub.id_subfraccionamiento}
                        >
                          {sub.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="white-space-16" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>N° Factura:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="factura"
                      id="factura"
                      className="input input-modals"
                      autoComplete="off"
                      maxLength="64"
                      required
                      onChange={(event) =>
                        this.setState({ num_factura: event.target.value })
                      }
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Fecha:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <div className="column full">
                      <input
                        type="date"
                        name="fecha"
                        className="input input-modals"
                        onChange={(event) =>
                          this.setState({ fecha: event.target.value })
                        }
                        placeholder="Fecha"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="white-space-16" />
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Proveedor:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <select
                      className={"input input-modals"}
                      name="proveedor"
                      onChange={(event) => {
                        let concepto_proveedor = proveedores.filter(
                          (p) => p.id_proveedor === parseInt(event.target.value)
                        );
                        this.setState({
                          id_concepto: concepto_proveedor[0].id_concepto,
                        });
                        this.setState({
                          id_proveedor: parseInt(event.target.value),
                        });
                        //console.log(parseInt(event.target.value))
                      }}
                    >
                      {proveedores.map((proveedor, key) => (
                        <option value={proveedor.id_proveedor} key={key}>
                          {proveedor.razon_social}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="white-space-16" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>Concepto:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <select
                      className={"input input-modals"}
                      name="concepto"
                      disabled="true"
                      value={this.state.id_concepto}
                    /* onChange={(event) => {
                this.setState({id_concepto: event.target.value});
              }} */
                    >
                      {conceptos.map((concepto, key) => (
                        <option value={concepto.id} key={key}>
                          {concepto.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Tipo:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <select
                      className={"input input-modals"}
                      name="tipo_factura"
                      onChange={(event) => {
                        this.setState({
                          tipo: event.target.value,
                        });
                      }}
                    >
                      {tipos.map((tipo_factura, key) => (
                        <option value={key} key={key}>
                          {tipo_factura}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="white-space-16" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>Importe:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="number"
                      name="importe"
                      className="input input-modals"
                      onChange={(event) =>
                        this.setState({ importe: event.target.value })
                      }
                      autoComplete="off"
                      required
                      minLength="2"
                      maxLength="4"
                    />
                  </div>

                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Estado:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <select
                      className={"input input-modals"}
                      name="estado"
                      onChange={(event) => {
                        this.setState({
                          status: event.target.value,
                        });
                      }}
                    >
                      {estados.map((estado, key) => (
                        <option value={estado.id} key={key}>
                          {estado.valor}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="white-space-16" />
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Observaciones:</b>
                    </p>
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center row full">
                    <div className="column full">
                      <textarea
                        className="textarea font-small full"
                        name="observaciones"
                        id="observaciones"
                        cols="5"
                        rows="5"
                        value={this.state.observaciones}
                        onChange={(event) =>
                          this.setState({ observaciones: event.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DinamicModal>
        }
      </div>
    );
  }

  componentDidMount() {
    this.getSubmenu();
    this.getSubFraccionamientos();
    this.get_EGR_Conceptos();
    this.getProveedores();
    this.getFacturas();
  }

  async uploadFolder(event) {
    event.persist();
    let xmlFacturas = [];

    let facturasInfo = {
      // total: 0,
      validas: 0,
      version_erronea: 0,
      uuid_faltante: 0,
      no_reconocidas: 0,
    };

    var parser = new DOMParser();

    if (event.target.files.length > 0) {
      for (const file of event.target.files) {
        //console.log(file.webkitRelativePath);
        if (file.type === "text/xml") {
          // facturasInfo.total++;
          let xml = await new Response(file).text();
          let xmlDoc = parser.parseFromString(xml, "text/xml");
          let comprobante = xmlDoc.getElementsByTagName("cfdi:Comprobante");
          if (comprobante[0] !== undefined) {
            if (comprobante[0].getAttribute("Version") === "4.0") {
              let data = {
                importe: 0,
                num_factura: 0,
                fecha: "",
                status: 1,
                tipo: "Egreso",
                // nombre_proveedor: "",
                // rfc_proveedor: "",
                id_proveedor: null,
                id_concepto: null,
                id_fraccionamiento: localStorage.getItem("frac"),
                id_subfraccionamiento: 0,
              };

              let complemento =
                comprobante[0].getElementsByTagName("cfdi:Complemento");
              let timbreFiscal = complemento[0].getElementsByTagName(
                "tfd:TimbreFiscalDigital"
              );
              let emisor = comprobante[0].getElementsByTagName("cfdi:Emisor");
              if (timbreFiscal[0].getAttribute("UUID")) {
                data.importe = comprobante[0].getAttribute("Total");
                data.num_factura = comprobante[0].getAttribute("Folio");
                data.tipo =
                  comprobante[0].getAttribute("TipoDeComprobante") === "I"
                    ? "Ingreso"
                    : "Egreso";
                data.fecha = moment(
                  comprobante[0].getAttribute("Fecha")
                ).format("YYYY-MM-DD");
                let rfc_proveedor = emisor[0].getAttribute("Rfc");
                let concepto_proveedor = this.state.proveedores.filter(
                  (proveedor) => proveedor.rfc === rfc_proveedor
                );
                if (concepto_proveedor.length > 0) {
                  data.id_concepto = concepto_proveedor[0].id_concepto;
                  data.id_proveedor = concepto_proveedor[0].id_proveedor;
                } else {
                  data.observaciones =
                    "- Debe agregar el siguiente proveedor al sistema:\n" +
                    "Nombre del proveedor:\n" +
                    emisor[0].getAttribute("Nombre") +
                    "\n" +
                    "Rfc\n" +
                    emisor[0].getAttribute("Rfc");
                }

                // data.nombre_proveedor = emisor[0].getAttribute("Nombre");
                // data.rfc_proveedor = emisor[0].getAttribute("Rfc");
                xmlFacturas.push(data);
                facturasInfo.validas++;
              } else {
                //cogoToast.error("La factura no cuenta con el atributo UUID");
                facturasInfo.uuid_faltante++;
              }
            } else {
              //cogoToast.error("La version de la factura es incorrecta");
              facturasInfo.version_erronea++;
            }
          } else {
            //cogoToast.error("Este xml no es un factura reconocida");
            facturasInfo.no_reconocidas++;
          }
        }
      }
      //mostrar ventana de resultados
      const { hide } = cogoToast.info(
        <div>
          <ul style={{ listStyle: "circle", paddingLeft: "15px" }}>
            {/* <li>
              <b>Total:</b> {facturasInfo.total}
            </li> */}
            <li>
              <b>Válidas:</b> {facturasInfo.validas}
            </li>
            <li>
              <b>Rechazadas:</b>{" "}
              {facturasInfo.version_erronea + facturasInfo.uuid_faltante}
            </li>
            <ul>
              <li style={{ paddingLeft: "10px" }}>
                <b>- Versión ≠ 4:</b> {facturasInfo.version_erronea}
              </li>
              <li style={{ paddingLeft: "10px" }}>
                <b>- Sin UUID:</b> {facturasInfo.uuid_faltante}
              </li>
            </ul>
            <li>
              <b>No reconocidas:</b> {facturasInfo.no_reconocidas}
            </li>
          </ul>
          <div className="white-space-16" />
          <button
            className="btn btn-primary btn-small color-white"
            type="button"
            onClick={() => {
              hide();
              this.handleSubmitFacturasXML(xmlFacturas);
            }}
          >
            {" "}
            Importar
          </button>
          <button
            className="btn btn-primary btn-small color-white"
            type="button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              hide();
            }}
          >
            {" "}
            Cancelar
          </button>
        </div>,
        { hideAfter: 0, heading: "CFDI analizados" }
      );
    } else {
      cogoToast.error("No se encuentra ningún fichero xml");
    }
    console.log(xmlFacturas);
    event.target.value = "";
  }

  async handleSubmitFacturasXML(xmlFacturas) {
    this.setState({ loadingSave: true });
    //const form = event.target;

    const response = await request.post("/facturas/create", xmlFacturas);

    if (response.created) {
      this.setState({ showModal: false });
      this.setState({ showModal: false });
      this.getFacturas();
      cogoToast.success("Facturas agregadas.", {
        position: "bottom-right",
      });
    } else {
      this.setState({
        empty: true /*, formMessage: "NO SE PUDO CREAR NUEVA FACTURA"*/,
      });
      cogoToast.error("No se pudieron agregar las facturas.", {
        position: "bottom-right",
      });
    }
    this.setState({ loadingSave: true });
  }

  async getSubmenu() {
    const submenu = await this.props.context.getSubmenuAdminName();
    if (submenu) {
      this.setState({ submenu });
    }

    const subLabel = window.localStorage.getItem("subLabel");
    if (subLabel) {
      this.setState({ subLabel });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  // obtener facturas
  async getFacturas() {
    this.setState({ facturas: [], loadingFacturas: true });

    let data = {
      //IdSub: this.state.subfraccionamiento,
      id_fraccionamiento: localStorage.getItem("frac"),
      id_subfraccionamiento: this.state.subfraccionamiento,
      //year: this.state.year
    };

    const response = await request.post("/facturas/get/all/", data);

    if (response && !response.error) {
      if (response.facturas && !response.empty) {
        this.setState({
          facturas: response.facturas,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          facturas: [],
          disabled: true,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
    this.setState({ factura: [], loadingFacturas: false });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loadingSave: true });
    let concepto_proveedor = this.state.proveedores.filter(
      (proveedor) => proveedor.id_proveedor === this.state.id_proveedor
    );
    //const form = event.target;
    let data = {
      importe: this.state.importe,
      num_factura: this.state.num_factura,
      fecha: this.state.fecha,
      status: this.state.status,
      tipo: this.state.tipos[this.state.tipo],
      id_concepto: concepto_proveedor[0].id_concepto,
      id_proveedor: this.state.id_proveedor,
      observaciones: this.state.observaciones,
      id_fraccionamiento: localStorage.getItem("frac"),
      id_subfraccionamiento: this.state.subfrac_id,
    };

    const response = await request.post("/facturas/factura/create", data);

    if (response.created) {
      this.setState({ showModal: false });
      this.closeModal("newBudget");
      this.getFacturas();
      cogoToast.success("Factura agregada.", {
        position: "bottom-right",
      });
    } else {
      this.setState({
        empty: true /*, formMessage: "NO SE PUDO CREAR NUEVA FACTURA"*/,
      });
      cogoToast.error("No se pudo agregar nueva factura.", {
        position: "bottom-right",
      });
    }

    this.setState({
      loadingSave: false,
      razon_social: "",
      rfc: "",
      municipio: "",
      cp: "",
      Id_Estado: 1,
      Id_Pais: 1,
      id_concepto: 1,
      cuenta_contable: "",
      observaciones: "",
    });
  }

  // traer subfraccionamientos
  async getSubFraccionamientos() {
    const fraccionamiento = localStorage.getItem("frac");
    if (!fraccionamiento) {
      return;
    }
    let data = { Idsub: fraccionamiento };
    const response = await request.post(
      "/admin/administracion/get/subfraccionamientos",
      data
    );
    if (response.subfraccionamiento) {
      this.setState({ subfraccionamientos: response.subfraccionamiento });
    } else {
      this.setState({ subfraccionamientos: [] });
    }
  }

  async get_EGR_Conceptos() {
    let data = { id_fraccionamiento: localStorage.getItem("frac") };

    const response = await request.post("/conceptos/get/all", data);
    if (response.empty || response.error) {
      await this.setState({ conceptos: [] });
    }

    if (response.conceptos) {
      await this.setState({
        conceptos: this.TransformRowsToTreeGridFormat(response.conceptos),
      });
    }
  }

  // obtener proveedores
  async getProveedores() {
    this.setState({ proveedores: [] });

    let data = {
      //IdSub: this.state.subfraccionamiento,
      id_fraccionamiento: localStorage.getItem("frac"),
      //year: this.state.year
    };

    const response = await request.post("/proveedores/get/all/", data);

    if (response && !response.error) {
      if (response.proveedores && !response.empty) {
        this.setState({
          proveedores: response.proveedores,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          proveedores: [],
          disabled: true,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
    //cmbio el id del concepto por el id del concepto del primer proveedor
    let concepto_proveedor = this.state.proveedores.filter(
      (proveedor) => proveedor.id_proveedor === this.state.id_proveedor
    );
    this.setState({ id_concepto: concepto_proveedor[0].id_concepto });

    this.setState({ proveedor: [] });
  }

  //ordenar los datos recibidos a formato: arbol con sus ramas
  TransformRowsToTreeGridFormat = (lista) => {
    let listaFinal = [];
    let arboles = lista.filter((item) => item.id_parent === 0);
    for (let index = 0; index < arboles.length; index++) {
      listaFinal.push(arboles[index]);
      lista
        .filter((item) => item.id_parent === arboles[index].id)
        .forEach((element) => {
          listaFinal.push(element);
          lista
            .filter((item) => item.id_parent === element.id)
            .forEach((elemento) => {
              listaFinal.push(elemento);
            });
        });
    }
    return listaFinal;
  };
}

export default Consumer(FacturaPage);