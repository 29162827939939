import React, { Component } from 'react';

import EdditModal from "./notificationsModal";
import ModalBorrar from "../modals/modalDelete";
import NotificationsSend from "./notificationsSend";
import cogoToast from 'cogo-toast';
import Request from "../../core/httpClient";
const request = new Request();

class NotificationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			showModal:      false,
			idNotification: null 
        }
    }

    reload() { this.props.updated() }

    delete = id => { this.handleDelete(id) }

    render() {
        return (
            <div className="card-table card-owners column" id="card-owners">

                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">
                                    <div className="row">
                                        Tipo &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'tipo' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'tipo')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="description">
                                    <div className="row">
                                        Mensaje &nbsp;
                                        <div className="align-center">
                                            {this.props.filterColumn.col === 'texto' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'texto')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'texto')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'texto')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center th-options-small">Enviar</th>
                                <th className="date text-center th-options-small">Editar</th>
                                <th className="date text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.props.notificaciones.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((notificacion, key) => {
                                return (
                                    <tr className={this.state.id_notificacion_row === notificacion.id_notificacion && 'row-active'} key={key}>
                                        <td>{notificacion.tipo}</td>
                                        <td>{notificacion.texto}</td>
                                        <td className="text-center">
                                            <button className       =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                    onClick         =   {this.openModal.bind(this,'sendNotificationsDef',notificacion.id_notificacion)} 
                                                   
                                            >
												<i className="fas fa-paper-plane font-text" />
											</button>
                                        </td>
                                        <td className="text-center"> 
                                        <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                type        =   'button' 
                                                onClick     =   {this.openModal.bind(this,'editNotificationsDef',  notificacion.id_notificacion) }
                                                
                                        >
											<i className="font-small fas fa-pen" />
										</button>
                                        </td>
                                        <td className="text-center">
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                    type            =   'button' 
                                                    onClick         =   {this.openModalDelete.bind(this,notificacion.id_notificacion)} 
                                                    style           =   {{ padding: '5px' }} 
                                                    disabled        =   {!this.props.escritura}
                                            >
												<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
											</button>
                                           </td>
                                    </tr>
                                )
                            }
                            )
                            }

                        </tbody>
                    </table>

				<ModalBorrar id={this.state.idNotification} delete={this.delete.bind(this)} />
                <EdditModal 
                    id          =   {this.state.idNotification} 
                    reload      =   {this.reload.bind(this)}
                    escritura   =   {this.props.escritura}
                />
                <NotificationsSend 
                    id              =   {this.state.idNotification} 
                    subfracsList    =   {this.props.subfraccionamientos}
                    escritura       =   {this.props.escritura}
                    subLabel        =   {this.props.subLabel}
                />
            </div>
        );
	}
	
	openModal(idModal,id) {
		this.setState({ idNotification: id, message:'', id_notificacion_row: id });
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        switch(idModal){
            case "sendNotificationsDef":
                document.getElementById('subfraccionamientoId').focus();
                break;
            case "editNotificationsDef":
                document.getElementById('msj').focus();
                break;

                

        }
	}

	openModalDelete(id) {
		this.setState({idNotification: id, id_notificacion_row: id});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {

        let data = { IdNotificacion: id }

        const response = await request.post("/notificaciones/delete/notification", data);
        //console.log(response);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                cogoToast.success('Notificación eliminada.', {
                    position: 'bottom-right',
                });
                //console.log("eim1");
            } else {
                this.setState({ empty: true/*, message: response.message*/ });
                cogoToast.error('No se pudo eliminar notificación.', {
                    position: 'bottom-right',
                });
                //console.log("eim2")
            }
        } else {
            this.setState({ error: true/*, message: response.message*/ });
            cogoToast.error('No se pudo eliminar notificación.', {
                position: 'bottom-right',
            });
            //console.log("eim3")
        }
        this.props.updated();
    }
}

export default NotificationTable;