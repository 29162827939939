// React and third-parties
import React from 'react';
import { Helmet } from "react-helmet";
import cogoToast from 'cogo-toast';
import moment from 'moment';
import { transform } from 'lodash';

// Context
import { Consumer } from "../../../context";

// Middlewares
import Permissions from '../../../middlewares/Permissions';

// Components
import Header from "../../../components/header/header";
import Sidebar from "../../../components/header/sidebar";
import ProductsTable from '../../../components/Billing/productsTable';
import Paginador from "../../../components/paginador/paginador";
import Controls from '../../../components/controls/controls';
import DinamicModal from '../../../components/dinamicModal/dinamicModal';
import SuperCore from './../../../components/core/SuperCore';
import PrelacionModal from './PrelacionModal';
import PaginadorParent from '../../../components/paginador/PaginadorParent';

//Helpers
import sortableData from '../../../helpers/sortableDataTable';

//HTTP
import Request from "../../../core/httpClient";

const request = new Request();

class Product extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            productos: 		[],
			pagedProductos: [],
			unidadesSAT:	[],
			tipos:			[],
			id_unidadSAT:	0,
			id_tipo:		0,
			escritura:		false,
			producto:		null,
			impuestos:		[],
			filter:			'',
			Prelacion: 		0,
			multifacturar:   false,
			direction: {
				Clave: 'desc',
				Nombre: 'desc',
				Tipo: 'desc',
				Descripcion: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			loadingProductos: false,
			prelacionesModalToggle: false
        }
        this.validarPermisos();

		this.submittedPrelacionesCallback = this.submittedPrelaciones.bind(this);
    }

    // async openModal(idModal) {
	// 	await this.clearData();
	// 	await this.setState({ message: null});
	// 	const modal = document.getElementById(idModal);
	// 	if (modal) {
	// 		modal.classList.remove('hide-dinamic-modal');
	// 		modal.classList.add('show-dinamic-modal');
	// 	}
	// }
	async clearData(){
		await this.setState({
			clave:			'',
			claveSAT:		'',
			descripcion:	'',
			id_tipo:		0,
			nombre:			'',
			
		});
	}

    async validarPermisos(){
		
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, menu: permisosInfo.menu});
		

    }
    
    async componentDidMount(){
		this.getSubmenu();
		await this.getProducts();
		await this.getUnidadesSAT();
		await this.getProductsTipo();
		await this.getImpuestos();
		await this.getTipoImpuestos();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}
	}
	
	async getImpuestos(){
         
        const response = await request.post('/facturacion/impuestos', {});

        if(response.empty || response.error){
			
			await this.setState({impuestos: []})
		}

		if(response.impuestos){
			await this.setState({impuestos: response.impuestos});
		}
		
	}
	
	async getTipoImpuestos(){
         
        const response = await request.post('/facturacion/tipo/impuestos', {});

        if(response.empty || response.error){
			
			await this.setState({tipos: []})
		}

		if(response.tipos){
			await this.setState({tipos: response.tipos});
		}
		
    }

	async getUnidadesSAT(){
		const response = await request.post('/facturacion/unidades', {});
		
		if(response.empty || response.error){
			await this.setState({unidadesSAT: []})
		}

		if(response.unidades){
			await this.setState({unidadesSAT: response.unidades});
		}

	}
	async getProductsTipo(){
         
		const response = await request.post('/facturacion/productos/tipo', {});
		
        if(response.empty || response.error){
            
            await this.setState({tipos: []});
        }

        if(response.tipos){
            await this.setState({tipos: response.tipos});
            
        }
    }

    async getProducts(){
         
        this.setState({ loadingProductos: true });

		try {
			const response = await request.post('/facturacion/productos', {
				id_frac: 	parseInt(localStorage.getItem('frac')) || 0,
				filtro:		0
			})
			if(response.empty || response.error){
				
				this.setState({productos: []});
			}
	
			if(response.productos){
				this.setState({ productos: response.productos, Prelacion: response.productos.length + 1 });
			}
		} catch (error) {
			console.log(error);
		}
		
        this.setState({ loadingProductos: false });
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.productos, this.state.direction[key]);
		console.log("Sorting productos");
		this.setState({
			productos: [...items],
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	openPrelacionesModal() {
		this.setState({ prelacionesModalToggle: !this.state.prelacionesModalToggle });
		this.superOpenModal("modificarPrelaciones");
	}

	submittedPrelaciones() {
		this.superCloseModal("modificarPrelaciones");
		this.getProducts();
	}

    render(){
		
		const filteredProductos = this.filter(this.state.pagedProductos);

        return(
            <div className="admin column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header 
                    sidebar     =   {true} 
                    active      =   {'entorno'} 
                    parent      =   {'config'} 
                    panel       =   {'panel4'} 
                    nav         =   {"catalogos"} 
                    // change      =   {this.change.bind(this)} 
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <Sidebar />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <Controls title={this.state.submenu}
                                    selectSub       =   {false}
									nuevo		=	{this.openModal.bind(this, "newProduct")}
									dataSet		=	{this.state.productos}
									setFilter	=	{this.setFilter.bind(this)}
									// subChange	=	{this.subChange.bind(this)} 
									newButton	=	{this.state.escritura}
								/>
								<div className="navigation align-center justify-end">
									<button
										type="button"
										disabled={this.state.loadingProductos}
										className={"btn btn-small color-white " + (this.state.loadingProductos ? "btn-disable" : "btn-primary")}
										onClick={() => this.openPrelacionesModal()}
									>
										<i className="fas fa-sort-amount-down font-text" />
										&nbsp; Prelaciones
									</button>
								</div>
								<div className="white-space-16"></div>
                                {/* <div className="white-space-32" />
                                <div className="navigation align-center">
                                    <div className="justify-start">
                                        <h3 className="weight-semi">Productos</h3>
                                    </div>
                                </div> */}

                                {/* Tabla */}
                                <ProductsTable
                                    productos       = 	{filteredProductos}
									loadingProductos =  {this.state.loadingProductos}
									escritura		=	{this.state.escritura}
									unidadesSAT		=	{this.state.unidadesSAT}
									tipos			=	{this.state.tipos}
									getProducts		=	{this.getProducts.bind(this)}
									impuestos		=	{this.state.impuestos}
									sortBy			=	{this.sortableTable.bind(this)}
									filterColumn	=	{this.state.filterColumn}
                                ></ProductsTable>
                                <div className="white-space-48" />
								<PaginadorParent
									data={this.state.productos}
									onPagedDataChange={({ pagedData }) => this.setState({ pagedProductos: pagedData })}
								/>
                            </div>
                        </div>
                    </div>
                </div>
				{this.state.productos.length > 0 &&
					<PrelacionModal
						productos={this.state.productos}
						modalToggle={this.state.prelacionesModalToggle}
						onSuccessfulSubmit={this.submittedPrelacionesCallback}
					/>
				}
                <DinamicModal 
					idModal					=	{'newProduct'}
					sizeModalContainer		=	{'small'}
					title					=	{'Nuevo producto'} 
					success					=	{this.handledSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
					
				>
					{this.renderNewProduct()}
				</DinamicModal>
            </div>
        );
	}

	filter(products) {

		products = products.filter(pro => {

			if(pro.Nombre.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1) {

				return pro;
			} else if(pro.Clave.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1){
				return pro;
			} else {

				return null;
			}
		})
		return products;
	}
	
	async handledSubmit(event){
		event.preventDefault();
		await this.setState({loadingSave: true});
		let datosValidados = true;

		if((this.state.id_unidadSAT === 0)){
			
			datosValidados = false;
			cogoToast.warn("Debes de elegir una Unidad SAT.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
		}

		if((this.state.id_tipo === 0)){
			datosValidados = false;
			
			cogoToast.warn("Debes elegir un tipo de producto.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
		}



		if(!datosValidados){
			await this.setState({loadingSave: false});
			return;
		}


		let user = await this.getUserInfo();



		let data = {
			id_frac: 			parseInt(localStorage.getItem('frac')) || 0,
			clave:				this.state.clave,
			claveSAT:			this.state.claveSAT,
			descripcion: 		this.state.descripcion,
			claveUnidad:		this.state.id_unidadSAT,
			date:				moment().tz('GMT-5').format('YYYY-MM-DD HH:mm:ss'),
			usuario:			user.info.usuario,
			nombre:				this.state.nombre,
			id_producto_tipo: 	parseInt(this.state.id_tipo),
			Prelacion: 			this.state.Prelacion,
			multifacturar:		this.state.multifacturar ? 1 : 0
		}

		//console.log(data);
		
        const res = await request.post('/facturacion/productos/create', data)
		
		if(!res || res.error){
			cogoToast.error(res.message || "No se pudo crear producto.",{
				position: 'bottom-center',
				heading:	'Crear nuevo producto'
			})
		}

		if(res.created){
			cogoToast.success("Producto creado con éxito.",{
				position: 'bottom-center',
				// heading:	'Crear nuevo producto'
			})
			this.closeModal('newProduct');
			await this.getProducts();
			this.clearData();
		}
		


		await this.setState({loadingSave: false});
	}

    renderNewProduct(){

        return(
            <div className="full row ">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input className	=	"input input-modals" 
								autoComplete = 'off'
								type		    =	"text" 
								name		    =	"nombre" 
								id			    = 	"nombre"
								required
								value={this.state.nombre}
								onChange={(event)=>{this.setState({nombre: event.target.value})}}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>

                    <div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Código:</b>
							</p>
						</div>
						<div className="column full">
							<input className	=	"input input-modals" 
							autoComplete = 'off'
								type		    =	"text" 
								name		    =	"Codigo" 
								id			    = 	"Codigo"
								required
								value={this.state.clave}
								onChange={(event)=>{this.setState({clave: event.target.value})}}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Descripción:</b>
							</p>
						</div>
						<div className="column full">
							<textarea
								className="input input-modals"
								name = "description"
								required
								value={this.state.descripcion}
								onChange={(event)=>{this.setState({descripcion: event.target.value})}}
								autoComplete = 'off'
							>
							</textarea>
						</div>
					</div>
					<div className="white-space-8"></div>

                    <div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Clave SAT:</b>
							</p>
						</div>
						<div className="column full">
							<input className	=	"input input-modals" 
							autoComplete = 'off'
								type		    =	"text" 
								name		    =	"clave" 
								id			    = 	"clave"
								minLength		=	{8}
								maxLength		=	{8}
								required
								value={this.state.claveSAT}
								onChange={(event)=>{this.setState({claveSAT: event.target.value})}}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>

                    <div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Unidad SAT:</b>
							</p>
						</div>
						<div className="column full">
							<select 
								className	=	"input input-modals"
								name		=	"unidades" 
								value={this.state.id_unidadSAT}
								onChange={(event) => this.setState({id_unidadSAT: event.target.value})}
								required>
									<option value={0} disabled>Selecciona una unidad</option>
								{  
								
									this.state.unidadesSAT.map((unidad, key) => (
									<option 
										value	=	{unidad.ClaveUnidad} 
										key		=	{key}
									>
										{unidad.Descripcion}
									</option>)) 
								}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>

                    <div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Tipo:</b>
							</p>
						</div>
						<div className="column full">
							<select 
								className	=	"input input-modals"
								name		=	"tipos" 
								value={this.state.id_tipo}
								onChange={(event) => this.setState({id_tipo: event.target.value})}
								required>
								<option value={0} disabled>Selecciona un tipo de producto</option>
								{  
									this.state.tipos.map((tipo, key) => (
									<option 
										value	=	{tipo.Id_TipoImpuesto} 
										key		=	{key}
									>
										{tipo.Nombre}
									</option>)) 
								}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Prelación:</b>
							</p>
						</div>
						<div className="row full">
							<div className="column label-normal tooltip bottom" data-tooltip="Modificar en ventana de Prelaciones">
								<input className="input input-modals" 
									autoComplete='off'
									type="number"
									name="prioridad"
									id="prioridad"
									minLength={8}
									maxLength={8}
									required
									value={this.state.Prelacion}
									disabled={true}
									onChange={(event) => { this.setState({ Prelacion: event.target.value }) }}
								/>
							</div>

							<div className="column label-normal space">
								<p>
									<b>Multifacturar:</b>
								</p>
							</div>
							<div className="column  space container-check">
								<label>
									<input type="checkbox" name="multifacturar" id="multifacturar" checked={this.state.multifacturar || false}
										onChange={event => { this.setState({ multifacturar: event.target.checked }) }} />
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
				</div>

				</div>
			</div>
        );
    }
}

export default Consumer(Product);