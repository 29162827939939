import React, { Component } from 'react';
import InquilinoEditarModal from './inquilinoEditar';
import ModalBorrar from '../modals/modalDelete';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import { AsYouType } from 'libphonenumber-js'

const request = new Request();


class InquilinosTable extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
			dataInquilino: '',
			residentes_status: []
		}
    }

	componentDidMount() {
		this.load();
	}

	async load() {
		const res = await request.get('/inquilinos/get/tipo_status');
		if(res.tipo_status) {
			this.setState({residentes_status: res.tipo_status});
		}
	}

	reload() { this.props.reload() }

    delete = id => {
        this.handleDelete(id)
    }
    getNumberFormat(cell_phone){

        try {
            /*if(cell_phone.includes('+'))
                return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+'+cell_phone);*/
			return `******${cell_phone.substring(cell_phone.length - 4)}`

        } catch (error) {
            return null;
        }
    }

    render() {

        let inquilinos = []
        if (this.props.inquilinos) {
            inquilinos = this.props.inquilinos
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
                                    <div className="row">
                                        Nombre &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="th-options-huge">Teléfono</th>
                                <th className="th-options-huge">Email</th>
                                <th className="th-options-huge">
                                    <div className="row">
                                    Vivienda &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
								<th className="th-options-huge">
                                    <div className="row">
                                        Estado APP &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'estatus' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'estatus')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'estatus')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'estatus')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquilinos.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((inquilino, key) => (
                                <tr className={this.state.id_inquilino_row === inquilino.id_inquilino ? 'row-active' : ''} key={key}>
                                    <td>
                                        {inquilino.nombre}
                                    </td>
                                    <td>
                                        {this.getNumberFormat(inquilino.telefono)}
                                    </td>
                                    <td className='text-left'>
                                        {inquilino.email}
                                    </td>
                                    <td className='text-left'>
                                        {inquilino.numero_registro}
                                    </td>
									<td>
                                        {inquilino.estatus}
                                    </td>
									<td className="text-center">
                                        <button 
                                                type        =   "button justify-center" 
                                                onClick     =   {this.openModal.bind(this, 'editInquilinos', inquilino)} 
                                                
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
								  	<td>
                                        <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                type        =   'button' 
                                                onClick     =   {this.openModalDelete.bind(this, inquilino)} 
                                                style       =   {{ padding: '5px' }} 
                                                disabled    =   {!this.props.escritura}
                                        >
                                            <i className="font-text fas fa-trash-alt "/>
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
                <InquilinoEditarModal 
                    reload      =   {this.reload.bind(this)} 
                    inquilino   =   {this.state.dataInquilino} 
					escritura   =   {this.props.escritura}
                    estadosAPP 	=   {this.state.residentes_status}
                    closeModal  =   {() => this.setState({dataInquilino: {}})}
                />
                                  
				<ModalBorrar
					id          =   {this.state.id_inquilino}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	openModal(idModal, data) {
        this.setState({dataInquilino: data, id_inquilino_row: data.id_inquilino});
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        document.getElementsByName('fullName')[0].focus();
	}

	openModalDelete(inq) {
        this.setState({ id_inquilino: inq.id_inquilino, id_inquilino_row: inq.id_inquilino});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {
        let data = {
            id_inquilino: id,
            id_usuario_del: localStorage.getItem("user_id")
        }
        const response = await request.post("/viviendas/inquilino/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                //this.setState({ message: response.message });
                this.reload();
                cogoToast.success('Residente eliminado.', {
                    position: 'bottom-right',
                });
            } else {
                this.setState({
                    empty: true,
                    //message: response.message,
                    loading: false
                });
                cogoToast.error('No se pudo eliminar residente.', {
                    position: 'bottom-right',
                });
            }
        } else {
            cogoToast.error('No se pudo eliminar residente.', {
                position: 'bottom-right',
            });
        }
    }
}

export default InquilinosTable;