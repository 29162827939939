import React from 'react';

const API_KEY = 'AIzaSyBojssMCZfjj6SUxpd4izBfHjClyKKCZ1M';

class Mapa extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			latitude: this.props.lat,
			longitude: this.props.lng
		}
	}

	componentDidMount() {
		this.setState({ latitude: this.props.data.p_latitude, longitude: this.props.data.p_longitude });
		window.initMap = this.initMap.bind(this);
		const script = document.createElement('script')
		script.async = true
		script.defer = true
		script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`
		document.head.appendChild(script)
	}


	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setState({ latitude: this.props.data.p_latitude, longitude: this.props.data.p_longitude });
			window.initMap = this.initMap.bind(this);
			const script = document.createElement('script')
			script.async = true
			script.defer = true
			script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`
			document.head.appendChild(script)
		}
	}

	initMap() {

		const div = document.getElementById('map');
		if (div) {
			let coord = { lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) }
			let map = new window.google.maps.Map(div, {
				center: coord,
				zoom: this.props.data.id_fraccionamiento === 0 ? 5 : 18,
				disableDefaultUI: true,
				zoomControl: true
			});

			let icon = {
				url: this.props.data.id_fraccionamiento >= 0 ? "https://image.flaticon.com/icons/png/128/148/148882.png" : "https://www.route66sodas.com/wp-content/uploads/2019/01/Alert.gif", // url
				scaledSize: new window.google.maps.Size(50, 50), // scaled size
				origin: new window.google.maps.Point(0, 0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
			};

			let marker = new window.google.maps.Marker({
				position: coord,
				map: map,
				icon: icon
			});
		}
	}

	render() {
		return (
			<div id="map" style={{ width: '100%', height: '389px' }}>

			</div>
		)
	}
}

export default Mapa;