import React from 'react';
import Core from './../../core/SuperCore';

class SubHeader extends Core{
    constructor(props){
        super(props);
        this.state ={
            id:             this.props.id,
            menus:          this.props.menus,
            id_menu_activo: this.props.id_menu_activo,
        }
    }

    componentDidUpdate(prevProps, prevState){

        if(prevProps.id != this.props.id){
            this.setState({id: this.props.id}); 
        }

        if(prevProps.menus != this.props.menus){
            this.setState({menus: this.props.menus});   
        }

        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            this.setState({id_menu_activo: this.props.id_menu_activo}); 
        }
        
        
    }

    render(){
        let menus = this.state.menus;
        return(
            <React.Fragment>
                {menus ? 
                    menus.map((nav, key)=>{
                        let id = `${this.props.id}-nav-${key}`;
                        return(
                            <button 
                                className   = {this.state.id_menu_activo === key ?  "simple-item simple-item-active" : "simple-item" } 
                                // style       = {{width:`${width}%`}}
                                id          = {id}
                                onClick     = {this.props.changeMenu.bind(this,key)}
                            >
                                <p>{nav}</p>
                            </button>
                        );
                    })

                : null}
            </React.Fragment>
        );
    }
}

export default SubHeader;
