import React, {Component, useState } from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import { Position } from "toasted-notes";

class ConfigTable extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const formatDate = (valor) => {
			let date = null;
			if (valor !== "") {
				date = valor.split("-");
				date = new Date(date[0], date[1] - 1, date[2]);
			}
			return date;
		};
		let config = this.props.config;

		return (
			<React.Fragment>
				<div className="card-table card-owners column" id="card-owners">
					<form
						className="column align-center"
						onSubmit={(event) => {
							this.props.updateConfig(event, config.id_tabla);
						}}
					>
						<div className="white-space-24" />
						<div className="container column">
							<div className="edit-button justify-start">
								<div className="column full align-start">
									<h3>{config.nombre}</h3>
								</div>
								<div className="column full">
									<div className="justify-end">
										<button
											className="btn btn-primary btn-small color-white"
											type="button"
											// id          =   "btn-modal-edit"
											onClick={(event) => {
												this.props.handleEdit(config.id_tabla);
											}}
											disabled={!this.props.escritura || !this.props.enabledEditButton || this.props.generalLoading}
										>
											<i className="fas fa-pen"></i> Editar
										</button>
									</div>
								</div>
							</div>

							<div className="white-space-24"></div>
							<div>
								{this.props.generalLoading || this.props.loading ? (
									<div className="row justify-center full">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
								) : config.campos ? (
									config.campos.map((data, key) => {
										return (
											<div className="billing-rows dinamic row full" key={key}>
												<div className="justify-start label-title-config">
													<h4>{data.nombre}:</h4>
												</div>
												<div className="justify-start align-center">
													{data.id_tipo === 1 ? (
														<select
															className={this.props.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
															value={data.index}
															onChange={(event) => {
																this.props.changeConfig(data.id_reg, event.target.value, config.id_tabla);
															}}
															disabled={this.props.disabled}
														>
															<option value={-1} disabled>Seleccionar</option>
															{data.lista.map((list, key) => (
																<option value={key} key={key}>
																	{list}
																</option>
															))}
														</select>
													) : data.id_tipo === 2 ? (
															<PasswordInput
																data={data}
																isPassword={data.id_tipo === 2 && (key === 3 || key === 5)}
																id_tabla={config.id_tabla}
																disabled={this.props.disabled}
																onInputChange={this.props.changeConfigValue}
															/>
													) : (
														<DatePicker
															className={this.props.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
															name="fechaLinea"
															selected={formatDate(data.valor)}
															disabled={this.props.disabled}
															dateFormat="MMM-yy"
															showMonthYearPicker
															locale="es"
															autoComplete="off"
															onChange={(value) => {
																let date = `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? "0" : ""}${value.getMonth() + 1}-01`;
																//console.log("fecha", date);
																this.props.changeConfigValue(data.id_reg, date, config.id_tabla);
															}}
														/>
													)}
												</div>
											</div>
										);
									})
								) : null}
							</div>

							{this.props.disabled === false && (
								<div className="btn-container justify-end">
									<button
										type="button"
										className="btn-action-c color-white"
										onClick={(event) => {
											this.props.handleEdit(config.id_tabla);
										}}
									>
										CANCELAR
									</button>
									<button type="submit" className="btn-action-s color-white">
										{this.props.loading ? (
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										) : (
											<div>ACTUALIZAR</div>
										)}
									</button>
								</div>
							)}
						</div>
						<div className="white-space-24" />
					</form>
				</div>
				<div className="white-space-48"></div>
			</React.Fragment>
		);
	}
}

export default ConfigTable;

const PasswordInput = (props) => {

	const { data, disabled, id_tabla, isPassword, onInputChange  } = props;
	const { valor, id_reg } = data;

	const [open, setOpen] = useState(false);

	const iconClassName = open ?
		"far fa-eye cursor-pointer"
		:
		"far fa-eye-slash cursor-pointer";

	return <div className="row full align-center justify-end">
		<input
			type={isPassword && !open ? "password" : "text"}
			name="input"
			className={disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
			disabled={disabled}
			value={valor}
			onChange={(event) => onInputChange(id_reg, event.target.value, id_tabla)}
			required
		/>
		{isPassword &&
			<i
				className={iconClassName}
				style={{ position: "absolute", color: "#666", paddingInline: 10 }}
				onClick={() => setOpen(prevValue => !prevValue)}
			/>
		}
	</div>

}
