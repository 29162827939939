import React, { Component } from "react";
import ReactModal from "react-modal";
import { Consumer } from "../../context";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Request from "../../core/httpClient";

const request = new Request();
ReactModal.setAppElement("#root");

class IncidentsTable extends Component {
	constructor(props) {
		super(props);
		this.openModal = this.openModal.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			showModal: false,
			incidentes: [],
			incidenteDetalle: {},
			resolucion: 0,
			idQueja: 0,
			Rdescription: "",
			user: {},
			message: '',
			disabled_resolution: false
		};
	};

	async componentDidMount() {
		await this.load();
	};

	componentDidUpdate(prevProps, prevState) {

		if (this.state.resolucion != prevState.resolucion) {
			if (this.state.resolucion === '2') {
				this.setState({ disabled_resolution: true });
			} else {
				this.setState({ disabled_resolution: false });
			};
		};
	};

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre
				}
			});
		};
	};

	// actualizar estatus de quejas
	async handleSubmit(event, id) {
		try {
			this.setState({ loadingSave: true });
			event.preventDefault();

			const data = {
				idQueja: this.state.idQueja,
				resolucion: parseInt(this.state.resolucion),
				resolucionDetalle: this.state.Rdescription,
				fechaResolucion: moment(new Date()).format("YYYY-MM-DD"),
				idUsuario: this.state.incidenteDetalle.id_usuario_resolucion ? this.state.incidenteDetalle.id_usuario_resolucion : this.state.user.idUsuario
			};

			const response = await request.post("/incidencias/incidents/update", data);

			if (response && !response.error) {
				if (response.updated && !response.empty) {
					this.setState({
						resolucion: '',
						idQueja: '',
						Rdescription: '',
					});
					this.handleClose();
					this.props.reload();
					cogoToast.success('Datos de incidencia actualizados.', {
						position: 'bottom-right',
					});
				} else {
					this.setState({ empty: true });
					cogoToast.error('No se pudo actualizar detalles del incidencia.', {
						position: 'bottom-right',
					});
				};
			} else {
				this.setState({ error: true });
				cogoToast.error('No se pudo actualizar detalles del incidencia.', {
					position: 'bottom-right',
				});
			};
			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};


	handleClose() {
		this.setState({ showModal: true, incidenteDetalle: {} });
	};

	//   traer una unica incidencia
	async getIncidenciaDetalle(idQueja) {
		try {
			const data = { idQueja: idQueja };

			const response = await request.post("/incidencias/get/detalle", data);

			if (response && !response.error) {
				if (response.incidenciaDetalle && !response.empty) {
					this.setState({
						incidenteDetalle: response.incidenciaDetalle,
						resolucion: response.incidenciaDetalle.resolucion,
						idQueja: response.incidenciaDetalle.id_queja,
						Rdescription: response.incidenciaDetalle.resolucion_descripcion
					});
				} else {
					this.setState({ empty: true });
				};
			} else {
				this.setState({ error: true });
			};
		} catch (error) {
			console.log(error);
		};
	};

	handleChange() {
		let idR = parseInt(document.getElementById('resolucion').value)
		this.props.filtrar(idR)
	};

	openModal(idModal, idQueja) {
		this.setState({ Rdescription: '' });
		this.getIncidenciaDetalle(idQueja);
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
		document.getElementById('desc').focus();
	};

	render() {

		let estado = false;

		if (this.props.estado) {
			estado = this.props.estado;
		};

		return (
			<div className="column">
				<div className="card-table column" id="card-owners">
					{estado ? (
						<div className="container column justify-center">
							<div className="white-space-32"></div>
							<div className="justify-center">
								<i className="fas fa-check color-primary fa-5x"></i>
							</div>
							<div className="white-space-8"></div>
							<h3 className="text-center">
								Sin incidencias de hoy
							</h3>
							<div className="white-space-32"></div>
						</div>
					) :
						<div className="table-responsiv">
							<table className="card-incidents full">
								<thead className="sticky-th">
									<tr className="text-left">
										<th className="type">
											<div className="row">
												Tipo &nbsp;
												<div className="align-center">
													{this.props.filterColumn.col === 'quejaDesripcion' ? this.props.filterColumn.filt === 'asc' ?
														<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'quejaDesripcion')}></i>
														:
														<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'quejaDesripcion')}></i>
														:
														<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'quejaDesripcion')}></i>
													}
												</div>
											</div>
										</th>
										<th className="name">
											<div className="row">
												Vivienda &nbsp;
												<div className="align-center">
													{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
														<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
														:
														<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
														:
														<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
													}
												</div>
											</div>
										</th>
										<th className="date">
											<div className="row">
												Fecha &nbsp;
												<div className="align-center">
													{this.props.filterColumn.col === 'fecha' ? this.props.filterColumn.filt === 'asc' ?
														<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fecha')}></i>
														:
														<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fecha')}></i>
														:
														<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fecha')}></i>
													}
												</div>
											</div>
										</th>
										<th className="description">
											<div className="row">
												Descripción &nbsp;
												<div className="align-center">
													{this.props.filterColumn.col === 'descripcion' ? this.props.filterColumn.filt === 'asc' ?
														<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
														:
														<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
														:
														<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
													}
												</div>
											</div>
										</th>
										<th className="resolution ">
											<div className="row">
												Resolución &nbsp;
												<div className="align-center">
													{this.props.filterColumn.col === 'resolucion' ? this.props.filterColumn.filt === 'asc' ?
														<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'resolucion')}></i>
														:
														<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'resolucion')}></i>
														:
														<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'resolucion')}></i>
													}
												</div>
											</div>
										</th>
										<th className="text-center th-options-small">Editar</th>
									</tr>
								</thead>
								<tbody>
									{this.props.incidentes.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((incidente, key) => {
										return (
											<tr className="tr" key={key}>
												<td className="weight-semi">{incidente.quejaDesripcion}</td>
												<td className="description-detail">{incidente.numero_registro}</td>
												<td className="">
													{moment(incidente.fecha).tz('GMT').format("DD/MM/YYYY")}
												</td>
												<td className="description text-justify">
													<p className="truncate-ellipsis">{incidente.descripcion}</p>
												</td>
												<td className="weight-semi">
													{incidente.resolucion === 0 ? ('Pendiente') : null || incidente.resolucion === 1 ? ('En revision') : null}
												</td>
												<td className="text-center weight-semi">
													<button
														className="btn-full justify-center align-center font-small btn-secondary color-white"
														onClick={() => this.openModal("detailIncidents", incidente.id_queja)}
													>
														<i className="font-small fas fa-pen" />
													</button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>}

					<DinamicModal
						idModal={'detailIncidents'}
						sizeModalContainer={'big'}
						title={'Detalle de incidencia'}
						success={this.handleSubmit}
						showBtnSuccess={this.props.escritura}
						btnTextSuccess={"ACTUALIZAR"}
						showBtnCancel={true}
						btnTextCancel={"CANCELAR"}
						loadingSave={this.state.loadingSave}
						message={this.state.message}
					>
						{this.renderDetailsIncident()}
					</DinamicModal>


				</div>
			</div>
		);
	};

	renderDetailsIncident() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-extra">
							<p><b>Nombre completo:</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="fullName"
								value={this.state.incidenteDetalle.nombre}
								onChange={event => this.setState({ pNombre: event.target.value })}
								className="input input-modals"
								placeholder="Nombre completo"
								required
								maxLength="64"
								disabled
							/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="row full">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>Tipo de incidencia:</b></p>
								</div>
								<div className="column full">
									<input
										type="text"
										name="numIdEddit"
										value={this.state.incidenteDetalle.quejaDesripcion}
										onChange={event => this.setState({ pNumId: event.target.value })}
										className="input input-modals"
										placeholder='tipo de incidencia'
										required={true}
										maxLength="24"
										disabled={true}
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start">
										<b>Fecha:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="date"
										name="numIdEddit"
										value={moment(this.state.incidenteDetalle.fecha).format("YYYY-MM-DD")}
										onChange={event => this.setState({ pNumId: event.target.value })}
										className="input input-modals"
										placeholder='Fecha'
										required
										maxLength="24"
										disabled
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>  Teléfono:	</b></p>
								</div>
								<div className="column full">
									<input
										type="text"
										name="numIdEddit"
										value={this.state.incidenteDetalle.telefono}
										onChange={event => this.setState({ pNumId: event.target.value })}
										className="input input-modals"
										placeholder='Número ID'
										required maxLength="24"
										disabled
									/>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start" >
										<b>Resolución:</b>
									</p>
								</div>
								<div className="column full">
									<select
										name="incident-resolution"
										id="incident-resolution"
										className="input input-modals"
										value={this.state.resolucion}
										onChange={event => this.setState({ resolucion: event.target.value })}
									>
										<option value="0">Sin resolver</option>
										<option value="1">En revisión</option>
										<option value="2">Resuelto</option>
									</select>
								</div>
							</div>
						</div>
						<div className="column full">
							<div className="responsive-img justify-end row">
								<div className="container-img justify-center">
									<img src={this.state.incidenteDetalle.imagen ? this.state.incidenteDetalle.imagen : require('../../img/empty_image.png')} className="img-duo-preview" id="previewAreaEdit" />
								</div>

							</div>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-extra">
							<p><b>  Usuario que atiende:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="fullName"
								value={this.state.incidenteDetalle.nombreResolucion ? this.state.incidenteDetalle.nombreResolucion : this.state.user.nombre}
								className="input input-modals"
								placeholder="Nombre completo"
								required
								maxLength="64"
								disabled
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="row full">
						<div className="column full">
							<div className="row full">
								<p><b> Descripción:	</b></p>
							</div>
							<div className="white-space-8"></div>
							<textarea
								type="text"
								name="fullName"
								value={this.state.incidenteDetalle.descripcion ? this.state.incidenteDetalle.descripcion : "N/A"}
								onChange={event => this.setState({ pNombre: event.target.value })}
								className="textarea input input-modals"
								cols="50"
								rows="3"
								maxLength="64"
								disabled
							/>
						</div>
						<div className="column full" style={{ paddingLeft: "20px" }}>
							<p>
								<b>Descripción de la resolución:</b>
							</p>
							<div className="white-space-8" />
							<textarea
								className="textarea input input-modals"
								value={this.state.disabled_resolution ? this.state.incidenteDetalle.resolucion_descripcion : this.state.Rdescription}
								onChange={event => this.setState({ Rdescription: event.target.value })}
								name="descriptionResolution"
								cols="50"
								rows="3"
								maxLength="600"
								minLength="20"
								disabled={this.state.disabled_resolution}
								id="desc"
							/>

						</div>
					</div>
				</div>
			</div>
		);
	};
};

export default Consumer(IncidentsTable);
