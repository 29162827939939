import React, { Component } from "react";
import ReactModal from "react-modal";
import DinamicModal from "../dinamicModal/dinamicModal";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";

const request = new Request();

class BudgetFacturaEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id_factura: this.props.factura.id_factura,
      razon_social: this.props.factura.razon_social,
      importe: this.props.factura.importe,
      num_factura: this.props.factura.num_factura,
      fecha: this.props.factura.fecha,
      status: this.props.factura.status,
      tipo: this.props.factura.tipo,
      id_concepto: this.props.factura.id_concepto || 0,
      id_proveedor: this.props.factura.id_proveedor || 0,
      observaciones: this.props.factura.observaciones,
      nombre_concepto: this.props.factura.nombre_concepto,
      conceptos: this.props.conceptos,
      tipos: this.props.tipos,
      estados: this.props.estados,
      proveedores: this.props.proveedores,
      showModal: false,
      subfraccionamientos: this.props.subfraccionamientos,
      id_subfraccionamiento: this.props.factura.id_subfraccionamiento,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.factura !== prevProps.factura) {
      this.setState({
        id_factura: this.props.factura.id_factura,
        razon_social: this.props.factura.razon_social,
        importe: this.props.factura.importe,
        num_factura: this.props.factura.num_factura,
        fecha: this.props.factura.fecha,
        status: this.props.factura.status,
        tipo: this.props.factura.tipo,
        id_concepto: this.props.factura.id_concepto || 0,
        id_proveedor: this.props.factura.id_proveedor || 0,
        observaciones: this.props.factura.observaciones,
        nombre_concepto: this.props.factura.nombre_concepto,
        id_subfraccionamiento: this.props.factura.id_subfraccionamiento,
      });
    }
  }

  render() {
    return (
      <div>
        <DinamicModal
          idModal={"editBudgetFactura"}
          sizeModalContainer={"small"}
          title={"Editar factura"}
          success={this.handleSubmit.bind(this)}
          showBtnSuccess={this.props.escritura}
          btnTextSuccess={"ACTUALIZAR"}
          showBtnCancel={true}
          btnTextCancel={"CANCELAR"}
          loadingSave={this.state.loadingSave}
          message={this.state.message}
        >
          {this.renderEditBudget()}
        </DinamicModal>
      </div>
    );
  }

  renderEditBudget() {
    let subfraccionamientos = [];
    let conceptos = [{ id: 0, label: "" }];
    let tipos = [];
    let estados = [];
    let proveedores = [{ id_proveedor: 0, razon_social: "", id_concepto: 0 }];

    if (Array.isArray(this.state.tipos)) {
      tipos = this.state.tipos;
    }

    if (Array.isArray(this.state.conceptos)) {
      conceptos = conceptos.concat(this.state.conceptos);
    }

    if (Array.isArray(this.state.estados)) {
      estados = this.state.estados;
    }

    if (Array.isArray(this.state.proveedores)) {
      proveedores = proveedores.concat(this.state.proveedores);
    }

    if (Array.isArray(this.state.proveedores)) {
      subfraccionamientos = this.state.subfraccionamientos;
    }

    return (
      <div className="full row align-center">
        <div className="column full">
          <div className="white-space-16" />
          <div className="input-form-content row full align-center ">
            <div className="column label-medium">
              {/* <p><b>Subfraccionamiento:	</b></p> */}
            </div>

            <div className="column full">
              {/* <input 
							type			=	"text" 
							name			=	"razon_social" 
							id				=	"razon_social" 
							className		=	"input input-modals" 
							autoComplete = 'off' 
							maxLength		=	"64"
							required 
							value={this.state.razon_social}
							onChange={event => this.setState({ razon_social: event.target.value })} 
						/> */}
            </div>
          </div>
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>Subfraccionamiento:</b>
              </p>
            </div>
            <div className="column full">
              <select
                className="input input-modals"
                name="subfraccionamiento"
                required
                value={this.state.id_subfraccionamiento}
                onChange={(event) =>
                  this.setState({ id_subfraccionamiento: event.target.value })
                }
              >
                <option value="0">Todos</option>
                {subfraccionamientos.map((sub) => (
                  <option
                    key={sub.id_subfraccionamiento}
                    value={sub.id_subfraccionamiento}
                  >
                    {sub.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-duo-big">
              <p>
                <b>N° Factura:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="text"
                name="factura"
                id="factura"
                value={this.state.num_factura}
                className="input input-modals"
                autoComplete="off"
                maxLength="64"
                required
                onChange={(event) =>
                  this.setState({ num_factura: event.target.value })
                }
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>Fecha:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <div className="column full">
                <input
                  type="date"
                  name="fecha"
                  className="input input-modals"
                  value={moment(this.state.fecha).format("YYYY-MM-DD")}
                  onChange={(event) =>
                    this.setState({ fecha: event.target.value })
                  }
                  placeholder="Fecha"
                  required
                />
              </div>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>Proveedor:</b>
              </p>
            </div>
            <div className="column full">
              <select
                className={"input input-modals"}
                name="proveedor"
                value={this.state.id_proveedor}
                onChange={(event) => {
                  let concepto_proveedor = proveedores.filter(
                    (p) => p.id_proveedor === parseInt(event.target.value)
                  );
                  this.setState({
                    id_concepto: concepto_proveedor[0].id_concepto,
                  });
                  this.setState({ id_proveedor: parseInt(event.target.value) });
                  //console.log(parseInt(event.target.value))
                }}
              >
                {proveedores.map((proveedor, key) => (
                  <option value={proveedor.id_proveedor} key={key}>
                    {proveedor.razon_social}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-duo-big">
              <p>
                <b>Concepto:</b>
              </p>
            </div>
            <div className="column full">
              <select
                className={"input input-modals"}
                name="concepto"
                disabled="true"
                value={this.state.id_concepto}
              /* onChange={(event) => {
                this.setState({id_concepto: event.target.value});
              }} */
              >
                {conceptos.map((concepto, key) => (
                  <option value={concepto.id} key={key}>
                    {concepto.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>Tipo:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <select
                className={"input input-modals"}
                name="tipo_factura"
                value={this.state.tipos.findIndex(
                  (tipo) => tipo === this.state.tipo
                )}
                onChange={(event) => {
                  this.setState({
                    tipo: this.state.tipos[event.target.value],
                  });
                }}
              >
                {tipos.map((tipo_factura, key) => (
                  <option value={key} key={key}>
                    {tipo_factura}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-duo-big">
              <p>
                <b>Importe:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="number"
                name="importe"
                className="input input-modals"
                value={this.state.importe}
                onChange={(event) =>
                  this.setState({ importe: event.target.value })
                }
                autoComplete="off"
                required
                minLength="2"
                maxLength="4"
              />
            </div>

            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>Estado:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <select
                className={"input input-modals"}
                name="estado"
                value={this.state.status}
                onChange={(event) => {
                  this.setState({
                    status: event.target.value,
                  });
                }}
              >
                {estados.map((estado, key) => (
                  <option value={estado.id} key={key}>
                    {estado.valor}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-medium">
              <p>
                <b>Observaciones:</b>
              </p>
            </div>
            <div className="white-space-8" />
            <div className="align-center row full">
              <div className="column full">
                <textarea
                  className="textarea font-small full"
                  name="observaciones"
                  id="observaciones"
                  cols="5"
                  rows="5"
                  value={this.state.observaciones}
                  onChange={(event) =>
                    this.setState({ observaciones: event.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async handleSubmit(event) {
    event.preventDefault();

    let data = {
      id_factura: this.state.id_factura,
      importe: this.state.importe,
      num_factura: this.state.num_factura,
      fecha: this.state.fecha,
      status: this.state.status,
      tipo: this.state.tipo || "Egreso",
      id_concepto: this.state.id_concepto !== 0 ? this.state.id_concepto : null,
      id_proveedor:
        this.state.id_proveedor !== 0 ? this.state.id_proveedor : null,
      observaciones: this.state.observaciones,
      id_fraccionamiento: localStorage.getItem("frac"),
      id_subfraccionamiento: this.state.id_subfraccionamiento,
    };
    const response = await request.post("/facturas/factura/update", data);
    if (response.updated && !response.empty) {
      this.props.reload();
      cogoToast.success("Factura actualizada.", {
        position: "bottom-right",
      });
    } else {
      this.setState({
        empty: true,
        //message: "NO SE PUDO ACTUALIZAR CONCEPTO"
      });
      cogoToast.error("No se pudo actualizar la factura.", {
        position: "bottom-right",
      });
    }
  }
}

export default BudgetFacturaEdit;