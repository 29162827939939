import React from 'react';
import SuperCore from './../core/SuperCore';
import Draggable from 'react-draggable';

import Header from './structure/header';
import SubHeader from './structure/subheader';
import Body from './structure/body';
import Footer from './structure/footer';

class RenderModal extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            id:             this.props.id,
            title:          this.props.title,
            menus:          this.props.menus,
            id_menu_activo: this.props.id_menu_activo ? this.props.id_menu_activo:0,
            screens:        this.props.screens,
            footer:         this.props.footer,
            lblSubmit:      this.props.lblSubmit,
            lblCancel:      this.props.lblCancel,
            nexFooter:      this.props.nexFooter,

            lblBack:        this.props.lblBack,
            lblNext:        this.props.lblNext,
            size:           this.props.size,
            showSubmit:     this.props.showSubmit,
            showClose:      this.props.showClose
        }
    }
    async componentDidUpdate(prevProps, prevState){
        if(prevProps.id != this.props.id){
            await this.setState({id: this.props.id});
        }

        if(prevProps.title != this.props.title){
            await this.setState({title: this.props.title});
        }

        if(prevProps.menus != this.props.menus){
            await this.setState({menus: this.props.menus});
        }

        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            await this.setState({id_menu_activo: this.props.id_menu_activo})
        }

        if(prevProps.screens != this.props.screens){
            await this.setState({screens: this.props.screens})
        }

        if(prevProps.footer != this.props.footer){
            await this.setState({footer: this.props.footer})
        }

        if(prevProps.lblSubmit != this.props.lblSubmit){
            await this.setState({lblSubmit: this.props.lblSubmit})
        }

        if(prevProps.lblCancel != this.props.lblCancel){
            await this.setState({lblCancel: this.props.lblCancel})
        }
        if(prevProps.nexFooter != this.props.nexFooter){
            await this.setState({nexFooter: this.props.nexFooter})
        }

        if(prevProps.lblBack != this.props.lblBack){
            await this.setState({lblBack: this.props.lblBack});
        }

        if(prevProps.lblNext != this.props.lblNext){
            await this.setState({lblNext: this.props.lblNext});
        }

        if(prevProps.size != this.props.size){
            await this.setState({size: this.props.size})
        }

        if(prevProps.showSubmit != this.props.showSubmit){
            await this.setState({showSubmit: this.props.showSubmit})
        }


        if(prevProps.showClose != this.props.showClose){
            await this.setState({showClose: this.props.showClose})
        }


        

        
    }
    async changeMenu(id){
        await this.setState({
            id_menu_activo: id || 0
        });
        this.props.onMenuChanged && this.props.onMenuChanged(id || 0);
    }
    async backMenu(){
        
        let sizeMenus   = 0;
        
        if(this.state.menus != null || typeof this.state.menus != 'undefined')
            sizeMenus = this.state.menus.length;

        let id_menu = this.state.id_menu_activo;
            id_menu++;
        if(id_menu < sizeMenus )
            id_menu = 0;
        
        await this.setState({id_menu_activo: id_menu });
        this.props.onMenuChanged && this.props.onMenuChanged(id_menu);

    }
    async nextMenu(){
        let sizeMenus   = 0;
        
        if(this.state.menus != null || typeof this.state.menus != 'undefined')
            sizeMenus = this.state.menus.length;

        let id_menu = this.state.id_menu_activo;
            id_menu++;
        if(id_menu > sizeMenus )
            id_menu = sizeMenus;
        
        await this.setState({id_menu_activo: id_menu });
        this.props.onMenuChanged && this.props.onMenuChanged(id_menu);
    }

    render(){
        let sizeMenus   = 0;
        let sizeScreens = 0;

        if(this.state.menus != null || typeof this.state.menus != 'undefined')
            sizeMenus = this.state.menus.length;
    
        if(this.state.screens != null || typeof this.state.screens != 'undefined')
            sizeScreens = this.state.screens.length;
        

       
        // //console.log(`id_menu_activo: ${this.state.id_menu_activo}`);
        let  defaultFooter = false;
        if(parseInt(sizeScreens) === 1 || parseInt(sizeMenus) === parseInt(this.state.id_menu_activo + 1))
            defaultFooter = true;
   
       
        return(
            <div className ="super-modal-container super-hide" id ={this.state.id}>
                <Draggable handle  =   ".super-header">
                {/* START SUPER MODAL */}
                <div className={`super-modal ${this.state.size}`}>
                    <div className = "super-header row align-center">
                        <Header
                            id          =   {this.state.id}
                            title       =   {this.state.title}
                            triggerCloseModal   =   {this.props.triggerCloseModal.bind(this)}
                        ></Header>
                    </div>
                    <div className = "super-sub-header" id = {`sub-header-${this.state.id}`}>
                        <SubHeader
                            id                  =   {this.state.id}
                            id_menu_activo      =   {this.state.id_menu_activo}
                            menus               =   {this.state.menus}
                            changeMenu          =   {this.changeMenu.bind(this)}
                        ></SubHeader>
                    </div>
                    <div className = "super-body align-center">
                        <Body
                            id              =   {this.state.id}
                            menus           =   {this.state.menus}
                            id_menu_activo  =   {this.state.id_menu_activo}
                            screens         =   {this.state.screens}
                        ></Body>
                    </div>
                    {!this.props.disableFooter &&
                        <div className = "super-footer align-center">
                            <Footer
                                id              = {this.state.id}
                                id_menu_activo  = {this.state.id_menu_activo}
                                footer          = {this.state.footer}
                                defaultFooter   = {defaultFooter}
                                nexFooter       = {this.state.nexFooter}
                                lblSubmit       = {this.state.lblSubmit}
                                lblCancel       = {this.state.lblCancel}
                                lblBack         = {this.state.lblBack}
                                lblNext         = {this.state.lblNext}
                                onSubmit        = {this.props.onSubmit.bind(this)}
                                backMenu        = {this.backMenu.bind(this)}
                                nextMenu        = {this.nextMenu.bind(this)}
                                loading			= {this.props.loading}

                                triggerCloseModal   =   {this.props.triggerCloseModal.bind(this)}

                                showSubmit      =   {this.state.showSubmit}
                                showClose       =   {this.state.showClose}
                            ></Footer>
                        </div>
                    }
                </div>
                {/* END SUPER MODAL */}
                </Draggable>
            </div>
        );
    }
}

export default RenderModal;