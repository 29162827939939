import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarOperacion from "../components/header/sidebarOperacion";
import Paginador from "../components/paginador/paginador";
import ReservationHistoryTable from "../components/commonAreasReservation/reservationHistoryTable";
import Request from "../core/httpClient";
import Controls from "../components/controls/controls";
import moment from "moment";
import Permissions from "../middlewares/Permissions";
import sortableData from "../helpers/sortableDataTable";
import { Consumer } from "../context";

const request = new Request();

class CommonAreasHistorry extends Component {
    constructor(props) {
        const today = new Date();
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        super(props);
        this.state = {
            commonAreas: [],
            keys: [],
            reservas: [],
            page: 1,
            filter: "",
            subfraccionamiento: localStorage.getItem("subfrac"),
            pageLength: 20,
            direction: {},
            filterColumn: {},
            submenu: "",
            startDate: lastMonth.toISOString().split("T")[0],
            endDate: today.toISOString().split("T")[0],
            filterTipo: "",
        };
    }

    async componentDidMount() {
        await this.validarPermisos();
        await this.getReservas();
        await this.getSubmenu();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.subfraccionamiento !== prevState.subfraccionamiento ||
            this.state.startDate !== prevState.startDate ||
            this.state.endDate !== prevState.endDate ||
            this.state.filterTipo !== prevState.filterTipo
        ) {
            this.getReservas();
        }
    }

    async validarPermisos() {
        let permisos = new Permissions();
        //  permisos.getResult();
        let permisosInfo = await permisos.getResult();
        this.setState({ escritura: permisosInfo.escritura });
    }

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }

    async paginatorChange(length) {
        await this.setState({ pageLength: length });
        this.setPage(1);
    }

    reload() {
        // this.getHouses();
        // this.getNombreSubFraccionamiento()
    }

    async changeFrac() {
        await this.getReservas();
    }

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    }

    nuevaArea() {
        this.setState({ showModal: true });
    }

    setFilter(filter) {
        this.setState({ filter: filter.toLowerCase() });
    }

    filter(CommonAreas) {
        CommonAreas = CommonAreas.filter(com => {
            if (com.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (com.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (com.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else {
                return false;
            }
        });
        return CommonAreas;
    }

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.reservas, this.state.direction[key]);

        this.setState({
            reservas: items,
            direction: {
                [key]: direction,
            },
            filterColumn: {
                col: key,
                filt: direction,
            },
        });
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        }
    }

    async getReservas() {
        try {
            this.setState({ reservas: [], loading: true });

            const idSub = this.state.subfraccionamiento;
            const data = {
                Id: localStorage.getItem("frac"),
                fecha: moment().format("YYYY-MM-DD"),
                idSub: idSub,
                desde: this.state.startDate,
                hasta: this.state.endDate,
            };
            // console.log("data sent", data);
            const response = await request.post("/commonArea/get/reservas/history", data);
            // console.log("respone", response);

            if (response && !response.error) {
                if (response.info && !response.empty) {
                    this.setState({ reservas: response.info });
                }
            } else {
                this.setState({
                    error: true,
                    message: response.message,
                });
            }

            this.setState({ loading: false });
        } catch (error) {
            console.log(error);
        }
    }

    handleStartDateChange(event) {
        this.setState({ startDate: event.target.value });
    }

    handleEndDateChange(event) {
        this.setState({ endDate: event.target.value });
    }

    handleTipoChange(event) {
        this.setState({ filterTipo: event.target.value });
    }

    filterByTipo(CommonAreas) {
        const { filterTipo } = this.state;
        if (filterTipo === "Canceladas") {
            return CommonAreas.filter(area => area.cancelada === "Cancelada");
        } else if (filterTipo === "No Canceladas") {
            return CommonAreas.filter(area => area.cancelada === "");
        }
        return CommonAreas;
    }

    render() {
        let CommonAreas = [];

        if (Array.isArray(this.state.reservas)) {
            CommonAreas = this.state.reservas;
            CommonAreas = this.filter(CommonAreas);
            CommonAreas = this.filterByTipo(CommonAreas);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME} - {this.state.submenu}
                    </title>
                </Helmet>
                <Header
                    reload={this.reload.bind(this)}
                    change={this.changeFrac.bind(this)}
                    sidebar={true}
                    active={"historiala"}
                    nav={"operacion"}
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <Controls
                                    title={this.state.submenu}
                                    nuevo={this.nuevaArea.bind(this)}
                                    dataSet={CommonAreas}
                                    dateFormat="dd/mm/yyyy"
                                    newButton={false}
                                    setFilter={this.setFilter.bind(this)}
                                    subChange={this.subChange.bind(this)}
                                />
                                <div
                                    id="second_controls"
                                    className="row-responsive full"
                                    style={{ backgroundColor: "white", marginBottom: "16px" }}
                                >
                                    <div className="data-from column full">
                                        <h5 className="color-black">DESDE:</h5>
                                        <input
                                            type="date"
                                            id="startdate"
                                            name="startdate"
                                            className="input input-text"
                                            placeholder="Desde"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDateChange.bind(this)}
                                        />
                                    </div>
                                    <div style={{ width: "16px" }}></div>
                                    <div className="data-to column full">
                                        <h5 className="color-black">HASTA:</h5>
                                        <input
                                            type="date"
                                            id="enddate"
                                            name="enddate"
                                            className="input input-text"
                                            placeholder="Hasta"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDateChange.bind(this)}
                                        />
                                    </div>
                                    <div style={{ width: "16px" }}></div>
                                    <div className="data-to column full">
                                        <h5 className="color-black">TIPO:</h5>
                                        <select
                                            className="input input-select"
                                            onChange={this.handleTipoChange.bind(this)}
                                        >
                                            <option value="">Todos</option>
                                            <option value="Canceladas">Canceladas</option>
                                            <option value="No Canceladas">No Canceladas</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="card-table card-owners column" id="card-owners">
                                    {this.state.loading ? (
                                        <div className="row justify-center">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div>
                                    ) : (
                                        <ReservationHistoryTable
                                            page={this.state.page}
                                            reservas={CommonAreas}
                                            pageLength={this.state.pageLength}
                                            sortBy={this.sortableTable.bind(this)}
                                            filterColumn={this.state.filterColumn}
                                        />
                                    )}
                                </div>
                                <div className="white-space-16"></div>
                                <Paginador
                                    pages={Math.ceil(CommonAreas.length / this.state.pageLength)}
                                    setPage={this.setPage.bind(this)}
                                    pageLength={this.state.pageLength}
                                    paginatorChange={this.paginatorChange.bind(this)}
                                    showPageLenght={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Consumer(CommonAreasHistorry);
