import React from "react";

import ReactSelect from "react-select";
import Paginador from "../paginador/paginador";
import DinamicModal from "../dinamicModal/dinamicModal";
import Core from "../core/SuperCore";
import cogoToast from "cogo-toast";
import Request from "../../core/httpClient";
import ModalBorrar from "../modals/modalDelete";
import Respuestas from "./asamblea_respuestas_table";

import Chart from "chart.js";
import NumberFormat from "react-number-format";

const request = new Request();

class PreguntasTable extends Core {
	constructor(props) {
		super(props);
		this.state = {
			preguntas: this.props.preguntas,
			id_encuesta: this.props.id_encuesta,
			preguntas_tipos: this.props.preguntas_tipos,

			id_pregunta_tipo: this.props.preguntas_tipos.length > 0 ? this.props.preguntas_tipos[0].id_encuesta_pregunta_tipo : 0,
			pregunta: "",
			codigo: "",
			resultados: [],

			title: "Añadir Pregunta",
			createAction: true,
			titlePregunta: "",

			respuestas: [],
			cerrado: this.props.cerrado,
			options: [],

			listaVotoManual: [],
			loadingVotoManual: false,
			savingVotoManual: false,
		};
	}

	async componentDidUpdate(prevProps) {
		if (this.props.preguntas != prevProps.preguntas) {
			this.setState({preguntas: this.props.preguntas});
		}

		if (this.props.id_encuesta != prevProps.id_encuesta) {
			this.setState({id_encuesta: this.props.id_encuesta});
		}

		if (this.props.preguntas_tipos != prevProps.preguntas_tipos) {
			this.setState({
				preguntas_tipos: this.props.preguntas_tipos,
				id_pregunta_tipo: this.props.preguntas_tipos.length > 0 ? this.props.preguntas_tipos[0].id_encuesta_pregunta_tipo : 0,
			});
		}

		if (this.props.cerrado != prevProps.cerrado) {
			this.setState({cerrado: this.props.cerrado});
		}
	}

	async openEditModal(pregunta) {
		await this.setState({
			title: "Editar pregunta",
			id_pregunta_tipo: pregunta.id_encuesta_pregunta_tipo,
			pregunta: pregunta.pregunta,
			id_pregunta: pregunta.id_encuesta_pregunta,
			codigo: pregunta.codigo,
			createAction: false,
		});

		this.openModal("createPregunta");
	}

	async openResultModal(pregunta) {
		this.setState({ id_pregunta_activa: null, id_encuesta_pregunta_row: pregunta.id_encuesta_pregunta });
		this.openModal("resultPregunta");
		await this.setState({
			title: "Resultados",
			pregunta: pregunta.pregunta,
		});

		let data = {
			IdEncuestaPregunta: pregunta.id_encuesta_pregunta,
		};

		const response = await request.get("/encuestas/get/preguntas/resultados", data);

		if (!response.error && !response.empty) {
			await this.setState({resultados: response.resultados});
		} else {
			await this.setState({resultados: []});
		}
		this.renderChart();
	}

	async openVotoManual(pregunta) {
		this.openModal("votoManual");

		await this.setState({
			id_pregunta: pregunta.id_encuesta_pregunta,
			titlePregunta: pregunta.pregunta,
			id_encuesta_pregunta_tipo: pregunta.id_encuesta_pregunta_tipo,
			loadingVotoManual: true,
		});

		await this.getRespuestas();

		let data = {
			id_encuesta_pregunta: pregunta.id_encuesta_pregunta,
		};

		const response = await request.get("/encuestas/get/respuestas/voto/manual", data);
		if (!response.error && !response.empty) {
			let lista = response.data ? response.data : [];

			let lm = [];
			lista.map((l) => {
				if (lm.filter((f) => f.id_vivienda === l.id_vivienda).length === 0) {
					let options = [];
					lista
						.filter((opl) => opl.id_vivienda === l.id_vivienda)
						.map((item) => {
							let res = this.state.options.filter((o) => o.value === item.IdEncuestaRespuesta)[0];
							if (res) {
								options.push(res);
							}
						});
					if (options.length > 0) {
						lm.push({...l, options: options});
					} else {
						lm.push({...l});
					}
				}
			});

			await this.setState({listaVotoManual: lm});
		} else {
			await this.setState({listaVotoManual: []});
		}
		
		this.setState({loadingVotoManual: false});
	}

	render() {
		let {preguntas} = this.state;
		return (
			<React.Fragment>
				<tr className="acordeon-row">
					<td colSpan="7" className="acordeon">
						<table className="full">
							<tbody>
								<tr className="pad-bot row-without-border">
									<th colSpan="8" className="text-left">
										<h4 className="weight-semi">Tipo de preguntas</h4>
									</th>
									<th>
										<button
											className="btn-full justify-center align-center btn-mini btn-primary color-white"
											type="button"
											onClick={(e) => {
												this.setState({title: "Añadir pregunta"});
												this.clearData();
												this.openModal("createPregunta");
											}}
											disabled={!this.props.escritura || this.props.cerrado}
										>
											<i className="fas fa-plus font-text mini-i" /> &nbsp; Añadir pregunta
										</button>
									</th>
								</tr>
								<tr>
									<th className="text-left th-options-mini"></th>
									<th className="text-left">Código</th>
									<th className="text-left th-options-huge">Pregunta</th>
									<th className="text-center">Tipo</th>
									<th>Respuestas</th>
									<th>Resultado</th>
									<th>Voto manual</th>
									<th>Editar</th>
									<th>Eliminar</th>
								</tr>

								{preguntas.map((preg) => {
									return (
										<React.Fragment>
											<tr className={this.state.id_encuesta_pregunta_row === preg.id_encuesta_pregunta ? "tr-selected-pregunta row-active" : null}>
												<td title={"check"} className="td-no-desborder">
													<label className="container-check container-check-billing">
														<input
															type="checkbox"
															checked={preg.es_actual}
															className="check-option"
															onClick={() => {
																this.setActual(preg);
															}}
														/>
														<span className="checkmark"></span>
													</label>
												</td>
												<td title={"codigo"} className="td-no-desborder">
													{preg.codigo}
												</td>
												<td title={preg.pregunta} className="td-no-desborder">
													{preg.pregunta}
												</td>
												<td className="text-center">{preg.pregunta_tipo}</td>
												<td>
													<button
														className="btn-full justify-center align-center btn-mini btn-secondary color-white"
														type="button"
														onClick={(event) => {
															this.popRow(preg.id_encuesta_pregunta);
														}}
													>
														<i className="font-small fas fa-bars" />
													</button>
												</td>
												<td>
													<button
														className="btn-full justify-center align-center btn-mini btn-secondary color-white"
														type="button"
														onClick={(event) => {
															this.openResultModal(preg);
														}}
													>
														<i className="font-small fas fa-poll" />
													</button>
												</td>
												<td>
													<button
														className="btn-full justify-center align-center btn-mini btn-secondary color-white"
														type="button"
														onClick={(event) => {
															this.openVotoManual(preg);
														}}
													>
														<i className="fas fa-user-check"></i>
													</button>
												</td>
												<td>
													<button
														className="btn-full justify-center align-center btn-mini btn-secondary color-white"
														type="button"
														onClick={(event) => {
															this.openEditModal(preg);
														}}
													>
														<i className="font-small fas fas fa-pen" />
													</button>
												</td>
												<td>
													<button
														className="btn-full justify-center align-center btn-mini btn-secondary color-white"
														type="button"
														onClick={(event) => {
															this.setState({id_pregunta: preg.id_encuesta_pregunta});
															this.openModalDelete("deleteModal-preguntas");
														}}
														disabled={!this.props.escritura || this.props.cerrado}
													>
														<i className="font-small fas fa-trash-alt" />
													</button>
												</td>
											</tr>
											{this.state.id_pregunta_activa === preg.id_encuesta_pregunta && preg.pregunta_tipo !== "Abierta" ? (
												<tr className="acordeon-row">
													<td className="acordeon" colSpan="9">
														<div className="row full">
															<Respuestas
																getRespuestas={this.getRespuestas.bind(this)}
																id_pregunta={preg.id_encuesta_pregunta}
																respuestas={this.state.respuestas}
																escritura={this.props.escritura}
																cerrado={this.props.cerrado}
															></Respuestas>
														</div>
													</td>
												</tr>
											) : null}
										</React.Fragment>
									);
								})}
								{/*<tr className="row-without-border">
									<th colSpan="9" className="text-center">
										<Paginador pages={Math.ceil(this.state.preguntas.length / 5)} setPage={this.setPage.bind(this)} />
									</th>
								</tr>*/}
							</tbody>
						</table>
					</td>
				</tr>

				<DinamicModal
					idModal={"createPregunta"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.title}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura && !this.props.cerrado}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderPregunta()}
				</DinamicModal>
				<DinamicModal
					idModal={"resultPregunta"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.title}
					success={() => {}}
					showBtnSuccess={false}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeResultados.bind(this)}
				>
					{this.renderResultados()}
				</DinamicModal>
				<DinamicModal
					idModal={"votoManual"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.titlePregunta}
					success={this.saveVotoManual.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.savingVotoManual}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderVotoManual()}
				</DinamicModal>
				<ModalBorrar name="deleteModal-preguntas" id={this.state.id_pregunta} delete={this.handleDelete.bind(this)} />
			</React.Fragment>
		);
	}

	async getRespuestas() {
		let data = {
			IdEncuestaPregunta: this.state.id_pregunta_activa || this.state.id_pregunta,
		};
		const response = await request.get("/encuestas/get/respuestas", data);
		if (response.error || response.empty) {
			this.setState({respuestas: []});
			return false;
		} else {
			let options = response.map((res) => {
				return {value: res.id_encuesta_respuesta, label: res.respuesta, explicacion: res.explicacion};
			});
			this.setState({respuestas: response, options: options});
		}
	}

	async setActual(pregunta) {
		this.props.setActual(pregunta);
	}

	async popRow(id_pregunta) {
		if (id_pregunta === this.state.id_pregunta_activa) {
			await this.setState({id_pregunta_activa: null, respuestas: []});
		} else {
			await this.setState({id_pregunta_activa: id_pregunta, id_encuesta_pregunta_row: id_pregunta});
			await this.getRespuestas();
		}
	}

	async handleDelete() {
		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaPregunta: this.state.id_pregunta,
			Usuario: usuario.info.usuario,
		};

		const response = await request.put("/encuestas/delete/pregunta", data);

		if (!response.error) {
			await this.props.getPreguntas();
			cogoToast.success("Pregunta eliminada.", {
				position: "bottom-right",
			});

			this.closeModalDelete("deleteModal-preguntas");
		} else {
			cogoToast.error("No se pudo eliminar pregunta.", {
				position: "bottom-right",
			});
		}

		this.closeModalDelete("deleteModal-preguntas");
		this.props.getPreguntas();
	}

	async editPregunta() {
		this.setState({loadingSave: true});

		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaPregunta: parseInt(this.state.id_pregunta),
			IdEncuestaPreguntaTipo: parseInt(this.state.id_pregunta_tipo),
			Pregunta: this.state.pregunta,
			Usuario: usuario.info.usuario,
			Codigo: this.state.codigo,
		};

		const response = await request.put("/encuestas/update/pregunta", data);

		if (response.error) {
			let msj = "No se pudo actualizar la pregunta.";

			if (response.Cerrado) msj = "No se puede editar pregunta, la encuesta está cerrada.";

			cogoToast.error(msj, {
				position: "bottom-right",
			});

			this.setState({loadingSave: false});
			return false;
		}

		cogoToast.success("Pregunta editada correctamente.", {
			position: "bottom-right",
		});
		this.closeModal("createPregunta");
		await this.clearData();
		await this.props.getPreguntas();

		this.setState({loadingSave: false});
	}

	async handleSubmit(event) {
		event.preventDefault();

		if (this.state.createAction) {
			await this.createPregunta();
			await this.clearData();
		} else {
			await this.editPregunta();
			this.setState({createAction: true});
		}
	}

	async createPregunta() {
		this.setState({loadingSave: true});

		let usuario = await this.getUserInfo();

		let data = {
			IdEncuesta: parseInt(this.state.id_encuesta),
			IdEncuestaPreguntaTipo: parseInt(this.state.id_pregunta_tipo),
			Pregunta: this.state.pregunta,
			Usuario: usuario.info.usuario,
			Codigo: this.state.codigo,
		};

		const response = await request.post("/encuestas/add/pregunta", data);

		if (response.error) {
			this.setState({loadingSave: false});

			cogoToast.error("No se pudo añadir pregunta.", {
				position: "bottom-right",
			});

			return false;
		}

		cogoToast.success("Pregunta añadida.", {
			position: "bottom-right",
		});

		await this.clearData();
		this.props.getPreguntas();
		this.closeModal("createPregunta");

		this.setState({loadingSave: false});
	}

	async clearData() {
		this.setState({
			id_pregunta_tipo: this.state.preguntas_tipos.length > 0 ? this.state.preguntas_tipos[0].id_encuesta_pregunta_tipo : 0,
			pregunta: "",
			codigo: "",
			resultados: [],
		});
	}

	async closeResultados() {
		this.closeModal.bind(this);
		await this.setState({resultados: []});

		let cont = document.getElementById("resultChart");
		let lineChart = new Chart(cont, {});
		lineChart.clear();
		lineChart.destroy();
	}

	renderPregunta() {
		let {preguntas_tipos} = this.state;

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Código:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								className="input input-modals"
								name=""
								id=""
								maxLength={5}
								value={this.state.codigo}
								onChange={(event) => this.setState({codigo: event.target.value})}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Pregunta:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								className="input input-modals"
								name=""
								id=""
								value={this.state.pregunta}
								onChange={(event) => this.setState({pregunta: event.target.value})}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Tipo de pregunta:</b>
							</p>
						</div>
						<div className="column full">
							<select
								name=""
								id=""
								className="input input-modals"
								value={this.state.id_pregunta_tipo}
								onChange={(event) => this.setState({id_pregunta_tipo: event.target.value})}
								required
							>
								{preguntas_tipos.map((pt) => (
									<option value={pt.id_encuesta_pregunta_tipo}>{pt.pregunta_tipo}</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderResultados() {
		let result = this.state.resultados;
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="justify-start">
						<h3 className="weight-semi">{this.state.pregunta}</h3>
					</div>
					<div className="white-space-16"></div>
					<div className="card-table card-owners column" id="card-owners">
						<div className="table-responsive">
							<table>
								<thead>
									<tr className="text-left">
										<th className="th-options-huge">Respuestas</th>
										<th className="th-options text-center">Resultados</th>
										<th className="th-options text-center">Indiviso</th>
									</tr>
								</thead>
								<tbody>
									{result
										? result.map((res) => {
												return (
													<tr>
														<td>{res.respuesta}</td>
														<td className="text-center">{res.Total_Votos}</td>
														<td className="text-center">
															<NumberFormat value={res.pro_indiviso_vivienda} displayType={"text"} thousandSeparator={true} decimalScale={4} fixedDecimalScale={true} />
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="column full">
						<canvas className="line" id="resultChart" width="50%" height="20%"></canvas>
					</div>
				</div>
			</div>
		);
	}

	renderChart() {
		let result = this.state.resultados;
		let labels = [];
		let data = [];
		result.map((res) => {
			labels.push(res.respuesta);
			data.push(res.Total_Votos);
		});

		let cont = document.getElementById("resultChart");
		let lineChart = new Chart(cont, {
			type: "pie",
			data: {
				labels: labels,
				datasets: [
					{
						data: data,
						borderColor: ["#d8eefeeb", "#ef4565eb", "#3da9fceb", "#5f6c7beb", "#e53170eb", "#ff8906eb"],
						backgroundColor: ["#d8eefeeb", "#ef4565eb", "#3da9fceb", "#5f6c7beb", "#e53170eb", "#ff8906eb"],
						borderWidth: 2,
						pointHitRadius: 3,
						pointBorderWidth: 3,
					},
				],
			},
			options: {
				legend: {
					display: false,
				},
				title: {
					display: true,
					text: this.state.pregunta,
				},
				// scales: {
				//     yAxes: [{
				//         stacked: true
				//     }]
				// }
			},
		});
	}

	renderVotoManual() {
		const customStyles = {
			option: (provided, state) => ({
				...provided,
				border: "0px",
			}),
			control: (provided, state) => ({
				...provided,
				border: "0px",
				width: "auto",
				padding: "2px 10px 2px",
				borderBottom: state.isFocused || state.isSelected ? "2px solid #EA545F" : "2px solid rgba(159, 159, 159, 0.5)",
			}),
		};

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="table-responsive table-asistencia-container">
						<table className="table-asistencia">
							<thead>
								<tr>
									<th className="th-options-huge text-left">Vivienda</th>
									<th className="th-options-huge text-center">Respuesta</th>
									<th className="th-options-huge">Explicación</th>
								</tr>
							</thead>
							<tbody>
								{this.state.loadingVotoManual ? (
									<tr>
										<td className="text-center" colSpan="3">
											<i className="fas fa-spinner fa-spin"></i>.
										</td>
									</tr>
								) : this.state.listaVotoManual.length === 0 ? (
									<tr>
										<td className="text-center" colSpan="3">
											Sin datos que mostrar.
										</td>
									</tr>
								) : (
									this.state.listaVotoManual.map((voto, key) => (
										<tr key={key}>
											<td>{voto.numero_registro}</td>
											<td>
												{this.state.id_encuesta_pregunta_tipo === 3 ? (
													<input
														type="text"
														className="input input-modals"
														// disabled={!(this.state.id_encuesta_pregunta_tipo === 3) && !voto.enableExplicacion}
														value={voto.Respuesta}
														onChange={(e) => {
															let list = this.state.listaVotoManual.map((vt) => {
																if (vt.id_vivienda === voto.id_vivienda) {
																	vt.Respuesta = e.target.value;
																	vt.modificated = true;
																}
																return vt;
															});
															this.setState({listaVotoManual: list});
														}}
													/>
												) : (
													<ReactSelect
														options={this.state.options}
														defaultValue={voto.options}
														styles={customStyles}
														isSearchable={false}
														isMulti={this.state.id_encuesta_pregunta_tipo === 2}
														onChange={(opts, q) => {
															let list = this.state.listaVotoManual.map((vt) => {
																if (vt.id_vivienda === voto.id_vivienda) {
																	let respuesta = opts.length ? opts[0] : opts;
																	vt.enableExplicacion = respuesta ? respuesta.explicacion : false;
																	vt.Respuesta = respuesta ? respuesta.label : "";
																	vt.Explicacion = "";
																	vt.modificated = true;
																	vt.options = opts;
																}
																return vt;
															});
															this.setState({listaVotoManual: list});
														}}
													/>
												)}
											</td>
											<td>
												<input
													type="text"
													className="input input-modals"
													disabled={this.state.id_encuesta_pregunta_tipo === 3 || !voto.enableExplicacion}
													value={voto.Explicacion}
													onChange={(e) => {
														let list = this.state.listaVotoManual.map((vt) => {
															if (vt.id_vivienda === voto.id_vivienda) {
																vt.Explicacion = e.target.value;
																vt.modificated = true;
															}
															return vt;
														});
														this.setState({listaVotoManual: list});
													}}
												/>
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}

	async saveVotoManual(e) {
		e.preventDefault();
		this.setState({savingVotoManual: true});
		let lista = this.state.listaVotoManual.filter((l) => l.modificated);

		// se mandan solo actualizaciones
		if (lista.length == 0) {
			this.setState({savingVotoManual: false});
			return;
		}

		let toSave = [];
		lista.map((l) => {
			if (l.options) {
				if (l.options.length) {
					// selección multiple
					l.options.map((opt) => {
						let object = {
							IdEncuestaPregunta: l.IdEncuestaPregunta,
							IdEncuestaRespuesta: opt.value,
							id_inquilino: l.id_inquilino,
							Respuesta: opt.label,
							id_vivienda: l.id_vivienda,
							Explicacion: l.Explicacion,
						};
						toSave.push(object);
					});
				} else {
					//  una sola opción
					let object = {
						IdEncuestaPregunta: l.IdEncuestaPregunta,
						IdEncuestaRespuesta: l.options.value,
						id_inquilino: l.id_inquilino,
						Respuesta: l.options.label,
						id_vivienda: l.id_vivienda,
						Explicacion: l.Explicacion,
					};
					toSave.push(object);
				}
			} else {
				// respuestas abiertas
				let object = {
					IdEncuestaPregunta: l.IdEncuestaPregunta,
					IdEncuestaRespuesta: l.IdEncuestaRespuesta,
					id_inquilino: l.id_inquilino,
					Respuesta: l.Respuesta,
					id_vivienda: l.id_vivienda,
					Explicacion: l.Explicacion,
				};
				toSave.push(object);
			}
		});

		let id_encuesta = this.state.id_encuesta;
		let data = {
			id_encuesta: id_encuesta,
			data: toSave,
		};
		let response = await request.post("/encuestas/guardar/voto/manual", data);

		if (!response.error) {
			cogoToast.success("Respuestas guardadas con éxito.", {
				position: "bottom-right",
			});
			this.closeModal("votoManual");
		} else {
			cogoToast.error("No se pudieron guardar las respuestas.", {
				position: "bottom-right",
			});
		}
		this.setState({savingVotoManual: false});
	}

	setPage(page) {
		this.setState({page_preguntas: page});
	}
}

export default PreguntasTable;
