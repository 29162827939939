import React, { useEffect } from 'react';

const DepartamentoForm = ({departamento, departamentoInput, setDepartamento}) => {
	useEffect(() => {
		if (departamento) {
			const input = document.getElementById('departamento_title');
			if (input) {
				input.value = departamento.departamento
			}
		}
	}, [departamento]);

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-normal">
						<p><b>Departamento:</b></p>
					</div>
					<div className="column full">
						<input
							id				=	"departamento_title"
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"departamento_title" 
							autoComplete 	=	'off'
							value			=	{departamentoInput}
							onChange		=	{(e) => setDepartamento(e.target.value)}
							required
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DepartamentoForm;
