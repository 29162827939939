import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import moment from 'moment-timezone';
import Resizer from 'react-image-file-resizer';
import cogoToast from 'cogo-toast';
import { Consumer } from "../../context";

const request = new Request();

class FamiliaresTableEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			parentesco: '',
			nombre: '',
			viv: '',
			tipo: '',
			acceso: '',
			telefono: '',
			id_frecuente_invitacion: 0,
			idfrac: localStorage.getItem('frac') || 0,
			message: '',
			fecha: moment().format('YYYY-MM-DD'),
			fecha2: moment().format('YYYY-MM-DD'),
			eine1: null,
			eine2: null,
			erotate1: 0,
			erotate2: 0,
			activated: false,
			ine1base64: false,
			ine2base64: false
		}

	}
	async clearState() {
		await this.setState({ message: '' });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.invitado !== prevProps.invitado) {
			this.setState({
				nombre: this.props.invitado.invitado,
				viv: this.props.invitado.numero_registro,
				tipo: this.props.invitado.tipo,
				acceso: this.props.invitado.acceso,
				telefono: this.props.invitado.telefono,
				id_frecuente_invitacion: this.props.invitado.id_frecuente_invitacion,
				horarios: this.props.invitado.horarios,
				eine1: this.props.invitado.ine1,
				eine2: this.props.invitado.ine2,
				rotate1: 0,
				rotate2: 0,
				message: '',
				activated: this.props.invitado.activated || false,
				parentesco: this.props.invitado.familiar_parentesco || '',
				code: this.props.invitado.code
			})

			if (this.props.invitado.ine1) {
				if (this.props.invitado.ine1.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.invitado.ine1,
						idFI: this.props.invitado.id_frecuente_invitacion,
						type: 1
					}
					this.base64ToUrl(data);
				}
			}

			if (this.props.invitado.ine2) {
				if (this.props.invitado.ine2.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.invitado.ine2,
						idFI: this.props.invitado.id_frecuente_invitacion,
						type: 2
					}
					this.base64ToUrl(data);
				}
			}

			//console.log(this.props.invitado);
		}
	}

	async base64ToUrl(info) {
		const data = {
			type: info.type,
			img: info.ine,
			idfrac: localStorage.getItem('frac') || 0,
			id_frecuente_invitacion: info.idFI,
			module: "frecuentes"
		}

		const res = await request.post("/invitadosFrecuentes/invitados/base64ToUrl", data);
		if (res) {
			//console.log(res);
			if (!res.error) {
				//return res.url;
				if (info.type === 1) {
					this.setState({ eine1: res.url });
				}

				if (info.type === 2) {
					this.setState({ eine2: res.url });
				}
			}
		}
	}

	closeModal() {
		this.setState({
			nombre: this.props.invitado.invitado,
			viv: this.props.invitado.numero_registro,
			tipo: this.props.invitado.tipo,
			acceso: this.props.invitado.acceso,
			telefono: this.props.invitado.telefono,
			id_frecuente_invitacion: this.props.invitado.id_frecuente_invitacion,
			horarios: this.props.invitado.horarios,
			eine1: this.props.invitado.ine1,
			eine2: this.props.invitado.ine2,
			rotate1: 0,
			rotate2: 0,
			message: '',
			activated: this.props.invitado.activated,
			parentesco: this.props.invitado.familiar_parentesco || '',
			code: this.props.invitado.code
		})
	}

	render() {
		return (
			<div>
				<DinamicModal
					idModal={'editFamiliar'}
					sizeModalContainer={'big'}
					title={'Editar familiar'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
					// closeModal				=	{this.clearState.bind(this)}
					closeModal={this.closeModal.bind(this)}
				>
					{this.rendereditFamiliar()}
				</DinamicModal>
			</div>
		);
	}

	rendereditFamiliar() {
		return (
			<div className="full row align-start">
				<div className="column full">
					<div className="white-space-8" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Nombre:</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="nombre"
								value={this.state.nombre}
								enable="true"
								onChange={event => this.setState({ nombre: event.target.value })}
								className="input input-modals"
								placeholder="Nombre"
								required
								maxLength="64"
								id="nombre"
							/>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="phone" value={this.props.getNumberFormat(this.state.telefono)}
								className="input input-modals" placeholder='Número Teléfono' required maxLength="24" disabled />
						</div>

					</div>
					{/*  */}
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start">
								<b>Acceso:</b>
							</p>
						</div>
						<div className="column full">
							<select name="acceso" id="acceso" className="input input-modals" value={this.state.acceso} enable="true"
								onChange={event => this.setState({ acceso: event.target.value })}>
								<option value="PENDIENTE">PENDIENTE</option>
								<option value="CONFIRMADO">CONFIRMADO</option>
							</select>
						</div>
					</div>

					{/*  */}
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Parentesco:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="tipo"
								value={this.state.parentesco}
								onChange={event => this.setState({ parentesco: event.target.value })}
								className="input input-modals"
								placeholder='Mamá, Hermano, Primo...'
								required={false}
								maxLength="24"
								disabled={false}
							/>
						</div>

					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start">
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<input type="text" name="vivienda" value={this.state.viv}
									onChange={event => this.setState({ viv: event.target.value })} className="input input-modals" placeholder='Número Vivienda' required maxLength="24" disabled />
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Código de verificación:	</b></p>
						</div>
						<div className="column full text-left" style={{fontSize: '0.9rem', fontFamily: '"Mukta", sans-serif'}}>
						    {this.state.code}
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start">
								<b>Dispositivo activado:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								{this.state.activated ?
									<label className="container-check">
										<input
											type="checkbox"
											name="activated"
											checked={this.state.activated ? true : false}
											onChange={event => this.setState({ activated: event.target.checked })}
											className="input input-modals"
										/>
										<span className="checkmark"></span>
									</label> :
									<label className="container-check">
										<input
											type="checkbox"
											name="activated"
											checked={this.state.activated ? true : false}
											className="input input-modals input-disabled"
											disabled
										/>
										<span className="checkmark-disabled"></span>
									</label>}
							</div>
						</div>
					</div>

				</div>

				<div className="column auto images-ine align-end">
					<div className="responsive-img  column">
						<div className="img-column align-center img-width-small">
							{this.state.eine1 &&
								<button type="button" onClick={this.rotateImg2.bind(this, 1)} >
									<i className="fas fa-redo-alt"></i>
								</button>
							}
							<div className="container-img">
								<img src={this.state.eine1 ? this.state.eine1 : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />

								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="eine1" name="AreaImage" accept="image/*" onChange={this.changeImg2.bind(this, 1)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir parte frontal de la INE</p>
									</label>
								</div>
							</div>
						</div>
						<div className="img-column align-center img-width-small">
							{this.state.eine2 &&
								<button type="button" onClick={this.rotateImg2.bind(this, 2)} >
									<i className="fas fa-redo-alt"></i>
								</button>
							}
							<div className="container-img">
								<img
									src={this.state.eine2 ? this.state.eine2 : require('../../img/empty_image.png')}
									className="img-duo-preview"
									id="epreviewArea"
								/>


								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="eine2" name="AreaImage" accept="image/*" onChange={this.changeImg2.bind(this, 2)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir parte trasera de la INE</p>
									</label>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		);
	};

	async rotateImg2(id) {
		//console.log(id)
		if (this.state.erotate1 == 360) {
			await this.setState({ erotate1: 0 });
		}
		if (this.state.erotate2 == 360) {
			await this.setState({ erotate2: 0 });
		}

		if (id == 1) {
			await this.setState({ erotate1: (this.state.erotate1 + 90) })
		} else {
			await this.setState({ erotate2: (this.state.erotate2 + 90) })
		}
		await this.changeImg2(id);
	};

	async changeImg2(id) {
		let rotate = 0;
		if (id == 1) {
			rotate = this.state.erotate1;
		} else {
			rotate = this.state.erotate2;
		}

		let file = document.getElementById(`eine${id}`);
		if (file) {
			if (file.files[0]) {

				Resizer.imageFileResizer(
					file.files[0],
					337,//337
					180,//180
					'JPEG',
					100,
					rotate,//270
					async uri => {
						if (id == 1) {

							await this.setState({ eine1: uri });
						} else {
							await this.setState({ eine2: uri });
						}

					},
					'base64'
				);
			};
		};
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });

			if (this.state.acceso === 'CONFIRMADO') {
				if (!this.state.eine1 &&  this.state.eine2 || 
					 this.state.eine1 && !this.state.eine2 || 
				    !this.state.eine1 && !this.state.eine2) {
				    cogoToast.warn('Debes elegir las dos imágenes', {
					   position: 'bottom-right',
				    });
				   this.setState({ loadingSave: false });
				   return;
			    };
			}

			const user = this.props.context.user.id.usuario;

			const data = {
				acceso: this.state.acceso,
				id_frecuente_invitacion: this.state.id_frecuente_invitacion,
				idfrac: this.state.idfrac,
				nombre: this.state.nombre,
				horarios: this.state.horarios,
				fecha: this.state.fecha,
				fecha2: this.state.fecha2,
				ine1: this.state.eine1,
				ine2: this.state.eine2,
				activated: this.state.activated,
				usuario: user,
				parentesco: this.state.parentesco || null
			};

			const response = await request.post("/invitadosFrecuentes/invitados/update", data);

			if (response && !response.error) {
				if (response.updated && !response.empty) {
					this.props.reload();
					cogoToast.success('Datos de invitado actualizados.', {
						position: 'bottom-right',
					});
				} else {
					this.setState({
						updated: response.updated,
						acceso: response.updated.acceso,
						idViv: response.updated.numero_registro,
						nombre: response.updated.nombre,
						tipo: response.updated.tipo.div,
						//message: 	"NO SE PUDO ACTUALIZAR INVITADO"
					});
					cogoToast.error('No se pudieron actualizar datos de invitado.', {
						position: 'bottom-right',
					});
				};

			} else {
				this.setState({
					error: true,
					//message: 	"NO SE PUDO ACTUALIZAR INVITADO"
				});
				cogoToast.error('No se pudieron actualizar datos de invitado.', {
					position: 'bottom-right',
				});
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};
}
export default Consumer(FamiliaresTableEdit);