import React, { Component } from 'react';
import { AsYouType } from 'libphonenumber-js';
import cogoToast from 'cogo-toast';
import * as htmlToImage from 'html-to-image';
import ProveedoresTableEdit from './proveedoresTableEdit';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import { Consumer } from "../../context";

import Request from "../../core/httpClient";
const request = new Request();

class ProvedoresTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invitado: {}
        };
    };

    getNumberFormat(cell_phone, oculto) {

        try {

            if (oculto) {
                return `******${cell_phone.substring(cell_phone.length - 4)}`;
            };

            if (cell_phone.includes('+')) {
                return new AsYouType('MXN').input(cell_phone);
            };

            return new AsYouType('MXN').input('+' + cell_phone);

        } catch (error) {
            return null;
        };
    };

    async openModal(idModal, invitado) {


        this.setState({
            invitado,
            id_invitado_row: invitado.id_frecuente_invitacion
        });

        const modal = document.getElementById(idModal);

        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };

        let nombre = document.getElementById('nombre');

        if (nombre) {
            nombre.focus();
        };

    };

    openModalDelete(inv) {

        this.setState({
            id_frecuente_invitacion: inv.id_frecuente_invitacion,
            id_invitado_row: inv.id_frecuente_invitacion
        });

        let modal = document.getElementById("deleteModal");

        if (modal) {
            modal.classList.remove('hide-modal');
            modal.classList.add('show-modal');
        }
    };

    async handleDelete(id, close) {

        let user = this.props.context.user.id.usuario;

        let data = {
            id_frecuente_invitacion: id,
            usuario: user
        };

        const response = await request.post('/invitadosFrecuentes/invitados/borrar', data);

        if (response) {

            if (response.deleted) {

                cogoToast.success('Invitado eliminado.', {
                    position: 'bottom-right',
                });

            } else {

                cogoToast.error('No se pudo eliminar invitado.', {
                    position: 'bottom-right',
                });
            };

        } else {
            cogoToast.error('No se pudo eliminar invitado.', {
                position: 'bottom-right',
            });
        };

        this.props.reload();

        if (close) {
            close();
        };
    };

    saveimg(invitado) {

        var node = document.getElementById('qr-print');

        htmlToImage.toPng(node)

            .then(function (dataUrl) {

                var a = document.createElement("a"); //Create <a>
                a.href = dataUrl //Image Base64 Goes here
                a.download = `QR_proveedor_${invitado}.png`; //File name Here
                a.click();
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };

    render() {

        let invitados = []

        if (this.props.invitados) {
            invitados = this.props.invitados
        }

        return (
            <div>
                <div className="table-responsiv column">
                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">Invitado</th>
                                <th className="">Teléfono</th>
                                <th className="">Vivienda</th>
                                <th className="text-left">Acceso</th>
                                <th className="text-left">Tipo</th>
                                <th className="text-center th-options-small">QR</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invitados.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((invitado, key) => (
                                <tr className={this.state.id_invitado_row === invitado.id_frecuente_invitacion && 'row-active'} key={key}>
                                    <td>{invitado.invitado}</td>
                                    <td>{this.getNumberFormat(invitado.telefono, true)}</td>
                                    <td>{invitado.numero_registro}</td>
                                    <td>{invitado.acceso}</td>
                                    <td>Proveedor</td>
                                    <td>
                                        <button
                                            className="btn-full justify-center btn-mini btn-secondary color-white"
                                            onClick={this.openModal.bind(this, "qrProveedor", invitado)}
                                        >
                                            <i className={`font-small fas fa-qrcode ${!invitado.activated && 'color-red'}`}></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            className="btn-full justify-center btn-mini btn-secondary color-white"
                                            type="button justify-center"
                                            onClick={this.openModal.bind(this, "editProveedor", invitado)}>
                                            <i className="font-small fas fa-pen" />
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                            type='button' onClick={this.openModalDelete.bind(this, invitado)}
                                            style={{ padding: '5px' }}
                                            disabled={!this.props.escritura}
                                        >
                                            <i className="font-text fas fa-trash-alt " />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <ProveedoresTableEdit
                    reload={this.props.reload.bind(this)}
                    invitado={this.state.invitado}
                    escritura={this.props.escritura}
                    getNumberFormat={this.getNumberFormat.bind(this)}
                />
                <ModalBorrar
                    id={this.state.id_frecuente_invitacion}
                    delete={this.handleDelete.bind(this)}
                />
                <DinamicModal
                    idModal={'qrProveedor'}
                    sizeModalContainer={'normal'}
                    title={'Proveedor'}
                >
                    <div className="column justify-center align-center full">
                        <div
                            id="qr-print" style={{ backgroundColor: 'white', width: '400px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}
                        >
                            <p style={{ width: '400px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Nombre: {this.state.invitado.invitado}</p>
                            <div className="white-space-8"></div>
                            <p style={{ width: '400px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Vivienda: {this.state.invitado.numero_registro}</p>
                            <div className="white-space-8"></div>
                            <p style={{ width: '400px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Empresa: {this.state.invitado.empresa}</p>
                            <div className="white-space-8"></div>
                            <img src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&color=000000&bgcolor=F5F4F4&data=id_vivienda:${this.state.invitado.id_vivienda}/id_frecuente_invitacion:${this.state.invitado.id_frecuente_invitacion}`}></img>
                            <div className="white-space-16"></div>
                        </div>
                        <button type="button" className="btn btn-primary btn-small color-white"
                            onClick={this.saveimg.bind(this, this.state.invitado.invitado)}>
                            Descargar
                        </button>
                    </div>
                </DinamicModal>
            </div>
        );
    };
};

export default Consumer(ProvedoresTable);