// React and Third-party
import React, { useState, Fragment, useEffect, useCallback } from 'react';
import cogoToast from 'cogo-toast';
import NumberFormat from 'react-number-format';

// Components
import SuperCore from '../../../components/core/SuperCore';
import TableHeadSort from './TableHeadSort';
import AbonarModal from './AbonarModal';
import AbonarForm from './AbonarForm';
import PaginadorParent from '../../../components/paginador/PaginadorParent';

// Helpers
import sortableData from '../../../helpers/sortableDataTable';

// Requests
import { setAbono, getMetodosPago } from '../requests';

const abonarModalInfoInit = {
    id_inquilino: 0,
    id_vivienda: 0,
    inquilino: "",
    numero_registro: "",
    subfraccionamiento: ""
}

const modal = new SuperCore();

const abonarModalId = "TarjetaAbonar";

export default function BalancesTable(props) {
    
    const { balances, loadingBalances, loadingInquilinosMovimientos, desdeFecha, hastaFecha, onMovimientosSearch, onAbonoCompleted, HistorialComponent } = props;

    const [metodosPago, setMetodosPago] = useState([]);

    const [activeInquilinoHistorial, setActiveInquilinoHistorial] = useState(0);
    const [abonarModalInfo, setAbonarModalInfo] = useState(abonarModalInfoInit);

    const [loadingAbono, setLoadingAbono] = useState(false);
    const [loadingMetodosPago, setLoadingMetodosPago] = useState(false);

    const [prePagedData, setPrePagedData] = useState(balances);
    const [pagedData, setPagedData] = useState(balances);

    const [keySort, setKeySort] = useState({ name: "", direction: "" });

    const sortByKey = (newOrder) => {

        const { name, direction } = newOrder;

        let sd = new sortableData();
		const { items } = sd.sortBy(name, prePagedData, direction);

        setKeySort(newOrder)
        setPrePagedData([...items]);
    }

    const loadMetodosPago = useCallback(async () => {

        setLoadingMetodosPago(true);

        const response = await getMetodosPago();

        if(response.error) {
            cogoToast.error(response.error, {
                position: "bottom-right",
                hideAfter: 5,
            });
        }

        if(response.metodosPago) {
            //Quitar saldo a favor
            const filteredMetodosPago = response.metodosPago.filter(metodo => metodo.Id_FormaPago !== -1);
            setMetodosPago(filteredMetodosPago);
        }

        setLoadingMetodosPago(false);

    }, [setLoadingMetodosPago, setMetodosPago]);

    useEffect(() => {
      
        setPrePagedData(balances);

    }, [balances]);

    useEffect(() => {
      
        loadMetodosPago();

    }, [loadMetodosPago]);

    const openInquilinoHistorial = (id_inquilino) => {

        const idToSet = activeInquilinoHistorial !== id_inquilino ? id_inquilino : 0;

        setActiveInquilinoHistorial(idToSet);

        if(activeInquilinoHistorial === id_inquilino) return;

        onMovimientosSearch(id_inquilino);
    }

    const abonarHandler = (inquilino) => {

        const abonoInfo = {
            inquilino: inquilino.inquilino,
            id_inquilino: inquilino.id_inquilino,
            id_vivienda: inquilino.id_vivienda,
            numero_registro: inquilino.numero_registro,
            subfraccionamiento: inquilino.subfraccionamiento,
        }

        setAbonarModalInfo(abonoInfo);
    }

    const loadAbonar = useCallback(async (formInfo) => {

        setLoadingAbono(true);

        const response = await setAbono(formInfo);

        if (response.error) {
            cogoToast.error(response.error, {
                position: "bottom-right",
                hideAfter: 5,
            });
        }

        setLoadingAbono(false);
        setAbonarModalInfo(abonarModalInfoInit);

        modal.superCloseModal(abonarModalId);

        if (response.created) {

            cogoToast.success("Movimiento Realizado", {
                position: "bottom-right",
                hideAfter: 5,
            });

            setActiveInquilinoHistorial(0);
            onAbonoCompleted();
        }

    },[onAbonoCompleted]);

    const AbonarFormCallback = useCallback((props) => (
        <AbonarForm
            {...props}
            {...abonarModalInfo}
            ref={props.formRef}
            metodosPago={metodosPago}
            onSubmit={loadAbonar}
        />
    ), [abonarModalInfo, metodosPago, loadAbonar]);

    const LoadingContent = () => (
        <tr>
            <td className="text-center" colSpan={8} style={{ height: 200 }}>
                <i className="fas fa-spinner fa-spin"></i>
            </td>
        </tr>
    )

    const EmptyContent = () => (
        <tr>
            <td className="text-center" colSpan={8} style={{ height: 200 }}>
                <h4>Sin Balances</h4>
            </td>
        </tr>
    )

    return (
        <div className="card-table card-owners column" id="card-owners">
            <table className='full table-equal' >
                <colgroup>
                    <col width="20%"></col>
                    <col width="20%"></col>
                    <col width="20%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                    <col width="10%"></col>
                </colgroup>
                <thead /*className='sticky-th'*/>
                    <tr style={{ position: "sticky", top: 226/*293*/ }}>
                        <th className="text-left">
                            <TableHeadSort
                                title="Residente"
                                orderName="inquilino"
                                orderKey={keySort}
                                onOrderKeyChange={(newOrder) => sortByKey(newOrder)}
                            />
                        </th>
                        <th className="text-left">
                            <TableHeadSort
                                title="Subfraccionamiento"
                                orderName="subfraccionamiento"
                                orderKey={keySort}
                                onOrderKeyChange={(newOrder) => sortByKey(newOrder)}
                            />
                        </th>
                        <th className="text-left">
                            <TableHeadSort 
                                title="Vivienda"
                                orderName="numero_registro"
                                orderKey={keySort}
                                onOrderKeyChange={(newOrder) => sortByKey(newOrder)}
                            />
                        </th>
                        <th>
                            <TableHeadSort
                                title="Balance"
                                orderName="saldo"
                                orderKey={keySort}
                                onOrderKeyChange={(newOrder) => sortByKey(newOrder)}
                            />
                        </th>
                        <th>Historial</th>
                        <th>Abonar</th>
                    </tr>
                </thead>
                <tbody className='extra-padding'>
                    {loadingBalances ?
                        <LoadingContent />
                        :
                        pagedData.length === 0 ?
                            <EmptyContent />
                            :
                            <TableContent
                                content={pagedData}
                                activeInquilinoHistorial={activeInquilinoHistorial}
                                loadingMovimientos={loadingInquilinosMovimientos}
                                abonarHandler={abonarHandler}
                                openInquilinoHistorial={openInquilinoHistorial}
                                desdeFecha={desdeFecha}
                                hastaFecha={hastaFecha}
                                HistorialComponent={HistorialComponent}
                            />
                    }
                </tbody>
            </table>
            {!loadingBalances &&
                <PaginadorParent
                    data={prePagedData}
                    onPagedDataChange={({ pagedData }) => setPagedData(pagedData)}
                />
            }
            <AbonarModal
                id={abonarModalId}
                id_inquilino={abonarModalInfo.id_inquilino}
                loadingAbono={loadingAbono}
                onCloseModal={() => setAbonarModalInfo(abonarModalInfoInit)}
                AbonarFormComponent={AbonarFormCallback}
            />
        </div>
    )
}

function TableContent(props) { 
        
    const { content, activeInquilinoHistorial, loadingMovimientos, abonarHandler, openInquilinoHistorial, desdeFecha, hastaFecha, HistorialComponent } = props;

    return content.map((inquilino, index) => (

        <Fragment key={inquilino.id_tarjeta}>
            <tr
                className={(activeInquilinoHistorial === inquilino.id_inquilino ? "row-active" : undefined)}
                style={activeInquilinoHistorial === inquilino.id_inquilino ? { position: "sticky", top: 226, backgroundColor: "#E7E7E7", zIndex: 2 } : undefined}
            >
                <td>{inquilino.propietario || inquilino.inquilino}</td>
                <td>{inquilino.subfraccionamiento}</td>
                <td>{inquilino.numero_registro}</td>
                <td>
                    <NumberFormat
                        value={inquilino.saldo}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </td>
                <td>
                    <button
                        className="btn-full btn-mini btn-secondary color-white"
                        disabled={loadingMovimientos}
                        type="button"
                        onClick={() => openInquilinoHistorial(inquilino.id_inquilino)}
                    >
                        {loadingMovimientos && activeInquilinoHistorial === inquilino.id_inquilino ?
                            <i className="fas fa-spinner fa-spin"></i>
                            :
                            <i className={"font-small fas fa-info-circle " +
                                (activeInquilinoHistorial === inquilino.id_inquilino
                                    ? 'color-red' : undefined)}
                            />
                        }
                    </button>
                </td>
                <td className='justify-center'>
                    <button
                        className="btn btn-primary btn-red-table color-white"
                        onClick={() => abonarHandler(inquilino)}
                    >
                        Abonar
                    </button>
                </td>
            </tr>
            {!loadingMovimientos 
            && activeInquilinoHistorial !== 0 
            && activeInquilinoHistorial === inquilino.id_inquilino &&               
                <tr
                    key={inquilino.id_inquilino + "mov"}
                    className="acordeon-row"
                    style={{ backgroundColor: "#FFF" }}
                >
                    <td className="acordeon" colSpan="9">
                        <HistorialComponent
                            inquilino={inquilino.inquilino}
                            dateRange={{
                                desde: desdeFecha,
                                hasta: hastaFecha
                            }}
                        />
                    </td>
                </tr>
            }
        </Fragment>
    ))
}
