import React, {Component} from 'react';

class SuperModalCore extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }
    
    /**
     * 
     * @param {Es el id del Modal a abrir} id 
     */
    async openModal(id){

        let modal = document.getElementById(id);
        if(modal){
            modal.classList.remove('hide-super-modal');
            modal.classList.add('show-super-modal');
        }
        
    }

    /**
     * 
     * @param {Es el id del Modal a cerrar} id 
     */
    closeModal(id){
        let modal =  document.getElementById(id);
        if(modal){
            modal.classList.remove('show-super-modal');
            modal.classList.add('hide-super-modal');
        }
        
    }
    

    getMenu(id){
        let index = id.split('-')[2];
        // //console.log(`Se cambio a menú: ${index}`);
        return {
            id,
            index: parseInt(index)
        }
    }
    /**
     * 
     * @param {Navs en los modales aray} nav 
     */
    prepareNavsPantallas(nav){
        let hayNav = true;
        if(nav === null ){
            //console.log("Es null");
            hayNav = false;
        }
       
        if(hayNav && !Array.isArray(nav)){
            //console.log("No es array");
            hayNav = false;
        }

        if(hayNav && nav.length < 1){
            //console.log("No tiene menus");
            hayNav = false;
        }

        if(!hayNav){
            
            return null;
        }
        
        return nav;
    }

    isFalseOrNull(val){
        // //console.log(val);
        if(val === null || val === false || (typeof val) === "undefined"){
            
            return true;
        }
        
        return false;
    }

    nextMenu(index, menus){
        let menusArr = Array.from(menus);
        let fin = false;
        
            index++;
            if(index > (menus.length -1))
            index = menus.length -1;

            if(index === (menus.length -1))
            {
                fin = true;
                //console.log("fin");
            }


            return {index, fin};
    }

    backMenu(index, menus){
        //console.log(index);
        let menusArr = Array.from(menus)
            index--;
            //console.log(index);
            if(index < 0)
            index = 0;
            return index;
    }

    esFinMenu(index, menus){
        let menusArr = Array.from(menus);
        let fin = false;
        //console.log(index);
        //console.log(menus.length -1);
        if(index === (menus.length -1))
            fin = true;
        
        return fin;
        
    }

    esInicioMenu(index){
        if(index === 0) return true;

        return false;
    }

    async oldOpenModal(idModal) {
        
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
    
    async oldCloseModal(idModal){
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }
    async oldOpenModalDelete() {
		let modal = document.getElementById('deleteModal');
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}
   
    
    async oldCloseModalDelete() {
		this.setState({loading: false});
		let modal = document.getElementById('deleteModal') 
		//console.log(modal);
		if(modal) {
			modal.classList.remove('show-modal');
			modal.classList.add('hide-modal');
			//console.log("cerrado");
		}
	}
    
}

export default SuperModalCore;