import React from "react";
import Core from "../core/SuperCore";

import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import ReactExport from "react-export-excel";
registerLocale("es", es);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Exportar extends Core {
	constructor(props) {
		super(props);
		this.state = {
			startDate: this.props.startDate,
			dataSet: this.props.dataSet,
			dataExport: this.props.dataExport,
			frac: this.props.frac,
		};
	}
	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.frac !== this.props.frac) {
			await this.setState({frac: this.props.frac});
		}
		if (prevProps.startDate !== this.props.startDate) {
			await this.setState({startDate: this.props.startDate});
		}

		if (prevProps.dataExport !== this.props.dataExport) {
			await this.setState({dataExport: this.props.dataExport});
		}
		if (this.props.dataSet !== prevProps.dataSet) {
			await this.setState({dataSet: this.props.dataSet});
		}
	}

	handleChange = (date) => {
		this.props.updateData({startDate: date});
		this.setState({startDate: date});
	};

	render() {
		let dataExport = [];

		if (Array.isArray(this.props.dataSet)) {
			if (this.props.dataSet.length > 0) {
				let columns = Object.keys(this.props.dataSet[0]);
				const columnsUpper = columns.map((column) =>
					column.toUpperCase().replace("_", " ")
				);
				const data = this.props.dataSet.map((row) => {
					return columns.map((column) => row[column]);
				});
				if (this.props.dataSet) {
					const dataSet = [
						{
							columns: columnsUpper,
							data,
						},
					];
					dataExport = dataSet;
				}
			}
		}

		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-24"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-big">
							<p>
								<b>Mes de facturación: </b>
							</p>
						</div>

						<div className="column full">
							<DatePicker
								className="input input-modals text-center"
								selected={this.state.startDate}
								onChange={this.handleChange}
								dateFormat="MM/yyyy"
								locale="es"
								showMonthYearPicker
							/>
						</div>
					</div>
					<div className="row full ">
						<div className="invisible">
							{dataExport === 0 ? (

										<button
											type="button"
											className="btn btn-disable btn-small color-white"
											disabled
										>
											<i className="far fa-file-excel font-text" />
											&nbsp; Excel
										</button>
							
							) : (
								<ExcelFile
									element={<button id="btn-export">Descargar archivo</button>}
									filename={`${
										this.state.frac
									} Cargos exportados ${moment().format("DD/MM/YYYY")}`}
								>
									<ExcelSheet
										dataSet={dataExport || []}
										name={`${
											this.state.frac
										} Cargos exportados ${moment().format("DD/MM/YYYY")}`}
									/>
								</ExcelFile>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Exportar;
