/**
 *  guests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Invitados
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Invitations from '../components/guests/guests';

class Guests extends Component {

    render() {
        return (
            <div className="guests column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - Invitados</title>
                </Helmet>
                <Header />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-16" />
                        <div className="navigation align-center">
                            <div className="justify-start">
                                <h3 className="weight-semi">Invitados</h3>
                            </div>
                            <div className="justify-end">
                                <p>
                                    <span className="weight-semi">{process.env.REACT_APP_NAME}</span> > Invitados
                                </p>
                            </div>
                        </div>
                        <div className="white-space-16" />
                        <Invitations />
                        <div className="white-space-16" />
                    </div>
                </div>
            </div>
        );
    }

}

export default Consumer(Guests);