//React and third-parties
import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";

// Components
import { selectDoc, getAllDocsSelected } from "./timbradoDocsSelection";

// HTTP
import Request from '../../core/httpClient';

const request = new Request();

const docSelectionKey = "Id_DocCobro";

const Timbrado = (props) => {

	const { docsTimbrar, selectedDocs, textFilter, loadingTimbrado, loadingSubmition,
		setTextFilter, setSelectedDocs, onStartSubmitting, onFinishedSubmitting } = props;

	const submitTimbrado = async (event) => {

		event.preventDefault();

		const setTimbrado = async () => {

			if (selectedDocs.length <= 0) {
				return { warning: "Debes seleccionar al menos un documento de cobro." };
			}

			let id_doc_timbrar = "";

			selectedDocs.forEach((id) => {
				id_doc_timbrar += id + ",";
			});
			id_doc_timbrar = id_doc_timbrar.substring(0, id_doc_timbrar.length - 1);

			// Sp_sat_createdoctimbrado
			const res = await request.post("/documentos/create/documents/timbrado", {
				ids: id_doc_timbrar,
			});

			if (res.error) {
				return { error: "No se pudieron facturar los documentos." };
			}

			if (res.created) {
				return { successful: "Documentos facturados de manera correcta." };
			}
		};

		onStartSubmitting();

		const response = await setTimbrado();

		onFinishedSubmitting(response);
	}

	const filterDocumentos = () => {

		if (!docsTimbrar) return [];

		if (textFilter === "") return docsTimbrar;

		const filterLowerCased = textFilter.toLowerCase();

		return docsTimbrar.filter(doc =>
			doc.NumFolio.toLowerCase().includes(filterLowerCased)
			|| doc.numero_registro.toLowerCase().includes(filterLowerCased)
			|| doc.RazonSocial.toLowerCase().includes(filterLowerCased)
		);
	};

	const selectNewTimbrado = (docs, allDocsSelected, id_doc = 0) => {

		const newDocsSelected = selectDoc(id_doc, selectedDocs, allDocsSelected, docs, docSelectionKey);
		setSelectedDocs(newDocsSelected);
	};

	const filteredDocumentos = filterDocumentos();
	const docsActivos = filteredDocumentos.filter(doc => doc.Estado_Cobro === 1);
	const allDocsSelected = textFilter !== "" ?
		getAllDocsSelected(selectedDocs, docsActivos, docSelectionKey)
		: !loadingTimbrado ? selectedDocs.length === docsActivos.length && docsActivos.length > 0
			: false;

	return (
		<div className="container" style={{ width: "95%", height: "100%" }}>
			<div className="column full">
				<div className="container container-control">
					<div className="filts column" style={{ paddingBlock: 8 }}>
						<div className="row align-center">
							<div className="search-div">
								<div className="input-clean">
									<input
										className="input input-filter"
										type="text"
										name="textFilter"
										id="filterData"
										placeholder="Buscar"
										value={textFilter}
										onChange={(event) => setTextFilter(event.target.value)}
									/>
									<i className="fas fa-search font-small" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="table-history table-responsive-sat" id="card-owners" style={{ flexGrow: 1 }}>
					<table className="full ">
						<thead>
							<tr>
								<th className="td-transparent text-center th-options-mini" style={{ minWidth: 50, paddingBlock: 4 }}>
									<label className="container-check" style={{ paddingLeft: 0 }}>
										<input
											type="checkbox"
											checked={allDocsSelected}
											name="viviendas-check"
											onChange={() => selectNewTimbrado(docsActivos, allDocsSelected)}
										/>
										<span className="checkmark"></span>
									</label>
								</th>
								<th className="th-sticky text-left">N° Doc.</th>
								<th className="th-sticky text-left" style={{ width: "120px" }}>Fecha</th>
								<th className="th-sticky text-left">Vivienda</th>
								<th className="th-sticky text-left" style={{ width: "320px" }}>Receptor</th>
								<th className="th-sticky text-left">Cobro</th>
								<th className="th-sticky text-left">Total</th>
							</tr>
						</thead>
						<tbody>
							{loadingTimbrado ? (
								<tr>
									<td colSpan="7" className="td-transparent text-center">
										<div className="white-space-16"></div>
										<i className="fas fa-spinner fa-spin"></i>
										<div className="white-space-16"></div>
									</td>
								</tr>
							) : filteredDocumentos.length == 0 ? (
								<tr>
									<td colSpan="7" className="td-transparent text-center">
										Sin datos que mostrar.
									</td>
								</tr>
							) : (
								filteredDocumentos.map((doc, key) => {

									const activo = doc.Estado_Cobro === 1;

									return (
										<tr key={doc.Id_DocCobro} style={{ padding: 8 }}>
											<td className="td-transparent text-center no-border" style={{ paddingBlock: 2, opacity: activo ? 1 : 0.3 }}>
												<label className="container-check" style={{ paddingLeft: 0 }}>
													<input
														disabled={!activo}
														type="checkbox"
														checked={selectedDocs.includes(doc.Id_DocCobro)}
														name="viviendas-check"
														onChange={() => selectNewTimbrado(docsActivos, allDocsSelected, doc.Id_DocCobro)}
													/>
													<span style={{ cursor: activo ? "auto" : "not-allowed" }} className="checkmark"></span>
												</label>
											</td>
											<td className="text-left">{doc.NumFolio}</td>
											<td className="text-left">{moment(doc.Fecha_Creacion).format("DD/MM/YYYY")}</td>
											<td className="text-left">{doc.numero_registro}</td>
											<td className="text-left">{doc.RazonSocial}</td>
											<td className="text-left">{activo ? "Pagado" : "Pendiente"}</td>
											<td className="text-right">
												<NumberFormat value={doc.Total} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
										</tr>
									);
								})
							)}
						</tbody>
					</table>
				</div>
				<div className="justify-center align-center" style={{ height: 60 }}>
					{loadingTimbrado ?
						<div></div>
						: loadingSubmition ?
							<i className="fas fa-spinner fa-spin"></i>
							:
							<div className="row full justify-end">
								{selectedDocs.length > 0 &&
									<p style={{ paddingRight: 8 }}>
										{selectedDocs.length + " documento(s) a facturar"}
									</p>
								}
								<button className="btn btn-primary btn-small color-white" onClick={submitTimbrado}>
									&nbsp;Facturar
								</button>
							</div>
					}
				</div>
			</div>
		</div>
	);
};

export default Timbrado;
