import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import ReportsTypeTable from '../components/incidents/reportsTypeTable'
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
import { Consumer } from "../context";
const request = new Request();

class ConfigurationTypeReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reporteTipo: '',
			showModal: false,
			tipos: [],
			page: 1,
			filter: '',
			loadingSave: false,
			escritura: false,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			direction: {},
			filterColumn: {},
			submenu: ''
		}
		this.validarPermisos();
	}
	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
		//console.log(permisosInfo);

	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	changeFrac() {
		this.getType()

	}

	getTipos() {
		this.getType()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	nuevoTipo() {
		this.setState({ showModal: true });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(tipos) {
		tipos = tipos.filter(tip => {

			if (tip.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return tipos;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.tipos, this.state.direction[key]);

		this.setState({
			tipos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let tipos = [];

		if (Array.isArray(this.state.tipos)) {
			tipos = this.state.tipos;
			tipos = this.filter(tipos);
		}
		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header sidebar={true} active={'tipoR'} parent={'config'} panel={'panel3'} nav={"catalogos"} />
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls
											title={this.state.submenu}
											nuevo={this.openModal.bind(this, "addNewTypeReport")}
											dataSet={tipos}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											selectSub={false}
											newButton={this.state.escritura}
										/>
										{this.state.loading ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<ReportsTypeTable
												types={tipos}
												page={this.state.page}
												reload={this.getTipos.bind(this)}
												escritura={this.state.escritura}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
											/>
										}
										<div className="white-space-16" />
										<Paginador pages={Math.ceil(tipos.length / 7)} setPage={this.setPage.bind(this)} />
									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>

				<DinamicModal
					idModal={'addNewTypeReport'}
					sizeModalContainer={'small'}
					title={'Nuevo tipo de incidencia'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					closeModal={this.resetValue.bind(this)}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.renderNewTypeReport()}
				</DinamicModal>

			</div>
		);
	}
	componentDidUpdate() {
		// this.refs['input' + this.props.line ].focus();
	}

	renderNewTypeReport() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Tipo de incidencia:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="conceptName"
								id="incidenciaNameReport"
								value={this.state.reporteTipo}
								onChange={event => this.setState({ reporteTipo: event.target.value })}
								className="input input-modals"
								required
								autoFocus={true}
								maxLength="64"
								autoComplete='off'
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
	closeModal(idModal) {

		const modal = document.getElementById(idModal)
		if (modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	async openModal(idModal) {
		this.setState({ mesagge: null });


		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
			document.getElementById("incidenciaNameReport").focus();
		}

	}

	componentDidMount() {
		this.getTipos();
		this.getSubmenu();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ loadingSave: true });
		let data = {
			id: parseInt(localStorage.getItem('frac')),
			texto: this.state.reporteTipo

		}
		const response = await request.post("/incidencias/create/type/report", data)


		if (response.created) {
			this.setState({
				showModal: false
			});
			this.getType();
			this.setState({ reporteTipo: '' });
			this.closeModal('addNewTypeReport');
			cogoToast.success('Nuevo tipo de incidencia añadido.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({
				empty: true,
			});
			cogoToast.error('No se pudo crear nuevo tipo de incidencia.', {
				position: 'bottom-right',
			});
		}
		this.setState({ loadingSave: false });


	}

	async resetValue() {
		this.setState({ reporteTipo: '' });
	}

	async getType() {
		this.setState({ tipos: [], loading: true });
		let data = {
			id: parseInt(localStorage.getItem('frac'))
		}
		const response = await request.post("/incidencias/get/types/reports", data)


		if (response && !response.error) {
			if (response.tipos && !response.empty) {
				this.setState({
					tipos: response.tipos
				});
			} else {
				this.setState({
					empty: true,
					//message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				//message: response.message
			});
		}
		this.setState({ loading: false });


	}
}

export default Consumer(ConfigurationTypeReports);