import React, { useState, useEffect } from 'react';
import { Consumer } from "../../context";
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarAdministracion from "../../components/header/sidebarAdministracion";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Paginador from "../../components/paginador/paginador";
import Controls from '../../components/controls/controls';
import Permissions from '../../middlewares/Permissions';
import TurnosTable from '../../components/personalSeguridad/turnosTable';
import TurnoForm from '../../components/personalSeguridad/formTurno';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

const Turnos = ({ context }) => {
	const [logo, setLogo] = useState(require ("../../img/logo-AppMosphera-r.png"));
	const [lectura, setLectura] = useState(false);
	const [escritura, setEscritura] = useState(false);
	const [loadingPersonal, setloadingPersonal] = useState(false);
	const [loadingSave, setloadingSave] = useState(false);
	const [page, setPage] = useState(1);
	const [pageLength, setPageLength] = useState(20);
	const [turnos, setTurnos] = useState([]);
	const [filter, setFilter] = useState('');
	const [message, setMessage] = useState(false);
	const [turno, setTurno] = useState('');
	const [filterColumn, setFilterColumn] = useState({
		col: '',
		filt: ''
	});
	const [direccion, setDireccion] = useState({
		turno: 'desc'
	});
	const [submenu, setSubmenu] = useState('');

	useEffect(() => {
		validarPermisos();
	}, [])

	useEffect(() => {
		getSubmenu();
		getTurnos();
	}, [lectura])

	async function validarPermisos(){
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();

		setEscritura(permisosInfo.escritura);
		setLectura(permisosInfo.lectura);
	}

	async function getSubmenu() {
		const submenu = await context.getSubmenuAdminName();
		if (submenu) {
			setSubmenu(submenu);
		}
	}

	async function getTurnos() {
		setloadingPersonal(true);

		const response = await request.post("/personal/turnos/get", {id_fraccionamiento: localStorage.getItem('frac')});
		if(Array.isArray(response.turnos)) {
			setTurnos(response.turnos);
		} else {
			setTurnos([]);
		}

        setloadingPersonal(false);
	}

	async function paginatorChange( length ){
		setPageLength(length);
		setPage(1);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setloadingSave(true);
		const form = event.target;
		const data = {
			id_fraccionamiento: localStorage.getItem('frac'),
			turno: form.turno_title.value
		}

		const response = await request.post('/personal/turnos/create', data);

		if (response.created) {
			closeModal('modal-nuevo-turno');
            reload();
            cogoToast.success('Turno actualizado.', {
                position: 'bottom-right',
            });
        } else {
            cogoToast.error(response.message || 'No se pudo actualizar el turno.', {
                position: 'bottom-right',
            });
        }

		setTurno('');
		setloadingSave(false);
	}

	function openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	function closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}

		setTurno('');
	}

	function reload() {
        getTurnos();
	}

	function filterArray(array) {
		array = array.filter(res => {
			if (res.turno.toLowerCase().indexOf(filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return array;
	}

	function sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, turnos, direccion[key]);

		setDireccion({
			[key]: direction
		});
		setTurnos(turnos);
		setFilterColumn({
			col: key,
			filt: direction
		});
	}

	return (
		<div className="owners column">
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - {submenu}</title>
			</Helmet>
			<Header sidebar={true} active={'personalSeguridad'} parent={'personal'} nav={"administracion"} />
			<div className="row">
				<div className="column" id="sidebar">
					<SidebarAdministracion />
				</div>
				<div className="column" id="content">
					<div className="justify-center">
						<div className="container column">
							{lectura ?
							<React.Fragment>
								<Controls 
									title		=	{submenu}
									nuevo		=	{openModal.bind(this, 'modal-nuevo-turno')}
									dataSet		=	{filterArray(turnos)}
									setFilter	=	{setFilter.bind(this)}
									newButton   =   {true}
									excelButton =   {false}
								/>
							{loadingPersonal?
								<div className="row justify-center">
									<i className="fas fa-spinner fa-spin"></i>
								</div>
								:
								<TurnosTable 
									turnos		=	{filterArray(turnos)} 
									page		=	{page} 
									reload		=	{reload.bind(this)} 
									escritura	=	{escritura}
									pageLength	=   {pageLength}
									sortBy		=	{sortableTable.bind(this)}
									filterColumn=	{filterColumn}
									turno		=	{turno}
									setTurno	=	{setTurno.bind(this)}
								/>
							}
							<div className="white-space-16"></div>
							<Paginador 
								pages={Math.ceil(filterArray(turnos).length / pageLength)} 
								setPage={setPage.bind(this)} 
								pageLength = {pageLength} 
								paginatorChange = {paginatorChange.bind(this)} 
								showPageLenght = {true}/>
							<div className="white-space-16"></div>
							</React.Fragment>:
							null
							}
						</div>
					</div>
				</div>
			</div>
			<DinamicModal 
				idModal				=	{'modal-nuevo-turno'}
				sizeModalContainer	=	{'small'}
				title				=	{'Añadir turno'} 
				success				=	{handleSubmit.bind(this)} 
				showBtnSuccess		=	{true}
				btnTextSuccess		=	{"GUARDAR"}
				showBtnCancel		=	{true}
				btnTextCancel		=	{"CANCELAR"}
				loadingSave			=	{loadingSave}
				message				=	{message}
				closeModal			=	{closeModal.bind(this)}
			>
				<TurnoForm turnoInput={turno} setTurno={setTurno.bind(this)} />
			</DinamicModal>
		</div>
	)
}

export default Consumer(Turnos);
