/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Tablas de propietarios
 */

import React, { Component } from "react";
import { Consumer } from "../../context";
import PropietarioEditarModal from './propietariosEditar';
import Request from "../../core/httpClient";
import moment from "moment";
import ModalBorrar from '../modals/modalDelete';
import {parsePhoneNumber} from 'react-phone-number-input';
import cogoToast from 'cogo-toast';
import { AsYouType } from 'libphonenumber-js'

const request = new Request();


class OwnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
		dataPropietario: ''
    };
  }

  reload() { this.props.reload() }
  getNumberFormat(cell_phone){

	try {
		/*if(cell_phone.includes('+'))
			return new AsYouType('MXN').input(cell_phone);

		return new AsYouType('MXN').input('+'+cell_phone);*/
		return `******${cell_phone.substring(cell_phone.length - 4)}`

	} catch (error) {
		return null;
	}
}
  render() {
    let propietarios = [];
    if (this.props.data) {
      propietarios = this.props.data;
    }
    return (
		<div className="card-table card-owners column" id="card-owners">
			<table>
				<thead className="sticky-th">
				<tr className="text-left">
					<th className="name th-options-huge">
						<div className="row">
							Nombre &nbsp;
							<div className="align-center">
								{this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
									<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
									:
									<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
									:
									<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
								}
							</div>
						</div>
					</th>
					<th className="description th-options-huge">Teléfono</th>
					<th className="email th-options-huge">Email</th>
					<th className="vivienda text-start th-options-huge">
						<div className="row">
							Vivienda &nbsp;
							<div className="align-center">
								{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
									<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
									:
									<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
									:
									<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
								}
							</div>
						</div>
					</th>
					<th className="date text-center th-options-small">Editar</th>
					<th className="date text-center th-options-small">Eliminar</th>
				</tr>
				</thead>
				<tbody>
				{propietarios.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((propietario, key) => {
					return (
						<tr className={this.state.id_propietario_row === propietario.id_propietario ? 'row-active' : ''} key={key}>
						<td>
							{propietario.nombre}
						</td>
						<td className="">
							{this.getNumberFormat(propietario.telefono)}
						</td>
						<td className="">
							{propietario.email}
						</td>
						<td className="justify-start">
							{propietario.numero_registro}
						</td>
						<td>
							<button type="button" onClick={this.openModal.bind(this, 'editPropietario', propietario)} className="btn-full btn-mini btn-secondary color-white">
								<i className="font-small fas fa-pen" />
							</button>
						</td>
						<td>
							<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
									type		=	'button' 
									onClick		=	{this.openModalDelete.bind(this, propietario)} 
									style		=	{{ padding: '5px' }} 
									disabled	=	{!this.props.escritura}
							>
								<i className="font-text fas fa-trash-alt " />
							</button>
						</td>
					</tr>
					)
				})}
				</tbody>
			</table>
			<PropietarioEditarModal 
				reload			=	{this.reload.bind(this)} 
				propietario		=	{this.state.dataPropietario}
                escritura		=	{this.props.escritura}
                closeModal      =   {() => this.setState({dataPropietario: {}})}
				subLabel		=	{this.props.subLabel}
			/>

			<ModalBorrar
				id={this.state.id_propietario}
				delete={this.handleDelete.bind(this)}/>
		</div>
		);
  	}

	openModal(idModal,data) {
		let phone = '';
		if(data.telefono) {
			phone = parsePhoneNumber(`+${data.telefono}`);
			phone = phone.number;
		}
		
		this.setState({dataPropietario: {...data, telefono: phone}, id_propietario_row: data.id_propietario });
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		document.getElementsByName('fullName')[0].focus();
	}

  	openModalDelete(prop) {
			this.setState({ id_propietario: prop.id_propietario, id_propietario_row: prop.id_propietario});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
		this.setState({
			user: {
			idUsuario: user.id.id_usuario,
			idPerfil: user.id.id_perfil,
			idFraccionamiento: user.id.id_fraccionamiento
			}
		});
		}
	}

	handleOpen(idPersona) {
		this.setState({ showModal: true });
		this.getPropietario(idPersona);
	}
  
	async handledSubmit(event) {
		event.preventDefault();

		let city = document.getElementById('city').value;
		
		let data = {
			Nombre: 		this.state.pNombre,
			Direccion: 		this.state.pDireccion,
			Municipio: 		this.state.pMunicipio,
			Estado: 		this.state.pEstado,
			cp: 			this.state.pCp,
			Telefono: 		this.state.pTelefono,
			Pais: 			this.state.pPais,
			Genero: 		parseInt(this.state.pGenero),
			TipoId: 		parseInt(this.state.pId),
			NumId: 			this.state.pNumId,
			Vigencia: 		this.state.pVigencia,
			IdPersona: 		this.state.pIdPersona,
			email:			this.state.email,
			city:			city
		};

		const response = await request.post("/propietarios/update/propietario", data);


		if (response && !response.error) {
		if (response.updated && !response.empty) {
			this.setState({ showModal: false });
		} else {
				this.setState({
				empty: true,
				message: response.message,
				loadingPropietario: false
			});
		}
		} else {
		this.setState({
			error: true,
			message: response.message,
			loadingPropietario: false
		});
		}
		this.props.reloadOwners()
	}

	vivienda = data => {

		this.setState({
		pviv: data.numero_registro,
		pidViv: data.id_vivienda,
		searchHouse: false

		});

	}

	handleClose() {
		this.setState({ showModal: false, propietario: "", edit: false, email:'' });
	}

  	// traer un unico propietario
	async getPropietario(idPersona) {
		let data = { idPersona: idPersona };
		const response = await request.post("/viviendas/vivienda/get/propietario", data);

		if (response && !response.error) {
		if (response.propietarioVivienda && !response.empty) {

			this.setState({
				pNombre: 		response.propietarioVivienda[0].nombre,
				pDireccion: 	response.propietarioVivienda[0].direccion,
				pMunicipio: 	response.propietarioVivienda[0].municipio,
				pEstado: 		response.propietarioVivienda[0].estado,
				pCp: 			response.propietarioVivienda[0].cp,
				pTelefono: 		response.propietarioVivienda[0].telefono,
				pPais: 			response.propietarioVivienda[0].pais,
				pGenero: 		response.propietarioVivienda[0].genero,
				pId: 			response.propietarioVivienda[0].tipo_identificacion,
				pNumId: 		response.propietarioVivienda[0].num_identificacion,
				pVigencia: 		response.propietarioVivienda[0].vigencia_identificacion,
				pFechaCompra: 	moment(response.propietarioVivienda[0].fecha_compra).format("MM-DD-YYYY"), 
				pIdPersona: 	response.propietarioVivienda[0].id_persona,
				pviv: 			response.propietarioVivienda[0].numero_registro,
				email: 			response.propietarioVivienda[0].email,
				citye:			response.propietarioVivienda[0].ciudad,

			});
		} else {
			this.setState({
				empty: 		true,
				message: 	response.message
			});
		}
		} else {
		this.setState({
				error: 		true,
				message: 	response.message
		});
		}
	}

  	//traer Inqulino de la vivienda
	async getInquilino(idInquilino) {
		let data = {
			idVivienda: idInquilino
		};

		const response = await request.post("/viviendas/vivienda/inquilino", data);

		if (response && !response.error) {
		if (response.inquilinoVivienda && !response.empty) {
			this.setState({
			inquilino: response.inquilinoVivienda
			});
		} else {
			this.setState({
			empty: true,
			message: response.message
			});
		}
		} else {
		this.setState({
			error: true,
			message: response.message
			// loadingPropietario: false
		});
		}
	}

	handleEdit() {
		this.setState({ edit: true });
	}

	async handleDelete(id, handleClose) {

		let data = {
			 id_propietario: id,
			 id_usuario_del: localStorage.getItem("user_id")
			 }

		const response = await request.post("/viviendas/propietario/delete", data);

		if (response && !response.error) {
			if (response.deleted && !response.empty) {
				this.props.reload();
				handleClose();
				cogoToast.success('Propietario eliminado.', {
					position: 'bottom-right',
				});
			} else {
				this.setState({ empty: true, message: response.message });
				cogoToast.error('No se pudo eliminar propietario.', {
					position: 'bottom-right',
				});
			}
		} else {
			this.setState({ error: true, message: response.message });
			cogoToast.error('No se pudo eliminar propietario.', {
				position: 'bottom-right',
			});
		}
	}
}

export default Consumer(OwnersTable);
