import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";
import ModalBorrar from "../modals/modalDelete";

function ReceipmentTable(props) {
	const [prevProps, setPrevProps] = useState(props);
	const [receiptList, setReceiptList] = useState(props.receipts);
	const [receipment, setReceipment] = useState({});

	useEffect(() => {

		if (prevProps.setReceiptList !== props.setReceiptList) {
			setReceiptList(props.receipts);
		};

		setPrevProps(props);

	}, []);

	function openModalDelete(rec) {

		setReceipment(rec);

		let modal = document.getElementById("deleteModal");

		if (modal) {
			modal.classList.remove("hide-modal");
			modal.classList.add("show-modal");
		};
	};

	function closeModalDelete() {

		let modal = document.getElementById("deleteModal");

		if (modal) {
			modal.classList.add("hide-modal");
			modal.classList.remove("show-modal");
		};
	};

	function deletePayment() {
		props.cancelReceipment(receipment);
		closeModalDelete();
	};

	return (
		<div className="card-table card-owners column" id="card-owners">
			<div className="row">
				<table className="full">
					<thead className="sticky-th">
						<tr className="text-center">
							<th className="text-start">
								<div className="row">
									Vivienda &nbsp;
									<div className="align-center">
										{props.filterColumn.col === "Unidad" ?
											props.filterColumn.filt === "asc" ?
												<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, "Unidad")}></i>
												:
												<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, "Unidad")}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, "Unidad")}></i>
										}
									</div>
								</div>
							</th>
							<th className="text-start">
								<div className="row">
									Fecha aplicado &nbsp;
									<div className="align-center">
										{props.filterColumn.col === "Fecha_recibo" ?
											props.filterColumn.filt === "asc" ?
												<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, "Fecha_recibo")}></i>
												:
												<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, "Fecha_recibo")}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, "Fecha_recibo")}></i>
										}
									</div>
								</div>
							</th>
							<th className="text-center">
								<div className="row">
									Importe &nbsp;
									<div className="align-center">
										{props.filterColumn.col === "Total_Cobrado" ?
											props.filterColumn.filt === "asc" ?
												<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, "Total_Cobrado")}></i>
												:
												<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, "Total_Cobrado")}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, "Total_Cobrado")}></i>
										}
									</div>
								</div>
							</th>
							<th className="text-start align-start">Forma de pago</th>
							<th className="text-center">Cancelar</th>
							<th className="text-center">Ver</th>
						</tr>
					</thead>
					<tbody>
						{receiptList.length === 0 ?
							<tr>
								<td className="text-center" colSpan="6">
									Sin datos que mostrar.
								</td>
							</tr>
							:
							receiptList
								.filter((dc) => dc.Unidad.toLowerCase().includes(props.filter) || dc.Propietario.toLowerCase().includes(props.filter))
								.slice((props.page - 1) * props.pageLength, props.page * props.pageLength)
								.map((rec, key) => (
									<tr key={key} className={props.idReceipment === rec.Id_Recibo ? 'row-active' : ''}>
										<td className="text-start">{rec.Unidad} </td>
										<td className="text-start">{moment(rec.Fecha_recibo).format("DD/MM/YYYY")}</td>
										<td className="text-start">
											<NumberFormat
												value={rec.Total_Cobrado}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</td>
										<td className="text-start">{rec.FormaPago} </td>
										<td className="text-center">
											<button
												className="btn-full justify-center btn-mini btn-secondary color-white"
												type="button"
												onClick={() => openModalDelete(rec)}
												style={{ padding: "5px" }}
												disabled={!props.escritura}
											>
												<i className="font-text fas fa-trash-alt " />
											</button>
										</td>
										<td className="text-center">
											<button
												className="btn-full btn-mini btn-secondary color-white"
												type="button "
												onClick={(event) => { props.openToEdit(rec); }}
											>
												<i className="fas fa-eye"></i>
											</button>
										</td>
									</tr>

								))
						}
					</tbody>
				</table>
				<ModalBorrar delete={deletePayment} />
			</div>
		</div>
	);
}
export default ReceipmentTable;