import React from 'react';
import { Helmet } from "react-helmet";
import Header from '../../components/header/header';
import SidebarOperacion from '../../components/header/sidebarOperacion';
import { Consumer } from "../../context";
import TablaActivas from '../../components/invitaciones/tablaActivas';
import Controls from '../../components/controls/controls';
import Paginador from "../../components/paginador/paginador";
import Request from "../../core/httpClient";
import Permissions from '../../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import Core from '../../components/core/SuperCore';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import sortableData from '../../helpers/sortableDataTable';
/**Socket */
import Socket from '../../core/socket';

registerLocale('es', es)

const request = new Request();


class Activas extends Core {

    constructor(props){
        super(props);
        this.state = {
            lectura:    false,
            escritura:  false,
            logo:		require ("../../img/logo-AppMosphera-r.png"),
            filter:     '',

            reservas:                       [],
            subfraccionamientos:        [],
            id_subfraccionamiento:      localStorage.getItem('subfrac'),
            id_unidad:                  0,
            unidades:                   [],
            areas:                      [],
            id_area:                    0,
            fecha:                      null,
            min_day:                    null,
            horarios:                   [],
            usuario:                    {},
            fianza:                     null,
			comentarios:                '',
			pageLength:					20,
			direction: {
				fecha_entrada: 'desc',
				tipo: 'desc',
				invitado: 'desc',
				vivienda: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: ''
        }
        this.validarPermisos();
    }

    async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura,lectura: permisosInfo.lectura});
	}

    setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

    async subChange(sub) {	
        await this.setState({id_subfraccionamiento: sub});
        await this.getInvitaciones();
    }

    setPage(page) {
		this.setState({page});
	}

	
    async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
    }

    async componentDidMount(){
		this.getSubmenu();
        await this.getInvitaciones();

        // obtener info del usuario
		let user = await this.getUserInfo();
		await this.setState({usuario: user.info});
		
		Socket.on('newInvitacion', (data) => {
			this.getInvitaciones();
		});
    }

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuOpeName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

    async getSubfraccionamientos() {
        const fraccionamiento = localStorage.getItem('frac');
        if(!fraccionamiento) {
			return;
        }
        let data = { Idsub: fraccionamiento, filter:1 }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

        if(response.error || response.empty){
            await this.setState({subfraccionamientos: []});
            return;
        }

        if(response.subfraccionamiento){
            await this.setState({
                subfraccionamientos: response.subfraccionamiento,
                id_subfraccionamiento: response.subfraccionamiento[0].id_subfraccionamiento
            });
        }
    }

    async changeSubfraccionamiento(event){
        let id_sub = event.target.value;
        await this.setState({invitaciones: []});
        await this.setState({id_subfraccionamiento: id_sub})
        await this.getInvitaciones();
    }

    async getInvitaciones() {
        await this.setState({loading: true, invitaciones:[]})
        let data = {
			idFrac:     localStorage.getItem('frac'),
			idSub:  	this.state.id_subfraccionamiento
        }

        const response = await request.post("/invitaciones/get/activas", data);
        
        if(response.empty || response.error){
            await this.setState({invitaciones: []});
            cogoToast.info('No se encontraron invitaciones.',{
				position: 'bottom-right',
            });
        }

        if(response.invitaciones){
            await this.setState({invitaciones: response.invitaciones});
		}
		await this.setState({loading: false})
    }

	filter(invitaciones) {
		return invitaciones.filter(inv => {
			return inv.invitado.toLowerCase().includes(this.state.filter) || 
				   inv.vivienda.toLowerCase().includes(this.state.filter) ||
				   (inv.placas && inv.placas.toLowerCase().includes(this.state.filter));
		});
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.invitaciones, this.state.direction[key]);

		this.setState({
			invitaciones: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

    render() {
        return this.renderReservationPage();
    }

    renderReservationPage() {
		let invitaciones = [];
		if (Array.isArray(this.state.invitaciones)) {
			invitaciones = this.filter(this.state.invitaciones);
		}
        return ( 
            <div className="houses column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header 
					sidebar		=	{true} 
					active		=	{'activas'} 
					parent		=	{'Seguridad'} 
					panel		=	{'panel3'} 
					nav			=	{"operacion"} 
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura?
								<React.Fragment>
								<Controls title={this.state.submenu}
									dataSet		=	{invitaciones}
									dateFormat 	= 	'dd/mm/yyyy HH:mm:ss'
									setFilter	=	{this.setFilter.bind(this)}
									subChange	=	{this.subChange.bind(this)}
									newButton	=	{false}
								/>
								{this.state.loading?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<TablaActivas 
										invitaciones	=	{invitaciones} 
										page			=	{this.state.page} 
										user        	=   {this.state.usuario}
										reload			=	{this.getInvitaciones.bind(this)} 
										escritura		=	{this.state.escritura}
										pageLength		=	{this.state.pageLength} 
										sortBy			=	{this.sortableTable.bind(this)}
										filterColumn	=	{this.state.filterColumn}
									/>
								}
								<div className="white-space-16"></div>
								<Paginador 
									pages		=	{Math.ceil(invitaciones.length / this.state.pageLength)} 
									setPage		=	{this.setPage.bind(this)}
									pageLength = {this.state.pageLength} 
                                    paginatorChange = {this.paginatorChange.bind(this)} 
                                    showPageLenght = {true}
								/>
							
								</React.Fragment>:
								null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
} //end Class

export default Consumer(Activas);