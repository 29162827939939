import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarOperacion from "../../components/header/sidebarOperacion";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Paginador from "../../components/paginador/paginador";
import Controls from '../../components/controls/controls';
import Permissions from '../../middlewares/Permissions';
import PaqueteriaTable from '../../components/paqueteria/paqueteriaTable';
import PaqueteForm from '../../components/paqueteria/formLlegada';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Request from '../../core/httpClient';
import { Consumer } from '../../context';
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

const Paqueteria = ({ context }) => {

	const [logo, setLogo] = useState(require("../../img/logo-AppMosphera-r.png"));
	const [lectura, setLectura] = useState(false);
	const [escritura, setEscritura] = useState(false);
	const [loadingPaqueteria, setloadingPaqueteria] = useState(false);
	const [loadingSave, setloadingSave] = useState(false);
	const [page, setPage] = useState(1);
	const [pageLength, setPageLength] = useState(20);
	const [paquetes, setPaquetes] = useState([]);
	const [filter, setFilter] = useState('');
	const [viviendas, setViviendas] = useState([]);
	const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [submenu, setSubmenu] = useState('');
	const [filterColumn, setFilterColumn] = useState({});
	const [direccion, setDireccion] = useState({});

	useEffect(() => {

		(async () => {
			await getSubmenu();
			await validarPermisos();
		})();

	}, []);

	useEffect(() => {

		(async () => {
			await getPaquetes();
			await loadViviendas();
		})();

	}, [lectura]);

	useEffect(() => {
		if (!loadingPaqueteria) {
			stickyTablePosition('header', 'controls', 'second_control', 'table_paqueteria');
		};
	}, [loadingPaqueteria]);

	const stickyTablePosition = (headerId, controlsId, secondControlsId, tableId) => {
		const header_Heigh = document.getElementById(headerId);
		const height_controls = document.getElementById(controlsId);
		const second_controls = document.getElementById(secondControlsId);

		const table_height = document.getElementById(tableId);

		let limit_sticy_top = header_Heigh && height_controls ? header_Heigh.offsetHeight + height_controls.offsetHeight : 226;

		if (second_controls) {
			second_controls.style.position = 'sticky';
			second_controls.style.top = `${limit_sticy_top}px`;

			limit_sticy_top += second_controls.offsetHeight;
		};

		if (table_height) {
			table_height.style.position = 'sticky';
			table_height.style.top = `${limit_sticy_top}px`;
		};
	};

	async function getSubmenu() {
		const submenu = await context.getSubmenuOpeName();
		if (submenu) {
			setSubmenu(submenu);
		};
	};

	async function validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();

		setEscritura(permisosInfo.escritura);
		setLectura(permisosInfo.lectura);
	};

	async function getPaquetes() {

		try {

			setloadingPaqueteria(true);

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
				inicio: startDate,
				fin: endDate
			};

			const response = await request.post("/paqueteria/get", data);

			if (Array.isArray(response.paqueteria)) {
				setPaquetes(response.paqueteria);
			} else {
				setPaquetes([]);
			};

			setloadingPaqueteria(false);
		} catch (error) {
			console.log(error);
		};
	};

	async function loadViviendas() {
		try {
			const frac = localStorage.getItem("frac");
			const dataVivienda = { id_fraccionamiento: frac, id_subfraccionamiento: 0 };

			const responseViviendas = await request.post("/viviendas/getViviendaBySubFraccionamiento", dataVivienda);

			if (responseViviendas && !responseViviendas.error) {
				if (!responseViviendas.empty) {
					setViviendas(responseViviendas.viviendas);
				};
			};

		} catch (error) {
			console.log(error);
		};
	};

	async function paginatorChange(length) {
		setPageLength(length);
		setPage(1);
	};

	function openModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
	};

	function closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		};
	};

	async function reload() {
		await getPaquetes();
	};

	function filterArray(array) {
		array = array.filter(res => {
			if (res.numero_registro.toLowerCase().indexOf(filter) !== -1) {
				return true;
			} else {
				return false;
			};
		});
		return array;
	};

	async function handleSubmit(event) {

		event.preventDefault();

		try {

			setloadingSave(true);

			const form = event.target;
			const foto_paquete = form.paquete_foto_llegada;

			let file_paquete = null;

			if (foto_paquete.files) {

				file_paquete = foto_paquete.files[0];

				if (!file_paquete) {

					cogoToast.warn('Se debe proporcionar una foto del paquete.', { position: 'bottom-right' });

					setloadingSave(false);

					return;
				};
			};

			const user = await context.loadUser();

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
				id_vivienda: form["paquete-id_vivienda"].value,
				empresa: form.paquete_empresa.value,
				residente: form.paquete_nombre_residente.value,
				fecha_llegada: moment(form.paquete_fecha_llegada.value).format(),
				id_usuario: user.id.id_usuario,
				comentarios: form.paquete_comentarios.value,
			};

			const response = await request.new_postFile('/paqueteria/create', [file_paquete], data);

			if (response.created) {

				closeModal('modal-nuevo-paquete');

				reload();

				cogoToast.success('Paquete creado.', { position: 'bottom-right', });

			} else {

				cogoToast.error(response.message || 'No se pudo crear el paquete.', { position: 'bottom-right', });
			};

			setloadingSave(false);

		} catch (error) {
			console.log(error);
		};
	};

	function sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, paquetes, direccion[key]);

		setDireccion({ [key]: direction });
		setPaquetes(paquetes);
		setFilterColumn({ col: key, filt: direction });
	};

	return (
		<div className="paqueteria column">
			<Helmet>
				<title>{process.env.REACT_APP_NAME} - {submenu}</title>
			</Helmet>
			<Header id='header' sidebar={true} active={'personalSeguridad'} parent={'personal'} nav={"operacion"} />
			<div className="row">
				<div className="column" id="sidebar">
					<SidebarOperacion />
				</div>
				<div className="column" id="content">
					<div className="justify-center">
						<div className="container column">
							{lectura ?
								<React.Fragment>
									<Controls
										id='controls'
										title={submenu}
										nuevo={openModal.bind(this, 'modal-nuevo-paquete')}
										dataSet={filterArray(paquetes)}
										setFilter={setFilter.bind(this)}
										newButton={true}
									/>
									<div id='second_control' className="row-responsive full" style={{ backgroundColor: 'white' }} >
										<div className="data-from column full">
											<h5 className="color-black">DESDE:</h5>
											<input
												type="date"
												id="startdate"
												name="startdate"
												className="input input-text"
												placeholder="Desde"
												value={startDate}
												onChange={(event) => setStartDate(event.target.value)}
											/>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column full">
											<h5 className="color-black">HASTA:</h5>
											<input
												type="date"
												id="enddate"
												name="enddate"
												className="input input-text"
												placeholder="Hasta"
												value={endDate}
												onChange={(event) => setEndDate(event.target.value)}
											/>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column justify-end" style={{ maxWidth: '80px' }} onClick={getPaquetes.bind(this)}>
											<div className="white-space-16" />
											<button type="button" className="btn btn-primary btn-small color-white">
												<i className="fas fa-search"></i>
											</button>
										</div>
									</div>
									{loadingPaqueteria ?
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
										:
										<PaqueteriaTable
											id='table_paqueteria'
											paquetes={filterArray(paquetes)}
											page={page}
											reload={reload.bind(this)}
											escritura={escritura}
											pageLength={pageLength}
											viviendas={viviendas || []}
											sortBy={sortableTable.bind(this)}
											filterColumn={filterColumn}
										/>
									}
									<div className="white-space-16"></div>
									<Paginador
										pages={Math.ceil(filterArray(paquetes).length / pageLength)}
										setPage={setPage.bind(this)}
										pageLength={pageLength}
										paginatorChange={paginatorChange.bind(this)}
										showPageLenght={true} />
									<div className="white-space-16"></div>
								</React.Fragment>
								:
								null
							}
						</div>
					</div>
				</div>
			</div>
			<DinamicModal
				idModal={'modal-nuevo-paquete'}
				sizeModalContainer={'big'}
				title={'Llegada paquete'}
				success={handleSubmit.bind(this)}
				showBtnSuccess={true}
				btnTextSuccess={"GUARDAR"}
				showBtnCancel={true}
				btnTextCancel={"CANCELAR"}
				loadingSave={loadingSave}
				closeModal={closeModal.bind(this)}
			>
				<PaqueteForm
					viviendas={viviendas || []}
				/>
			</DinamicModal>
		</div>
	);
};

export default Consumer(Paqueteria);